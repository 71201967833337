import "@formatjs/intl-pluralrules/polyfill";
import "@formatjs/intl-pluralrules/locale-data/pt"; // Add locale data for pt

import "@formatjs/intl-relativetimeformat/polyfill";
import "@formatjs/intl-relativetimeformat/locale-data/pt"; // Add locale data for pt
import ptMessages from "../locales/pt_PT.json";

const ptLang = {
    messages: {
        ...ptMessages,
    },
    locale: "pt-PT",
};

export default ptLang;
