module.exports = {
    phoneConverter: function (num) {
        let phone = num;
        phone = phone.replace(/(\d{3})(\d{3})(\d+)/, "($1) $2-$3");
        return phone;
    },
    hexToRGBA(hex, a = 1) {
        const r = parseInt(hex.slice(1, 3), 16);
        const g = parseInt(hex.slice(3, 5), 16);
        const b = parseInt(hex.slice(5, 7), 16);
        return `rgba(${r}, ${g}, ${b}, ${a})`;
    },
    validMail(mail) {
        return /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()\.,;\s@\"]+\.{0,1})+([^<>()\.,;:\s@\"]{2,}|[\d\.]+))$/.test(
            mail
        );
    },
    validNumber(e) {
        const re = /^[0-9\b]+$/;
        if (e.target.value === "" || re.test(e.target.value)) {
            return true;
        } else {
            return false;
        }
    },
    validNegPosNumber(e) {
        const re = /^\-?\d*$/;
        if (e.target.value === "" || re.test(e.target.value)) {
            return true;
        } else {
            return false;
        }
    },
    validBirthDate(data) {
        let result = `${data.slice(0, 2)}/${data.slice(2, 4)}/${data.slice(4)}`;
        return result;
    },
    filterList(list, type, searchValue) {
        let newList = list.filter((obj) =>
            Object.keys(obj).some((key) => {
                if (type.includes(key)) {
                    return JSON.stringify(obj[key])
                        .toLowerCase()
                        .includes(searchValue.toLowerCase());
                }
            })
        );
        newList = searchValue != "" ? newList : list;
        return newList;
    },
    sortList(order, list, property) {
        const collator = new Intl.Collator(undefined, {
            numeric: true,
            sensitivity: "base",
        });
        list.sort((a, b) =>
            collator.compare(
                order ? b[property] : a[property],
                order ? a[property] : b[property]
            )
        );
        return list;
    },
    setWithExpiry(key, value, ttl) {
        const item = {
            value: value,
            expiry: new Date().getTime() + ttl
        };
        localStorage.setItem(key, JSON.stringify(item));
    },
    getWithExpiry(key) {
        const itemString = window.localStorage.getItem(key);
        if (!itemString) return null;
        
        const item = JSON.parse(itemString);
        const isExpired = new Date().getTime() > item.expiry;
        
        if (isExpired) {
            localStorage.removeItem(key);
            return null;
        }
        
        return item.value;
    },
};
