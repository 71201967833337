import "@formatjs/intl-pluralrules/polyfill";
import "@formatjs/intl-pluralrules/locale-data/ko"; // Add locale data for ko

import "@formatjs/intl-relativetimeformat/polyfill";
import "@formatjs/intl-relativetimeformat/locale-data/ko"; // Add locale data for ko
import koMessages from "../locales/ko_KR.json";

const koLang = {
    messages: {
        ...koMessages,
    },
    locale: "ko",
};

export default koLang;
