import React from "react";
import { connect } from "react-redux";
import { fadeInDown } from "react-animations";
import { StyleSheet, css } from "aphrodite";
import { injectIntl } from "react-intl";
import { setSidebar } from "actions/App";

class MenuHeader extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { alt, src, theme, height, padding, background } = this.props;
        this.styles = styles(theme, height, padding, background);
        return (
            <div
                className={css(
                    this.styles.imageWrapper,
                    this.styles.fadeInDown
                )}
            >
                <img alt={alt} src={src} className={css(this.styles.image)} />
            </div>
        );
    }
}

MenuHeader.defaultProps = {
    padding: "0px 20px",
    height: 61,
    width: "100%",
    alt: "Smart Loto",
    src: "assets/images/Themes/Demo/logo.png",
};

const mapStateToProps = ({ app }) => {
    const { theme, hideSidebar } = app;
    return { theme, hideSidebar };
};

export default injectIntl(connect(mapStateToProps, { setSidebar })(MenuHeader));

const styles = (theme, height, padding, background) =>
    StyleSheet.create({
        imageWrapper: {
            // width: "100%",
            padding,
            height,
            backgroundColor: background,
            borderBottom: `solid 1px ${theme.color.borderColor}`,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
        },
        image: {
            display: "block",
            height: height - 20,
            marginLeft: 10,
        },
        fadeInDown: {
            animationName: fadeInDown,
            animationDuration: "1.2s",
        },
    });
