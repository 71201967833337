import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Drawer from "rmc-drawer";
import { fadeInLeft } from "react-animations";
import { StyleSheet, css } from "aphrodite";

import {
    COLLAPSED_DRAWER,
    FIXED_DRAWER,
    HORIZONTAL_NAVIGATION,
} from "constants/ActionTypes";
import { toggleCollapsedNav, updateWindowWidth } from "actions/App";

import MenuList from "components/Menus/sidebar/list";
import MenuFooter from "components/Menus/sidebar/footer";
import MenuHeader from "components/Menus/sidebar/header";

class Menu extends React.PureComponent {
    constructor(props) {
        super(props);
    }
    onToggleCollapsedNav = (e) => {
        const val = !this.props.navCollapsed;
        this.props.toggleCollapsedNav(val);
    };
    render() {
        const {
            navCollapsed,
            drawerType,
            width,
            isDirectionRTL,
            navigationStyle,
            theme,
            authUser,
            hideSidebar,
        } = this.props;
        this.styles = styles(theme);
        let drawerStyle = drawerType.includes(FIXED_DRAWER)
            ? "d-xl-flex"
            : drawerType.includes(COLLAPSED_DRAWER)
            ? ""
            : "d-flex";
        let type = true;
        const backgroundCol = (authUser.ClientId === "SCH20201123c6dfa96b2deba2a83cFRA" // Snyder Electric
                            || authUser.ClientId === "SCH20211109864171908742010805IND" // Snyder Electric
                            || authUser.ClientId === "SCH20210629219631eba90a80000fFRA") // Snyder Electric
                            ?
                            "#45d26b"
                            :
                            (authUser.ClientId === "NVR2021060497871519642010a80fCAD") // NouvLR
                            ?
                            "#07284f"
                            :
                            theme.color.headerColor;
        const compLogo = (authUser.ClientId === "SCH20201123c6dfa96b2deba2a83cFRA" // Snyder Electric
                        || authUser.ClientId === "SCH20211109864171908742010805IND" // Snyder Electric
                        || authUser.ClientId === "SCH20210629219631eba90a80000fFRA") // Snyder Electric
                        ?
                        "assets/images/Themes/Demo/SELogo.png"
                        :
                        (authUser.ClientId === "81d3d981dcb4493aa14752c79d605b4a") // Queensland Rail
                        ?
                        "assets/images/Themes/Demo/QRLogo.jpg"
                        :
                        (authUser.ClientId === "NVR2021060497871519642010a80fCAD") // NouvLR
                        ?
                        "assets/images/Themes/Demo/nouvlr-logo-white-top.png"
                        :
                        (authUser.ClientId === "c9845ac3adda44fbbb1b54faad08d37d") // Disney
                        ?
                        "assets/images/Themes/Demo/Disney.png"
                        :
                        (authUser.ClientId === "MAH202004220f651b98a3ae54a800IND") // Mahindra
                        ?
                        "assets/images/Themes/Demo/Mahindra.jpg"
                        :
                        theme.logo;
        if (
            drawerType.includes(COLLAPSED_DRAWER) ||
            (drawerType.includes(FIXED_DRAWER) && width < 1200)
        ) {
            type = false;
        }
        if (navigationStyle === HORIZONTAL_NAVIGATION) {
            drawerStyle = "";
            type = false;
        }
        return (
            <Drawer
                docked={type}
                className={
                    !hideSidebar ? `app-sidebar` && drawerStyle : undefined
                }
                style={{ overflow: "auto", userSelect: 'none' }}
                touch={true}
                position={isDirectionRTL ? "right" : "left"}
                transitions={true}
                enableDragHandle={true}
                open={navCollapsed}
                onOpenChange={this.onToggleCollapsedNav}
                sidebarStyle={!navCollapsed && !hideSidebar ? {transform: "translateX(0%)"}: {}}
                sidebar={
                    <div
                        className={
                            !hideSidebar
                                ? `side-nav ${css(this.styles.wrapper)}`
                                : undefined
                        }
                    >
                        <MenuHeader alt={theme.title} src={compLogo} background={backgroundCol} />
                        <div
                            className={css(this.styles.fadeInLeft)}
                            style={{
                                height: "calc(100vh - 181px)",
                                overflow: "auto",
                            }}
                        >
                            <MenuList authUser={authUser} />
                        </div>
                        <MenuFooter src={theme.footer} />
                    </div>
                }
            >
                <div />
            </Drawer>
        );
    }
}

const mapStateToProps = ({ app, auth }) => {
    const { authUser } = auth;
    const {
        navCollapsed,
        drawerType,
        width,
        isDirectionRTL,
        navigationStyle,
        theme,
        hideSidebar,
    } = app;
    return {
        navCollapsed,
        drawerType,
        width,
        isDirectionRTL,
        navigationStyle,
        theme,
        authUser,
        hideSidebar,
    };
};

export default withRouter(
    connect(mapStateToProps, { toggleCollapsedNav, updateWindowWidth })(Menu)
);

const styles = (theme) =>
    StyleSheet.create({
        wrapper: {
            backgroundColor: theme.color.menuColor,
            borderRight: "none",
        },
        fadeInLeft: {
            animationName: fadeInLeft,
            animationDuration: "1.2s",
        },
    });
