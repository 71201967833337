import React from "react";
import { connect } from "react-redux";
import { StyleSheet, css } from "aphrodite";
import { Input, Row, Col } from "reactstrap";
import { injectIntl } from "react-intl";
import moment from "moment";

import { setLoader } from "actions/App";
import { searchActivity, setActivitySearchTerms } from "actions/Help";
import { fetchSitesList } from "actions/Settings";
import { searchMachines, fetchJobsByMachineId } from "actions/Machines";
import { fetchLocksSearchSelect } from "actions/Locks";
import { searchUsers } from "actions/Users";

import Tooltip from "components/Tooltips";
import CSV from "components/Export";

class SearchActivity extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchActivityTerms: props.searchActivityTerms,
            lockSearchText: "",
            selectingLock: false,
        };
    }
    componentDidMount() {
        const { fetchLocksSearchSelect, setLoader, fetchSitesList } =
            this.props;
        fetchSitesList();
        fetchLocksSearchSelect();
        setLoader(false);
    }
    handleSearch = () => {
        const { searchActivity, setLoader } = this.props;
        let { searchActivityTerms } = this.state;
        searchActivity(searchActivityTerms);
        setLoader(false);
    };
    keyPress = (e) => {
        if (e.keyCode === 13) {
            this.handleSearch();
        }
    };
    updateData = (e) => {
        const {
            setActivitySearchTerms,
            searchMachines,
            searchedMachines,
            fetchJobsByMachineId,
            authUser,
        } = this.props;
        let updateData = { ...this.state.searchActivityTerms };
        if (
            e.target.name === "SiteId" &&
            e.target.value !== "" &&
            authUser.Settings.Product === "isolation"
        ) {
            searchMachines({
                Name: "",
                SiteId: e.target.value,
                TemplateId: "",
                RequireLockConnect: "",
                RequireLockConnectAic: "",
                RequireAicOnTicket: "",
                RequirePointImage: "",
                LocksRequired: "",
                Note: "",
                IsActive: "",
            });
            updateData.MachineId = "";
            updateData.JobId = "";
        }
        if (e.target.name === "SiteId" && e.target.value === "") {
            updateData.MachineId = "";
            updateData.JobId = "";
        }
        if (e.target.name === "MachineId" && e.target.value !== "") {
            let templateId = searchedMachines.filter(
                (m) => m.Id == e.target.value
            )[0].Id;
            fetchJobsByMachineId(templateId);
            updateData.JobId = "";
        }
        if (e.target.name === "MachineId" && e.target.value === "") {
            updateData.JobId = "";
        }
        updateData[e.target.name] = e.target.value;
        setActivitySearchTerms(updateData);
        this.setState({ searchActivityTerms: updateData, changesMade: true });
    };
    clearField = (target) => {
        const { setActivitySearchTerms } = this.props;
        let updateData = { ...this.state.searchActivityTerms };
        updateData[target] = "";
        setActivitySearchTerms(updateData);
        this.setState({ searchActivityTerms: updateData, changesMade: true });
    };
    clearFields = () => {
        const { setActivitySearchTerms } = this.props;
        let newSearchActivityTerms = {
            Activity: "",
            WorkOrderId: 0,
            UserName: "",
            SiteId: "",
            MachineId: "",
            JobId: "",
            StartDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
            EndDate: moment().format("YYYY-MM-DD"),
        };
        setActivitySearchTerms(newSearchActivityTerms);
        this.setState({
            searchActivityTerms: newSearchActivityTerms,
            lockSearchText: "",
        });
        this.props.searchActivity(newSearchActivityTerms);
    };
    render() {
        let { searchActivityTerms } = this.state;
        const {
            intl,
            width,
            activity,
            sitesList,
            searchedMachines,
            machineJobs,
            authUser,
            theme,
        } = this.props;
        this.styles = styles(theme);
        let exportList = activity
            ? activity.map((a) => Object.assign({}, a))
            : [];
        exportList.map((item) => {
            delete item.Icon;
            delete item.Id;
            return item;
        });
        return (
            searchActivityTerms && (
                <div>
                    <div
                        className={css(this.styles.searchWrapper)}
                        onKeyDown={(e) => this.keyPress(e)}
                    >
                        <Col lg={12}>
                            {width < 992 && (
                                <Row>
                                    <Col
                                        lg={12}
                                        className={css(this.styles.flexRow)}
                                    >
                                        <div>
                                            <i
                                                id="searchActivity"
                                                className={`material-icons ${css(
                                                    this.styles.searchIcon
                                                )}`}
                                                onClick={() =>
                                                    this.handleSearch()
                                                }
                                            >
                                                search
                                            </i>
                                            <Tooltip
                                                message={"comp.search.default"}
                                                target="searchActivity"
                                            />
                                        </div>
                                        <div>
                                            <i
                                                id="resetActivitySearch"
                                                className={`material-icons ${css(
                                                    this.styles.searchIcon
                                                )}`}
                                                onClick={() =>
                                                    this.clearFields()
                                                }
                                            >
                                                low_priority
                                            </i>
                                            <Tooltip
                                                message={
                                                    "comp.resetSearch.default"
                                                }
                                                target="resetActivitySearch"
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            )}

                            <Row>
                                <Col
                                    lg={10}
                                    className={css(this.styles.flexRow)}
                                >
                                    {width > 800 && (
                                        <Col
                                            lg={3}
                                            className={css(this.styles.flexRow)}
                                        >
                                            <label
                                                className={css(
                                                    this.styles.labelDropdown
                                                )}
                                            >
                                                {this.props.intl.formatMessage({
                                                    id: "comp.activity.Header",
                                                })}
                                            </label>
                                            <Input
                                                className={css(
                                                    this.styles.inputText
                                                )}
                                                name="Activity"
                                                type="text"
                                                onChange={this.updateData}
                                                value={
                                                    searchActivityTerms.Activity ||
                                                    ""
                                                }
                                            ></Input>
                                        </Col>
                                    )}
                                    {width > 800 && (
                                        <Col
                                            lg={3}
                                            className={css(this.styles.flexRow)}
                                        >
                                            <label
                                                className={css(
                                                    this.styles.labelDropdown
                                                )}
                                            >
                                                {this.props.intl.formatMessage({
                                                    id: "comp.tickets.Header",
                                                })}
                                            </label>
                                            <Input
                                                className={css(
                                                    this.styles.inputText
                                                )}
                                                name="WorkOrderId"
                                                type="text"
                                                onChange={this.updateData}
                                                value={
                                                    searchActivityTerms.WorkOrderId ||
                                                    ""
                                                }
                                            ></Input>
                                        </Col>
                                    )}
                                    
                                    {width > 800 && (
                                        <Col
                                            lg={3}
                                            className={css(this.styles.flexRow)}
                                        >
                                            <label
                                                className={css(
                                                    this.styles.labelDropdown
                                                )}
                                            >
                                                {this.props.intl.formatMessage({
                                                    id: "form.start",
                                                })}
                                            </label>
                                            <Input
                                                onChange={this.updateData}
                                                type="date"
                                                name="StartDate"
                                                id="StartDate"
                                                placeholder="date placeholder"
                                                className={css(
                                                    this.styles.inputSelect,
                                                    this.styles.dateInput
                                                )}
                                                value={
                                                    searchActivityTerms.StartDate !==
                                                    ""
                                                        ? moment
                                                              .utc(
                                                                  searchActivityTerms.StartDate
                                                              )
                                                              .format(
                                                                  "YYYY-MM-DD"
                                                              )
                                                        : ""
                                                }
                                            />
                                        </Col>
                                    )}
                                    {width > 800 && (
                                        <Col
                                            lg={3}
                                            className={css(this.styles.flexRow)}
                                        >
                                            <label
                                                className={css(
                                                    this.styles.labelDropdown
                                                )}
                                            >
                                                {this.props.intl.formatMessage({
                                                    id: "form.end",
                                                })}
                                            </label>
                                            <Input
                                                onChange={this.updateData}
                                                type="date"
                                                name="EndDate"
                                                id="EndDate"
                                                placeholder="date placeholder"
                                                className={css(
                                                    this.styles.inputSelect,
                                                    this.styles.dateInput,
                                                    this.styles.secondDateInput
                                                )}
                                                value={
                                                    searchActivityTerms.EndDate !==
                                                    ""
                                                        ? moment
                                                              .utc(
                                                                  searchActivityTerms.EndDate
                                                              )
                                                              .format(
                                                                  "YYYY-MM-DD"
                                                              )
                                                        : ""
                                                }
                                            />
                                        </Col>
                                    )}
                                </Col>
                                {width > 800 && (
                                    <Col lg={2}>
                                        {width > 991 && (
                                            <Row>
                                                <Col
                                                    lg={12}
                                                    className={css(
                                                        this.styles.flexRow,
                                                        this.styles.rightAligned
                                                    )}
                                                >
                                                    <div>
                                                        <i
                                                            id="searchActivity"
                                                            className={`material-icons ${css(
                                                                this.styles
                                                                    .searchIcon
                                                            )}`}
                                                            onClick={() =>
                                                                this.handleSearch()
                                                            }
                                                        >
                                                            search
                                                        </i>
                                                        <Tooltip
                                                            message={
                                                                "comp.search.default"
                                                            }
                                                            target="searchActivity"
                                                        />
                                                    </div>
                                                    <div>
                                                        <i
                                                            id="resetActivitySearch"
                                                            className={`material-icons ${css(
                                                                this.styles
                                                                    .searchIcon
                                                            )}`}
                                                            onClick={() =>
                                                                this.clearFields()
                                                            }
                                                        >
                                                            low_priority
                                                        </i>
                                                        <Tooltip
                                                            message={
                                                                "comp.resetSearch.default"
                                                            }
                                                            target="resetActivitySearch"
                                                        />
                                                    </div>
                                                    {authUser.Permissions.includes(
                                                        "ExportReportActivity"
                                                    ) && (
                                                        <div>
                                                            <CSV
                                                                data={
                                                                    exportList
                                                                }
                                                                margin={
                                                                    "0 12px 0 0"
                                                                }
                                                            />
                                                            <Tooltip
                                                                message={
                                                                    "comp.button.export"
                                                                }
                                                                target="exportFile"
                                                            />
                                                        </div>
                                                    )}
                                                </Col>
                                            </Row>
                                        )}
                                    </Col>
                                )}
                            </Row>

                            <Row>
                                <Col
                                    lg={10}
                                    className={css(this.styles.flexRow)}
                                >
                                    {width > 800 && (
                                        <Col
                                            lg={3}
                                            className={css(this.styles.flexRow)}
                                        >
                                            <label
                                                className={css(
                                                    this.styles.labelDropdown
                                                )}
                                            >
                                                {this.props.intl.formatMessage({
                                                    id: "comp.sites.Header",
                                                })}
                                            </label>
                                            <Input
                                                className={css(
                                                    this.styles.input
                                                )}
                                                name="SiteId"
                                                type="select"
                                                placeholder={intl.formatMessage(
                                                    { id: "form.selectSite" }
                                                )}
                                                onChange={this.updateData}
                                                value={
                                                    searchActivityTerms.SiteId ||
                                                    ""
                                                }
                                            >
                                                <option value={""}>
                                                    {intl.formatMessage({
                                                        id: "form.all",
                                                    })}
                                                </option>
                                                {sitesList.map((site) => (
                                                    <option
                                                        key={"site" + site.Id}
                                                        value={site.Id || ""}
                                                    >
                                                        {site.SiteName}
                                                    </option>
                                                ))}
                                            </Input>
                                        </Col>
                                    )}
                                    {width > 800 &&
                                        authUser.Settings.Product ===
                                            "isolation" && (
                                            <Col
                                                lg={3}
                                                className={css(
                                                    this.styles.flexRow
                                                )}
                                            >
                                                <label
                                                    className={css(
                                                        this.styles
                                                            .labelDropdown
                                                    )}
                                                >
                                                    {this.props.intl.formatMessage(
                                                        {
                                                            id: "pages.machines.Type",
                                                        }
                                                    )}
                                                </label>
                                                <Input
                                                    className={css(
                                                        this.styles.input
                                                    )}
                                                    name="MachineId"
                                                    type="select"
                                                    placeholder={intl.formatMessage(
                                                        {
                                                            id: "form.selectMachine",
                                                        }
                                                    )}
                                                    onChange={this.updateData}
                                                    value={
                                                        searchActivityTerms.MachineId ||
                                                        ""
                                                    }
                                                    disabled={
                                                        searchActivityTerms.SiteId ===
                                                            "" ||
                                                        searchedMachines.length ==
                                                            0
                                                    }
                                                >
                                                    <option value={""}>
                                                        {searchActivityTerms.SiteId ===
                                                        ""
                                                            ? intl.formatMessage(
                                                                  {
                                                                      id: "form.selectSiteFirst",
                                                                  }
                                                              )
                                                            : intl.formatMessage(
                                                                  {
                                                                      id: "form.all",
                                                                  }
                                                              )}
                                                    </option>
                                                    {searchedMachines.map(
                                                        (machine) => (
                                                            <option
                                                                key={
                                                                    "machine" +
                                                                    machine.Id
                                                                }
                                                                value={
                                                                    machine.Id ||
                                                                    ""
                                                                }
                                                            >
                                                                {machine.Name}
                                                            </option>
                                                        )
                                                    )}
                                                </Input>
                                            </Col>
                                        )}
                                    {width > 800 &&
                                        authUser.Settings.Product ===
                                            "isolation" && (
                                            <Col
                                                lg={3}
                                                className={css(
                                                    this.styles.flexRow
                                                )}
                                            >
                                                <label
                                                    className={css(
                                                        this.styles
                                                            .labelDropdown
                                                    )}
                                                >
                                                    {this.props.intl.formatMessage(
                                                        {
                                                            id: "comp.job.Header",
                                                        }
                                                    )}
                                                </label>
                                                <Input
                                                    className={css(
                                                        this.styles.input
                                                    )}
                                                    name="JobId"
                                                    type="select"
                                                    placeholder={intl.formatMessage(
                                                        { id: "form.selectJob" }
                                                    )}
                                                    onChange={this.updateData}
                                                    value={
                                                        searchActivityTerms.JobId ||
                                                        ""
                                                    }
                                                    disabled={
                                                        searchActivityTerms.MachineId ===
                                                            "" ||
                                                        machineJobs.length == 0
                                                    }
                                                >
                                                    <option value={""}>
                                                        {searchActivityTerms.SiteId ===
                                                            "" ||
                                                        searchActivityTerms.MachineId ===
                                                            ""
                                                            ? intl.formatMessage(
                                                                  {
                                                                      id: "form.selectSiteMachineFirst",
                                                                  }
                                                              )
                                                            : intl.formatMessage(
                                                                  {
                                                                      id: "form.all",
                                                                  }
                                                              )}
                                                    </option>
                                                    {machineJobs.map((job) => (
                                                        <option
                                                            key={"job" + job.Id}
                                                            value={job.Id || ""}
                                                        >
                                                            {job.JobName}
                                                        </option>
                                                    ))}
                                                </Input>
                                            </Col>
                                        )}
                                    {width > 800 && (
                                        <Col
                                            lg={3}
                                            className={css(this.styles.flexRow)}
                                        >
                                            <label
                                                className={css(
                                                    this.styles.labelDropdown
                                                )}
                                            >
                                                {this.props.intl.formatMessage({
                                                    id: "form.user",
                                                })}
                                            </label>
                                            <Input
                                                onChange={this.updateData}
                                                type="text"
                                                name="UserName"
                                                id="UserName"
                                                value={
                                                    searchActivityTerms.UserName ||
                                                    ""
                                                }
                                                className={css(
                                                    this.styles.inputText
                                                )}
                                            />
                                        </Col>
                                    )}
                                </Col>
                            </Row>
                        </Col>
                    </div>
                </div>
            )
        );
    }
}

const mapStateToProps = ({ app, auth, help, settings, machines }) => {
    const { theme, width } = app;
    const { authUser } = auth;
    const { searchActivityTerms, activity } = help;
    const { sitesList } = settings;
    const { searchedMachines, machineJobs } = machines;
    return {
        theme,
        width,
        authUser,
        searchActivityTerms,
        sitesList,
        searchedMachines,
        machineJobs,
        activity,
    };
};

export default injectIntl(
    connect(mapStateToProps, {
        searchActivity,
        setActivitySearchTerms,
        fetchSitesList,
        searchMachines,
        fetchJobsByMachineId,
        searchUsers,
        fetchLocksSearchSelect,
        setLoader,
    })(SearchActivity)
);

const styles = (theme) =>
    StyleSheet.create({
        flexRow: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            height: "2.25rem",
            paddingLeft: 0,
        },
        rightAligned: {
            paddingRight: 0,
            justifyContent: "flex-end",
        },
        input: {
            border: "none",
            boxShadow: "none",
            borderRadius: 0,
            fontWeight: 400,
            fontSize: 12,
            letterSpacing: 2,
            marginBottom: 0,
            color: theme.color.themeColor,
            border: "1px solid white",
            backgroundColor: theme.color.compColor,
            "::placeholder": {
                letterSpacing: 1,
                color: theme.color.fontColor,
                fontSize: 12,
            },
        },
        inputSelect: {
            border: "none",
            boxShadow: "none",
            borderRadius: 0,
            fontWeight: 400,
            fontSize: 12,
            letterSpacing: 2,
            border: "1px solid white",
            paddingTop: 0,
            paddingRight: 0,
            paddingBottom: 0,
            color: theme.color.themeColor,
            backgroundColor: theme.color.compColor,
            "::placeholder": {
                letterSpacing: 1,
                color: theme.color.fontColor,
                fontSize: 12,
            },
        },
        dateInput: {
            marginBottom: 4,
            marginLeft: 4,
        },
        secondDateInput: {
            paddingRight: 6,
        },
        inputText: {
            border: "none",
            boxShadow: "none",
            borderRadius: 0,
            fontWeight: 400,
            fontSize: 12,
            letterSpacing: 2,
            marginBottom: 0,
            padding: 0,
            width: "100%",
            marginLeft: 5,
            color: theme.color.themeColor,
            borderBottom: `solid whitesmoke 1px`,
            backgroundColor: theme.color.compColor,
            "::placeholder": {
                letterSpacing: 1,
                color: theme.color.themeColor,
                fontSize: 12,
            },
        },
        searchWrapper: {
            position: "relative",
            display: "flex",
            flexDirection: "row",
        },
        searchIcon: {
            color: theme.color.themeColor,
            marginRight: 12,
            ":hover": {
                cursor: "pointer",
                opacity: ".8",
            },
        },
        labelDropdown: {
            fontSize: 12,
            color: theme.color.fontColor,
            whiteSpace: "nowrap",
        },
    });
