import {
    DARK_THEME,
    DRAWER_TYPE,
    SWITCH_LANGUAGE,
    SET_TERMS_MESSAGES,
    SET_THEME_MODE,
    SET_THEME_MODE_CONTRAST,
    SET_THEME_COLORS,
    TOGGLE_COLLAPSED_NAV,
    WINDOW_WIDTH,
    WINDOW_HEIGHT,
    HORIZONTAL_MENU_POSITION,
    HIDE_MESSAGE,
    SHOW_MESSAGE,
    SET_NEWS,
    SET_SEARCH,
    SET_FILTER,
    SET_LOADER,
    SET_SIDEBAR,
    SET_SHOW_HELP,
    SET_HELP_ANCHOR
} from 'constants/ActionTypes';

export const showMessage = (message,type) => {
    return {
        type: SHOW_MESSAGE,
        payload: {
            message,
            type: type ? type : 'error',
        }
    };
};

export const hideMessage = () => {
    return {
        type: HIDE_MESSAGE,
    };
};

export function toggleCollapsedNav(isNavCollapsed) {
    return {type: TOGGLE_COLLAPSED_NAV, isNavCollapsed};
}

export function setDrawerType(drawerType) {
    return {type: DRAWER_TYPE, drawerType};
}

export function updateWindowWidth(width) {
    return {type: WINDOW_WIDTH, width};
}

export function updateWindowHeight(height) {
    return {type: WINDOW_HEIGHT, height};
}

export function setCustomThemeColor() {
    return {type: SET_CUSTOM_THEME_COLOR, }
}

export function setDarkTheme() {
    return {type: DARK_THEME};
}

export function setHorizontalMenuPosition(navigationPosition) {
    return {
        type: HORIZONTAL_MENU_POSITION,
        payload: navigationPosition
    };
}

export function switchLanguage(locale) {
    return {
        type: SWITCH_LANGUAGE,
        payload: locale
    };
}

export function setTermsMessages(messages, oldMessages) {
    return {
        type: SET_TERMS_MESSAGES,
        payload: {messages, oldMessages}
    }
}

export const setSearch = (localList,searchType) => {
    return {
        type: SET_SEARCH,
        payload: {
            localList,
            searchType
        }
    };
};

export const setFilter = (list,action) => {
    return {
        type: SET_FILTER,
        payload: {
            list,
            action
        }
    };
};

export const setLoader = (show) => {
    return {
        type: SET_LOADER,
        payload: show
    };
};

export const setThemeMode = (mode) => {
    return {
        type: SET_THEME_MODE,
        mode,
    };
}

export const setThemeModeContrast = (mode) => {
    return {
        type: SET_THEME_MODE_CONTRAST,
        mode,
    };
}

export const setThemeColor = (colors) => {
    return {
        type: SET_THEME_COLORS,
        colors
    };
}

export const setNews = (data) => {
    return {
        type: SET_NEWS,
        payload: data
    };
};

export const setSidebar = (show) => {
    return {
        type: SET_SIDEBAR,
        payload: show
    };
};

export const setShowHelp = (show) => {
    return {
        type: SET_SHOW_HELP,
        payload: show
    }
}

export const setHelpAnchor = (anchor) => {
    return {
        type: SET_HELP_ANCHOR,
        payload: anchor
    }
}

