const languageData = [
    {
        languageId: "english",
        locale: "en",
        name: "English",
        icon: "us",
    },
    // {
    //     languageId: 'chinese',
    //     locale: 'zh',
    //     name: '中文',
    //     icon: 'cn'
    // },
    {
        languageId: "spanish",
        locale: "es",
        name: "Español",
        icon: "es",
    },
    {
        languageId: "french",
        locale: "fr",
        name: "Français",
        icon: "fr",
    },
    {
        languageId: "german",
        locale: "de",
        name: "Deutsche",
        icon: "de",
    },
    // {
    //     languageId: 'italian',
    //     locale: 'it',
    //     name: 'Italiano',
    //     icon: 'it'
    // },
    {
        languageId: "saudi-arabia",
        locale: "ar",
        name: "عربى",
        icon: "sa",
    },
    {
        languageId: "portuguese",
        locale: "pt",
        name: "Português",
        icon: "pt",
    },
    {
        languageId: "hebrew",
        locale: "he",
        name: "עִברִית",
        icon: "he",
    },
    {
        languageId: "dutch",
        locale: "nl",
        name: "Nederlands",
        icon: "nl",
    },
    {
        languageId: "korean",
        locale: "ko",
        name: "한국어",
        icon: "ko",
    },
];
export default languageData;
