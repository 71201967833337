import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";
//=====================================
// VERSION CONTROL
//=====================================
import { version } from "../../package.json";
export const VERSION = "v" + version.split(".")[0];

//=====================================
// ENVIROMENT
//=====================================
const domain = window.location.href;
const ENV =
    domain.includes("loto-go-dev") || domain.includes("localhost")
        ? "go-dev"
        : "go";
// const ENV = process.env.NODE_ENV === "development" ? "go-dev" : "go";

//=====================================
// LOTO URLS
//=====================================
export const URL = `https://loto-dot-loto-${ENV}.appspot.com/api/${VERSION}`;
export const ADMIN_URL = `https://loto-dot-loto-${ENV}.appspot.com/internal/client`;
export const BLANK_URL = `https://loto-dot-loto-${ENV}.appspot.com`;

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
var firebaseConfig = {
    apiKey: "AIzaSyCexiCpFEbefJJs0iN717aETJW3GmiFwUE",
    authDomain: "loto-go-89a66.firebaseapp.com",
    databaseURL: "https://loto-go-default-rtdb.firebaseio.com",
    projectId: "loto-go",
    storageBucket: "loto-go.appspot.com",
    messagingSenderId: "214290243537",
    appId: "1:214290243537:web:46eda337d6d332153c8af0"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const db = firebase.database();
export const auth = firebase.auth();
