import React, { Component, Suspense, lazy } from 'react';
import {Route, Switch} from 'react-router-dom';
import { connect } from 'react-redux';
import { ErrorBoundary } from "react-error-boundary";

import Spinner from 'components/Spinner';
import ErrorFallback from 'components/ErrorFallback';

const Request = lazy(() => import("./routes/Form"));
class RequestIndex extends Component {
    render() {
        const {loader} = this.props;
        return (
        loader
        ? <Spinner height={'100vh'} />
        :
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Suspense fallback={<Spinner fallback={true} />}>
                <Switch>
                    <Route component={Request} />
                </Switch>
            </Suspense>
        </ErrorBoundary>
        );
    }
}

const mapStateToProps = ({ auth, app }) => {
    const { loader, authUser } = auth;
    const { theme } = app;
    return { loader, authUser, theme }
};

export default connect(mapStateToProps)(RequestIndex);
