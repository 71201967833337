import React from "react";
import { connect } from "react-redux";
import { fadeIn } from "react-animations";
import { Container, Row, Col } from "reactstrap";
import { StyleSheet, css } from "aphrodite";

import { LogoIcon } from "util/icons";

class AuthTemplate extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { theme, width } = this.props;
        this.styles = styles(theme);
        return (
            <Container fluid>
                <Row className={css(this.styles.outside)}>
                    {width > 991 && (
                        <Col xs={0} lg={6} className="indexPage">
                            <div className={css(this.styles.fadeIn)}>
                                <Row
                                    className={css(
                                        this.styles.row,
                                        this.styles.fadeIn
                                    )}
                                >
                                    <img
                                        className={css(this.styles.image)}
                                        src={theme.logo}
                                    ></img>
                                </Row>
                            </div>
                        </Col>
                    )}
                    <Col
                        xs={12}
                        lg={6}
                        className={css(this.styles.formWrapper)}
                    >
                        <div className={css(this.styles.fadeIn)}>
                            <div
                                className={css(
                                    this.styles.logo,
                                    this.styles.fadeIn
                                )}
                            >
                                <LogoIcon fill={theme.color.themeColor} />
                            </div>
                            {this.props.children}
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
}

AuthTemplate.defaultProps = {
    src: "assets/images/Themes/Demo/auth.png",
};

const mapStateToProps = ({ app }) => {
    const { theme, width } = app;
    return { theme, width };
};

export default connect(mapStateToProps)(AuthTemplate);

const styles = (theme) =>
    StyleSheet.create({
        outside: {
            height: "100%",
        },
        formWrapper: {
            backgroundColor: theme.color.compColor,
            padding: "10vh 10vw",
        },
        row: {
            margin: "11.25vh 0",
            display: "flex",
            justifyContent: "center",
        },
        image: {
            maxHeight: "85px",
        },
        logo: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            marginTop: 40,
        },
        fadeIn: {
            animationName: fadeIn,
            animationDuration: "1.2s",
        },
    });
