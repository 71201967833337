import { all, call, fork, put, takeEvery, select } from "redux-saga/effects";
import { history } from "store/history";
import { API } from "api";
import { validMail, sortList } from "util";
import { LockIcon, TicketIcon, MachineIcon, UserIcon } from "util/icons";
import {
    FETCH_USERS_LIST,
    FETCH_USERS_LIST_AIC,
    FETCH_USER_BY_ID,
    FETCH_USER_NOTIFICATIONS,
    FETCH_USER_UNREAD_NOTIFICATIONS,
    ACKNOWLEDGE_NOTIFICATION,
    DELETE_NOTIFICATION,
    CREATE_USER,
    EDIT_USER,
    EDIT_SELF,
    DELETE_USER,
    FILTER_USERS,
    FETCH_USER_ACTIVITY,
    SEARCH_USERS,
} from "constants/ActionTypes";
import {
    fetchUsersList,
    fetchUsersListAIC,
    setUsers,
    setUser,
    setUserNotifications,
    setUserUnreadNotifications,
    setUserActivity,
    setSearchedUsers,
    fetchUserNotifications,
} from "actions/Users";
import { userSignOut } from "actions/Auth";
import { showMessage, setLoader } from "actions/App";

const getHeaders = (state) => state.users.userFilterList;
const getTeam = (state) => state.carseals.teamObject;

function* requestUsers({ payload }) {
    try {
        const users = yield call(API, payload.url, payload.data);
        const sessionUser = JSON.parse(sessionStorage.getItem("user_data"));
        if (users.message) {
            if (users.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(users.message, "message"));
            }
        } else if (!users.data) {
            yield put(setUsers([]));
        } else if (!sessionUser.Permissions.includes("UserList")) {
            users.data = users.data.filter(
                (user) => user.Id === sessionUser.Id
            );
            users.data.map((item) => {
                item.Icon = UserIcon;
            });
            yield put(setUsers(users.data));
        } else {
            users.data.map((item) => {
                item.Icon = UserIcon;
            });
            yield put(setLoader(false));
            const headers = yield select(getHeaders);
            const activeHeader = headers.find((item) => item.active);
            users.data = sortList(
                activeHeader.order,
                users.data,
                activeHeader.sortTitle
            );
            yield put(setUsers(users.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestUser({ payload }) {
    try {
        const user = yield call(API, payload.url, payload.data);
        if (user.message) {
            if (user.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(user.message));
            }
        } else if (user.data === "") {
            yield put(showMessage("notifications.error.fail.notClientUser"));
            yield history.push("/app/users/all");
        } else {
            const image = yield call(API, "/image/", {
                Filename: user.data.Id,
            });
            image.data
                ? (user.data.Image = `data:image/png;base64, ${image.data}`)
                : (user.data.Icon = UserIcon);
            yield put(setUser(user.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestUserNotifications({ payload }) {
    try {
        const notification = yield call(API, payload.url, payload.data);
        if (notification.message) {
            if (notification.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(notification.message));
            }
        } else if (notification.data === "") {
            yield put(setUserNotifications([]));
        } else {
            yield put(setUserNotifications(notification.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestUserUnreadNotifications({ payload }) {
    try {
        const notification = yield call(API, payload.url, payload.data);
        if (notification.message) {
            if (notification.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(notification.message));
            }
        } else if (notification.data === "") {
            yield put(setUserUnreadNotifications([]));
        } else {
            yield put(setUserUnreadNotifications(notification.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestAcknowledgeNotification({ payload }) {
    try {
        const notification = yield call(API, payload.url, payload.data);
        if (notification.message) {
            if (notification.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(notification.message));
            }
        } else {
            if (payload.UserId) {
                yield put(fetchUserNotifications(payload.UserId));
            }
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestDeleteNotification({ payload }) {
    try {
        const notification = yield call(API, payload.url, payload.data);
        if (notification.message) {
            if (notification.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(notification.message));
            }
        } else {
            if (payload.UserId) {
                yield put(fetchUserNotifications(payload.UserId));
            }
        }
    } catch (error) {
        console.warn(error);
    }
}

function* handleFilter({ payload }) {
    yield put(setUsers(null));
    switch (payload.title) {
        case "filters.users.all":
            yield put(fetchUsersList());
            return;
        case "filters.users.aic":
            yield put(fetchUsersListAIC());
            return;
        default:
            yield put(fetchUsersList());
            return;
    }
}

function* requestCreateUser({ payload }) {
    try {
        const required = {
            FirstName: payload.data.FirstName,
            LastName: payload.data.LastName,
            Email: payload.data.Email,
        };
        for (var key in required) {
            if (required[key] === undefined || required[key] === "") {
                yield put(
                    showMessage(`notifications.error.blank.${key}`, "warning")
                );
                return;
            }
        }
        if (!validMail(payload.data.Email)) {
            yield put(showMessage("notifications.error.fail.email", "warning"));
            return;
        }
        payload.data.UserRoleId = JSON.parse(payload.data.UserRoleId);
        const user = yield call(API, payload.url, payload.data);
        if (user.message) {
            if (user.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(user.message, "message"));
            }
        } else if (user.validationError) {
            var error = Object.keys(user.validationError).map(function (key) {
                return user.validationError[key];
            });
            yield put(showMessage(error, "message"));
        } else {
            yield put(showMessage("notifications.success.add", "success"));
            if (!payload.another) {
                yield history.push(`/app/users/display/${user.data.Id}`);
            }
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestEditUser({ payload }) {
    try {
        const required = {
            FirstName: payload.data.FirstName,
            LastName: payload.data.LastName,
            Email: payload.data.Email,
        };
        for (var key in required) {
            if (required[key] === undefined || required[key] === "") {
                yield put(
                    showMessage(`notifications.error.blank.${key}`, "warning")
                );
                return;
            }
        }
        if (!validMail(payload.data.Email)) {
            yield put(showMessage("notifications.error.fail.email", "warning"));
            return;
        }
        payload.data.IsActive = JSON.stringify(payload.data.IsActive);
        payload.data.UserRoleId = JSON.parse(payload.data.UserRoleId);
        const user = yield call(API, payload.url, payload.data);
        if (user.message) {
            if (user.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(user.message, "message"));
            }
        } else {
            let authUser = JSON.parse(sessionStorage.getItem("user_data"));
            if (user.data.Id === authUser.Id) {
                authUser.SiteId = user.data.SiteId;
                authUser.SiteName = user.data.SiteName;
                sessionStorage.setItem("user_data", JSON.stringify(authUser));
            }
            yield put(showMessage("notifications.success.edit", "success"));
            yield history.push(`/app/users/display/${payload.data.Id}`);
            user.data.Id === authUser.Id && window.location.reload();
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestEditSelf({ payload }) {
    try {
        const required = {
            FirstName: payload.data.FirstName,
            LastName: payload.data.LastName,
            Email: payload.data.Email,
        };
        for (var key in required) {
            if (required[key] === undefined || required[key] === "") {
                yield put(
                    showMessage(`notifications.error.blank.${key}`, "warning")
                );
                return;
            }
        }
        if (!validMail(payload.data.Email)) {
            yield put(showMessage("notifications.error.fail.email", "warning"));
            return;
        }
        payload.data.IsActive = JSON.stringify(payload.data.IsActive);
        payload.data.UserRoleId = JSON.parse(payload.data.UserRoleId);
        const user = yield call(API, payload.url, payload.data);
        if (user.message) {
            if (user.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(user.message, "message"));
            }
        } else {
            yield put(showMessage("notifications.success.edit", "success"));
            yield history.push("/app/settings/site");
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestDeleteUser({ payload }) {
    try {
        if (!payload.data) {
            yield put(showMessage(`notifications.error.permission.deny`));
            return;
        }
        const user = yield call(API, payload.url, payload.data);
        if (user.message) {
            if (user.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(user.message, "message"));
            }
        } else {
            yield put(showMessage("notifications.success.delete", "success"));
            yield history.push("/app/users/all");
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestUserActivity({ payload }) {
    try {
        yield put(setUserActivity(null));
        const activity = yield call(API, payload.url, payload.data);
        if (activity.message) {
            if (activity.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(activity.message));
            }
        } else if (activity.data === "") {
            yield put(setUserActivity([]));
        } else {
            const images = yield all(
                activity.data.map(
                    (item) =>
                        item.ImageId !== "" &&
                        call(API, "/image/", { Filename: item.ImageId })
                )
            );
            activity.data.map((item, i) => {
                if (item.Event === "Ticket") {
                    item.Icon = TicketIcon;
                } else if (item.Event === "Machine") {
                    item.Icon = MachineIcon;
                } else if (item.Event === "Lock") {
                    item.Icon = LockIcon;
                } else if (item.Event === "User") {
                    item.Icon = UserIcon;
                } else if (item.Event === "Point") {
                    item.Icon = LockIcon;
                } else if (item.Event === "Job") {
                    item.Icon = TicketIcon;
                } else if (item.Event === "Template") {
                    item.Icon = MachineIcon;
                } else {
                    item.Icon = UserIcon;
                }
                item.Image = images[i].data
                    ? `data:image/png;base64, ${images[i].data}`
                    : null;
            });
            yield put(setUserActivity(activity.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestSearchUsers({ payload }) {
    try {
        payload.data.UserRoleId = Number(payload.data.UserRoleId);
        const users = yield call(API, payload.url, payload.data);
        if (users.message) {
            if (users.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(users.message, "message"));
            }
        } else if (!users.data) {
            yield put(setSearchedUsers([]));
        } else {
            var searchedUsers = users.data;
            searchedUsers.map((item) => {
                item.Icon = UserIcon;
            });
            yield put(setSearchedUsers(searchedUsers));
        }
    } catch (error) {
        console.warn(error);
    }
}

export function* selectUsersList() {
    yield takeEvery(FETCH_USERS_LIST, requestUsers);
}

export function* selectUsersListAIC() {
    yield takeEvery(FETCH_USERS_LIST_AIC, requestUsers);
}

export function* selectUserById() {
    yield takeEvery(FETCH_USER_BY_ID, requestUser);
}

export function* selectUserNotifications() {
    yield takeEvery(FETCH_USER_NOTIFICATIONS, requestUserNotifications);
}

export function* selectUserUnreadNotifications() {
    yield takeEvery(
        FETCH_USER_UNREAD_NOTIFICATIONS,
        requestUserUnreadNotifications
    );
}

export function* selectAcknowledgeNotification() {
    yield takeEvery(ACKNOWLEDGE_NOTIFICATION, requestAcknowledgeNotification);
}

export function* selectDeleteNotification() {
    yield takeEvery(DELETE_NOTIFICATION, requestDeleteNotification);
}

export function* createUser() {
    yield takeEvery(CREATE_USER, requestCreateUser);
}

export function* editUser() {
    yield takeEvery(EDIT_USER, requestEditUser);
}

export function* editSelf() {
    yield takeEvery(EDIT_SELF, requestEditSelf);
}

export function* deleteUser() {
    yield takeEvery(DELETE_USER, requestDeleteUser);
}

export function* selectFilterUser() {
    yield takeEvery(FILTER_USERS, handleFilter);
}

export function* selectUserActivity() {
    yield takeEvery(FETCH_USER_ACTIVITY, requestUserActivity);
}

export function* selectSearchUsers() {
    yield takeEvery(SEARCH_USERS, requestSearchUsers);
}

export default function* rootSaga() {
    yield all([
        fork(selectUsersList),
        fork(selectUsersListAIC),
        fork(selectUserById),
        fork(selectUserNotifications),
        fork(selectUserUnreadNotifications),
        fork(selectAcknowledgeNotification),
        fork(selectDeleteNotification),
        fork(createUser),
        fork(editUser),
        fork(editSelf),
        fork(deleteUser),
        fork(selectFilterUser),
        fork(selectUserActivity),
        fork(selectSearchUsers),
    ]);
}
