import "@formatjs/intl-pluralrules/polyfill";
import "@formatjs/intl-pluralrules/locale-data/it"; // Add locale data for it

import "@formatjs/intl-relativetimeformat/polyfill";
import "@formatjs/intl-relativetimeformat/locale-data/it"; // Add locale data for it
import itMessages from "../locales/it_IT.json";

const itLang = {
    messages: {
        ...itMessages,
    },
    locale: "it-IT",
};

export default itLang;
