import {
    FETCH_ACTIVITY,
    SET_ACTIVITY,
    FETCH_SNAPSHOT,
    SET_SNAPSHOT,
    FETCH_SNAPSHOT_WORKORDER,
    SET_SNAPSHOT_WORKORDER,
    SET_ACTIVITY_SEARCH_TERMS,
    SEARCH_ACTIVITY,
    FETCH_REPORTS,
    SET_REPORTS,
    FETCH_TICKETS_NOT_STARTED,
    SET_TICKETS_NOT_STARTED,
} from "constants/ActionTypes";
import moment from "moment";

let authUser = JSON.parse(sessionStorage.getItem("user_data"));

export const fetchActivity = (Id) => {
    return {
        type: FETCH_ACTIVITY,
        payload: {
            url: "/activity/list/",
            data: {
                Id,
            },
        },
    };
};

export const setActivity = (data) => {
    return {
        type: SET_ACTIVITY,
        payload: data,
    };
};

export const fetchReports = () => {
    return {
        type: FETCH_REPORTS,
        payload: {
            url: "/type/report/list/select/",
            data: {},
        },
    };
};

export const setReports = (data) => {
    return {
        type: SET_REPORTS,
        payload: data,
    };
};

export const fetchTicketsNotStarted = () => {
    return {
        type: FETCH_TICKETS_NOT_STARTED,
        payload: {
            url: "/isolation/reporting/report/workordersnotstarted/",
            data: {},
        },
    };
};
export const setTicketsNotStarted = (data) => {
    return {
        type: SET_TICKETS_NOT_STARTED,
        payload: data,
    };
};

export const fetchSnapshot = (StartDate, EndDate) => {
    if (!authUser) {
        authUser = JSON.parse(sessionStorage.getItem("user_data"));
    }
    if (authUser.Settings.Product.includes("isolation")) {
        return {
            type: FETCH_SNAPSHOT,
            payload: {
                url: "/isolation/reporting/snapshot/",
                data: {
                    StartDate,
                    EndDate,
                },
            },
        };
    } else {
        return {
            type: FETCH_SNAPSHOT,
            payload: {
                url: "/lockout/reporting/snapshot/",
                data: {
                    StartDate,
                    EndDate,
                },
            },
        };
    }
};

export const setSnapshot = (data) => {
    return {
        type: SET_SNAPSHOT,
        payload: data,
    };
};

export const fetchSnapshotWorkorder = (StartDate, EndDate) => {
    if (!authUser) {
        authUser = JSON.parse(sessionStorage.getItem("user_data"));
    }
    if (authUser.Settings.Product.includes("isolation")) {
        return {
            type: FETCH_SNAPSHOT_WORKORDER,
            payload: {
                url: "/isolation/reporting/snapshot/workorder/",
                data: {
                    StartDate,
                    EndDate,
                },
            },
        };
    } else {
        return {
            type: FETCH_SNAPSHOT_WORKORDER,
            payload: {
                url: "/lockout/reporting/snapshot/workorder/",
                data: {
                    StartDate,
                    EndDate,
                },
            },
        };
    }
};

export const setSnapshotWorkorder = (data) => {
    return {
        type: SET_SNAPSHOT_WORKORDER,
        payload: data,
    };
};

export const searchActivity = ({
    Activity = "",
    WorkOrderId = 0,
    UserId = "",
    UserName = "",
    SiteId = "",
    MachineId = "",
    JobId = "",
    LockId = "",
    StartDate = moment().subtract(1, "days").format("YYYY-MM-DD"),
    EndDate = moment().format("YYYY-MM-DD"),
}) => {
    return {
        type: SEARCH_ACTIVITY,
        payload: {
            url: "/activity/list/search/",
            data: {
                Activity,
                WorkOrderId,
                UserId,
                UserName,
                SiteId,
                MachineId,
                JobId,
                LockId,
                StartDate,
                EndDate,
            },
        },
    };
};

export const setActivitySearchTerms = (data) => {
    return {
        type: SET_ACTIVITY_SEARCH_TERMS,
        payload: data,
    };
};
