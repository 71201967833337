import "@formatjs/intl-pluralrules/polyfill";
import "@formatjs/intl-pluralrules/locale-data/de"; // Add locale data for de

import "@formatjs/intl-relativetimeformat/polyfill";
import "@formatjs/intl-relativetimeformat/locale-data/de"; // Add locale data for de
import deMessages from "../locales/de_DE.json";

const deLang = {
    messages: {
        ...deMessages,
    },
    locale: "de-DE",
};

export default deLang;
