import React from "react";
import { connect } from "react-redux";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { fadeIn } from "react-animations";
import { StyleSheet, css } from "aphrodite";
import { injectIntl } from "react-intl";

import Menu from "components/Menus/filter";

class HeaderFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
        };
    }
    render() {
        const { intl, action, list, theme, width, data } = this.props;
        const { show } = this.state;
        this.styles = styles(theme, width);
        let active = data.find((filt) => filt.active);
        return (
            <div className={css(this.styles.wrapper, this.styles.fadeIn)}>
                <Dropdown
                    direction={"down"}
                    isOpen={show}
                    toggle={() => this.setState({ show: !show })}
                >
                    <DropdownToggle
                        className="d-inline-block"
                        tag="span"
                        data-toggle="dropdown"
                    >
                        {!list ? (
                            <span className={css(this.styles.header)}>
                                {" "}
                                -{" "}
                                {intl.formatMessage({
                                    id: active ? active.title : "blank",
                                })}{" "}
                                <i
                                    className={`material-icons ${css(
                                        this.styles.icon
                                    )}`}
                                >
                                    arrow_drop_down
                                </i>
                            </span>
                        ) : (
                            <span className={css(this.styles.header)}>
                                {" "}
                                - {active ? active.title : ""}{" "}
                                <i
                                    className={`material-icons ${css(
                                        this.styles.icon
                                    )}`}
                                >
                                    arrow_drop_down
                                </i>
                            </span>
                        )}
                    </DropdownToggle>
                    <DropdownMenu
                        right
                        className={css(this.styles.menuWrapper)}
                    >
                        <div className={css(this.styles.menu)}>
                            <Menu
                                list={list}
                                toggle={() => this.setState({ show: !show })}
                                data={data}
                                update={this.updateFilter}
                                action={action}
                            />
                        </div>
                    </DropdownMenu>
                </Dropdown>
            </div>
        );
    }
}

const mapStateToProps = ({ app }) => {
    const { theme, width, filterCreds } = app;
    return { theme, width, filterCreds };
};

export default injectIntl(connect(mapStateToProps)(HeaderFilter));

const styles = (theme, width) =>
    StyleSheet.create({
        wrapper: {
            display: "inline-block",
            marginLeft: 5,
            paddingTop: 7,
            "@media(max-width:800px)": {
                paddingTop: 3,
            },
        },
        header: {
            color: theme.color.themeColor,
            fontSize: width > 800 ? 14 : 12,
            fontWeight: 400,
            paddingBottom: 0,
            marginBottom: 0,
            ":hover": {
                cursor: "pointer",
                opacity: "0.8",
            },
        },
        menuWrapper: {
            border: `solid .5px ${theme.color.borderColor}`,
            backgroundColor: theme.color.compColor,
            padding: 10,
            width: 250,
        },
        fadeIn: {
            animationName: fadeIn,
            animationDuration: ".6s",
        },
    });
