import React, { Suspense, lazy } from "react";
import { withRouter } from "react-router-dom";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { isIOS, isMobile } from "react-device-detect";
import { StyleSheet, css } from "aphrodite";
import { fadeInRight } from "react-animations";
import { ErrorBoundary } from "react-error-boundary";

import { COLLAPSED_DRAWER, FIXED_DRAWER } from "constants/ActionTypes";
import { Resizable } from "re-resizable";
import Header from "components/Header";
import NoPermissions from "components/Error403";
import Menu from "components/Menus/sidebar";
import Help from "components/Help/General/help";
import Box from "components/Box";
import Spinner from "components/Spinner";
import ErrorFallback from 'components/ErrorFallback';

const Home = lazy(() => import("./routes/Home"));
const Tickets = lazy(() => import("./routes/Tickets"));
const Machines = lazy(() => import("./routes/Machines"));
const Locks = lazy(() => import("./routes/Locks"));
const Users = lazy(() => import("./routes/Users"));
const Reports = lazy(() => import("./routes/Reports"));
const Client = lazy(() => import("./routes/Clients"));
const Settings = lazy(() => import("./routes/Settings"));
const Carseals = lazy(() => import("./routes/Carseals"));
const Feedback = lazy(() => import("./routes/Feedback"));
const Website = lazy(() => import("./routes/Website"));
const Roles = lazy(() => import("./routes/Settings"));
const Logout = lazy(() => import("./routes/Auth/routes/Logout"));
const Error404 = lazy(() => import("components/Error404"));
class App extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        this.styles = styles();
        const { authUser, match, drawerType, hideSidebar, showHelp } =
            this.props;
        const drawerStyle = drawerType.includes(FIXED_DRAWER)
            ? "fixed-drawer"
            : drawerType.includes(COLLAPSED_DRAWER)
            ? "collapsible-drawer"
            : "mini-drawer";
        if (isIOS && isMobile) {
            document.body.classList.add("ios-mobile-view-height");
        } else if (document.body.classList.contains("ios-mobile-view-height")) {
            document.body.classList.remove("ios-mobile-view-height");
        }
        const permissions = authUser.Permissions;
        return (
            <div className={`app-container ${drawerStyle}`}>
                <Menu />
                <div className="app-main-container">
                    <div className="app-header">
                        <Header />
                    </div>
                    <main className="app-main-content-wrapper">
                        <div
                            className={
                                !hideSidebar
                                    ? `app-main-content`
                                    : `app-main-contentShort`
                            }
                            style={{ display: "flex" }}
                        >
                            <div
                                className={
                                    showHelp
                                        ? css(this.styles.mostWidth)
                                        : css(this.styles.fullWidth)
                                }
                            >
                                <ErrorBoundary FallbackComponent={ErrorFallback}>
                                    <Suspense fallback={<Spinner fallback={true} />}>
                                        <Switch>
                                            <Route
                                                path={`${match.url}/home`}
                                                component={
                                                    permissions.includes("TicketList")
                                                        ? Home
                                                        : NoPermissions
                                                }
                                            />
                                            <Route
                                                path={`${match.url}/tickets`}
                                                component={
                                                    permissions.includes("TicketList")
                                                        ? Tickets
                                                        : NoPermissions
                                                }
                                            />
                                            <Route
                                                path={`${match.url}/machines`}
                                                component={
                                                    permissions.includes("MachineList")
                                                        ? Machines
                                                        : NoPermissions
                                                }
                                            />
                                            <Route
                                                path={`${match.url}/locks`}
                                                component={
                                                    permissions.includes("LockList")
                                                        ? Locks
                                                        : NoPermissions
                                                }
                                            />
                                            <Route
                                                path={`${match.url}/users`}
                                                component={Users}
                                            />
                                            <Route
                                                path={`${match.url}/reports`}
                                                component={
                                                    permissions.includes("TicketList")
                                                        ? Reports
                                                        : NoPermissions
                                                }
                                            />
                                            <Route
                                                path={`${match.url}/client`}
                                                component={
                                                    permissions.includes(
                                                        "InternalClientList"
                                                    )
                                                        ? Client
                                                        : NoPermissions
                                                }
                                            />
                                            <Route
                                                path={`${match.url}/settings`}
                                                component={Settings}
                                            />
                                            <Route
                                                path={`${match.url}/carseals`}
                                                component={Carseals}
                                            />
                                            <Route
                                                path={`${match.url}/feedback`}
                                                component={Feedback}
                                            />
                                            <Route
                                                path={`${match.url}/website`}
                                                component={Website}
                                            />
                                            <Route
                                                path={`${match.url}/roles`}
                                                component={Roles}
                                            />
                                            <Route
                                                path={`${match.url}/logout`}
                                                component={Logout}
                                            />
                                            <Route component={Error404} />
                                        </Switch>
                                    </Suspense>
                                </ErrorBoundary>
                            </div>
                            {showHelp && (
                                <Resizable
                                    defaultSize={{
                                        width: "30%",
                                        height: "100%",
                                    }}
                                    minWidth="30%"
                                    minHeight="100%"
                                    maxHeight="100%"
                                    className={css(this.styles.fadeInRight)}
                                    style={{ paddingRight: 17 }}
                                >
                                    <Box>
                                        <Help />
                                    </Box>
                                </Resizable>
                            )}
                        </div>
                    </main>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ auth, app }) => {
    const { authUser } = auth;
    const { drawerType, hideSidebar, showHelp } = app;
    return { authUser, drawerType, hideSidebar, showHelp };
};

export default withRouter(connect(mapStateToProps, {})(App));

const styles = () =>
    StyleSheet.create({
        fullWidth: {
            width: "100%",
        },
        mostWidth: {
            width: "70%",
        },
        fadeInRight: {
            animationName: fadeInRight,
            animationDuration: "1s",
        },
    });
