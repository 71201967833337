import React from "react";
import { connect } from "react-redux";
import { StyleSheet, css } from "aphrodite";
import { Input, Row, Col } from "reactstrap";
import { injectIntl } from "react-intl";
import { geolocated } from "react-geolocated";

import {
    fetchTeamsSelect,
    fetchUnitsSelect,
    searchSealAudit,
    setAuditSearchTerms,
} from "actions/Carseals";
import { setLoader } from "actions/App";
import { fetchSitesList } from "actions/Settings";

import Tooltip from "components/Tooltips";
import CSV from "components/Export";

class SearchAudit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchAuditTerms: props.searchAuditTerms,
        };
    }
    componentDidMount() {
        const { fetchSitesList, fetchTeamsSelect, fetchUnitsSelect } =
            this.props;
        fetchTeamsSelect();
        fetchUnitsSelect();
        fetchSitesList();
    }
    handleSearch = () => {
        const { searchSealAudit, setLoader } = this.props;
        let { searchAuditTerms } = this.state;
        searchSealAudit(searchAuditTerms);
        setLoader(false);
    };
    keyPress = (e) => {
        if (e.keyCode === 13) {
            this.handleSearch();
        }
    };
    updateData = (e) => {
        const { setAuditSearchTerms } = this.props;
        let updateData = { ...this.state.searchAuditTerms };
        if (e.target.type === "checkbox") {
            if (e.target.checked) {
                updateData[e.target.name] = true;
                setAuditSearchTerms(updateData);
                this.setState({
                    searchAuditTerms: updateData,
                    changesMade: true,
                });
            } else {
                updateData[e.target.name] = false;
                setAuditSearchTerms(updateData);
                this.setState({
                    searchAuditTerms: updateData,
                    changesMade: true,
                });
            }
        } else {
            updateData[e.target.name] = e.target.value;
            setAuditSearchTerms(updateData);
            this.setState({ searchAuditTerms: updateData, changesMade: true });
        }
    };
    clearField = (target) => {
        let updateData = { ...this.state.searchAuditTerms };
        updateData[target] = "";
        this.setState({ searchAuditTerms: updateData, changesMade: true });
    };
    clearFields = () => {
        const { coords } = this.props;
        let newSearchAuditTerms = {
            Location: "",
            LocationIdentifier: "",
            CreatedByName: "",
            TeamId: 0,
            UnitId: 0,
            SiteId: "",
            StartingLatLong: coords
                ? `${coords.latitude}, ${coords.longitude}`
                : "",
            Failed: "",
        };
        this.setState({ searchAuditTerms: newSearchAuditTerms });
        this.props.searchSealAudit(newSearchAuditTerms);
    };
    render() {
        let { searchAuditTerms } = this.state;
        const {
            intl,
            teamsSelectList,
            unitsSelectList,
            sitesList,
            width,
            searchedSealAuditList,
            authUser,
            theme,
        } = this.props;
        this.styles = styles(theme);
        let exportList = searchedSealAuditList
            ? searchedSealAuditList.map((a) => Object.assign({}, a))
            : [];
        exportList.map((item) => {
            delete item.Icon;
            delete item.Id;
            return item;
        });
        return (
            searchAuditTerms && (
                <div>
                    <div
                        className={css(this.styles.searchWrapper)}
                        onKeyDown={(e) => this.keyPress(e)}
                    >
                        <Col lg={12}>
                            {width < 992 && (
                                <Row>
                                    <Col
                                        lg={12}
                                        className={css(this.styles.flexRow)}
                                    >
                                        <div>
                                            <i
                                                id="searchSeal"
                                                className={`material-icons ${css(
                                                    this.styles.searchIcon
                                                )}`}
                                                onClick={() =>
                                                    this.handleSearch()
                                                }
                                            >
                                                search
                                            </i>
                                            <Tooltip
                                                message={"comp.search.default"}
                                                target="searchSeal"
                                            />
                                        </div>
                                        <div>
                                            <i
                                                id="resetSealSearch"
                                                className={`material-icons ${css(
                                                    this.styles.searchIcon
                                                )}`}
                                                onClick={() =>
                                                    this.clearFields()
                                                }
                                            >
                                                low_priority
                                            </i>
                                            <Tooltip
                                                message={
                                                    "comp.resetSearch.default"
                                                }
                                                target="resetSealSearch"
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            )}
                            <Row className={css(this.styles.flexRow)}>
                                <Col lg={10}>
                                    <Row>
                                        {width > 800 && (
                                            <Col
                                                lg={4}
                                                className={css(
                                                    this.styles.flexRow
                                                )}
                                            >
                                                <label
                                                    className={css(
                                                        this.styles
                                                            .labelDropdown
                                                    )}
                                                >
                                                    {this.props.intl.formatMessage(
                                                        {
                                                            id: "pages.teams.Type",
                                                        }
                                                    )}
                                                </label>
                                                <Input
                                                    className={css(
                                                        this.styles.inputSelect
                                                    )}
                                                    name="TeamId"
                                                    type="select"
                                                    placeholder={intl.formatMessage(
                                                        {
                                                            id: "form.selectTeam",
                                                        }
                                                    )}
                                                    onChange={this.updateData}
                                                    value={
                                                        searchAuditTerms.TeamId ||
                                                        ""
                                                    }
                                                >
                                                    <option value={""}>
                                                        {intl.formatMessage({
                                                            id: "form.all",
                                                        })}
                                                    </option>
                                                    {teamsSelectList.map(
                                                        (team) => (
                                                            <option
                                                                key={
                                                                    "team" +
                                                                    team.Id
                                                                }
                                                                value={
                                                                    team.Id ||
                                                                    ""
                                                                }
                                                            >
                                                                {team.TypeName}
                                                            </option>
                                                        )
                                                    )}
                                                </Input>
                                            </Col>
                                        )}
                                        {width > 800 && (
                                            <Col
                                                lg={4}
                                                className={css(
                                                    this.styles.flexRow
                                                )}
                                            >
                                                <label
                                                    className={css(
                                                        this.styles
                                                            .labelDropdown
                                                    )}
                                                >
                                                    {this.props.intl.formatMessage(
                                                        {
                                                            id: "pages.Unit.Type",
                                                        }
                                                    )}
                                                </label>
                                                <Input
                                                    className={css(
                                                        this.styles.inputSelect
                                                    )}
                                                    name="UnitId"
                                                    type="select"
                                                    placeholder={intl.formatMessage(
                                                        {
                                                            id: "form.selectUnit",
                                                        }
                                                    )}
                                                    onChange={this.updateData}
                                                    value={
                                                        searchAuditTerms.UnitId ||
                                                        ""
                                                    }
                                                >
                                                    <option value={""}>
                                                        {intl.formatMessage({
                                                            id: "form.all",
                                                        })}
                                                    </option>
                                                    {unitsSelectList.map(
                                                        (unit) => (
                                                            <option
                                                                key={
                                                                    "unit" +
                                                                    unit.Id
                                                                }
                                                                value={
                                                                    unit.Id ||
                                                                    ""
                                                                }
                                                            >
                                                                {unit.TypeName}
                                                            </option>
                                                        )
                                                    )}
                                                </Input>
                                            </Col>
                                        )}
                                        {width > 800 && (
                                            <Col
                                                lg={4}
                                                className={css(
                                                    this.styles.flexRow
                                                )}
                                            >
                                                <label
                                                    className={css(
                                                        this.styles.labelText
                                                    )}
                                                >
                                                    {this.props.intl.formatMessage(
                                                        { id: "form.location" }
                                                    )}
                                                </label>
                                                <Input
                                                    name="Location"
                                                    type="text"
                                                    className={css(
                                                        this.styles.inputText
                                                    )}
                                                    onChange={(e) =>
                                                        this.updateData(e)
                                                    }
                                                    value={
                                                        searchAuditTerms.Location
                                                    }
                                                />
                                                {searchAuditTerms.Location !==
                                                    "" && (
                                                    <i
                                                        className={`material-icons ${css(
                                                            this.styles
                                                                .closeIcon
                                                        )}`}
                                                        onClick={() =>
                                                            this.clearField(
                                                                "Location"
                                                            )
                                                        }
                                                    >
                                                        close
                                                    </i>
                                                )}
                                            </Col>
                                        )}
                                    </Row>
                                </Col>
                                {width > 800 && (
                                    <Col lg={2}>
                                        {width > 991 && (
                                            <Row>
                                                <Col
                                                    lg={12}
                                                    className={css(
                                                        this.styles.flexRow,
                                                        this.styles.rightAligned
                                                    )}
                                                >
                                                    {width > 800 && (
                                                        <div>
                                                            <i
                                                                id="searchSeal"
                                                                className={`material-icons ${css(
                                                                    this.styles
                                                                        .searchIcon
                                                                )}`}
                                                                onClick={() =>
                                                                    this.handleSearch()
                                                                }
                                                            >
                                                                search
                                                            </i>
                                                            <Tooltip
                                                                message={
                                                                    "comp.search.default"
                                                                }
                                                                target="searchSeal"
                                                            />
                                                        </div>
                                                    )}
                                                    {width > 800 && (
                                                        <div>
                                                            <i
                                                                id="resetSealSearch"
                                                                className={`material-icons ${css(
                                                                    this.styles
                                                                        .searchIcon
                                                                )}`}
                                                                onClick={() =>
                                                                    this.clearFields()
                                                                }
                                                            >
                                                                low_priority
                                                            </i>
                                                            <Tooltip
                                                                message={
                                                                    "comp.resetSearch.default"
                                                                }
                                                                target="resetSealSearch"
                                                            />
                                                        </div>
                                                    )}
                                                    {width > 800 &&
                                                        authUser.Permissions.includes(
                                                            "ExportSecureAudits"
                                                        ) && (
                                                            <div>
                                                                <CSV
                                                                    data={
                                                                        exportList
                                                                    }
                                                                />
                                                                <Tooltip
                                                                    message={
                                                                        "comp.button.export"
                                                                    }
                                                                    target="exportFile"
                                                                />
                                                            </div>
                                                        )}
                                                </Col>
                                            </Row>
                                        )}
                                    </Col>
                                )}
                            </Row>
                        </Col>
                    </div>
                    <div>
                        <Col lg={10}>
                            <Row>
                                <Col
                                    lg={4}
                                    className={css(this.styles.flexRow)}
                                >
                                    <label
                                        className={css(
                                            this.styles.labelDropdown
                                        )}
                                    >
                                        {this.props.intl.formatMessage({
                                            id: "comp.sites.Header",
                                        })}
                                    </label>
                                    <Input
                                        className={css(this.styles.inputSelect)}
                                        name="SiteId"
                                        type="select"
                                        placeholder={intl.formatMessage({
                                            id: "form.selectSite",
                                        })}
                                        onChange={this.updateData}
                                        value={searchAuditTerms.SiteId || ""}
                                    >
                                        <option value={""}>
                                            {intl.formatMessage({
                                                id: "form.all",
                                            })}
                                        </option>
                                        {sitesList.map((site) => (
                                            <option
                                                key={"site" + site.Id}
                                                value={site.Id || ""}
                                            >
                                                {site.SiteName}
                                            </option>
                                        ))}
                                    </Input>
                                </Col>
                                <Col
                                    lg={4}
                                    className={css(this.styles.flexRow)}
                                >
                                    <label
                                        className={css(this.styles.labelText)}
                                    >
                                        {this.props.intl.formatMessage({
                                            id: "form.createdByName",
                                        })}
                                    </label>
                                    <Input
                                        name="CreatedByName"
                                        type="text"
                                        className={css(this.styles.inputText)}
                                        onChange={(e) => this.updateData(e)}
                                        value={searchAuditTerms.CreatedByName}
                                    />
                                    {searchAuditTerms.CreatedByName !== "" && (
                                        <i
                                            className={`material-icons ${css(
                                                this.styles.closeIcon
                                            )}`}
                                            onClick={() =>
                                                this.clearField("CreatedByName")
                                            }
                                        >
                                            close
                                        </i>
                                    )}
                                </Col>
                                <Col
                                    lg={4}
                                    className={css(this.styles.flexRow)}
                                >
                                    <label
                                        className={css(
                                            this.styles.labelDropdown
                                        )}
                                    >
                                        {this.props.intl.formatMessage({
                                            id: "form.auditStatus",
                                        })}
                                    </label>
                                    <Input
                                        className={css(this.styles.inputSelect)}
                                        name="Failed"
                                        type="select"
                                        placeholder={intl.formatMessage({
                                            id: "form.selectSite",
                                        })}
                                        onChange={this.updateData}
                                        value={searchAuditTerms.Failed || ""}
                                    >
                                        <option value={""}>
                                            {intl.formatMessage({
                                                id: "form.all",
                                            })}
                                        </option>
                                        <option value={"open"}>
                                            {intl.formatMessage({
                                                id: "form.open",
                                            })}
                                        </option>
                                        <option value={"failed"}>
                                            {intl.formatMessage({
                                                id: "form.failed",
                                            })}
                                        </option>
                                    </Input>
                                </Col>
                            </Row>
                            <Row>
                                {this.props.coords && (
                                    <Col
                                        lg={3}
                                        className={css(this.styles.flexRow)}
                                    >
                                        <label
                                            className={css(
                                                this.styles.labelText
                                            )}
                                        >
                                            {this.props.intl.formatMessage({
                                                id: "form.startingLatLong",
                                            })}
                                        </label>
                                        <Input
                                            name="StartingLatLong"
                                            type="text"
                                            className={css(
                                                this.styles.inputText
                                            )}
                                            onChange={(e) => this.updateData(e)}
                                            value={
                                                searchAuditTerms.StartingLatLong
                                            }
                                        />
                                        {searchAuditTerms.StartingLatLong !==
                                            "" && (
                                            <i
                                                className={`material-icons ${css(
                                                    this.styles.closeIcon
                                                )}`}
                                                onClick={() =>
                                                    this.clearField(
                                                        "StartingLatLong"
                                                    )
                                                }
                                            >
                                                close
                                            </i>
                                        )}
                                    </Col>
                                )}
                            </Row>
                        </Col>
                    </div>
                </div>
            )
        );
    }
}

SearchAudit.defaultProps = {
    searchTitle: "blank",
    searchType: ["name"],
    data: [],
};

const mapStateToProps = ({ app, carseals, settings, auth }) => {
    const { theme, width } = app;
    const {
        teamsSelectList,
        unitsSelectList,
        searchAuditTerms,
        searchedSealAuditList,
    } = carseals;
    const { sitesList } = settings;
    const { authUser } = auth;
    return {
        theme,
        width,
        teamsSelectList,
        unitsSelectList,
        searchAuditTerms,
        searchedSealAuditList,
        sitesList,
        authUser,
    };
};

export default injectIntl(
    connect(mapStateToProps, {
        fetchTeamsSelect,
        fetchUnitsSelect,
        searchSealAudit,
        setLoader,
        fetchSitesList,
        setAuditSearchTerms,
    })(
        geolocated({
            positionOptions: {
                enableHighAccuracy: false,
            },
            userDecisionTimeout: 5000,
        })(SearchAudit)
    )
);

const styles = (theme) =>
    StyleSheet.create({
        flexRow: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            height: "2.25rem",
            paddingLeft: 0,
        },
        rightAligned: {
            paddingRight: 0,
            justifyContent: "flex-end",
        },
        inputSelect: {
            border: "none",
            boxShadow: "none",
            borderRadius: 0,
            fontWeight: 400,
            fontSize: 12,
            letterSpacing: 2,
            border: "1px solid white",
            paddingTop: 0,
            paddingRight: 0,
            paddingBottom: 0,
            color: theme.color.themeColor,
            backgroundColor: theme.color.compColor,
            "::placeholder": {
                letterSpacing: 1,
                color: theme.color.fontColor,
                fontSize: 12,
            },
        },
        inputText: {
            border: "none",
            boxShadow: "none",
            borderRadius: 0,
            fontWeight: 400,
            fontSize: 12,
            letterSpacing: 2,
            marginBottom: 0,
            padding: 0,
            width: "100%",
            marginLeft: 5,
            color: theme.color.themeColor,
            borderBottom: `solid whitesmoke 1px`,
            backgroundColor: theme.color.compColor,
            "::placeholder": {
                letterSpacing: 1,
                color: theme.color.themeColor,
                fontSize: 12,
            },
        },
        searchWrapper: {
            position: "relative",
            display: "flex",
            flexDirection: "row",
        },
        searchIcon: {
            color: theme.color.themeColor,
            marginRight: 12,
            ":hover": {
                cursor: "pointer",
                opacity: ".8",
            },
        },
        closeIcon: {
            color: theme.color.fontColor,
            position: "absolute",
            top: 5,
            right: 5,
            fontSize: 16,
            ":hover": {
                cursor: "pointer",
                opacity: ".8",
            },
        },
        labelDropdown: {
            fontSize: 12,
            color: theme.color.fontColor,
            whiteSpace: "nowrap",
        },
        labelText: {
            fontSize: 12,
            color: theme.color.fontColor,
            whiteSpace: "nowrap",
        },
    });
