import {
    HIDE_MESSAGE,
    ON_HIDE_LOADER,
    ON_SHOW_LOADER,
    SHOW_MESSAGE,
    SIGNIN_USER_SUCCESS,
    SIGNOUT_USER_SUCCESS,
    SET_AUTH_USER,
    SET_COMPANY_LIST,
    SHOW_AUTH,
    SET_WELCOME_MESSAGE,
} from "constants/ActionTypes";

const INIT_STATE = {
    loader: false,
    alertMessage: "",
    showMessage: false,
    showWelcome: false,
    showAuth: false,
    companyList: [],
    authUser: JSON.parse(sessionStorage.getItem("user_data")),
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SIGNIN_USER_SUCCESS: {
            return {
                ...state,
                loader: false,
                authUser: action.payload,
            };
        }
        case SIGNOUT_USER_SUCCESS: {
            return {
                ...state,
                authUser: null,
                loader: false,
            };
        }
        case SET_COMPANY_LIST: {
            return {
                ...state,
                companyList: action.payload,
            };
        }
        case SHOW_AUTH: {
            return {
                ...state,
                loader: false,
                showAuth: action.payload,
            };
        }
        case SHOW_MESSAGE: {
            return {
                ...state,
                alertMessage: action.payload,
                showMessage: true,
                loader: false,
            };
        }
        case HIDE_MESSAGE: {
            return {
                ...state,
                alertMessage: "",
                showMessage: false,
                loader: false,
            };
        }
        case ON_SHOW_LOADER: {
            return {
                ...state,
                loader: true,
            };
        }
        case ON_HIDE_LOADER: {
            return {
                ...state,
                loader: false,
            };
        }
        case SET_AUTH_USER: {
            return {
                ...state,
                authUser: action.payload,
            };
        }
        case SET_WELCOME_MESSAGE: {
            return {
                ...state,
                showWelcome: action.payload,
            };
        }
        default:
            return state;
    }
};
