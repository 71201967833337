import React, { Component, Suspense, lazy } from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { ErrorBoundary } from "react-error-boundary";

import AuthTemplate from "components/Auth";
import Spinner from "components/Spinner";
import ErrorFallback from 'components/ErrorFallback';

const Password = lazy(() => import("./routes/Password"));
const Pin = lazy(() => import("./routes/Pin"));
const Terms = lazy(() => import("./routes/Terms"));
const Forgot = lazy(() => import("./routes/Forgot"));
const Login = lazy(() => import("./routes/Login"));
class Auth extends Component {
    render() {
        const { match, loader, authUser, theme } = this.props;
        return loader ? (
            <Spinner height={"100vh"} />
        ) : (
            <AuthTemplate theme={theme}>
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <Suspense fallback={<Spinner fallback={true} />}>
                        <Switch>
                            {authUser && authUser.RequirePwdReset && authUser.Login && (
                                <Route
                                    path={`${match.url}/password`}
                                    component={Password}
                                />
                            )}
                            {authUser && authUser.RequirePinReset && authUser.Login && (
                                <Route path={`${match.url}/pin`} component={Pin} />
                            )}
                            {authUser && authUser.RequireTermsAck && (
                                <Route path={`${match.url}/terms`} component={Terms} />
                            )}
                            <Route path={`${match.url}/forgot`} component={Forgot} />
                            <Route path={`${match.url}/:id`} component={Login} />
                            <Route component={Login} />
                        </Switch>
                    </Suspense>
                </ErrorBoundary>
            </AuthTemplate>
        );
    }
}

const mapStateToProps = ({ auth, app }) => {
    const { loader, authUser } = auth;
    const { theme } = app;
    return { loader, authUser, theme };
};

export default connect(mapStateToProps)(Auth);
