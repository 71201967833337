import {
    SET_CLIENT_LIST,
    SET_CLIENT_LOGIN_LIST,
    SET_CLIENT_OBJECT,
    SET_CLIENT_FILTER,
    SET_ACTIVE_CLIENT,
} from "constants/ActionTypes";

const INIT_STATE = {
    loader: false,
    clientList: [],
    clientLoginList: [],
    clientListFilter: [],
    clientObject: null,
    activeClient: 0,
    clientFilterList: [
        {
            title: "comp.table.header.client",
            width: "16%",
            active: true,
            sortable: true,
            sortTitle: "name",
            order: false,
            mobile: true,
        },
        {
            title: "comp.table.header.country",
            width: "14%",
            active: false,
            sortable: true,
            sortTitle: "Country",
            order: false,
            mobile: false,
        },
        {
            title: "comp.table.header.contractDate",
            width: "14%",
            active: false,
            sortable: true,
            sortTitle: "ContractDate",
            order: false,
            mobile: false,
        },
        {
            title: "comp.table.header.capitalization",
            width: "14%",
            active: false,
            sortable: true,
            sortTitle: "MarketCap",
            order: false,
            mobile: false,
        },
        {
            title: "comp.table.header.accountManager",
            width: "14%",
            active: false,
            sortable: true,
            sortTitle: "description",
            order: false,
            mobile: false,
        },
        {
            title: "comp.table.header.users",
            width: "14%",
            active: false,
            sortable: true,
            sortTitle: "Users",
            order: false,
            mobile: true,
        },
        {
            title: "comp.table.header.logins",
            width: "14%",
            active: false,
            sortable: true,
            sortTitle: "Logins",
            order: false,
            mobile: true,
        },
        {
            title: "comp.table.header.devices",
            width: "14%",
            active: false,
            sortable: true,
            sortTitle: "Devices",
            order: false,
            mobile: true,
        },
    ],
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_CLIENT_LIST: {
            return {
                ...state,
                clientList: action.payload,
                clientListFilter: [],
            };
        }
        case SET_CLIENT_LOGIN_LIST: {
            return {
                ...state,
                clientLoginList: action.payload,
                clientListFilter: [],
            };
        }
        case SET_CLIENT_OBJECT: {
            return {
                ...state,
                clientObject: action.payload,
            };
        }
        case SET_CLIENT_FILTER: {
            return {
                ...state,
                clientFilterList: action.payload,
            };
        }
        case SET_ACTIVE_CLIENT: {
            return {
                ...state,
                activeClient: action.payload,
            };
        }
        default:
            return state;
    }
};
