import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import { fadeInLeft } from "react-animations";
import { StyleSheet, css } from "aphrodite";
import { Input, Modal, Label, ModalBody } from "reactstrap";
import { injectIntl } from "react-intl";

import { userSignIn, userGetCompanies } from "actions/Auth";
import { setShowHelp } from "actions/App";
class MenuList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectingCompany: false,
            show: false,
            clientId: "",
        };
    }
    isActive = (page) => {
        return this.props.location.pathname.includes(page.replace('/all', ''));
    };
    checkPermissions = (permissions) => {
        const { authUser } = this.props;
        if (
            !permissions ||
            permissions.filter((p) => authUser.Permissions.includes(p)).length >
                0
        ) {
            return true;
        }
        return false;
    };
    checkProduct = (product) => {
        const { authUser } = this.props;
        if (!product || authUser.Settings.Product.includes(product)) {
            return true;
        }
        return false;
    };
    getCompanies = (e) => {
        e.stopPropagation();
        const { authUser, userGetCompanies } = this.props;
        userGetCompanies({ Username: authUser.Email });
    };
    switchCompany = (e) => {
        this.setState({ show: false, cliendId: "", selectingCompany: false });
        let concatUserName = "576562" + this.props.authUser.Email;
        this.props.userSignIn(
            {
                Username: concatUserName,
                Password: e.target.value,
                ClientId: this.state.clientId,
            },
            true
        );
    };
    render() {
        const { intl, authUser, theme, hideSidebar, companyList, showHelp } =
            this.props;
        const { selectingCompany, show } = this.state;
        this.styles = styles(theme);
        const list = [
            {
                id: "pages.home.TYPE",
                name: "home",
            },
            {
                id: "pages.tickets.TYPES",
                name: "tickets/all",
            },
            {
                id: "pages.locks.TYPES",
                name: "locks/all",
            },
            {
                id: "pages.users.TYPES",
                name: "users/all",
            },
            {
                id: "pages.reports.TYPES",
                name: "reports",
                permissions: [
                    "ReportsActivity",
                    "ReportsSnapshot",
                    "ReportsReports",
                ],
            },
            {
                id: "pages.client.TYPES",
                name: "client/all",
                permissions: ["InternalClientList"],
            },
            {
                id: "pages.settings.TYPES",
                name: "settings",
                hr: true,
            },
            {
                id: "pages.help.TYPES",
                name: "help",
                action: () => this.props.setShowHelp(!showHelp),
            },
            {
                id: "pages.logout.TYPE",
                name: "logout",
            },
        ];
        authUser.Settings.Product.includes("isolation") &&
            list.splice(2, 0, {
                id: "pages.machines.TYPES",
                name: "machines/all",
            });
        authUser.Settings.Product.includes("isolation") &&
            authUser.Settings.SecureEnabled &&
            list.splice(5, 0, {
                id: "pages.carSeals.TYPES",
                name: "carseals/all",
            });
        return (
            <ul className={`nav-menu ${css(this.styles.wrapper)}`}>
                <li className={css(this.styles.firstList)}>
                    <NavLink
                        to={`/app/users/display/${authUser.Id}`}
                        className={css(this.styles.navLink)}
                    >
                        {hideSidebar && (
                            <img
                                src={
                                    "assets/images/Icons/user/userIconDefault.png"
                                }
                                className={css(this.styles.avatar)}
                            />
                        )}
                        {!hideSidebar && (
                            <span
                                className={css(
                                    this.isActive(authUser.Id)
                                        ? this.styles.activeNav
                                        : this.styles.link
                                )}
                            >
                                {authUser.FirstName} {authUser.LastName}
                            </span>
                        )}
                    </NavLink>
                    {!hideSidebar && !selectingCompany && (
                        <p
                            className={css(this.styles.company)}
                            onClick={(e) => {
                                this.getCompanies(e);
                                this.setState({ selectingCompany: true });
                            }}
                        >
                            {authUser.ClientName}
                        </p>
                    )}
                    {selectingCompany && companyList.length == 1 && (
                        <p className={css(this.styles.company)}>
                            {authUser.ClientName}
                        </p>
                    )}
                    {selectingCompany && companyList.length > 1 && (
                        <Input
                            className={css(this.styles.select)}
                            type="select"
                            onChange={(e) =>
                                e.target.value != authUser.ClientId
                                    ? this.setState({
                                          clientId: e.target.value,
                                          show: true,
                                      })
                                    : this.setState({ selectingCompany: false })
                            }
                            defaultValue={authUser.ClientId}
                            autoFocus
                        >
                            {companyList.map((company) => (
                                <option key={company.Id} value={company.Id}>
                                    {company.CompanyName}
                                </option>
                            ))}
                        </Input>
                    )}
                    <Modal isOpen={show} padding={50} autoFocus={false}>
                        <ModalBody>
                            <Label className={css(this.styles.label)}>
                                {intl.formatMessage({ id: "form.password" })}
                            </Label>
                            <Input
                                ref={(input) => {
                                    this.nameInput = input;
                                }}
                                className={css(this.styles.input)}
                                type="password"
                                placeholder="Password"
                                onKeyDown={(e) =>
                                    e.keyCode === 13 && this.switchCompany(e)
                                }
                                autoFocus
                            />
                        </ModalBody>
                        <i
                            className={`material-icons ${css(
                                this.styles.closeIcon
                            )}`}
                            onClick={() =>
                                this.setState({
                                    cliendId: "",
                                    show: false,
                                    selectingCompany: false,
                                })
                            }
                        >
                            close
                        </i>
                    </Modal>
                </li>
                <hr className={css(this.styles.hr)} />
                {list.map((item) => {
                    let Icon = item.Icon;
                    return (
                        this.checkPermissions(item.permissions) &&
                        this.checkProduct(item.product) && (
                            <div key={item.id}>
                                {item.hr && (
                                    <hr className={css(this.styles.hr)} />
                                )}
                                <li
                                    className={css(
                                        this.styles.list,
                                        this.styles.fadeInLeft
                                    )}
                                >
                                    {item.action ? (
                                        <div
                                            onClick={item.action}
                                            className={css(this.styles.navLink)}
                                        >
                                            <div
                                                className={css(
                                                    this.styles.block
                                                )}
                                            >
                                                <span
                                                    className={css(
                                                        this.isActive(item.name)
                                                            ? this.styles
                                                                  .activeNav
                                                            : this.styles.link
                                                    )}
                                                >
                                                    {!hideSidebar &&
                                                        intl.formatMessage({
                                                            id: item.id,
                                                        })}{" "}
                                                </span>
                                            </div>
                                        </div>
                                    ) : (
                                        <NavLink
                                            to={`/app/${item.name}`}
                                            className={css(this.styles.navLink)}
                                        >
                                            <div
                                                className={css(
                                                    this.styles.block
                                                )}
                                            >
                                                <span
                                                    className={css(
                                                        this.isActive(item.name)
                                                            ? this.styles
                                                                  .activeNav
                                                            : this.styles.link
                                                    )}
                                                >
                                                    {!hideSidebar &&
                                                        intl.formatMessage({
                                                            id: item.id,
                                                        })}{" "}
                                                </span>
                                            </div>
                                        </NavLink>
                                    )}
                                </li>
                            </div>
                        )
                    );
                })}
            </ul>
        );
    }
}

const mapStateToProps = ({ app, auth }) => {
    const { theme, hideSidebar, showHelp } = app;
    const { companyList } = auth;
    return { theme, hideSidebar, companyList, showHelp };
};

export default injectIntl(
    withRouter(
        connect(mapStateToProps, { userSignIn, userGetCompanies, setShowHelp })(
            MenuList
        )
    )
);

const styles = (theme) =>
    StyleSheet.create({
        wrapper: {
            backgroundColor: theme.color.menuColor,
            color: theme.color.fontColor,
        },
        firstList: {
            padding: `${"2vh" || 10} 20px`,
            height: 60,
        },
        navLink: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            cursor: "pointer",
            fontSize: 14,
        },
        list: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: `0 20px`,
            maxHeight: 45,
            height: 45,
            "@media(max-height:800px)": {
                maxHeight: 40,
                height: 40,
            },
        },
        icon: {
            margin: 0,
            fontSize: 20,
            color: theme.color.fontColor,
            transition: "all .2s ease-in-out",
        },
        activeIcon: {
            margin: 0,
            fontSize: 20,
            color: theme.color.themeColor,
        },
        icons: {
            height: 25,
            display: "block",
            float: "left",
            transition: "all .2s ease-in-out",
        },
        iconsShort: {
            display: "block",
            float: "left",
            marginBottom: 13,
            "@media(max-height:800px)": {
                marginBottom: 8,
            },
        },
        iconClass: {
            transition: "all .2s ease-in-out",
        },
        avatar: {
            display: "inline-block",
            backgroundColor: theme.color.themeColor,
            width: 20,
            height: 20,
            borderRadius: "50%",
        },
        link: {
            color: theme.color.fontColor,
            fontWeight: 400,
            marginLeft: 10,
            display: "inline-block",
            textTransform: "uppercase",
            transition: "all .2s ease-in-out",
            ":hover": {
                color: theme.color.themeColor,
                pointer: "cursor",
            },
        },
        activeNav: {
            marginLeft: 10,
            color: theme.color.themeColor,
            fontWeight: 400,
            textTransform: "uppercase",
        },
        company: {
            cursor: "pointer",
            marginLeft: 10,
            fontSize: 12,
            marginTop: -2,
            color: theme.color.fontColor,
            ":hover": {
                color: theme.color.fontColor,
            },
        },
        hr: {
            border: `solid .5px ${theme.color.borderColor}`,
            margin: `5px 20px`,
        },
        fadeInLeft: {
            animationName: fadeInLeft,
            animationDuration: "1.2s",
        },
        select: {
            border: "none",
            boxShadow: "none",
            borderRadius: 0,
            fontWeight: 400,
            fontSize: 12,
            letterSpacing: 2,
            height: 20,
            color: theme.color.themeColor,
            padding: 0,
            paddingLeft: 5,
            backgroundColor: theme.color.pageColor,
            "::placeholder": {
                letterSpacing: 1,
                color: theme.color.fontColor,
                fontSize: 12,
            },
        },
        input: {
            border: "none",
            boxShadow: "none",
            borderRadius: 0,
            padding: "20px 0 5px 0",
            fontWeight: 400,
            fontSize: 14,
            letterSpacing: 2,
            width: "95%",
            color: theme.color.themeColor,
            borderBottom: `solid ${theme.color.borderColor} 1px`,
            backgroundColor: theme.color.compColor,
            "::placeholder": {
                letterSpacing: 1,
                color: theme.color.fontColor,
                fontSize: 14,
            },
        },
        closeIcon: {
            color: theme.color.fontColor,
            position: "absolute",
            top: 10,
            right: 10,
            fontSize: 16,
            ":hover": {
                cursor: "pointer",
                opacity: ".8",
            },
        },
    });
