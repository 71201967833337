import React from "react";
import { connect } from "react-redux";
import { StyleSheet, css } from "aphrodite";
import { fadeIn } from "react-animations";
import { injectIntl } from "react-intl";
import { Link, withRouter } from "react-router-dom";
import HeaderFilter from "components/Header/table/filter";
import Search from "components/Search";
import SearchUsers from "components/Search/user";
import SearchUserList from "components/Search/userList";
import SearchTicket from "components/Search/ticket";
import SearchSeal from "components/Search/seal";
import SearchMachine from "components/Search/machine";
import SearchJobTemplate from "components/Search/jobTemplate";
import SearchAudit from "components/Search/audit";
import SearchLock from "components/Search/lock";
import SearchSealLock from "components/Search/sealLock";
import SearchSealActivity from "components/Search/sealActivity";
import SearchActivity from "components/Search/activity";
import Dropdown from "components/Dropdowns";
import Tooltip from "components/Tooltips";
import CSV from "components/Export";

class HeaderTable extends React.Component {
    constructor(props) {
        super(props);
    }
    handleNav = (e) => {
        this.props.history.push(`${this.props.addLink}`);
        e.stopPropagation();
    };
    render() {
        const {
            intl,
            linkIcon,
            filter,
            icon,
            Icon,
            total,
            subTitle,
            refresh,
            actionFilter,
            filters,
            search,
            searchUsers,
            searchUsersSmall,
            smallTitle,
            searchUserList,
            searchMachineList,
            searchSealList,
            searchAuditList,
            searchTicketList,
            searchLockList,
            searchSealLockList,
            searchActivitySealList,
            searchActivity,
            searchActiveLockouts,
            searchJobTemplates,
            update,
            headers,
            data,
            add,
            menu,
            list,
            link,
            disable,
            iconTitle,
            width,
            exportList,
            addOne,
            theme,
            border,
        } = this.props;
        this.styles = styles(theme, border);
        return (
            <div className={css(this.styles.wrapper, this.styles.fadeIn)}>
                <div>
                    {Icon ? (
                        <div className={css(this.styles.iconWrapper)}>
                            {Icon}
                        </div>
                    ) : (
                        icon && (
                            <div className={css(this.styles.iconWrapper)}>
                                <i
                                    className={`material-icons ${css(
                                        this.styles.icon
                                    )}`}
                                >
                                    {icon}
                                </i>
                                {total != 0 && !subTitle && (
                                    <div className={css(this.styles.notify)}>
                                        {total}
                                    </div>
                                )}
                            </div>
                        )
                    )}
                    {filter && (
                        <HeaderFilter
                            list={list}
                            data={filters}
                            action={actionFilter}
                        />
                    )}

                    {subTitle && (
                        <div className={css(this.styles.subInfo)}>
                            <p className={css(this.styles.total)}>
                                -{" "}
                                {subTitle &&
                                    intl.formatMessage({ id: subTitle })}
                                : {total}
                            </p>
                        </div>
                    )}
                    {search && (
                        <div className={css(this.styles.search)}>
                            {
                                <Search
                                    update={update}
                                    filter={headers}
                                    searchType={headers}
                                    data={data}
                                />
                            }
                        </div>
                    )}
                    {searchUserList && (
                        <div className={css(this.styles.searchUsers)}>
                            <SearchUserList
                                update={update}
                                filter={headers}
                                searchType={headers}
                                data={data}
                            />
                        </div>
                    )}
                </div>
                {searchUsersSmall && (
                    <div className={css(this.styles.searchUsersSmall)}>
                        {smallTitle && (
                            <h2>{intl.formatMessage({ id: smallTitle })}</h2>
                        )}
                        <SearchUsers searchUsersSmall={searchUsersSmall} />
                    </div>
                )}
                {searchUsers && (
                    <div style={{ width: "100%" }}>
                        <SearchUsers action={this.props.action} />
                    </div>
                )}
                {searchSealList && width > 800 && (
                    <div style={{ width: "100%" }}>
                        <SearchSeal />
                    </div>
                )}
                {searchMachineList && width > 800 && (
                    <div style={{ width: "100%" }}>
                        <SearchMachine action={this.props.action} />
                    </div>
                )}
                {searchJobTemplates && width > 800 && (
                    <div style={{ width: "100%" }}>
                        <SearchJobTemplate action={this.props.action} />
                    </div>
                )}
                {searchTicketList && width > 800 && (
                    <div style={{ width: "100%" }}>
                        <SearchTicket action={this.props.action} />
                    </div>
                )}
                {searchActiveLockouts && width > 800 && (
                    <div className={css(this.styles.searchTickets)}>
                        <SearchLockouts action={this.props.action} />
                    </div>
                )}
                {searchAuditList && width > 800 && (
                    <div style={{ width: "100%" }}>
                        <SearchAudit />
                    </div>
                )}
                {searchLockList && width > 800 && (
                    <div style={{ width: "100%" }}>
                        <SearchLock action={this.props.action} />
                    </div>
                )}
                {searchSealLockList && width > 800 && (
                    <div style={{ width: "100%" }}>
                        <SearchSealLock />
                    </div>
                )}
                {searchActivitySealList && width > 800 && (
                    <div style={{ width: "100%" }}>
                        <SearchSealActivity />
                    </div>
                )}
                {searchActivity && width > 800 && (
                    <div style={{ width: "100%" }}>
                        <SearchActivity />
                    </div>
                )}
                <div>
                    {addOne && (
                        <div
                            onClick={this.handleNav}
                            className={css(this.styles.export)}
                        >
                            <i
                                id="addOne"
                                className="material-icons"
                                style={{
                                    fontSize: 24,
                                    color: theme.color.themeColor,
                                    cursor: "pointer",
                                }}
                            >
                                add_circle_outline
                            </i>
                            <Tooltip
                                message={"comp.form.add"}
                                target="addOne"
                            />
                        </div>
                    )}
                    {exportList && (
                        <div className={css(this.styles.export)}>
                            <CSV data={exportList} />
                            <Tooltip
                                message={"comp.button.export"}
                                target="exportFile"
                            />
                        </div>
                    )}
                </div>
                {refresh && !link && !add && (
                    <div
                        className={css(this.styles.refresh)}
                        onClick={() => this.props.action()}
                    >
                        <i
                            id="refreshTables"
                            className={`material-icons ${css(
                                this.styles.icon
                            )}`}
                        >
                            sync
                        </i>
                        <Tooltip
                            message={"comp.button.refresh"}
                            target="refreshTables"
                        />
                    </div>
                )}
                {refresh && !link && add && (
                    <div className={css(this.styles.refresh)}>
                        <i
                            onClick={() => this.props.action()}
                            id="refreshTables"
                            className={`material-icons ${css(
                                this.styles.icon
                            )}`}
                        >
                            sync
                        </i>
                        <Tooltip
                            message={"comp.button.refresh"}
                            target="refreshTables"
                        />
                        <div className={css(this.styles.add)}>
                            <li className="list-inline-item mail-tour">
                                <Dropdown menu={menu}>
                                    <i
                                        id="addDropdown"
                                        className={`material-icons ${css(
                                            this.styles.icon
                                        )}`}
                                    >
                                        add_circle_outline
                                    </i>
                                    <Tooltip
                                        message={"comp.form.add"}
                                        target="addDropdown"
                                    />
                                </Dropdown>
                            </li>
                        </div>
                    </div>
                )}
                {!refresh && !link && add && (
                    <div className={css(this.styles.add)}>
                        <li className="list-inline-item mail-tour">
                            <Dropdown menu={menu}>
                                <i
                                    id="addDropdown"
                                    className={`material-icons ${css(
                                        this.styles.icon
                                    )}`}
                                >
                                    add_circle_outline
                                </i>
                                <Tooltip
                                    message={"comp.form.add"}
                                    target="addDropdown"
                                />
                            </Dropdown>
                        </li>
                    </div>
                )}
                {link && refresh && (
                    <div className={css(this.styles.iconWrapper)}>
                        {!disable ? (
                            <Link
                                to={link}
                                className={css(this.styles.iconLink)}
                            >
                                <i
                                    id="addTableHeader"
                                    className={`material-icons ${css(
                                        this.styles.icon
                                    )}`}
                                >
                                    {linkIcon}
                                </i>
                                <Tooltip
                                    message={iconTitle}
                                    target="addTableHeader"
                                />
                            </Link>
                        ) : (
                            <div className={css(this.styles.iconDisable)}>
                                <i
                                    id="addTableHeader"
                                    className={`material-icons ${css(
                                        this.styles.icon
                                    )}`}
                                >
                                    {linkIcon}
                                </i>
                                <Tooltip
                                    message={iconTitle}
                                    target="addTableHeader"
                                />
                            </div>
                        )}
                        <div
                            className={css(this.styles.refreshLink)}
                            onClick={() => this.props.action()}
                        >
                            <i
                                id="refreshTable"
                                className={`material-icons ${css(
                                    this.styles.icon
                                )}`}
                            >
                                sync
                            </i>
                            <Tooltip
                                message={"comp.button.refresh"}
                                target="refreshTable"
                            />
                        </div>
                    </div>
                )}
                {link && !refresh && (
                    <div className={css(this.styles.iconWrapper)}>
                        {!disable ? (
                            <Link
                                to={link}
                                className={css(this.styles.iconLink)}
                            >
                                <i
                                    id="iconTitleTable"
                                    className={`material-icons ${css(
                                        this.styles.icon
                                    )}`}
                                >
                                    {linkIcon}
                                </i>
                                <Tooltip
                                    message={iconTitle}
                                    target="iconTitleTable"
                                />
                            </Link>
                        ) : (
                            <div className={css(this.styles.iconDisable)}>
                                <i
                                    id="iconTitleTable"
                                    className={`material-icons ${css(
                                        this.styles.icon
                                    )}`}
                                >
                                    {linkIcon}
                                </i>
                                <Tooltip
                                    message={iconTitle}
                                    target="iconTitleTable"
                                />
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }
}

HeaderTable.defaultProps = {
    title: "blank",
    filter: false,
    border: true,
    icon: null,
    Icon: null,
    total: 0,
    subTitle: null,
};

const mapStateToProps = ({ app }) => {
    const { theme, width } = app;
    return { theme, width };
};

export default withRouter(injectIntl(connect(mapStateToProps)(HeaderTable)));

const styles = (theme, border) =>
    StyleSheet.create({
        wrapper: {
            paddingBottom: border ? 5 : 0,
            marginBottom: 0,
            borderBottom: border
                ? `solid .5px ${theme.color.borderColor}`
                : "none",
            textAlign: "left",
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "space-between",
        },
        subInfo: {
            display: "block",
            float: "right",
            textAlign: "right",
        },
        refresh: {
            display: "block",
            fontSize: 14,
            fontWeight: 400,
            float: "right",
            textAlign: "right",
            justifySelf: "flexEnd",
            marginTop: 0,
            marginBottom: 10,
            color: theme.color.themeColor,
        },
        export: {
            fontSize: 14,
            fontWeight: 400,
            float: "right",
            display: "flex",
        },
        refreshLink: {
            display: "block",
            fontSize: 14,
            fontWeight: 400,
            float: "right",
            textAlign: "right",
            marginTop: 0,
            marginRight: 8,
            color: theme.color.themeColor,
        },
        add: {
            display: "block",
            float: "right",
            margin: 0,
            color: theme.color.themeColor,
        },
        search: {
            display: "block",
            float: "left",
            margin: 0,
            marginBottom: 2,
            color: theme.color.themeColor,
        },
        searchUsers: {
            display: "block",
            float: "left",
            margin: 0,
            marginLeft: 15,
            marginTop: 5,
            maxWidth: "70%",
            color: theme.color.themeColor,
        },
        searchUsersSmall: {
            width: "100%",
            display: "flex",
            flexDirection: "column",
        },
        searchTickets: {
            width: "80%",
            display: "inline-block",
            float: "left",
            margin: 0,
            maxWidth: "80%",
            color: theme.color.themeColor,
            "@media (maxWidth: 800px)": {
                width: "0%",
                maxWidth: "0%",
                display: "none",
            },
        },
        total: {
            margin: 0,
            padding: "8.5px 0 0 7px",
            color: theme.color.themeColor,
        },
        iconWrapper: {
            display: "block",
            float: "left",
            fontSize: 22,
            position: "relative",
            paddingTop: 3,
        },
        icon: {
            width: 28,
            height: 28,
            marginLeft: 6,
            ":hover": {
                textDecoration: "none",
                opacity: ".8",
                cursor: "pointer",
            },
        },
        notify: {
            height: 18,
            width: 18,
            backgroundColor: "red",
            borderRadius: "50%",
            position: "absolute",
            top: 0,
            right: 0,
            margin: 0,
            color: "#fff",
            fontSize: 12,
            textAlign: "center",
        },
        fadeIn: {
            animationName: fadeIn,
            animationDuration: ".6s",
        },
        iconLink: {
            color: theme.color.themeColor,
            fontSize: 14,
            fontWeight: 400,
            float: "right",
        },
        iconDisable: {
            color: "#e3e5e7",
            fontSize: 14,
            fontWeight: 400,
            float: "right",
        },
    });
