import {
    CREATE_FEEDBACK,
    FETCH_FEEDBACK,
    SET_FEEDBACK,
    FETCH_FEEDBACK_ITEM,
    ARCHIVE_FEEDBACK,
    SET_FEEDBACK_ITEM,
} from "constants/ActionTypes";

export const createFeedback = (data) => {
    return {
        type: CREATE_FEEDBACK,
        payload: {
            url: "/feedback/create/",
            data,
        },
    };
};

export const fetchFeedback = () => {
    return {
        type: FETCH_FEEDBACK,
        payload: {
            url: "/feedback/list/",
            data: {},
        },
    };
};

export const setFeedback = (data) => {
    return {
        type: SET_FEEDBACK,
        payload: data,
    };
};

export const fetchFeedbackItem = (Id) => {
    return {
        type: FETCH_FEEDBACK_ITEM,
        payload: {
            url: "/feedback/byid/",
            data: {
                Id,
            },
        },
    };
};

export const archiveFeedback = (data) => {
    return {
        type: ARCHIVE_FEEDBACK,
        payload: {
            url: "/feedback/archive/",
            data,
        },
    };
};

export const setFeedbackItem = (data) => {
    return {
        type: SET_FEEDBACK_ITEM,
        payload: data,
    };
};
