import enLang from "./entries/en-US";
import zhLang from "./entries/zh-Hans-CN";
import arLang from "./entries/ar_SA";
import itLang from "./entries/it_IT";
import esLang from "./entries/es_ES";
import frLang from "./entries/fr_FR";
import deLang from "./entries/de_DE";
import ptLang from "./entries/pt_PT";
import heLang from "./entries/he_HE";
import nlLang from "./entries/nl_NL";
import koLang from "./entries/ko_KR";

const AppLocale = {
    en: enLang,
    zh: zhLang,
    ar: arLang,
    it: itLang,
    es: esLang,
    fr: frLang,
    de: deLang,
    pt: ptLang,
    he: heLang,
    nl: nlLang,
    ko: koLang,
};

export default AppLocale;
