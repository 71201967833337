import { all, call, fork, put, takeEvery, select } from "redux-saga/effects";
import { history } from "../store/history";
import { API } from "../api";
import { LockIcon, TicketIcon, MachineIcon, UserIcon } from "util/icons";
import {
    FETCH_LOCKS_LIST_WORK_ORDER,
    FETCH_LOCKS_BY_ID,
    FETCH_LOCKS_BY_MAC,
    FETCH_LOCKS_BY_SERIAL,
    CREATE_LOCK,
    EDIT_LOCK,
    DELETE_LOCK,
    FETCH_LOCKS_LIST_AVAILABLE,
    FETCH_LOCK_ACTIVITY,
    SEARCH_LOCKS,
    FETCH_LOCKS_SEARCH_SELECT,
} from "constants/ActionTypes";
import {
    setLocks,
    setLock,
    setLockActivity,
    setSearchedLocks,
    setLocksSearchSelect,
} from "actions/Locks";
import { userSignOut } from "actions/Auth";
import { showMessage, setLoader } from "actions/App";
import { sortList } from "util";

const getHeaders = (state) => state.locks.lockFilterList;

function* requestLocks({ payload }) {
    try {
        const locks = yield call(API, payload.url, payload.data);
        if (locks.message) {
            if (locks.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(locks.message, "message"));
            }
        } else if (!locks.data) {
            yield put(setLocks([]));
        } else {
            locks.data.map((item) => {
                item.Icon = LockIcon;
            });
            yield put(setLoader(false));
            const headers = yield select(getHeaders);
            const activeHeader = headers.find((item) => item.active);
            locks.data = sortList(
                activeHeader.order,
                locks.data,
                activeHeader.sortTitle
            );
            yield put(setLocks(locks.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestLocksSearchSelect({ payload }) {
    try {
        const locks = yield call(API, payload.url, payload.data);
        if (locks.message) {
            if (locks.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(locks.message, "message"));
            }
        } else if (!locks.data) {
            yield put(setLocksSearchSelect([]));
        } else {
            yield put(setLoader(false));
            yield put(setLocksSearchSelect(locks.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestLock({ payload }) {
    try {
        const lock = yield call(API, payload.url, payload.data);
        if (lock.message) {
            if (lock.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(locks.message, "message"));
            }
        } else if (lock.data === "") {
            yield put(showMessage("notifications.error.fail.notClientLock"));
            yield history.push("/app/locks/all");
        } else {
            const image = yield call(API, "/image/", {
                Filename: lock.data.Id,
            });
            image.data
                ? (lock.data.Image = `data:image/png;base64, ${image.data}`)
                : (lock.data.Icon = LockIcon);
            lock.data.name = lock.data.Label;
            lock.data.description = lock.data.SerialNumber;
            lock.data.button = "Unlock";
            (lock.data.id = lock.data.Id),
                (lock.data.header = lock.data.Label),
                (lock.data.type = "locks"),
                (lock.data.color =
                    "#" +
                    lock.data.MacAddress[0] +
                    lock.data.MacAddress[1] +
                    lock.data.MacAddress[3]),
                (lock.data.map = [
                    {
                        color:
                            "#" +
                            lock.data.MacAddress[0] +
                            lock.data.MacAddress[1] +
                            lock.data.MacAddress[3],
                        lat: 40.421268,
                        lng: -111.8836463,
                    },
                ]),
                (lock.data.info = [
                    {
                        name: "Serial",
                        description: lock.data.SerialNumber,
                    },
                    {
                        name: "Mac Address",
                        description: lock.data.MacAddress,
                    },
                    {
                        name: "Unlock Method",
                        description: "MANUAL",
                    },
                    {
                        name: "Click-Code",
                        description: "On",
                    },
                ]),
                (lock.data.activity = [
                    {
                        id: "demo1",
                        name: "LOTO DEMO",
                        description: "Created Ticket",
                        date: "Oct 6, 2018",
                        time: "10:22am",
                        status: true,
                    },
                    {
                        id: "demo2",
                        name: "LOTO DEMO",
                        description: "Placed Lock",
                        date: "Oct 6, 2018",
                        time: "10:22am",
                        status: false,
                    },
                    {
                        id: "demo3",
                        name: "LOTO DEMO",
                        description: "Added Lockout",
                        date: "Oct 6, 2018",
                        time: "10:22am",
                        status: true,
                    },
                    {
                        id: "demo4",
                        name: "LOTO DEMO",
                        description: "Removed Lockout",
                        date: "Oct 6, 2018",
                        time: "10:22am",
                        status: false,
                    },
                ]),
                (lock.data.reports = {
                    users: {
                        total: 12,
                        live: 8,
                    },
                    groups: {
                        total: 2,
                        live: 2,
                    },
                    active: {
                        hour: 2,
                        today: 10,
                        week: 19,
                        month: 26,
                    },
                });
            yield put(setLock(lock.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestCreateLock({ payload }) {
    try {
        const required = {
            Label: payload.data.Label,
            SerialNumber: payload.data.SerialNumber,
        };
        for (var key in required) {
            if (required[key] === undefined || required[key] === "") {
                yield put(
                    showMessage(`notifications.error.blank.${key}`, "warning")
                );
                return;
            }
        }
        const lock = yield call(API, payload.url, payload.data);
        if (lock.message) {
            if (lock.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(lock.message, "message"));
            }
        } else if (lock.validationError) {
            var error = Object.keys(lock.validationError).map(function (key) {
                return lock.validationError[key];
            });
            yield put(showMessage(error, "message"));
        } else {
            yield put(showMessage("notifications.success.add", "success"));
            if (!payload.another) {
                yield history.push(`/app/locks/display/${lock.data.Id}`);
            }
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestEditLock({ payload }) {
    try {
        const required = {
            Label: payload.data.Label,
            SerialNumber: payload.data.SerialNumber,
            SiteName: payload.data.SiteName,
        };
        for (var key in required) {
            if (required[key] === undefined || required[key] === "") {
                yield put(
                    showMessage(`notifications.error.blank.${key}`, "warning")
                );
                return;
            }
        }
        const lock = yield call(API, payload.url, payload.data);
        if (lock.message) {
            if (lock.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(lock.message, "message"));
            }
        } else {
            yield put(showMessage("notifications.success.edit", "success"));
            yield history.push(`/app/locks/display/${payload.data.id}`);
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestDeleteLock({ payload }) {
    try {
        if (!payload.data) {
            yield put(showMessage(`notifications.error.permission.deny`));
            return;
        }
        const lock = yield call(API, payload.url, payload.data);
        if (lock.message) {
            if (lock.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(lock.message, "message"));
            }
        } else {
            yield put(showMessage("notifications.success.delete", "success"));
            yield history.push("/app/locks/all");
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestLockActivity({ payload }) {
    try {
        yield put(setLockActivity(null));
        const activity = yield call(API, payload.url, payload.data);
        if (activity.message) {
            if (activity.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(activity.message));
            }
        } else if (activity.data === "") {
            yield put(setLockActivity([]));
        } else {
            activity.data = sortList(true, activity.data, "Date");
            const images = yield all(
                activity.data.map(
                    (item) =>
                        item.ImageId !== "" &&
                        call(API, "/image/", { Filename: item.ImageId })
                )
            );
            activity.data.map((item, i) => {
                if (item.Event === "Ticket") {
                    item.Icon = TicketIcon;
                } else if (item.Event === "Machine") {
                    item.Icon = MachineIcon;
                } else if (item.Event === "Lock") {
                    item.Icon = LockIcon;
                } else if (item.Event === "User") {
                    item.Icon = UserIcon;
                } else if (item.Event === "Point") {
                    item.Icon = LockIcon;
                } else if (item.Event === "Job") {
                    item.Icon = TicketIcon;
                } else if (item.Event === "Template") {
                    item.Icon = MachineIcon;
                } else {
                    item.Icon = UserIcon;
                }
                item.Image = images[i].data
                    ? `data:image/png;base64, ${images[i].data}`
                    : null;
            });
            yield put(setLockActivity(activity.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestSearchLocks({ payload }) {
    try {
        const locks = yield call(API, payload.url, payload.data);
        if (locks.message) {
            if (locks.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(locks.message, "message"));
            }
        } else if (!locks.data) {
            yield put(setSearchedLocks([]));
        } else {
            yield put(setSearchedLocks(locks.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

export function* selectLocksListWorkOrder() {
    yield takeEvery(FETCH_LOCKS_LIST_WORK_ORDER, requestLocks);
}

export function* selectLocksListAvailable() {
    yield takeEvery(FETCH_LOCKS_LIST_AVAILABLE, requestLocks);
}

export function* selectLocksSearchSelect() {
    yield takeEvery(FETCH_LOCKS_SEARCH_SELECT, requestLocksSearchSelect);
}

export function* selectLocksById() {
    yield takeEvery(FETCH_LOCKS_BY_ID, requestLock);
}

export function* selectLocksByMac() {
    yield takeEvery(FETCH_LOCKS_BY_MAC, requestLock);
}

export function* selectLocksBySerial() {
    yield takeEvery(FETCH_LOCKS_BY_SERIAL, requestLock);
}

export function* createLock() {
    yield takeEvery(CREATE_LOCK, requestCreateLock);
}

export function* editLock() {
    yield takeEvery(EDIT_LOCK, requestEditLock);
}

export function* deleteLock() {
    yield takeEvery(DELETE_LOCK, requestDeleteLock);
}

export function* selectLockActivity() {
    yield takeEvery(FETCH_LOCK_ACTIVITY, requestLockActivity);
}

export function* selectSearchLocks() {
    yield takeEvery(SEARCH_LOCKS, requestSearchLocks);
}

export default function* rootSaga() {
    yield all([
        fork(selectLocksListWorkOrder),
        fork(selectLocksById),
        fork(selectLocksByMac),
        fork(selectLocksBySerial),
        fork(createLock),
        fork(editLock),
        fork(deleteLock),
        fork(selectLocksListAvailable),
        fork(selectLocksSearchSelect),
        fork(selectLockActivity),
        fork(selectSearchLocks),
    ]);
}
