import React from "react";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import { StyleSheet, css } from "aphrodite";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";

class HeaderBackButton extends React.Component {
    render() {
        const { intl, theme, authUser } = this.props;
        const backgroundCol = (authUser.ClientId === "SCH20201123c6dfa96b2deba2a83cFRA" // Snyder Electric
        || authUser.ClientId === "SCH20211109864171908742010805IND" // Snyder Electric
        || authUser.ClientId === "SCH20210629219631eba90a80000fFRA") // Snyder Electric
        ?
        "#45d26b"
        :
        (authUser.ClientId === "NVR2021060497871519642010a80fCAD") // NouvLR
        ?
        "#07284f"
        :
        theme.color.headerColor;
        const iconCol = (authUser.ClientId === "SCH20201123c6dfa96b2deba2a83cFRA" // Snyder Electric
        || authUser.ClientId === "SCH20211109864171908742010805IND" // Snyder Electric
        || authUser.ClientId === "SCH20210629219631eba90a80000fFRA" // Snyder Electric
        || authUser.ClientId === "NVR2021060497871519642010a80fCAD") // NouvLR
        ?
        "#ffffff"
        :
        theme.color.themeColor;
        this.styles = styles(theme, backgroundCol, iconCol);
        return (
            <Button
                onClick={() => this.props.history.goBack()}
                outline
                className={css(this.styles.button)}
            >
                <i className={`material-icons ${css(this.styles.icon)}`}>
                    arrow_back_ios
                </i>
                <span className={css(this.styles.backLink)}>
                    {intl.formatMessage({ id: "comp.button.back" })}
                </span>
            </Button>
        );
    }
}

const mapStateToProps = ({ app, auth }) => {
    const { theme } = app;
    const { authUser } = auth;
    return { theme, authUser };
};

export default injectIntl(
    withRouter(connect(mapStateToProps)(HeaderBackButton))
);

const styles = (theme, background, color) =>
    StyleSheet.create({
        button: {
            margin: "0 0 0 0",
            padding: 0,
            fontSize: 12,
            backgroundColor: background,
            border: "none",
            color: theme.color.themeColor,
            ":hover": {
                opacity: ".5",
            },
        },
        backLink: {
            fontWeight: 400,
            fontSize: 14,
            marginLeft: 0,
            color: color
        },
        icon: {
            fontSize: 18,
            color: color
        },
    });
