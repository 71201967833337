import { SET_REQUEST_INFO, SET_REQUEST_INFO_ITEM } from "constants/ActionTypes";

const INIT_STATE = {
    requestInfoList: [],
    requestInfoObject: {},
    requestInfoFilterList: [
        {
            title: "comp.table.header.blank",
            width: "50px",
            active: false,
            sortable: false,
            sortTitle: "",
            order: false,
            mobile: false,
        },
        {
            title: "comp.table.header.name",
            width: "20%",
            active: false,
            sortable: true,
            sortTitle: "Name",
            style: { textAlign: "left" },
            order: false,
            mobile: true,
        },
        {
            title: "form.phoneNumber",
            width: "20%",
            active: false,
            sortable: true,
            sortTitle: "PhoneNumber",
            style: { textAlign: "left" },
            order: false,
            mobile: true,
        },
        {
            title: "form.email",
            width: "20%",
            active: false,
            sortable: true,
            sortTitle: "Email",
            style: { textAlign: "left" },
            order: false,
            mobile: true,
        },
        {
            title: "form.companyName",
            width: "20%",
            active: false,
            sortable: true,
            sortTitle: "CompanyName",
            style: { textAlign: "left" },
            order: false,
            mobile: true,
        },
        {
            title: "comp.table.header.dateCreated",
            width: "20%",
            active: true,
            sortable: true,
            sortTitle: "CreatedDate",
            order: false,
            mobile: true,
            style: { textAlign: "right" },
        },
    ],
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_REQUEST_INFO: {
            return {
                ...state,
                requestInfoList: action.payload,
            };
        }
        case SET_REQUEST_INFO_ITEM: {
            return {
                ...state,
                requestInfoObject: action.payload,
            };
        }
        default:
            return state;
    }
};
