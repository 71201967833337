import {
    ABOVE_THE_HEADER,
    DARK_THEME,
    DRAWER_TYPE,
    FIXED_DRAWER,
    HORIZONTAL_MENU_POSITION,
    SWITCH_LANGUAGE,
    SET_TERMS_MESSAGES,
    SET_THEME_MODE,
    SET_THEME_MODE_CONTRAST,
    SET_THEME_COLORS,
    TOGGLE_COLLAPSED_NAV,
    VERTICAL_NAVIGATION,
    WINDOW_WIDTH,
    WINDOW_HEIGHT,
    SHOW_MESSAGE,
    HIDE_MESSAGE,
    SET_NEWS,
    SET_SEARCH,
    SET_FILTER,
    SET_LOADER,
    SET_SIDEBAR,
    SET_SHOW_HELP,
    SET_HELP_ANCHOR,
} from "constants/ActionTypes";
import themes from "themes";
import colors from "themes/colors";
import AppLocale from "../lngProvider";

// GRAB THEME
const subdomain = window.location.host.split(".");
var site = subdomain[0];
if (subdomain[0] === "www") {
    site = subdomain[1];
}
if (site.includes("localhost")) {
    site = "demo";
}
if (site.includes("loto-go-dev")) {
    site = "demo";
}
if (site.includes("smartloto")) {
    site = "demo";
}
if (!site) {
    site = "demo";
}
let theme = themes[site];
if (!theme) {
    theme = themes["demo"];
}

// SET FAVICON
const link =
    document.querySelector("link[rel*='icon']") ||
    document.createElement("link");
link.type = "image/x-icon";
link.rel = "shortcut icon";
link.href = theme.favicon + "?v=2";
document.getElementsByTagName("head")[0].appendChild(link);
document.title = theme.title;

// SET STATE
const rltLocale = ["arr"];
const initialSettings = {
    navCollapsed: false,
    drawerType: FIXED_DRAWER,
    theme: theme,
    darkTheme: false,
    width: window.innerWidth,
    height: window.innerHeight,
    isDirectionRTL: false,
    navigationStyle: VERTICAL_NAVIGATION,
    horizontalNavPosition: ABOVE_THE_HEADER,
    contentMessage: "",
    typeMessage: "error",
    showMessage: false,
    locale: theme.locale,
    appLocale: theme.appLocale,
    messages: theme.messages,
    newsList: [],
    showLoader: false,
    hideSidebar: false,
    showHelp: false,
    helpAnchor: null,
    searchCreds: {
        localList: [],
        searchType: ["Name"],
    },
    filterCreds: {
        list: [],
        action: null,
    },
};

const app = (state = initialSettings, action) => {
    let tempTheme = { ...state.theme };
    switch (action.type) {
        case "@@router/LOCATION_CHANGE":
            return {
                ...state,
                navCollapsed: false,
            };
        case TOGGLE_COLLAPSED_NAV:
            return {
                ...state,
                navCollapsed: action.isNavCollapsed,
            };
        case DRAWER_TYPE:
            return {
                ...state,
                drawerType: action.drawerType,
            };
        case WINDOW_WIDTH:
            return {
                ...state,
                width: action.width,
            };
        case WINDOW_HEIGHT:
            return {
                ...state,
                height: action.height,
            };
        case SET_THEME_COLORS:
            tempTheme.color = action.colors;
            sessionStorage.setItem(
                "themeColor",
                JSON.stringify(tempTheme.color)
            );
            return {
                ...state,
                theme: tempTheme,
            };
        case SET_THEME_MODE:
            tempTheme.color = colors[action.mode];
            tempTheme.color.themeColor = colors[site].themeColor;
            sessionStorage.setItem(
                "themeColor",
                JSON.stringify(tempTheme.color)
            );
            return {
                ...state,
                theme: tempTheme,
            };
        case SET_THEME_MODE_CONTRAST:
            tempTheme.color = colors[action.mode];
            sessionStorage.setItem(
                "themeColor",
                JSON.stringify(tempTheme.color)
            );
            return {
                ...state,
                theme: tempTheme,
            };
        case DARK_THEME:
            return {
                ...state,
                darkTheme: !state.darkTheme,
            };
        case SWITCH_LANGUAGE:
            return {
                ...state,
                locale: action.payload,
                appLocale: AppLocale[action.payload.locale].locale,
                messages: AppLocale[action.payload.locale].messages,
                isDirectionRTL: rltLocale.includes(action.payload.locale),
            };
        case SET_TERMS_MESSAGES:
            let newMessages = Object.assign({}, state.messages);
            if (action.payload.oldMessages) {
                Object.keys(newMessages).forEach((k) => {
                    newMessages[k] = newMessages[k].replace(
                        new RegExp(action.payload.oldMessages[0].Plural, "gi"),
                        `${action.payload.messages[0].Plural}`
                    );
                    newMessages[k] = newMessages[k].replace(
                        new RegExp(
                            action.payload.oldMessages[0].Singular,
                            "gi"
                        ),
                        `${action.payload.messages[0].Singular}`
                    );
                    newMessages[k] = newMessages[k].replace(
                        new RegExp(action.payload.oldMessages[1].Plural, "gi"),
                        `${action.payload.messages[1].Plural}`
                    );
                    newMessages[k] = newMessages[k].replace(
                        new RegExp(
                            action.payload.oldMessages[1].Singular,
                            "gi"
                        ),
                        `${action.payload.messages[1].Singular}`
                    );
                    newMessages[k] = newMessages[k].replace(
                        new RegExp(action.payload.oldMessages[2].Plural, "gi"),
                        `${action.payload.messages[2].Plural}`
                    );
                    newMessages[k] = newMessages[k].replace(
                        new RegExp(
                            action.payload.oldMessages[2].Singular,
                            "gi"
                        ),
                        `${action.payload.messages[2].Singular}`
                    );
                    newMessages[k] = newMessages[k].replace(
                        new RegExp(action.payload.oldMessages[3].Plural, "gi"),
                        `${action.payload.messages[3].Plural}`
                    );
                    newMessages[k] = newMessages[k].replace(
                        new RegExp(
                            action.payload.oldMessages[3].Singular,
                            "gi"
                        ),
                        `${action.payload.messages[3].Singular}`
                    );
                    newMessages[k] = newMessages[k].replace(
                        new RegExp(action.payload.oldMessages[4].Plural, "gi"),
                        `${action.payload.messages[4].Plural}`
                    );
                    newMessages[k] = newMessages[k].replace(
                        new RegExp(
                            action.payload.oldMessages[4].Singular,
                            "gi"
                        ),
                        `${action.payload.messages[4].Singular}`
                    );
                });
            } else {
                Object.keys(newMessages).forEach((k) => {
                    newMessages[k] = newMessages[k].replace(
                        /tickets/gi,
                        `${action.payload.messages[0].Plural}`
                    );
                    newMessages[k] = newMessages[k].replace(
                        /ticket/gi,
                        `${action.payload.messages[0].Singular}`
                    );
                    newMessages[k] = newMessages[k].replace(
                        /last lockouts/gi,
                        `${action.payload.messages[1].Plural}`
                    );
                    newMessages[k] = newMessages[k].replace(
                        /last lockout/gi,
                        `${action.payload.messages[1].Singular}`
                    );
                    newMessages[k] = newMessages[k].replace(
                        /machines/gi,
                        `${action.payload.messages[2].Plural}`
                    );
                    newMessages[k] = newMessages[k].replace(
                        /machine/gi,
                        `${action.payload.messages[2].Singular}`
                    );
                    newMessages[k] = newMessages[k].replace(
                        /job descriptions/gi,
                        `${action.payload.messages[3].Plural}`
                    );
                    newMessages[k] = newMessages[k].replace(
                        /job description/gi,
                        `${action.payload.messages[3].Singular}`
                    );
                    newMessages[k] = newMessages[k].replace(
                        /jobs/gi,
                        `${action.payload.messages[4].Plural}`
                    );
                    newMessages[k] = newMessages[k].replace(
                        /job/gi,
                        `${action.payload.messages[4].Singular}`
                    );
                });
            }
            return {
                ...state,
                messages: newMessages,
            };
        case HORIZONTAL_MENU_POSITION:
            return {
                ...state,
                horizontalNavPosition: action.payload,
            };
        case SHOW_MESSAGE:
            return {
                ...state,
                contentMessage: action.payload.message,
                typeMessage: action.payload.type,
                showMessage: true,
            };
        case HIDE_MESSAGE:
            return {
                ...state,
                contentMessage: "",
                showMessage: false,
            };
        case SET_NEWS:
            return {
                ...state,
                newsList: action.payload,
            };
        case SET_SEARCH:
            return {
                ...state,
                searchCreds: action.payload,
            };
        case SET_FILTER:
            return {
                ...state,
                filterCreds: action.payload,
            };
        case SET_LOADER:
            return {
                ...state,
                showLoader: action.payload,
            };
        case SET_SIDEBAR:
            return {
                ...state,
                hideSidebar: action.payload,
            };
        case SET_SHOW_HELP:
            return {
                ...state,
                showHelp: action.payload,
            };
        case SET_HELP_ANCHOR:
            return {
                ...state,
                helpAnchor: action.payload,
            };
        default:
            return state;
    }
};

export default app;
