import React from "react";
import { fadeIn } from "react-animations";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { StyleSheet, css } from "aphrodite";

class Box extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const {
            children,
            border,
            boxShadow,
            height,
            padding,
            width,
            margin,
            borderRadius,
            theme,
            backgroundColor,
            route,
        } = this.props;
        this.styles = styles(
            border,
            boxShadow,
            height,
            padding,
            width,
            margin,
            borderRadius,
            theme,
            backgroundColor,
            route
        );
        return (
            <div
                className={css(this.styles.boxWrapper, this.styles.fadeIn)}
                onClick={() => route && this.props.history.push(route)}
            >
                {children}
            </div>
        );
    }
}

Box.defaultProps = {
    border: null,
    boxShadow: null,
    height: "83vh",
    padding: 20,
    width: "100%",
    margin: "10px 0",
    borderRadius: 5,
    backgroundColor: null,
    route: null,
};

const mapStateToProps = ({ app }) => {
    const { theme } = app;
    return { theme };
};

export default withRouter(connect(mapStateToProps)(Box));

const styles = (
    border,
    boxShadow,
    height,
    padding,
    width,
    margin,
    borderRadius,
    theme,
    backgroundColor,
    route
) =>
    StyleSheet.create({
        boxWrapper: {
            border: border ? border : `solid 1px ${theme.color.borderColor}`,
            boxShadow: boxShadow
                ? boxShadow
                : `1px 1px 10px ${theme.color.borderColor}`,
            minHeight: height,
            padding,
            width,
            margin,
            borderRadius,
            backgroundColor: backgroundColor
                ? backgroundColor
                : theme.color.compColor,
            overflowY: "auto",
            overflowX: "hidden",
            ":hover": {
                cursor: route ? "pointer" : "default",
                opacity: route ? ".8" : "1",
                boxShadow: route
                    ? "none"
                    : boxShadow
                    ? boxShadow
                    : `1px 1px 10px ${theme.color.borderColor}`,
            },
        },
        fadeIn: {
            animationName: fadeIn,
            animationDuration: ".65s",
        },
    });
