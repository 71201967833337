import React from "react";
import { connect } from "react-redux";
import { StyleSheet, css } from "aphrodite";
import { Input } from "reactstrap";
import { injectIntl } from "react-intl";

import { filterList } from "util";

class Search extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchTitle: props.searchTitle,
            searchType: props.searchType,
            searchValue: "",
            show: props.width > 800 ? true : false,
        };
    }
    handleSearch = (value) => {
        let data = [...this.props.data];
        let { searchType } = this.state;
        data = filterList(data, searchType, value);
        this.setState({ searchValue: value });
        this.props.update(data);
    };
    render() {
        const { intl, data, theme } = this.props;
        const { searchTitle, searchValue, show } = this.state;
        this.styles = styles(theme);
        return (
            <div className={css(this.styles.searchWrapper)}>
                <i
                    className={`material-icons ${css(this.styles.searchIcon)}`}
                    onClick={() => this.setState({ show: !show })}
                >
                    search
                </i>
                {searchValue != "" && (
                    <i
                        className={`material-icons ${css(
                            this.styles.closeIcon
                        )}`}
                        onClick={() => this.handleSearch("")}
                    >
                        close
                    </i>
                )}
                {show ? (
                    <Input
                        type="text"
                        className={css(this.styles.input)}
                        placeholder={`${intl.formatMessage({
                            id: `comp.search.default`,
                        })} ${intl.formatMessage({ id: searchTitle })}`}
                        onChange={(e) =>
                            data && this.handleSearch(e.target.value)
                        }
                        value={searchValue}
                        autoFocus
                    />
                ) : (
                    <div className={css(this.styles.placeholder)}></div>
                )}
            </div>
        );
    }
}

Search.defaultProps = {
    searchTitle: "blank",
    searchType: ["name"],
    data: [],
};

const mapStateToProps = ({ app }) => {
    const { theme, width } = app;
    return { theme, width };
};

export default injectIntl(connect(mapStateToProps)(Search));

const styles = (theme) =>
    StyleSheet.create({
        input: {
            border: "none",
            boxShadow: "none",
            borderRadius: 0,
            fontWeight: 400,
            fontSize: 12,
            letterSpacing: 2,
            marginBottom: 10,
            padding: "6px 0px 6px 35px",
            width: 230,
            color: theme.color.themeColor,
            backgroundColor: theme.color.compColor,
            "::placeholder": {
                letterSpacing: 1,
                color: theme.color.fontColor,
                fontSize: 12,
            },
        },
        placeholder: {
            width: 35,
        },
        searchWrapper: {
            position: "relative",
        },
        searchIcon: {
            color: theme.color.themeColor,
            position: "absolute",
            top: 3,
            left: 5,
            ":hover": {
                cursor: "pointer",
                opacity: ".8",
            },
        },
        closeIcon: {
            color: theme.color.fontColor,
            position: "absolute",
            top: 5,
            right: 10,
            fontSize: 16,
            ":hover": {
                cursor: "pointer",
                opacity: ".8",
            },
        },
    });
