import {
    FETCH_CLIENT_LIST,
    FETCH_CLIENT_BY_ID,
    CREATE_CLIENT,
    CREATE_CLIENT_NOTE,
    EDIT_CLIENT,
    DELETE_CLIENT,
    FETCH_CLIENT_LOGIN_LIST,
    SET_CLIENT_OBJECT,
    SET_CLIENT_LIST,
    SET_CLIENT_LOGIN_LIST,
    SET_CLIENT_FILTER,
    SET_ACTIVE_CLIENT,
    CREATE_LOCK_API,
} from "constants/ActionTypes";

export const fetchClientList = (param, headers) => {
    return {
        type: FETCH_CLIENT_LIST,
        payload: {
            url: "/list/",
            data: {},
            param: param,
        },
    };
};

export const fetchClientLoginList = (Id) => {
    return {
        type: FETCH_CLIENT_LOGIN_LIST,
        payload: {
            url: "/list/login/",
            data: { Id },
        },
    };
};

export const fetchClientById = (Id) => {
    return {
        type: FETCH_CLIENT_BY_ID,
        payload: {
            url: "/byid/",
            data: {
                Id,
            },
        },
    };
};

export const createClient = (data) => {
    return {
        type: CREATE_CLIENT,
        payload: {
            url: "/create/",
            data,
        },
    };
};

export const createClientNote = (data) => {
    return {
        type: CREATE_CLIENT_NOTE,
        payload: {
            url: "/note/create/",
            data,
        },
    };
};

export const editClient = (data) => {
    return {
        type: EDIT_CLIENT,
        payload: {
            url: "/update/",
            data,
        },
    };
};

export const deleteClient = (Id) => {
    return {
        type: DELETE_CLIENT,
        payload: {
            url: "/remove/",
            data: {
                Id,
            },
        },
    };
};

export const setClientList = (data) => {
    return {
        type: SET_CLIENT_LIST,
        payload: data,
    };
};

export const setClientLoginList = (data) => {
    return {
        type: SET_CLIENT_LOGIN_LIST,
        payload: data,
    };
};

export const setClientObject = (data) => {
    return {
        type: SET_CLIENT_OBJECT,
        payload: data,
    };
};

export const setClientFilter = (data) => {
    return {
        type: SET_CLIENT_FILTER,
        payload: data,
    };
};

export const setActiveClient = (data) => {
    return {
        type: SET_ACTIVE_CLIENT,
        payload: data,
    };
};

export const createLockApi = (data) => {
    return {
        type: CREATE_LOCK_API,
        payload: {
            url: "/addcorelock/",
            data,
        },
    };
};
