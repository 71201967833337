import React from "react";
import { connect } from "react-redux";
import { StyleSheet, css } from "aphrodite";
import { injectIntl } from "react-intl";

class FilterMenu extends React.Component {
    constructor(props) {
        super(props);
    }
    handleFilters = (active) => {
        const { action } = this.props;
        let { data } = this.state;
        data.map((filter) =>
            filter === active ? (filter.active = true) : (filter.active = false)
        );
        action && action(data);
        this.props.toggle();
    };
    render() {
        const { intl, list, theme, data } = this.props;
        this.styles = styles(theme);
        return (
            <ul className={`list-unstyled ${css(this.styles.wrapper)}`}>
                {data.length > 0 &&
                    data.map((filter, i) => (
                        <li
                            key={filter.title + i}
                            className={css(
                                filter.active
                                    ? this.styles.activeList
                                    : this.styles.list
                            )}
                            onClick={() => this.handleFilters(filter)}
                        >
                            {!list ? (
                                <p className={css(this.styles.title)}>
                                    {intl.formatMessage({ id: filter.title })}
                                </p>
                            ) : (
                                <p className={css(this.styles.title)}>
                                    {filter.title}
                                </p>
                            )}
                            <i
                                className={`material-icons ${css(
                                    this.styles.icon
                                )}`}
                            >
                                arrow_forward_ios
                            </i>
                        </li>
                    ))}
            </ul>
        );
    }
}

const mapStateToProps = ({ app }) => {
    const { theme } = app;
    return { theme };
};

export default injectIntl(connect(mapStateToProps, {})(FilterMenu));

const styles = (theme) =>
    StyleSheet.create({
        wrapper: {
            opacity: "1",
        },
        activeList: {
            borderBottom: `solid 1px ${theme.color.borderColor}`,
            width: "100%",
            height: 40,
            color: theme.color.themeColor,
            ":hover": {
                textDecoration: "none",
                opacity: ".8",
                cursor: "pointer",
            },
        },
        list: {
            borderBottom: `solid 1px ${theme.color.borderColor}`,
            width: "100%",
            height: 40,
            color: theme.color.themeColor,
            ":hover": {
                textDecoration: "none",
                opacity: ".8",
                cursor: "pointer",
            },
        },
        title: {
            float: "left",
            margin: 0,
            padding: "10px 0 1px 10px",
            fontWeight: 400,
            fontFamily: "Roboto",
            fontSize: 12,
            textTransform: "uppercase",
        },
        icon: {
            display: "block",
            float: "right",
            padding: 8,
            textAlign: "right",
        },
    });
