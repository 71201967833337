import { all, call, fork, put, takeEvery, select } from "redux-saga/effects";
import { history } from "../store/history";
import { API } from "../api";
import { TicketIcon } from "util/icons";
import { sortList } from "util";
import {
    FETCH_TICKETS_LIST,
    FETCH_TICKETS_LIST_OPEN,
    FETCH_TICKETS_LIST_BY_USER_ID,
    FETCH_TICKET_LIST_ACTIVITY,
    FETCH_TICKET_LIST_LOCKOUTS,
    FETCH_TICKET_LIST_POINTS,
    FETCH_TICKET_LIST_NOTES,
    FETCH_TICKET_LIST_PERMITS,
    FETCH_TICKET_DOCUMENTS,
    FETCH_TICKET_DOCUMENT_BY_ID,
    FETCH_TICKET_USERS,
    ADD_TICKET_USER,
    REMOVE_TICKET_USER,
    FETCH_TICKETS_BY_ID,
    FETCH_TICKETS_BY_MACHINE_ID,
    COMPLETE_TICKET,
    CREATE_TICKET,
    CREATE_TICKET_NOTE,
    CREATE_TICKET_PERMIT,
    CREATE_TICKET_DOCUMENT,
    EDIT_TICKET,
    DELETE_TICKET,
    CLOSE_TICKET,
    FILTER_TICKETS,
    FILTER_TICKETS_LIST,
    SEARCH_TICKET_LIST,
    FETCH_TICKET_PROCESS,
    FETCH_TICKET_POINT_PROCESS,
    FETCH_TICKET_HIERARCHY,
    SEARCH_TICKETS,
    SEARCH_PARENT_TICKETS,
    SET_PARENT_TICKET,
    FETCH_TICKETS_BY_PARENT_ID,
    CREATE_TICKET_AIC,
    TRANSFER_TICKET_AIC,
    PULL_TICKET_AIC,
    ADD_LOCKOUT,
    REMOVE_LOCKOUT,
    FETCH_ACTIVE_LOCKOUTS,
    FETCH_TICKET_EQUIPMENT,
} from "constants/ActionTypes";
import {
    fetchTicketsList,
    fetchTicketsListOpen,
    fetchTicketsListByUserId,
    setTicketActivity,
    setTicketLockouts,
    setTicketPoints,
    setTicketNotes,
    setTicketPermits,
    setTicketDocuments,
    setTicketDocument,
    setTicketUsers,
    setFilterTickets,
    setTickets,
    setTicket,
    setTicketProcess,
    setTicketProcessLoaded,
    setTicketPointProcess,
    setTicketHierarchy,
    setSearchedTickets,
    setParentTickets,
    fetchTicketById,
    fetchTicketUsers,
    setActiveLockouts,
    setTicketEquipment,
} from "actions/Tickets";
import { fetchMachineById, setMachine } from "actions/Machines";
import { userSignOut } from "actions/Auth";
import { showMessage, setLoader } from "actions/App";

let authUser = JSON.parse(sessionStorage.getItem("user_data"));
const getHeaders = (state) => state.tickets.ticketFilterList;

function* requestTickets({ payload }) {
    try {
        const tickets = yield call(API, payload.url, payload.data);
        if (tickets.message) {
            if (tickets.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(tickets.message, "message"));
            }
        } else if (!tickets.data) {
            yield put(setTickets([]));
        } else {
            tickets.data.map((ticket) => {
                if (ticket.LockoutStatus === "Green") {
                    ticket.image = "assets/images/Icons/lock/safe@3x.png";
                } else if (ticket.LockoutStatus === "Red") {
                    ticket.image =
                        "assets/images/Icons/lock/dangerousDefault.png";
                } else {
                    ticket.image = "assets/images/Icons/lock/unsafe@3x.png";
                }
            });
            tickets.data.map((item) => {
                item.Icon = TicketIcon;
            });
            yield put(setLoader(false));
            const headers = yield select(getHeaders);
            const activeHeader = headers.find((item) => item.active);
            tickets.data = sortList(
                activeHeader.order,
                tickets.data,
                activeHeader.sortTitle
            );
            yield put(setTickets(tickets.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestTicket({ payload }) {
    try {
        yield put(setTicket(null));
        yield put(setTicketActivity(null));
        yield put(setTicketLockouts(null));
        yield put(setTicketPoints(null));
        yield put(setTicketNotes([]));
        yield put(setMachine(""));
        const ticket = yield call(API, payload.url, payload.data);
        if (ticket.message) {
            if (ticket.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(ticket.message, "message"));
            }
        } else if (ticket.data === "") {
            yield put(showMessage("notifications.error.fail.notClientTicket"));
            yield history.push("/app/tickets/all");
        } else {
            if (!ticket.data.Notes) {
                ticket.data.Notes = [];
            }
            ticket.data.Icon = TicketIcon;
            ticket.data.Notes = ticket.data.Notes.sort(function (a, b) {
                return (
                    b.CreatedDate.length - a.CreatedDate.length ||
                    b.CreatedDate.localeCompare(a.CreatedDate)
                );
            });
            if (ticket.data.LockoutStatus === "Red") {
                ticket.data.image =
                    "assets/images/Icons/lock/dangerousDefault.png";
            } else if (ticket.data.LockoutStatus === "Green") {
                ticket.data.image = "assets/images/Icons/lock/safe@3x.png";
            } else {
                ticket.data.image = "assets/images/Icons/lock/unsafe@3x.png";
            }
            if (ticket.data.MachineId)
                yield put(fetchMachineById(ticket.data.MachineId));
            yield put(setTicket(ticket.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestTicketActivity({ payload }) {
    try {
        const activity = yield call(API, payload.url, payload.data);
        if (activity.message) {
            if (activity.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(activity.message, "message"));
            }
        } else if (activity.data === "") {
            yield put(setTicketActivity([]));
        } else {
            activity.data.map((item, i) => {
                item.UserImage = "assets/images/Icons/user/userIconDefault.png";
                item.EventImage = item.Event.includes("Removed")
                    ? "assets/images/Icons/lock/activityLock.jpg"
                    : "assets/images/Icons/lock/activityUnlock.jpg";
            });
            activity.data = activity.data.sort(function (a, b) {
                return (
                    b.Date.length - a.Date.length ||
                    b.Date.localeCompare(a.Date)
                );
            });
            yield put(setTicketActivity(activity.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestTicketLockouts({ payload }) {
    try {
        const lotos = yield call(API, payload.url, payload.data);
        if (lotos.message) {
            if (lotos.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(lotos.message, "message"));
            }
        } else if (lotos.data === "") {
            yield put(setTicketLockouts([]));
        } else {
            const lotoImages = yield all(
                lotos.data.map((item) =>
                    call(API, "/image/", { Filename: item.LockoutAddedById })
                )
            );
            lotos.data.map((item, i) => {
                item.Image = lotoImages[i].data
                    ? `data:image/png;base64, ${lotoImages[i].data}`
                    : "assets/images/Icons/user/userIconDefault.png";
            });
            lotos.data = lotos.data.sort(function (a, b) {
                return (
                    a.LockoutAddedByName.length - b.LockoutAddedByName.length ||
                    a.LockoutAddedByName.localeCompare(b.LockoutAddedByName)
                );
            });
            yield put(setTicketLockouts(lotos.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestTicketPoints({ payload }) {
    try {
        const points = yield call(API, payload.url, payload.data);
        if (points.message) {
            if (points.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(points.message, "message"));
            }
        } else if (points.data === "") {
            yield put(setTicketPoints([]));
        } else {
            const pointImages = yield all(
                points.data.map((item) =>
                    call(API, "/image/", { Filename: item.Id })
                )
            );
            points.data.map((item, i) => {
                item.Image = pointImages[i].data
                    ? `data:image/png;base64, ${pointImages[i].data}`
                    : "assets/images/Icons/lock/lockDefault.png";
            });
            points.data = points.data.sort(function (a, b) {
                return (
                    b.Label.length - a.Label.length ||
                    b.Label.localeCompare(a.Label)
                );
            });
            yield put(setTicketPoints(points.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestTicketNotes({ payload }) {
    try {
        const notes = yield call(API, payload.url, payload.data);
        if (notes.message) {
            if (notes.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(notes.message, "message"));
            }
        } else if (notes.data === "" || notes.data === null) {
            yield put(setTicketNotes([]));
        } else {
            let sortedNotes = sortList(true, notes.data, "CreatedDate");
            yield put(setTicketNotes(sortedNotes));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestTicketPermits({ payload }) {
    try {
        const permits = yield call(API, payload.url, payload.data);
        if (permits.message) {
            if (notes.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(permits.message, "message"));
            }
        } else if (permits.data === "" || permits.data === null) {
            yield put(setTicketPermits([]));
        } else {
            yield put(setTicketPermits(permits.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestTicketDocuments({ payload }) {
    try {
        const documents = yield call(API, payload.url, payload.data);
        if (documents.message) {
            if (notes.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(documents.message, "message"));
            }
        } else if (documents.data === "" || documents.data === null) {
            yield put(setTicketDocuments([]));
        } else {
            yield put(setTicketDocuments(documents.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestTicketDocumentById({ payload }) {
    try {
        yield put(setTicketDocument(null));
        const document = yield call(API, payload.url, payload.data);
        if (document.message) {
            if (notes.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(document.message, "message"));
            }
        } else if (document.data === "" || document.data === null) {
            yield put(setTicketDocument(null));
        } else {
            yield put(setTicketDocument(document.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestTicketUsers({ payload }) {
    try {
        const users = yield call(API, payload.url, payload.data);
        if (users.message) {
            if (users.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(users.message, "message"));
            }
        } else if (users.data === "" || users.data === null) {
            yield put(setTicketUsers([]));
        } else {
            let authUser = users.data.filter(
                (user) => user.AuthorizedInChargeId !== ""
            );
            let lockUsers = users.data.filter(
                (user) => user.AuthorizedInChargeId === "" && user.Lockouts
            );
            let regUsers = users.data.filter(
                (user) => user.AuthorizedInChargeId === "" && !user.Lockouts
            );
            yield put(setTicketUsers([...authUser, ...lockUsers, ...regUsers]));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestAddTicketUser({ payload }) {
    try {
        const users = yield call(API, payload.url, payload.data);
        if (users.message) {
            if (users.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(users.message, "message"));
            }
        } else if (users.data === "" || users.data === null) {
            yield put(setTicketUsers([]));
        } else {
            yield put(setTicketUsers(users.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestRemoveTicketUser({ payload }) {
    try {
        const users = yield call(API, payload.url, payload.data);
        if (users.message) {
            if (users.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(users.message, "message"));
            }
        } else if (users.data === "" || users.data === null) {
            yield put(setTicketUsers([]));
        } else {
            yield put(setTicketUsers(users.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* handleFilter({ payload }) {
    yield put(setTickets(null));
    switch (payload.title) {
        case "filters.tickets.all":
            yield put(fetchTicketsList());
            return;
        case "filters.tickets.open":
            yield put(fetchTicketsListOpen());
            return;
        case "filters.tickets.user":
            yield put(fetchTicketsListByUserId(payload.Id));
            return;
        default:
            yield put(fetchTicketsList());
            return;
    }
}

function* handleFilterList({ payload }) {
    try {
        let newList = payload.list.filter((ticket) => {
            return (
                ticket.MachineName.toLowerCase().search(
                    payload.searchValue.toLowerCase()
                ) !== -1
            );
        });
        yield put(setFilterTickets(newList));
    } catch (error) {
        console.warn(error);
    }
}

function* requestCompleteTicket({ payload }) {
    try {
        const ticket = yield call(API, payload.url, payload.data);
        if (ticket.message) {
            if (ticket.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(ticket.message, "message"));
            }
        } else {
            yield put(showMessage("notifications.success.add", "success"));
            yield history.push("/app/tickets/all");
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestCreateTicket({ payload }) {
    try {
        if (!authUser) {
            authUser = JSON.parse(sessionStorage.getItem("user_data"));
        }
        const ticket = yield call(API, payload.url, payload.data);
        if (ticket.message) {
            if (ticket.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(ticket.message, "message"));
            }
        } else if (ticket.validationError) {
            var error = Object.keys(ticket.validationError).map(function (key) {
                return ticket.validationError[key];
            });
            yield put(showMessage(error, "message"));
        } else {
            yield put(showMessage("notifications.success.add", "success"));
            if (!payload.another) {
                yield history.push(`/app/tickets/display/${ticket.data.Id}`);
            }
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestCreateTicketNote({ payload }) {
    try {
        const required = {
            Note: payload.data.Note,
        };
        for (var key in required) {
            if (required[key] === undefined || required[key] === "") {
                yield put(
                    showMessage(`notifications.error.blank.${key}`, "warning")
                );
                return;
            }
        }
        const ticket = yield call(API, payload.url, payload.data);
        if (ticket.message) {
            if (ticket.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(ticket.message, "message"));
            }
        } else if (ticket.validationError) {
            var error = Object.keys(ticket.validationError).map(function (key) {
                return ticket.validationError[key];
            });
            yield put(showMessage(error, "message"));
        } else {
            yield put(showMessage("notifications.success.add", "success"));
            let sortedNotes = sortList(true, ticket.data, "CreatedDate");
            yield put(setTicketNotes(sortedNotes));
            yield !payload.data.NoRedirect &&
                history.push(
                    `/app/tickets/display/${payload.data.WorkOrderId}`
                );
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestCreateTicketPermit({ payload }) {
    try {
        const required = {
            PermitId: payload.data.PermitId,
        };
        for (var key in required) {
            if (required[key] === undefined || required[key] === "") {
                yield put(
                    showMessage(`notifications.error.blank.${key}`, "warning")
                );
                return;
            }
        }
        const ticket = yield call(API, payload.url, payload.data);
        if (ticket.message) {
            if (ticket.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(ticket.message, "message"));
            }
        } else if (ticket.validationError) {
            var error = Object.keys(ticket.validationError).map(function (key) {
                return ticket.validationError[key];
            });
            yield put(showMessage(error, "message"));
        } else {
            yield put(showMessage("notifications.success.add", "success"));
            yield !payload.data.NoRedirect &&
                history.push(
                    `/app/tickets/display/${payload.data.WorkOrderId}`
                );
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestCreateTicketDocument({ payload }) {
    try {
        const formData = new FormData();
        formData.append("file", payload.data.file);
        formData.append("description", payload.data.description);
        formData.append("workorderid", payload.data.workorderid);
        formData.append("machineId", payload.data.machineId);
        const ticket = yield call(API, payload.url, formData);
        if (ticket.message) {
            if (ticket.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(ticket.message, "message"));
            }
        } else if (ticket.validationError) {
            var error = Object.keys(ticket.validationError).map(function (key) {
                return ticket.validationError[key];
            });
            yield put(showMessage(error, "message"));
        } else {
            yield put(showMessage("notifications.success.add", "success"));
            yield history.push(
                `/app/tickets/display/${payload.data.workorderid}`
            );
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestEditTicket({ payload }) {
    try {
        const ticket = yield call(API, payload.url, payload.data);
        if (ticket.message) {
            if (ticket.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(ticket.message, "message"));
            }
        } else {
            yield put(showMessage("notifications.success.edit", "success"));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestDeleteTicket({ payload }) {
    try {
        if (!payload.data) {
            yield put(showMessage(`notifications.error.permission.deny`));
            return;
        }
        const ticket = yield call(API, payload.url, payload.data);
        if (ticket.message) {
            if (ticket.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(ticket.message, "message"));
            }
        } else {
            yield put(showMessage("notifications.success.delete", "success"));
            yield history.push("/app/tickets/all");
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestCloseTicket({ payload }) {
    try {
        if (!payload.data) {
            yield put(showMessage(`notifications.error.permission.deny`));
            return;
        }
        payload.data.Id = payload.data.WorkOrderId;
        const ticket = yield call(API, payload.url, payload.data);
        if (ticket.message) {
            if (ticket.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(ticket.message, "message"));
            }
        } else if (ticket.validationError) {
            let message = Object.values(ticket.validationError);
            console.warn(message);
            yield put(showMessage(message[0], "message"));
        } else {
            yield put(showMessage("notifications.success.close", "success"));
            yield history.push("/app/tickets/all");
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestTicketProcess({ payload }) {
    try {
        yield put(setTicketProcessLoaded(false));
        yield put(setTicketProcess([]));
        const process = yield call(API, payload.url, payload.data);
        if (process.message) {
            if (process.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(process.message));
            }
        } else if (process.data === "") {
            yield put(setTicketProcessLoaded(true));
            yield put(setTicketProcess([]));
        } else {
            !process.data.Actions ? (process.data.Actions = []) : "";
            !process.data.Points ? (process.data.Points = []) : "";
            const pointImages = yield all(
                process.data.Points.map(
                    (item) =>
                        item.IsolationId !== "" &&
                        call(API, "/image/", { Filename: item.IsolationId })
                )
            );
            process.data.Points.map((item, i) => {
                item.Image = pointImages[i].data
                    ? `data:image/png;base64, ${pointImages[i].data}`
                    : null;
                item.Icon = TicketIcon;
            });
            let processList = process.data.Actions.concat(process.data.Points);
            let processedList = sortList(false, processList, "ProcessOrder");
            yield put(setTicketProcessLoaded(true));
            yield put(setTicketProcess(processedList));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestTicketPointProcess({ payload }) {
    try {
        yield put(setTicketPointProcess(null));
        const process = yield call(API, payload.url, payload.data);
        if (process.message) {
            if (process.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(process.message));
            }
        } else if (process.data === "") {
            yield put(setTicketPointProcess([]));
        } else {
            process.data = sortList(false, process.data, "ProcessOrder");
            yield put(setTicketPointProcess(process.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestSearchTickets({ payload }) {
    try {
        payload.data.SystemTypeWorkOrderStatusId = Number(
            payload.data.SystemTypeWorkOrderStatusId
        );
        let newPayload = { ...payload.data };
        newPayload.StartDate = newPayload.StartDate
            ? newPayload.StartDate + "T00:00:00+00:00"
            : "";
        newPayload.EndDate = newPayload.EndDate
            ? newPayload.EndDate + "T00:00:00+00:00"
            : "";
        const tickets = yield call(API, payload.url, newPayload);
        if (tickets.message) {
            if (tickets.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(tickets.message, "message"));
            }
        } else if (!tickets.data) {
            yield put(setSearchedTickets([]));
        } else {
            tickets.data.map((ticket) => {
                if (ticket.LockoutStatus === "Green") {
                    ticket.image = "assets/images/Icons/lock/safe@3x.png";
                } else if (ticket.LockoutStatus === "Red") {
                    ticket.image =
                        "assets/images/Icons/lock/dangerousDefault.png";
                } else {
                    ticket.image = "assets/images/Icons/lock/unsafe@3x.png";
                }
            });
            const headers = yield select(getHeaders);
            const activeHeader = headers.find((item) => item.active);
            tickets.data = sortList(
                activeHeader.order,
                tickets.data,
                activeHeader.sortTitle
            );
            yield put(setSearchedTickets(tickets.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestSearchParentTickets({ payload }) {
    try {
        const tickets = yield call(API, payload.url, payload.data);
        if (tickets.message) {
            if (tickets.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(tickets.message, "message"));
            }
        } else if (!tickets.data) {
            yield put(setParentTickets([]));
        } else {
            tickets.data.map((ticket) => {
                if (ticket.LockoutStatus === "Green") {
                    ticket.image = "assets/images/Icons/lock/safe@3x.png";
                } else if (ticket.LockoutStatus === "Red") {
                    ticket.image =
                        "assets/images/Icons/lock/dangerousDefault.png";
                } else {
                    ticket.image = "assets/images/Icons/lock/unsafe@3x.png";
                }
            });
            yield put(setParentTickets(tickets.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestSetParentTicket({ payload }) {
    try {
        const ticket = yield call(API, payload.url, payload.data);
        if (ticket.message) {
            if (ticket.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(ticket.message, "message"));
            }
        } else {
            yield put(showMessage("notifications.success.update", "success"));
            yield put(setTicket(ticket.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFetchTicketsByParentId({ payload }) {
    try {
        const tickets = yield call(API, payload.url, payload.data);
        if (tickets.message) {
            if (tickets.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(tickets.message, "message"));
            }
        } else if (!tickets.data) {
            yield put(setSearchedTickets([]));
        } else {
            tickets.data.map((ticket) => {
                if (ticket.LockoutStatus === "Green") {
                    ticket.image = "assets/images/Icons/lock/safe@3x.png";
                } else if (ticket.LockoutStatus === "Red") {
                    ticket.image =
                        "assets/images/Icons/lock/dangerousDefault.png";
                } else {
                    ticket.image = "assets/images/Icons/lock/unsafe@3x.png";
                }
            });
            yield put(setSearchedTickets(tickets.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFetchTicketHierarchy({ payload }) {
    try {
        const tickets = yield call(API, payload.url, payload.data);
        if (tickets.message) {
            if (tickets.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(tickets.message, "message"));
            }
        } else {
            const lowercaseKeys = (obj) => {
                const isObject = (o) =>
                    Object.prototype.toString.apply(o) === "[object Object]";
                const isArray = (o) =>
                    Object.prototype.toString.apply(o) === "[object Array]";
                let transformedObj = isArray(obj) ? [] : {};
                for (let key in obj) {
                    const transformedKey = key.replace(/^\w/, (c, _) =>
                        c.toLowerCase()
                    );
                    if (isObject(obj[key]) || isArray(obj[key])) {
                        transformedObj[transformedKey] = lowercaseKeys(
                            obj[key]
                        );
                    } else {
                        transformedObj[transformedKey] = obj[key];
                    }
                }
                return transformedObj;
            };
            const newObj = lowercaseKeys(tickets.data);
            yield put(setTicketHierarchy(newObj));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestCreateTicketAIC({ payload }) {
    try {
        const ticket = yield call(API, payload.url, payload.data);
        if (ticket.message) {
            if (ticket.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(ticket.message, "message"));
            }
        } else {
            yield put(showMessage("notifications.success.edit", "success"));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestTransferTicketAIC({ payload }) {
    try {
        const ticket = yield call(API, payload.url, payload.data);
        if (ticket.message) {
            if (ticket.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(ticket.message, "message"));
            }
        } else {
            yield put(showMessage("notifications.success.edit", "success"));
            yield put(setTicket(ticket.data));
            yield put(fetchTicketUsers({ Id: ticket.data.Id }));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestPullTicketAIC({ payload }) {
    try {
        const ticket = yield call(API, payload.url, payload.data);
        if (ticket.message) {
            if (ticket.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(ticket.message, "message"));
            }
        } else {
            yield put(showMessage("notifications.success.edit", "success"));
            yield put(setTicket(ticket.data));
            yield put(fetchTicketUsers({ Id: ticket.data.Id }));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestAddLockout({ payload }) {
    try {
        const lockout = yield call(API, payload.url, payload.data);
        if (lockout.message) {
            if (lockout.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(lockout.message, "message"));
            }
        } else {
            yield put(showMessage("notifications.success.edit", "success"));
            yield put(fetchTicketById(payload.data.WorkOrderId));
            yield put(fetchTicketUsers({ Id: payload.data.WorkOrderId }));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestRemoveLockout({ payload }) {
    try {
        const lockout = yield call(API, payload.url, payload.data);
        if (lockout.message) {
            if (lockout.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(lockout.message, "message"));
            }
        } else {
            yield put(showMessage("notifications.success.edit", "success"));
            yield put(fetchTicketById(payload.id));
            yield put(fetchTicketUsers({ Id: payload.id }));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFetchActiveLockouts({ payload }) {
    try {
        const lockouts = yield call(API, payload.url, payload.data);
        if (lockouts.message) {
            if (lockouts.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(lockouts.message, "message"));
            }
        } else if (!lockouts.data) {
            yield put(setActiveLockouts([]));
        } else {
            yield put(setActiveLockouts(lockouts.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFetchTicketEquipment({ payload }) {
    try {
        const equipment = yield call(API, payload.url, payload.data);
        if (equipment.message) {
            if (equipment.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(equipment.message, "message"));
            }
        } else if (!equipment.data) {
            yield put(setTicketEquipment([]));
        } else {
            yield put(setTicketEquipment(equipment.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

export function* selectTicketsList() {
    yield takeEvery(FETCH_TICKETS_LIST, requestTickets);
}

export function* selectTicketsListOpen() {
    yield takeEvery(FETCH_TICKETS_LIST_OPEN, requestTickets);
}

export function* selectTicketsListByUserId() {
    yield takeEvery(FETCH_TICKETS_LIST_BY_USER_ID, requestTickets);
}

export function* selectTicketListActivity() {
    yield takeEvery(FETCH_TICKET_LIST_ACTIVITY, requestTicketActivity);
}

export function* selectTicketListLockouts() {
    yield takeEvery(FETCH_TICKET_LIST_LOCKOUTS, requestTicketLockouts);
}

export function* selectTicketUsers() {
    yield takeEvery(FETCH_TICKET_USERS, requestTicketUsers);
}

export function* selectAddTicketUser() {
    yield takeEvery(ADD_TICKET_USER, requestAddTicketUser);
}

export function* selectRemoveTicketUser() {
    yield takeEvery(REMOVE_TICKET_USER, requestRemoveTicketUser);
}

export function* selectTicketListPoints() {
    yield takeEvery(FETCH_TICKET_LIST_POINTS, requestTicketPoints);
}

export function* selectTicketListNotes() {
    yield takeEvery(FETCH_TICKET_LIST_NOTES, requestTicketNotes);
}

export function* selectTicketListPermits() {
    yield takeEvery(FETCH_TICKET_LIST_PERMITS, requestTicketPermits);
}

export function* selectFetchTicketEquipment() {
    yield takeEvery(FETCH_TICKET_EQUIPMENT, requestFetchTicketEquipment);
}

export function* selectTicketDocuments() {
    yield takeEvery(FETCH_TICKET_DOCUMENTS, requestTicketDocuments);
}

export function* selectTicketDocumentById() {
    yield takeEvery(FETCH_TICKET_DOCUMENT_BY_ID, requestTicketDocumentById);
}

export function* selectTicketsById() {
    yield takeEvery(FETCH_TICKETS_BY_ID, requestTicket);
}

export function* selectTicketsByMachineId() {
    yield takeEvery(FETCH_TICKETS_BY_MACHINE_ID, requestTicket);
}

export function* completeTicket() {
    yield takeEvery(COMPLETE_TICKET, requestCompleteTicket);
}

export function* createTicket() {
    yield takeEvery(CREATE_TICKET, requestCreateTicket);
}

export function* createTicketNote() {
    yield takeEvery(CREATE_TICKET_NOTE, requestCreateTicketNote);
}

export function* createTicketPermit() {
    yield takeEvery(CREATE_TICKET_PERMIT, requestCreateTicketPermit);
}

export function* createTicketDocument() {
    yield takeEvery(CREATE_TICKET_DOCUMENT, requestCreateTicketDocument);
}

export function* editTicket() {
    yield takeEvery(EDIT_TICKET, requestEditTicket);
}

export function* deleteTicket() {
    yield takeEvery(DELETE_TICKET, requestDeleteTicket);
}

export function* closeTicket() {
    yield takeEvery(CLOSE_TICKET, requestCloseTicket);
}

export function* selectFilterTicket() {
    yield takeEvery(FILTER_TICKETS, handleFilter);
}

export function* selectFilterTicketList() {
    yield takeEvery(FILTER_TICKETS_LIST, handleFilterList);
}

export function* selectSearchTicketsList() {
    yield takeEvery(SEARCH_TICKET_LIST, handleFilterList);
}

export function* selectTicketProcessById() {
    yield takeEvery(FETCH_TICKET_PROCESS, requestTicketProcess);
}

export function* selectTicketPointProcessById() {
    yield takeEvery(FETCH_TICKET_POINT_PROCESS, requestTicketPointProcess);
}

export function* selectSearchTickets() {
    yield takeEvery(SEARCH_TICKETS, requestSearchTickets);
}

export function* selectSearchParentTickets() {
    yield takeEvery(SEARCH_PARENT_TICKETS, requestSearchParentTickets);
}

export function* selectSetParentTicket() {
    yield takeEvery(SET_PARENT_TICKET, requestSetParentTicket);
}

export function* selectFetchTicketsByParentId() {
    yield takeEvery(FETCH_TICKETS_BY_PARENT_ID, requestFetchTicketsByParentId);
}

export function* selectFetchTicketHierarchy() {
    yield takeEvery(FETCH_TICKET_HIERARCHY, requestFetchTicketHierarchy);
}

export function* selectCreateTicketAIC() {
    yield takeEvery(CREATE_TICKET_AIC, requestCreateTicketAIC);
}

export function* selectTransferTicketAIC() {
    yield takeEvery(TRANSFER_TICKET_AIC, requestTransferTicketAIC);
}

export function* selectPullTicketAIC() {
    yield takeEvery(PULL_TICKET_AIC, requestPullTicketAIC);
}

export function* selectAddLockout() {
    yield takeEvery(ADD_LOCKOUT, requestAddLockout);
}

export function* selectRemoveLockout() {
    yield takeEvery(REMOVE_LOCKOUT, requestRemoveLockout);
}

export function* selectFetchActiveLockouts() {
    yield takeEvery(FETCH_ACTIVE_LOCKOUTS, requestFetchActiveLockouts);
}

export default function* rootSaga() {
    yield all([
        fork(selectTicketsList),
        fork(selectTicketsListOpen),
        fork(selectTicketsListByUserId),
        fork(selectTicketListActivity),
        fork(selectTicketListLockouts),
        fork(selectTicketListPoints),
        fork(selectTicketListNotes),
        fork(selectTicketListPermits),
        fork(selectTicketsById),
        fork(selectTicketsByMachineId),
        fork(selectTicketUsers),
        fork(selectAddTicketUser),
        fork(selectRemoveTicketUser),
        fork(completeTicket),
        fork(createTicket),
        fork(createTicketNote),
        fork(createTicketPermit),
        fork(createTicketDocument),
        fork(editTicket),
        fork(deleteTicket),
        fork(closeTicket),
        fork(selectFilterTicket),
        fork(selectFilterTicketList),
        fork(selectSearchTicketsList),
        fork(selectTicketProcessById),
        fork(selectTicketPointProcessById),
        fork(selectSearchTickets),
        fork(selectSearchParentTickets),
        fork(selectSetParentTicket),
        fork(selectFetchTicketsByParentId),
        fork(selectFetchTicketHierarchy),
        fork(selectCreateTicketAIC),
        fork(selectTransferTicketAIC),
        fork(selectPullTicketAIC),
        fork(selectAddLockout),
        fork(selectRemoveLockout),
        fork(selectTicketDocuments),
        fork(selectTicketDocumentById),
        fork(selectFetchActiveLockouts),
        fork(selectFetchTicketEquipment),
    ]);
}
