import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { ADMIN_API } from "../api";
import {
    FETCH_REQUEST_INFO,
    FETCH_REQUEST_INFO_ITEM,
    ARCHIVE_REQUEST,
} from "constants/ActionTypes";
import { setRequestInfo, setRequestInfoItem } from "actions/RequestInfo";
import { userSignOut } from "actions/Auth";
import { showMessage } from "actions/App";

function* requestRequestInfo({ payload }) {
    try {
        yield put(setRequestInfo(null));
        const request = yield call(ADMIN_API, payload.url, payload.data);
        if (request.message) {
            if (request.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(request.message));
            }
        } else if (request.data === "") {
            yield put(setRequestInfo([]));
        } else {
            yield put(setRequestInfo(request.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestArchiveRequest({ payload }) {
    try {
        const request = yield call(ADMIN_API, payload.url, payload.data);
        if (request.message) {
            if (request.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(request.message));
            }
        } else if (request.data === "") {
            yield put(showMessage("notifications.success.archived", "success"));
            yield put(setRequestInfo([]));
        } else {
            yield put(showMessage("notifications.success.archived", "success"));
            yield put(setRequestInfo(request.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestRequestInfoItem({ payload }) {
    try {
        yield put(setRequestInfoItem(null));
        const request = yield call(ADMIN_API, payload.url, payload.data);
        if (request.message) {
            if (request.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(request.message));
            }
        } else if (request.data === "") {
            yield put(setRequestInfoItem([]));
        } else {
            yield put(setRequestInfoItem(request.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

export function* selectFetchRequestInfo() {
    yield takeEvery(FETCH_REQUEST_INFO, requestRequestInfo);
}

export function* selectFetchRequestInfoItem() {
    yield takeEvery(FETCH_REQUEST_INFO_ITEM, requestRequestInfoItem);
}

export function* selectArchiveRequest() {
    yield takeEvery(ARCHIVE_REQUEST, requestArchiveRequest);
}

export default function* rootSaga() {
    yield all([
        fork(selectFetchRequestInfo),
        fork(selectFetchRequestInfoItem),
        fork(selectArchiveRequest),
    ]);
}
