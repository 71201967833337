import {
    FETCH_USERS_LIST,
    FETCH_USERS_LIST_AIC,
    FETCH_USER_NOTIFICATIONS,
    FETCH_USER_UNREAD_NOTIFICATIONS,
    ACKNOWLEDGE_NOTIFICATION,
    DELETE_NOTIFICATION,
    FETCH_USER_BY_ID,
    CREATE_USER,
    EDIT_USER,
    EDIT_SELF,
    DELETE_USER,
    FILTER_USERS,
    SET_USERS,
    SET_USER,
    SET_USER_NOTIFICATIONS,
    SET_USER_UNREAD_NOTIFICATIONS,
    SET_CHECKED_NOTIFICATIONS,
    SET_USER_FILTER,
    FETCH_USER_ACTIVITY,
    SET_USER_ACTIVITY,
    SEARCH_USERS,
    SET_SEARCHED_USERS,
    SET_USER_SEARCH_TERMS,
} from "constants/ActionTypes";

export const fetchUsersList = () => {
    return {
        type: FETCH_USERS_LIST,
        payload: {
            url: "/user/list/",
            data: {},
        },
    };
};
export const fetchUsersListAIC = () => {
    return {
        type: FETCH_USERS_LIST_AIC,
        payload: {
            url: "/user/list/aic/",
            data: {},
        },
    };
};
export const fetchUserById = (Id) => {
    return {
        type: FETCH_USER_BY_ID,
        payload: {
            url: "/user/byid/",
            data: {
                Id,
            },
        },
    };
};
export const fetchUserNotifications = (Id) => {
    return {
        type: FETCH_USER_NOTIFICATIONS,
        payload: {
            url: "/notification/list/byid/",
            data: {
                Id,
            },
        },
    };
};
export const fetchUserUnreadNotifications = (Id) => {
    return {
        type: FETCH_USER_UNREAD_NOTIFICATIONS,
        payload: {
            url: "/notification/list/unread/byid/",
            data: {
                Id,
            },
        },
    };
};
export const acknowledgeNotification = (Ids, UserId) => {
    return {
        type: ACKNOWLEDGE_NOTIFICATION,
        payload: {
            url: "/notification/setisread/",
            data: Ids,
            UserId,
        },
    };
};
export const deleteNotification = (Ids, UserId) => {
    return {
        type: DELETE_NOTIFICATION,
        payload: {
            url: "/notification/delete/",
            data: Ids,
            UserId,
        },
    };
};
export const setCheckedNotifications = (data) => {
    return {
        type: SET_CHECKED_NOTIFICATIONS,
        payload: data,
    };
};
export const createUser = (data, another) => {
    return {
        type: CREATE_USER,
        payload: {
            url: "/user/create/",
            another,
            data,
        },
    };
};
export const editUser = (data) => {
    return {
        type: EDIT_USER,
        payload: {
            url: "/user/update/",
            data,
        },
    };
};
export const editSelf = (data) => {
    return {
        type: EDIT_SELF,
        payload: {
            url: "/user/update/",
            data,
        },
    };
};
export const deleteUser = (Id) => {
    return {
        type: DELETE_USER,
        payload: {
            url: "/user/deactivate/",
            data: {
                Id,
            },
        },
    };
};
export const filterUsers = (filter) => {
    return {
        type: FILTER_USERS,
        payload: filter,
    };
};
export const setUserFilter = (data) => {
    return {
        type: SET_USER_FILTER,
        payload: data,
    };
};
export const setUsers = (data) => {
    return {
        type: SET_USERS,
        payload: data,
    };
};
export const setUser = (data) => {
    return {
        type: SET_USER,
        payload: data,
    };
};

export const setUserNotifications = (data) => {
    return {
        type: SET_USER_NOTIFICATIONS,
        payload: data,
    };
};

export const setUserUnreadNotifications = (data) => {
    return {
        type: SET_USER_UNREAD_NOTIFICATIONS,
        payload: data,
    };
};

export const fetchUserActivity = (Id) => {
    return {
        type: FETCH_USER_ACTIVITY,
        payload: {
            url: "/activity/list/user/",
            data: {
                Id,
            },
        },
    };
};

export const setUserActivity = (data) => {
    return {
        type: SET_USER_ACTIVITY,
        payload: data,
    };
};

export const searchUsers = (data) => {
    return {
        type: SEARCH_USERS,
        payload: {
            url: "/user/list/search/",
            data,
        },
    };
};

export const setSearchedUsers = (data) => {
    return {
        type: SET_SEARCHED_USERS,
        payload: data,
    };
};

export const setUserSearchTerms = (data) => {
    return {
        type: SET_USER_SEARCH_TERMS,
        payload: data,
    };
};
