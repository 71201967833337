import React from "react";
import { connect } from "react-redux";
import { Container } from "reactstrap";
import { StyleSheet, css } from "aphrodite";
import { injectIntl } from "react-intl";
import Header from "components/Header/table";
import { HelpIcon } from "util/icons";

import { setShowHelp, setHelpAnchor } from "actions/App";

class GeneralHelp extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        if (this.props.helpAnchor) {
            let node = document.getElementById(`${this.props.helpAnchor}`);
            setTimeout(() => {
                node.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                });
                node.style.border = "solid 1px #ADADAD";
                setTimeout(() => {
                    node.style.border = "none";
                    this.props.setHelpAnchor(null);
                }, 3000);
            }, 1000);
        }
    }
    componentDidUpdate() {
        if (this.props.helpAnchor !== null) {
            let node = document.getElementById(`${this.props.helpAnchor}`);
            node.scrollIntoView({
                behavior: "smooth",
                block: "start",
            });
            node.style.border = "solid 1px #ADADAD";
            setTimeout(() => {
                node.style.border = "none";
                this.props.setHelpAnchor(null);
            }, 3000);
        }
    }
    render() {
        const { intl, theme } = this.props;
        this.styles = styles();
        const lockout =
            this.props.authUser &&
            this.props.authUser.Settings.Product.includes("lockout");
        return (
            <Container
                fluid
                className={css(this.styles.container, this.styles.maxHeight)}
            >
                <i
                    onClick={() => this.props.setShowHelp(false)}
                    className={`material-icons ${css(this.styles.closeIcon)}`}
                >
                    highlight_off
                </i>
                <Header
                    title="pages.general.type"
                    Icon={<HelpIcon color={theme.color.themeColor} />}
                    filter={false}
                />
                <h1 style={{ marginTop: 10 }}>
                    {intl.formatMessage({ id: "help.intro" })}
                </h1>
                <div id="about">
                    <h3>{intl.formatMessage({ id: "help.about.title" })}</h3>
                    <p>
                        {intl.formatMessage({ id: "help.about.description1" })}
                    </p>
                </div>
                <div id="access">
                    <h3>{intl.formatMessage({ id: "help.access.title" })}</h3>
                    <p>
                        {intl.formatMessage({ id: "help.access.description1" })}
                    </p>
                </div>
                <div id="login">
                    <h3>{intl.formatMessage({ id: "help.login.title" })}</h3>
                    <p>
                        {intl.formatMessage({ id: "help.login.description1" })}
                    </p>
                </div>
                <div id="dashboard">
                    <h3>
                        {intl.formatMessage({ id: "help.dashboard.title" })}
                    </h3>
                    <p>
                        {intl.formatMessage({
                            id: "help.dashboard.description1",
                        })}
                    </p>
                </div>

                {/* TICKETS */}

                <h1>{intl.formatMessage({ id: "help.tickets" })}</h1>
                <div id="addTicket">
                    <h3>
                        {intl.formatMessage({ id: "help.addTicket.title" })}
                    </h3>
                    <p>
                        {intl.formatMessage({
                            id: "help.addTicket.description1",
                        })}
                    </p>
                    <p>
                        {lockout
                            ? intl.formatMessage({
                                  id: "help.addTicket.description2lockout",
                              })
                            : intl.formatMessage({
                                  id: "help.addTicket.description2",
                              })}
                    </p>
                    <p>
                        {intl.formatMessage({
                            id: "help.addTicket.description3",
                        })}
                    </p>
                </div>
                <div id="viewTicket">
                    <h3>
                        {intl.formatMessage({ id: "help.viewTicket.title" })}
                    </h3>
                    <p>
                        {lockout
                            ? intl.formatMessage({
                                  id: "help.viewTicket.description1lockout",
                              })
                            : intl.formatMessage({
                                  id: "help.viewTicket.description1",
                              })}
                    </p>
                    <p>
                        {lockout
                            ? intl.formatMessage({
                                  id: "help.viewTicket.description2lockout",
                              })
                            : intl.formatMessage({
                                  id: "help.viewTicket.description2",
                              })}
                    </p>
                </div>
                {lockout ? (
                    <div id="ticketLockPoints">
                        <h3>
                            {intl.formatMessage({
                                id: "help.ticketLockPoints.title",
                            })}
                        </h3>
                        <p>
                            {intl.formatMessage({
                                id: "help.ticketLockPoints.description1",
                            })}
                        </p>
                        <p>
                            {intl.formatMessage({
                                id: "help.ticketLockPoints.description2",
                            })}
                        </p>
                    </div>
                ) : (
                    <div id="ticketJob">
                        <h3>
                            {intl.formatMessage({ id: "help.ticketJob.title" })}
                        </h3>
                        <p>
                            {intl.formatMessage({
                                id: "help.ticketJob.description1",
                            })}
                        </p>
                        <p>
                            {intl.formatMessage({
                                id: "help.ticketJob.description2",
                            })}
                        </p>
                    </div>
                )}
                <div id="ticketUsers">
                    <h3>
                        {intl.formatMessage({ id: "help.ticketUsers.title" })}
                    </h3>
                    <p>
                        {intl.formatMessage({
                            id: "help.ticketUsers.description1",
                        })}
                    </p>
                    <p>
                        {intl.formatMessage({
                            id: "help.ticketUsers.description2",
                        })}
                    </p>
                    <p>
                        {intl.formatMessage({
                            id: "help.ticketUsers.description3",
                        })}
                    </p>
                    <p>
                        {intl.formatMessage({
                            id: "help.ticketUsers.description4",
                        })}
                    </p>
                    <p>
                        {intl.formatMessage({
                            id: "help.ticketUsers.description5",
                        })}
                    </p>
                    <p>
                        {intl.formatMessage({
                            id: "help.ticketUsers.description6",
                        })}
                    </p>
                </div>
                <div id="ticketDocuments">
                    <h3>
                        {intl.formatMessage({ id: "help.ticketDocuments.title" })}
                    </h3>
                    <p>
                        {intl.formatMessage({
                            id: "help.ticketDocuments.description1",
                        })}
                    </p>
                    <p>
                        {intl.formatMessage({
                            id: "help.ticketDocuments.description2",
                        })}
                    </p>
                    <p>
                        {intl.formatMessage({
                            id: "help.ticketDocuments.description3",
                        })}
                    </p>
                    <p>
                        {intl.formatMessage({
                            id: "help.ticketDocuments.description4",
                        })}
                    </p>
                    <p>
                        {intl.formatMessage({
                            id: "help.ticketDocuments.description5",
                        })}
                    </p>
                </div>
                <div id="ticketEquipment">
                    <h3>
                        {intl.formatMessage({ id: "help.ticketEquipment.title" })}
                    </h3>
                    <p>
                        {intl.formatMessage({
                            id: "help.ticketEquipment.description1",
                        })}
                    </p>
                    <p>
                        {intl.formatMessage({
                            id: "help.ticketEquipment.description2",
                        })}
                    </p>
                    <p>
                        {intl.formatMessage({
                            id: "help.ticketEquipment.description3",
                        })}
                    </p>
                </div>
                <div id="ticketNotes">
                    <h3>
                        {intl.formatMessage({ id: "help.ticketNotes.title" })}
                    </h3>
                    <p>
                        {intl.formatMessage({
                            id: "help.ticketNotes.description1",
                        })}
                    </p>
                    <p>
                        {intl.formatMessage({
                            id: "help.ticketNotes.description2",
                        })}
                    </p>
                    <p>
                        {intl.formatMessage({
                            id: "help.ticketNotes.description3",
                        })}
                    </p>
                    <p>
                        {intl.formatMessage({
                            id: "help.ticketNotes.description4",
                        })}
                    </p>
                </div>
                <div id="ticketActivity">
                    <h3>
                        {intl.formatMessage({
                            id: "help.ticketActivity.title",
                        })}
                    </h3>
                    <p>
                        {intl.formatMessage({
                            id: "help.ticketActivity.description1",
                        })}
                    </p>
                    <p>
                        {intl.formatMessage({
                            id: "help.ticketActivity.description2",
                        })}
                    </p>
                    <p>
                        {intl.formatMessage({
                            id: "help.ticketActivity.description3",
                        })}
                    </p>
                </div>
                <div id="closeTicket">
                    <h3>
                        {intl.formatMessage({ id: "help.closeTicket.title" })}
                    </h3>
                    <p>
                        {intl.formatMessage({
                            id: "help.closeTicket.description1",
                        })}
                    </p>
                    <p>
                        {intl.formatMessage({
                            id: "help.closeTicket.description2",
                        })}
                    </p>
                </div>
                <div id="viewTickets">
                    <h3>
                        {intl.formatMessage({ id: "help.viewTickets.title" })}
                    </h3>
                    <p>
                        {lockout
                            ? intl.formatMessage({
                                  id: "help.viewTickets.description1lockout",
                              })
                            : intl.formatMessage({
                                  id: "help.viewTickets.description1",
                              })}
                    </p>
                    <p>
                        {lockout
                            ? intl.formatMessage({
                                  id: "help.viewTickets.description2lockout",
                              })
                            : intl.formatMessage({
                                  id: "help.viewTickets.description2",
                              })}
                    </p>
                    <p>
                        {intl.formatMessage({
                            id: "help.viewTickets.description3",
                        })}
                    </p>
                </div>
                <div id="searchTickets">
                    <h3>
                        {intl.formatMessage({ id: "help.searchTickets.title" })}
                    </h3>
                    <p>
                        {lockout
                            ? intl.formatMessage({
                                  id: "help.searchTickets.description1lockout",
                              })
                            : intl.formatMessage({
                                  id: "help.searchTickets.description1",
                              })}
                    </p>
                    <p>
                        {intl.formatMessage({
                            id: "help.searchTickets.description2",
                        })}
                    </p>
                    <p>
                        {intl.formatMessage({
                            id: "help.searchTickets.description3",
                        })}
                    </p>
                </div>
                <div id="exportTickets">
                    <h3>
                        {intl.formatMessage({ id: "help.exportTickets.title" })}
                    </h3>
                    <p>
                        {intl.formatMessage({
                            id: "help.exportTickets.description1",
                        })}
                    </p>
                </div>
                {!lockout && (
                    <div id="ticketHierarchy">
                        <h3>
                            {intl.formatMessage({
                                id: "help.ticketHierarchy.title",
                            })}
                        </h3>
                        <p>
                            {intl.formatMessage({
                                id: "help.ticketHierarchy.description1",
                            })}
                        </p>
                        <p>
                            {intl.formatMessage({
                                id: "help.ticketHierarchy.description2",
                            })}
                        </p>
                        <p>
                            {intl.formatMessage({
                                id: "help.ticketHierarchy.description3",
                            })}
                        </p>
                        <p>
                            {intl.formatMessage({
                                id: "help.ticketHierarchy.description4",
                            })}
                        </p>
                    </div>
                )}
                {!lockout && (
                    <div id="ticketActiveLockouts">
                        <h3>
                            {intl.formatMessage({
                                id: "help.ticketActiveLockouts.title",
                            })}
                        </h3>
                        <p>
                            {intl.formatMessage({
                                id: "help.ticketActiveLockouts.description1",
                            })}
                        </p>
                        <p>
                            {intl.formatMessage({
                                id: "help.ticketActiveLockouts.description2",
                            })}
                        </p>
                        <p>
                            {intl.formatMessage({
                                id: "help.ticketActiveLockouts.description3",
                            })}
                        </p>
                    </div>
                )}

                {/* MACHINES */}

                {!lockout && (
                    <div>
                        <h1>{intl.formatMessage({ id: "help.machines" })}</h1>
                        <div id="addMachine">
                            <h3>
                                {intl.formatMessage({
                                    id: "help.addMachine.title",
                                })}
                            </h3>
                            <p>
                                {intl.formatMessage({
                                    id: "help.addMachine.description1",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.addMachine.description2",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.addMachine.description3",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.addMachine.description4",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.addMachine.description5",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.addMachine.description6",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.addMachine.description7",
                                })}
                            </p>
                        </div>
                        <div id="viewMachine">
                            <h3>
                                {intl.formatMessage({
                                    id: "help.viewMachine.title",
                                })}
                            </h3>
                            <p>
                                {intl.formatMessage({
                                    id: "help.viewMachine.description1",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.viewMachine.description2",
                                })}
                            </p>
                        </div>
                        <div id="machineJobs">
                            <h3>
                                {intl.formatMessage({
                                    id: "help.machineJobs.title",
                                })}
                            </h3>
                            <p>
                                {intl.formatMessage({
                                    id: "help.machineJobs.description1",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.machineJobs.description2",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.machineJobs.description3",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.machineJobs.description4",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.machineJobs.description5",
                                })}
                            </p>
                        </div>
                        <div id="viewJob">
                            <h3>
                                {intl.formatMessage({
                                    id: "help.viewJob.title",
                                })}
                            </h3>
                            <p>
                                {intl.formatMessage({
                                    id: "help.viewJob.description1",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.viewJob.description2",
                                })}
                            </p>
                        </div>
                        <div id="permitRequired">
                            <h3>
                                {intl.formatMessage({
                                    id: "help.permitRequired.title",
                                })}
                            </h3>
                            <p>
                                {intl.formatMessage({
                                    id: "help.permitRequired.description1",
                                })}
                            </p>
                        </div>
                        <div id="requireLastLockout">
                            <h3>
                                {intl.formatMessage({
                                    id: "help.requireLastLockout.title",
                                })}
                            </h3>
                            <p>
                                {intl.formatMessage({
                                    id: "help.requireLastLockout.description1",
                                })}
                            </p>
                        </div>
                        <div id="requireFullIsolation">
                            <h3>
                                {intl.formatMessage({
                                    id: "help.requireFullIsolation.title",
                                })}
                            </h3>
                            <p>
                                {intl.formatMessage({
                                    id: "help.requireFullIsolation.description1",
                                })}
                            </p>
                        </div>
                        <div id="requireIsolationVerification">
                            <h3>
                                {intl.formatMessage({
                                    id: "help.requireIsolationVerification.title",
                                })}
                            </h3>
                            <p>
                                {intl.formatMessage({
                                    id: "help.requireIsolationVerification.description1",
                                })}
                            </p>
                        </div>
                        <div id="requireLockConnection">
                            <h3>
                                {intl.formatMessage({
                                    id: "help.requireLockConnection.title",
                                })}
                            </h3>
                            <p>
                                {intl.formatMessage({
                                    id: "help.requireLockConnection.description1",
                                })}
                            </p>
                        </div>
                        <div id="requireLockConnectionAIC">
                            <h3>
                                {intl.formatMessage({
                                    id: "help.requireLockConnectionAIC.title",
                                })}
                            </h3>
                            <p>
                                {intl.formatMessage({
                                    id: "help.requireLockConnectionAIC.description1",
                                })}
                            </p>
                        </div>
                        <div id="requireLockPlacedImage">
                            <h3>
                                {intl.formatMessage({
                                    id: "help.requireLockPlacedImage.title",
                                })}
                            </h3>
                            <p>
                                {intl.formatMessage({
                                    id: "help.requireLockPlacedImage.description1",
                                })}
                            </p>
                        </div>
                        <div>
                            <h3>
                                {intl.formatMessage({
                                    id: "help.viewJob.titleContinued",
                                })}
                            </h3>
                            <p>
                                {intl.formatMessage({
                                    id: "help.viewJob.description3",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.viewJob.description4",
                                })}
                            </p>
                        </div>
                        <div id="machineLockPoints">
                            <h3>
                                {intl.formatMessage({
                                    id: "help.machineLockPoints.title",
                                })}
                            </h3>
                            <p>
                                {intl.formatMessage({
                                    id: "help.machineLockPoints.description1",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.machineLockPoints.description2",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.machineLockPoints.description3",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.machineLockPoints.description4",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.machineLockPoints.description5",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.machineLockPoints.description6",
                                })}
                            </p>
                        </div>
                        <div id="machineDocuments">
                            <h3>
                                {intl.formatMessage({
                                    id: "help.machineDocuments.title",
                                })}
                            </h3>
                            <p>
                                {intl.formatMessage({
                                    id: "help.machineDocuments.description1",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.machineDocuments.description2",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.machineDocuments.description3",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.machineDocuments.description4",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.machineDocuments.description5",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.machineDocuments.description6",
                                })}
                            </p>
                        </div>
                        <div id="machineActivity">
                            <h3>
                                {intl.formatMessage({
                                    id: "help.machineActivity.title",
                                })}
                            </h3>
                            <p>
                                {intl.formatMessage({
                                    id: "help.machineActivity.description1",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.machineActivity.description2",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.machineActivity.description3",
                                })}
                            </p>
                        </div>
                        <div id="updateMachine">
                            <h3>
                                {intl.formatMessage({
                                    id: "help.updateMachine.title",
                                })}
                            </h3>
                            <p>
                                {intl.formatMessage({
                                    id: "help.updateMachine.description1",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.updateMachine.description2",
                                })}
                            </p>
                        </div>
                        <div id="deleteMachine">
                            <h3>
                                {intl.formatMessage({
                                    id: "help.deleteMachine.title",
                                })}
                            </h3>
                            <p>
                                {intl.formatMessage({
                                    id: "help.deleteMachine.description1",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.deleteMachine.description2",
                                })}
                            </p>
                        </div>
                        <div id="viewMachines">
                            <h3>
                                {intl.formatMessage({
                                    id: "help.viewMachines.title",
                                })}
                            </h3>
                            <p>
                                {intl.formatMessage({
                                    id: "help.viewMachines.description1",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.viewMachines.description2",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.viewMachines.description3",
                                })}
                            </p>
                        </div>
                        <div id="searchMachines">
                            <h3>
                                {intl.formatMessage({
                                    id: "help.searchMachines.title",
                                })}
                            </h3>
                            <p>
                                {intl.formatMessage({
                                    id: "help.searchMachines.description1",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.searchMachines.description2",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.searchMachines.description3",
                                })}
                            </p>
                        </div>
                        <div id="exportMachines">
                            <h3>
                                {intl.formatMessage({
                                    id: "help.exportMachines.title",
                                })}
                            </h3>
                            <p>
                                {intl.formatMessage({
                                    id: "help.exportMachines.description1",
                                })}
                            </p>
                        </div>
                        <div id="jobTemplates">
                            <h3>
                                {intl.formatMessage({
                                    id: "help.jobTemplates.title",
                                })}
                            </h3>
                            <p>
                                {intl.formatMessage({
                                    id: "help.jobTemplates.description1",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.jobTemplates.description2",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.jobTemplates.description3",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.jobTemplates.description4",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.jobTemplates.description5",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.jobTemplates.description6",
                                })}
                            </p>
                        </div>
                    </div>
                )}

                {/* LOCKS */}

                <h1>{intl.formatMessage({ id: "help.locks" })}</h1>
                <div id="addLock">
                    <h3>{intl.formatMessage({ id: "help.addLock.title" })}</h3>
                    <p>
                        {intl.formatMessage({
                            id: "help.addLock.description1",
                        })}
                    </p>
                    <p>
                        {intl.formatMessage({
                            id: "help.addLock.description2",
                        })}
                    </p>
                    <p>
                        {intl.formatMessage({
                            id: "help.addLock.description3",
                        })}
                    </p>
                </div>
                <div id="viewLock">
                    <h3>{intl.formatMessage({ id: "help.viewLock.title" })}</h3>
                    <p>
                        {intl.formatMessage({
                            id: "help.viewLock.description1",
                        })}
                    </p>
                    <p>
                        {intl.formatMessage({
                            id: "help.viewLock.description2",
                        })}
                    </p>
                </div>
                <div id="lockActivity">
                    <h3>
                        {intl.formatMessage({ id: "help.lockActivity.title" })}
                    </h3>
                    <p>
                        {intl.formatMessage({
                            id: "help.lockActivity.description1",
                        })}
                    </p>
                    <p>
                        {intl.formatMessage({
                            id: "help.lockActivity.description2",
                        })}
                    </p>
                </div>
                <div id="updateLock">
                    <h3>
                        {intl.formatMessage({ id: "help.updateLock.title" })}
                    </h3>
                    <p>
                        {intl.formatMessage({
                            id: "help.updateLock.description1",
                        })}
                    </p>
                    <p>
                        {intl.formatMessage({
                            id: "help.updateLock.description2",
                        })}
                    </p>
                </div>
                <div id="deleteLock">
                    <h3>
                        {intl.formatMessage({ id: "help.deleteLock.title" })}
                    </h3>
                    <p>
                        {intl.formatMessage({
                            id: "help.deleteLock.description1",
                        })}
                    </p>
                    <p>
                        {intl.formatMessage({
                            id: "help.deleteLock.description2",
                        })}
                    </p>
                </div>
                <div id="viewLocks">
                    <h3>
                        {intl.formatMessage({ id: "help.viewLocks.title" })}
                    </h3>
                    <p>
                        {intl.formatMessage({
                            id: "help.viewLocks.description1",
                        })}
                    </p>
                    <p>
                        {intl.formatMessage({
                            id: "help.viewLocks.description2",
                        })}
                    </p>
                    <p>
                        {intl.formatMessage({
                            id: "help.viewLocks.description3",
                        })}
                    </p>
                </div>
                <div id="searchLocks">
                    <h3>
                        {intl.formatMessage({ id: "help.searchLocks.title" })}
                    </h3>
                    <p>
                        {intl.formatMessage({
                            id: "help.searchLocks.description1",
                        })}
                    </p>
                    <p>
                        {intl.formatMessage({
                            id: "help.searchLocks.description2",
                        })}
                    </p>
                    <p>
                        {intl.formatMessage({
                            id: "help.searchLocks.description3",
                        })}
                    </p>
                </div>
                <div id="exportLocks">
                    <h3>
                        {intl.formatMessage({ id: "help.exportLocks.title" })}
                    </h3>
                    <p>
                        {intl.formatMessage({
                            id: "help.exportLocks.description1",
                        })}
                    </p>
                </div>

                {/* USERS */}

                <h1>{intl.formatMessage({ id: "help.users" })}</h1>
                <div id="addUser">
                    <h3>{intl.formatMessage({ id: "help.addUser.title" })}</h3>
                    <p>
                        {intl.formatMessage({
                            id: "help.addUser.description1",
                        })}
                    </p>
                    <p>
                        {intl.formatMessage({
                            id: "help.addUser.description2",
                        })}
                    </p>
                    <p>
                        {intl.formatMessage({
                            id: "help.addUser.description3",
                        })}
                    </p>
                </div>
                <div id="viewUser">
                    <h3>{intl.formatMessage({ id: "help.viewUser.title" })}</h3>
                    <p>
                        {intl.formatMessage({
                            id: "help.viewUser.description1",
                        })}
                    </p>
                    <p>
                        {intl.formatMessage({
                            id: "help.viewUser.description2",
                        })}
                    </p>
                </div>
                <div id="themeSetting">
                    <h3>
                        {intl.formatMessage({ id: "help.themeSetting.title" })}
                    </h3>
                    <p>
                        {intl.formatMessage({
                            id: "help.themeSetting.description1",
                        })}
                    </p>
                    <p>
                        {intl.formatMessage({
                            id: "help.themeSetting.description2",
                        })}
                    </p>
                    <p>
                        {intl.formatMessage({
                            id: "help.themeSetting.description3",
                        })}
                    </p>
                    <p>
                        {intl.formatMessage({
                            id: "help.themeSetting.description4",
                        })}
                    </p>
                </div>
                <div id="resetPassword">
                    <h3>
                        {intl.formatMessage({ id: "help.resetPassword.title" })}
                    </h3>
                    <p>
                        {intl.formatMessage({
                            id: "help.resetPassword.description1",
                        })}
                    </p>
                    <p>
                        {intl.formatMessage({
                            id: "help.resetPassword.description2",
                        })}
                    </p>
                    <p>
                        {intl.formatMessage({
                            id: "help.resetPassword.description3",
                        })}
                    </p>
                </div>
                <div id="changePassword">
                    <h3>
                        {intl.formatMessage({
                            id: "help.changePassword.title",
                        })}
                    </h3>
                    <p>
                        {intl.formatMessage({
                            id: "help.changePassword.description1",
                        })}
                    </p>
                    <p>
                        {intl.formatMessage({
                            id: "help.changePassword.description2",
                        })}
                    </p>
                    <p>
                        {intl.formatMessage({
                            id: "help.changePassword.description3",
                        })}
                    </p>
                </div>
                <div id="changePin">
                    <h3>
                        {intl.formatMessage({ id: "help.changePin.title" })}
                    </h3>
                    <p>
                        {intl.formatMessage({
                            id: "help.changePin.description1",
                        })}
                    </p>
                    <p>
                        {intl.formatMessage({
                            id: "help.changePin.description2",
                        })}
                    </p>
                    <p>
                        {intl.formatMessage({
                            id: "help.changePin.description3",
                        })}
                    </p>
                </div>
                <div id="userActivity">
                    <h3>
                        {intl.formatMessage({ id: "help.userActivity.title" })}
                    </h3>
                    <p>
                        {intl.formatMessage({
                            id: "help.userActivity.description1",
                        })}
                    </p>
                    <p>
                        {intl.formatMessage({
                            id: "help.userActivity.description2",
                        })}
                    </p>
                </div>
                <div id="updateUser">
                    <h3>
                        {intl.formatMessage({ id: "help.updateUser.title" })}
                    </h3>
                    <p>
                        {intl.formatMessage({
                            id: "help.updateUser.description1",
                        })}
                    </p>
                    <p>
                        {intl.formatMessage({
                            id: "help.updateUser.description2",
                        })}
                    </p>
                </div>
                {/* <div id='deleteUser'>
                    <h3>Set Lock as Inactive</h3>
                    <p>On the lock details page click the 'make inactive' icon at the top right to set the lock as inactive. This icon will only appear if there are no active lockouts on that lock.</p>
                    <p>A confirmation will appear asking if you are sure you want to set that lock as inactive. Click 'Make Inactive' to finalize setting the lock as inactive. The lock will still be viewable from the locks table if you adjust the filter to also include 'inactive' locks.</p>
                </div> */}
                <div id="viewUsers">
                    <h3>
                        {intl.formatMessage({ id: "help.viewUsers.title" })}
                    </h3>
                    <p>
                        {intl.formatMessage({
                            id: "help.viewUsers.description1",
                        })}
                    </p>
                    <p>
                        {intl.formatMessage({
                            id: "help.viewUsers.description2",
                        })}
                    </p>
                    <p>
                        {intl.formatMessage({
                            id: "help.viewUsers.description3",
                        })}
                    </p>
                </div>
                <div id="searchUsers">
                    <h3>
                        {intl.formatMessage({ id: "help.searchUsers.title" })}
                    </h3>
                    <p>
                        {intl.formatMessage({
                            id: "help.searchUsers.description1",
                        })}
                    </p>
                    <p>
                        {intl.formatMessage({
                            id: "help.searchUsers.description2",
                        })}
                    </p>
                    <p>
                        {intl.formatMessage({
                            id: "help.searchUsers.description3",
                        })}
                    </p>
                </div>
                <div id="exportUsers">
                    <h3>
                        {intl.formatMessage({ id: "help.exportUsers.title" })}
                    </h3>
                    <p>
                        {intl.formatMessage({
                            id: "help.exportUsers.description1",
                        })}
                    </p>
                </div>

                {/* SECURE */}

                {!lockout && (
                    <div>
                        <h1>{intl.formatMessage({ id: "help.secure" })}</h1>
                        <div id="secureSnapshot">
                            <h3>
                                {intl.formatMessage({
                                    id: "help.secureSnapshot.title",
                                })}
                            </h3>
                            <p>
                                {intl.formatMessage({
                                    id: "help.secureSnapshot.description1",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.secureSnapshot.description2",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.secureSnapshot.description3",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.secureSnapshot.description4",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.secureSnapshot.description5",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.secureSnapshot.description6",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.secureSnapshot.description7",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.secureSnapshot.description8",
                                })}
                            </p>
                        </div>
                        <div id="securePoints">
                            <h3>
                                {intl.formatMessage({
                                    id: "help.securePoints.title",
                                })}
                            </h3>
                            <p>
                                {intl.formatMessage({
                                    id: "help.securePoints.description1",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.securePoints.description2",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.securePoints.description3",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.securePoints.description4",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.securePoints.description5",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.securePoints.description6",
                                })}
                            </p>
                        </div>
                        <div id="secureAudits">
                            <h3>
                                {intl.formatMessage({
                                    id: "help.secureAudits.title",
                                })}
                            </h3>
                            <p>
                                {intl.formatMessage({
                                    id: "help.secureAudits.description1",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.secureAudits.description2",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.secureAudits.description3",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.secureAudits.description4",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.secureAudits.description5",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.secureAudits.description6",
                                })}
                            </p>
                        </div>
                        <div id="secureLocks">
                            <h3>
                                {intl.formatMessage({
                                    id: "help.secureLocks.title",
                                })}
                            </h3>
                            <p>
                                {intl.formatMessage({
                                    id: "help.secureLocks.description1",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.secureLocks.description2",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.secureLocks.description3",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.secureLocks.description4",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.secureLocks.description5",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.secureLocks.description6",
                                })}
                            </p>
                        </div>
                        <div id="secureActivity">
                            <h3>
                                {intl.formatMessage({
                                    id: "help.secureActivity.title",
                                })}
                            </h3>
                            <p>
                                {intl.formatMessage({
                                    id: "help.secureActivity.description1",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.secureActivity.description2",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.secureActivity.description3",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.secureActivity.description4",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.secureActivity.description5",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.secureActivity.description6",
                                })}
                            </p>
                        </div>
                        <div id="secureTeams">
                            <h3>
                                {intl.formatMessage({
                                    id: "help.secureTeams.title",
                                })}
                            </h3>
                            <p>
                                {intl.formatMessage({
                                    id: "help.secureTeams.description1",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.secureTeams.description2",
                                })}
                            </p>
                        </div>
                        <div id="secureSettings">
                            <h3>
                                {intl.formatMessage({
                                    id: "help.secureSettings.title",
                                })}
                            </h3>
                            <p>
                                {intl.formatMessage({
                                    id: "help.secureSettings.description1",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.secureSettings.description2",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.secureSettings.description3",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.secureSettings.description4",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.secureSettings.description5",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.secureSettings.description6",
                                })}
                            </p>
                        </div>
                    </div>
                )}

                {/* REPORTS */}

                <h1>Reports</h1>
                <div id="reportsSnapshot">
                    <h3>
                        {intl.formatMessage({
                            id: "help.reportsSnapshot.title",
                        })}
                    </h3>
                    <p>
                        {intl.formatMessage({
                            id: "help.reportsSnapshot.description1",
                        })}
                    </p>
                    <p>
                        {intl.formatMessage({
                            id: "help.reportsSnapshot.description2",
                        })}
                    </p>
                    <p>
                        {intl.formatMessage({
                            id: "help.reportsSnapshot.description3",
                        })}
                    </p>
                    <p>
                        {intl.formatMessage({
                            id: "help.reportsSnapshot.description4",
                        })}
                    </p>
                    <p>
                        {intl.formatMessage({
                            id: "help.reportsSnapshot.description5",
                        })}
                    </p>
                </div>
                <div id="reportsActivity">
                    <h3>
                        {intl.formatMessage({
                            id: "help.reportsActivity.title",
                        })}
                    </h3>
                    <p>
                        {intl.formatMessage({
                            id: "help.reportsActivity.description1",
                        })}
                    </p>
                    <p>
                        {lockout
                            ? intl.formatMessage({
                                  id: "help.reportsActivity.description2lockout",
                              })
                            : intl.formatMessage({
                                  id: "help.reportsActivity.description2",
                              })}
                    </p>
                    <p>
                        {intl.formatMessage({
                            id: "help.reportsActivity.description3",
                        })}
                    </p>
                    <p>
                        {intl.formatMessage({
                            id: "help.reportsActivity.description4",
                        })}
                    </p>
                    <p>
                        {intl.formatMessage({
                            id: "help.reportsActivity.description5",
                        })}
                    </p>
                </div>
                <div id="reportsReports">
                    <h3>
                        {intl.formatMessage({
                            id: "help.reportsReports.title",
                        })}
                    </h3>
                    <p>
                        {intl.formatMessage({
                            id: "help.reportsReports.description1",
                        })}
                    </p>
                    <p>
                        {intl.formatMessage({
                            id: "help.reportsReports.description2",
                        })}
                    </p>
                </div>

                {/* SETTINGS */}

                <h1>{intl.formatMessage({ id: "help.settings" })}</h1>
                <div id="systemSettings">
                    <h3>
                        {intl.formatMessage({
                            id: "help.systemSettings.title",
                        })}
                    </h3>
                    <p>
                        {intl.formatMessage({
                            id: "help.systemSettings.description1",
                        })}
                    </p>
                    <p>
                        {intl.formatMessage({
                            id: "help.systemSettings.description2",
                        })}
                    </p>
                    <p>
                        {intl.formatMessage({
                            id: "help.systemSettings.description3",
                        })}
                    </p>
                    <p>
                        {intl.formatMessage({
                            id: "help.systemSettings.description4",
                        })}
                    </p>
                </div>
                <div id="userRoles">
                    <h3>
                        {intl.formatMessage({ id: "help.userRoles.title" })}
                    </h3>
                    <p>
                        {intl.formatMessage({
                            id: "help.userRoles.description1",
                        })}
                    </p>
                    <p>
                        {intl.formatMessage({
                            id: "help.userRoles.description2",
                        })}
                    </p>
                    <p>
                        {intl.formatMessage({
                            id: "help.userRoles.description3",
                        })}
                    </p>
                    <p>
                        {intl.formatMessage({
                            id: "help.userRoles.description4",
                        })}
                    </p>
                </div>
                <div id="sites">
                    <h3>{intl.formatMessage({ id: "help.sites.title" })}</h3>
                    <p>
                        {intl.formatMessage({ id: "help.sites.description1" })}
                    </p>
                    <p>
                        {intl.formatMessage({ id: "help.sites.description2" })}
                    </p>
                    <p>
                        {intl.formatMessage({ id: "help.sites.description3" })}
                    </p>
                    <p>
                        {intl.formatMessage({ id: "help.sites.description4" })}
                    </p>
                    <p>
                        {intl.formatMessage({ id: "help.sites.description5" })}
                    </p>
                    <p>
                        {intl.formatMessage({ id: "help.sites.description6" })}
                    </p>
                    <p>
                        {intl.formatMessage({ id: "help.sites.description7" })}
                    </p>
                </div>
                {!lockout && (
                    <div>
                        <div id="hazardTypes">
                            <h3>
                                {intl.formatMessage({
                                    id: "help.hazardTypes.title",
                                })}
                            </h3>
                            <p>
                                {intl.formatMessage({
                                    id: "help.hazardTypes.description1",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.hazardTypes.description2",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.hazardTypes.description3",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.hazardTypes.description4",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.hazardTypes.description5",
                                })}
                            </p>
                        </div>
                        <div id="isolationEquipment">
                            <h3>
                                {intl.formatMessage({
                                    id: "help.isolationEquipment.title",
                                })}
                            </h3>
                            <p>
                                {intl.formatMessage({
                                    id: "help.isolationEquipment.description1",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.isolationEquipment.description2",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.isolationEquipment.description3",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.isolationEquipment.description4",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.isolationEquipment.description5",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.isolationEquipment.description6",
                                })}
                            </p>
                        </div>
                        <div id="zoneClassifications">
                            <h3>
                                {intl.formatMessage({
                                    id: "help.zoneClassifications.title",
                                })}
                            </h3>
                            <p>
                                {intl.formatMessage({
                                    id: "help.zoneClassifications.description1",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.zoneClassifications.description2",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.zoneClassifications.description3",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.zoneClassifications.description4",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.zoneClassifications.description5",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.zoneClassifications.description6",
                                })}
                            </p>
                        </div>
                        <div id="permitTypes">
                            <h3>
                                {intl.formatMessage({
                                    id: "help.permitTypes.title",
                                })}
                            </h3>
                            <p>
                                {intl.formatMessage({
                                    id: "help.permitTypes.description1",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.permitTypes.description2",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.permitTypes.description3",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.permitTypes.description4",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.permitTypes.description5",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "help.permitTypes.description6",
                                })}
                            </p>
                        </div>
                    </div>
                )}
            </Container>
        );
    }
}

const mapStateToProps = ({ app, auth }) => {
    const { theme, helpAnchor } = app;
    const { authUser } = auth;
    return { theme, helpAnchor, authUser };
};

export default injectIntl(
    connect(mapStateToProps, { setShowHelp, setHelpAnchor })(GeneralHelp)
);

const styles = () =>
    StyleSheet.create({
        maxHeight: {
            maxHeight: 736,
            overflow: "auto",
        },
        closeIcon: {
            position: "absolute",
            left: 5,
            top: 15,
            cursor: "pointer",
        },
    });
