import {
    ON_HIDE_LOADER,
    ON_SHOW_LOADER,
    SIGNIN_USER,
    SUBMIT_CODE,
    GET_COMPANIES,
    SIGNIN_USER_SUCCESS,
    SIGNOUT_USER,
    SIGNOUT_USER_SUCCESS,
    FORGOT_PASSWORD,
    FORGOT_USER_PASSWORD,
    RESET_PASSWORD,
    RESET_PIN,
    RESET_USER_PASSWORD,
    RESET_USER_PIN,
    USER_TERMS_ACK,
    SET_AUTH_USER,
    SET_COMPANY_LIST,
    SHOW_AUTH,
    SET_WELCOME_MESSAGE,
    SUBMIT_REQUEST_INFO_FORM,
} from "constants/ActionTypes";

export const userSignIn = (data, switchCompany, authDevice, setup) => {
    return {
        type: SIGNIN_USER,
        payload: {
            url: "/auth/login/",
            data,
            switchCompany,
            authDevice,
            setup,
        },
    };
};

export const submitCode = (data, user) => {
    return {
        type: SUBMIT_CODE,
        payload: {
            url: "/auth/device/verify/",
            data,
            user,
        },
    };
};

export const submitRequestInfoForm = (data) => {
    return {
        type: SUBMIT_REQUEST_INFO_FORM,
        payload: {
            url: "/request/create/",
            data,
        },
    };
};

export const userGetCompanies = (data) => {
    return {
        type: GET_COMPANIES,
        payload: {
            url: "/auth/company/list/",
            data,
        },
    };
};

export const setCompanyList = (data) => {
    return {
        type: SET_COMPANY_LIST,
        payload: data,
    };
};

export const showAuth = (show) => {
    return {
        type: SHOW_AUTH,
        payload: show,
    };
};

export const userSignOut = () => {
    return {
        type: SIGNOUT_USER,
    };
};

export const userSignInSuccess = (authUser) => {
    return {
        type: SIGNIN_USER_SUCCESS,
        payload: authUser,
    };
};
export const userSignOutSuccess = () => {
    return {
        type: SIGNOUT_USER_SUCCESS,
    };
};

export const forgotPassword = (data) => {
    return {
        type: FORGOT_PASSWORD,
        payload: {
            url: "/auth/reset/",
            data,
        },
    };
};

export const resetPassword = (data) => {
    return {
        type: RESET_PASSWORD,
        payload: {
            url: "/auth/set/",
            data,
        },
    };
};

export const resetPin = (data) => {
    return {
        type: RESET_PIN,
        payload: {
            url: "/auth/set/pin/",
            data,
        },
    };
};

export const forgotUserPassword = (data) => {
    return {
        type: FORGOT_USER_PASSWORD,
        payload: {
            url: "/auth/reset/",
            data,
        },
    };
};

export const resetUserPassword = (data) => {
    return {
        type: RESET_USER_PASSWORD,
        payload: {
            url: "/auth/set/",
            data,
        },
    };
};

export const resetUserPin = (data) => {
    return {
        type: RESET_USER_PIN,
        payload: {
            url: "/auth/set/pin/",
            data,
        },
    };
};

export const showAuthLoader = () => {
    return {
        type: ON_SHOW_LOADER,
    };
};

export const hideAuthLoader = () => {
    return {
        type: ON_HIDE_LOADER,
    };
};

export const userTermsAck = () => {
    return {
        type: USER_TERMS_ACK,
        payload: {
            url: "/user/update/terms/",
        },
    };
};

export const setAuthUser = (authUser) => {
    return {
        type: SET_AUTH_USER,
        payload: authUser,
    };
};

export const setWelcomeMessage = (data) => {
    return {
        type: SET_WELCOME_MESSAGE,
        payload: data,
    };
};
