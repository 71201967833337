import React from "react";
import { connect } from "react-redux";

import LanguageList from "components/LanguageSwitcher/list";
import Dropdown from "components/Dropdowns";
import "../../../public/vendors/flag/sprite-flags-24x24.css";

class LanguageSwitcher extends React.Component {
    render() {
        const { locale } = this.props;
        return (
            <Dropdown
                menu={LanguageList}
                direction="up"
                width={180}
                title={"comp.header.select.lang"}
            >
                <div
                    className="d-flex align-items-center pointer"
                    style={{ margin: "0 0 5px 0" }}
                >
                    <h4 style={{ margin: 0 }}>{locale.name}</h4>
                    <i className="material-icons">arrow_drop_down</i>
                </div>
            </Dropdown>
        );
    }
}

const mapStateToProps = ({ app }) => {
    const { locale } = app;
    return { locale };
};

export default connect(mapStateToProps)(LanguageSwitcher);
