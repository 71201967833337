import {
    SET_ACTIVE_MACHINES,
    SET_ACTIVE_MACHINE_TAB,
    SET_FILTER_MACHINES,
    SET_LOTO_DEVICE_SELECT,
    SET_MACHINE,
    SET_MACHINES,
    SET_MACHINES_SELECT,
    SET_MACHINE_ACTIVITY,
    SET_MACHINE_DOCUMENT,
    SET_MACHINE_DOCUMENTS,
    SET_MACHINE_FILTER,
    SET_MACHINE_JOB,
    SET_MACHINE_JOBS,
    SET_MACHINE_JOB_ACTION,
    SET_MACHINE_JOB_ACTIONS,
    SET_MACHINE_JOB_PROCESS,
    SET_MACHINE_POINT,
    SET_MACHINE_POINTS,
    SET_MACHINE_POINT_ACTION,
    SET_MACHINE_POINT_ACTIONS,
    SET_MACHINE_POINT_ACTION_LIST,
    SET_MACHINE_SEARCH_TERMS,
    SET_POINT_ACTIVITY,
    SET_POINT_EQUIPMENT,
    SET_POINT_HAZARDS,
    SET_SEARCHED_MACHINES,
    SET_SITE_MACHINES,
    SET_JOB_TEMPLATES,
    SET_MACHINE_JOB_TEMPLATE,
    SET_JOB_TEMPLATES_SELECT
} from "constants/ActionTypes";

const INIT_STATE = {
    activeMachineTab: "0",
    loader: false,
    lotoDevicesSelect: [],
    machineActivity: [],
    machineDocument: null,
    machineDocuments: [],
    machineJobActionObject: [],
    machineJobActionsList: [],
    machineJobObject: null,
    machineJobTemplateObject: null,
    machineJobProcess: [],
    machineJobs: [],
    machineObject: {},
    machinePointActionList: [],
    machinePointActionObject: [],
    machinePointActionsList: [],
    machinePointObject: [],
    machinePoints: [],
    machinesActiveList: [],
    machinesList: [],
    machinesListFilter: [],
    machinesSelect: [],
    pointActivity: [],
    pointEquipment: [],
    pointHazards: [],
    searchedMachines: [],
    jobTemplates: [],
    siteMachines: [],
    jobTemplatesSelect: [],
    machineFilterList: [
        {
            title: "comp.table.header.name",
            width: "20%",
            active: false,
            sortable: true,
            sortTitle: "Name",
            order: false,
            mobile: true,
            isolation: true,
            lockout: true,
        },
        {
            title: "pages.Site.Type",
            width: "20%",
            active: false,
            sortable: true,
            sortTitle: "SiteName",
            order: false,
            mobile: false,
            isolation: true,
            lockout: true,
        },
        {
            title: "comp.table.header.lockoutPoints",
            width: "20%",
            active: false,
            sortable: true,
            sortTitle: "UdbLockoutPoint",
            order: false,
            mobile: false,
            isolation: false,
            lockout: true,
        },
        {
            title: "pages.dashboard.openTickets",
            width: "20%",
            active: true,
            sortable: true,
            sortTitle: "OpenWorkOrders",
            order: true,
            mobile: true,
            isolation: true,
            lockout: false,
        },
        {
            title: "comp.table.header.ticket",
            width: "20%",
            active: true,
            sortable: true,
            sortTitle: "WorkOrderId",
            order: true,
            mobile: true,
            isolation: false,
            lockout: true,
        },
        {
            title: "comp.table.header.active",
            width: "20%",
            active: false,
            sortable: true,
            sortTitle: "IsActive",
            order: false,
            mobile: true,
            isolation: true,
            lockout: false,
            style: { textAlign: "right" },
        },
        {
            title: "comp.table.header.status",
            width: "20%",
            active: false,
            sortable: true,
            sortTitle: "IsActive",
            order: false,
            mobile: true,
            isolation: false,
            lockout: true,
        },
    ],
    machinePointFilterList: [
        {
            title: "form.name",
            width: "30%",
            active: true,
            sortable: true,
            sortTitle: "Description",
            style: { textAlign: "left" },
            order: false,
            mobile: true,
        },
        {
            title: "form.locationDescript",
            width: "33%",
            active: false,
            sortable: true,
            sortTitle: "LocationDescription",
            order: false,
            mobile: false,
        },
        {
            title: "comp.form.clickAddPoint",
            width: "36%",
            active: false,
            sortable: false,
            sortTitle: "Name",
            style: { textAlign: "right" },
            order: true,
            mobile: true,
        },
    ],
    jobTemplatesFilterList: [
        {
            title: "form.name",
            width: "50%",
            active: true,
            sortable: true,
            sortTitle: "Name",
            order: false,
            mobile: true,
        },
        {
            title: "comp.table.header.active",
            width: "50%",
            active: false,
            sortable: true,
            sortTitle: "IsActive",
            order: false,
            mobile: true,
            isolation: false,
            lockout: true,
            style: { textAlign: "right" },
        },
    ],
    searchMachineTerms: {
        Name: "",
        SiteId: "",
        Identifier: "",
        RequireLockConnect: "",
        RequireLockConnectAic: "",
        RequireAicOnTicket: "",
        RequirePointImage: "",
        LocksRequired: "",
        IsActive: "1",
        HasWorkOrder: "",
    },
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_MACHINES: {
            return {
                ...state,
                machinesList: action.payload,
                machinesListFilter: [],
            };
        }
        case SET_ACTIVE_MACHINES: {
            return {
                ...state,
                machinesActiveList: action.payload,
            };
        }
        case SET_MACHINE: {
            return {
                ...state,
                machineObject: action.payload,
            };
        }
        case SET_FILTER_MACHINES: {
            return {
                ...state,
                machinesListFilter: action.payload,
            };
        }
        case SET_MACHINE_FILTER: {
            return {
                ...state,
                machineFilterList: action.payload,
            };
        }
        case SET_SITE_MACHINES: {
            return {
                ...state,
                siteMachines: action.payload,
            };
        }
        case SET_MACHINE_ACTIVITY: {
            return {
                ...state,
                machineActivity: action.payload,
            };
        }
        case SET_SEARCHED_MACHINES: {
            return {
                ...state,
                searchedMachines: action.payload,
            };
        }
        case SET_JOB_TEMPLATES: {
            return {
                ...state,
                jobTemplates: action.payload
            }
        }
        case SET_MACHINE_JOB_TEMPLATE: {
            return {
                ...state,
                machineJobTemplateObject: action.payload
            }
        }
        case SET_MACHINE_DOCUMENTS: {
            return {
                ...state,
                machineDocuments: action.payload,
            };
        }
        case SET_MACHINE_DOCUMENT: {
            return {
                ...state,
                machineDocument: action.payload,
            };
        }
        case SET_MACHINE_SEARCH_TERMS: {
            return {
                ...state,
                searchMachineTerms: action.payload,
            };
        }
        case SET_MACHINES_SELECT: {
            return {
                ...state,
                machinesSelect: action.payload,
            };
        }
        case SET_JOB_TEMPLATES_SELECT: {
            return {
                ...state,
                jobTemplatesSelect: action.payload,
            };
        }
        case SET_MACHINE_JOBS: {
            return {
                ...state,
                machineJobs: action.payload,
            };
        }
        case SET_MACHINE_POINTS: {
            return {
                ...state,
                machinePoints: action.payload,
            };
        }
        case SET_LOTO_DEVICE_SELECT: {
            return {
                ...state,
                lotoDevicesSelect: action.payload,
            };
        }
        case SET_MACHINE_POINT: {
            return {
                ...state,
                machinePointObject: action.payload,
            };
        }
        case SET_MACHINE_JOB: {
            return {
                ...state,
                machineJobObject: action.payload,
            };
        }
        case SET_MACHINE_JOB_ACTIONS: {
            return {
                ...state,
                machineJobActionsList: action.payload,
            };
        }
        case SET_MACHINE_POINT_ACTIONS: {
            return {
                ...state,
                machinePointActionsList: action.payload,
            };
        }
        case SET_MACHINE_POINT_ACTION: {
            return {
                ...state,
                machinePointActionObject: action.payload,
            };
        }
        case SET_MACHINE_JOB_ACTION: {
            return {
                ...state,
                machineJobActionObject: action.payload,
            };
        }
        case SET_MACHINE_JOB_PROCESS: {
            return {
                ...state,
                machineJobProcess: action.payload,
            };
        }
        case SET_POINT_ACTIVITY: {
            return {
                ...state,
                pointActivity: action.payload,
            };
        }
        case SET_POINT_HAZARDS: {
            return {
                ...state,
                pointHazards: action.payload,
            };
        }
        case SET_POINT_EQUIPMENT: {
            return {
                ...state,
                pointEquipment: action.payload,
            };
        }
        case SET_MACHINE_POINT_ACTION_LIST: {
            return {
                ...state,
                machinePointActionList: action.payload,
            };
        }
        case SET_ACTIVE_MACHINE_TAB: {
            return {
                ...state,
                activeMachineTab: action.payload,
            };
        }
        default:
            return state;
    }
};
