// CORE
export const RX_SERVICE_UUID = "1bc50001-0200-d29e-e511-446c609db825";
export const RX_CHAR_UUID = "1bc50002-0200-d29e-e511-446c609db825";
export const TX_CHAR_UUID = "1bc50003-0200-d29e-e511-446c609db825";
export const STATE_CHAR_UUID = "1bc50004-0200-d29e-e511-446c609db825";
export const FETCH_BLUETOOTH_DEVICE = 'fetchBluetoothDevice';

// LOCKS
export const FETCH_LOCKS_LIST_WORK_ORDER = 'fetchLocksListWorkOrder';
export const FETCH_LOCKS_BY_ID = 'fetchLocksById';
export const FETCH_LOCKS_BY_MAC = 'fetchLocksByMac';
export const FETCH_LOCKS_BY_SERIAL = 'fetchLocksBySerial';
export const FETCH_LOCKS_LIST_AVAILABLE = 'fetchLocksListAvailable';
export const FETCH_LOCKS_SEARCH_SELECT = 'fetchLocksSearchSelect';
export const SET_LOCKS = 'setLocks';
export const SET_LOCK = 'setLock';
export const CREATE_LOCK = 'createLock';
export const EDIT_LOCK = 'editLock';
export const DELETE_LOCK = 'deleteLock';
export const SET_LOCK_FILTER = 'setLockFilter';
export const FETCH_LOCK_ACTIVITY = 'fetchLockActivity';
export const SET_LOCK_ACTIVITY = 'setLockActivity';
export const SEARCH_LOCKS = 'searchLocks';
export const SET_SEARCHED_LOCKS = 'setSearchedLocks';
export const SET_LOCK_SEARCH_TERMS = 'setLockSearchTerms';
export const SET_LOCKS_SEARCH_SELECT = 'setLocksSearchSelect';

// TICKETS
export const FETCH_TICKETS_LIST = 'fetchTicketsList';
export const FETCH_TICKETS_LIST_OPEN = 'fetchTicketsListOpen';
export const FETCH_TICKETS_LIST_BY_USER_ID = 'fetchTicketsListByUserId';
export const FETCH_TICKET_LIST_ACTIVITY = 'fetchTicketListActivity';
export const FETCH_TICKET_LIST_LOCKOUTS = 'fetchTicketListLockouts';
export const FETCH_TICKET_LIST_POINTS = 'fetchTicketListPoints';
export const FETCH_TICKET_LIST_NOTES = 'fetchTicketListNotes';
export const FETCH_TICKET_LIST_PERMITS = 'fetchTicketListPermits';
export const FETCH_TICKET_EQUIPMENT = 'fetchTicketEquipment';
export const SET_TICKET_EQUIPMENT = 'setTicketEquipment';
export const FETCH_TICKET_DOCUMENTS = 'fetchTicketDocuments';
export const FETCH_TICKET_DOCUMENT_BY_ID = 'fetchTicketDocumentById';
export const FETCH_TICKET_USERS = 'fetchTicketUsers';
export const ADD_TICKET_USER = 'addTicketUser';
export const REMOVE_TICKET_USER = 'removeTicketUser';
export const FETCH_TICKETS_BY_ID = 'fetchTicketsById';
export const FETCH_TICKETS_BY_MACHINE_ID = 'fetchTicketsByMachineId';
export const COMPLETE_TICKET = 'completeTicket';
export const CREATE_TICKET = 'createTicket';
export const CREATE_TICKET_NOTE = 'createTicketNote';
export const CREATE_TICKET_PERMIT = 'createTicketPermit';
export const CREATE_TICKET_DOCUMENT = 'createTicketDocument';
export const EDIT_TICKET = 'editTicket';
export const DELETE_TICKET = 'deleteTicket';
export const CLOSE_TICKET = 'closeTicket';
export const FILTER_TICKETS = 'filterTickets';
export const FILTER_TICKETS_LIST = 'filterTicketsList';
export const SEARCH_TICKET_LIST = 'searchTicketsList';
export const SET_FILTER_TICKETS = 'setFilterTickets';
export const SET_TICKETS = 'setTickets';
export const SET_TICKET = 'setTicket';
export const SET_TICKET_ACTIVITY = 'setTicketActivity';
export const SET_TICKET_LOCKOUTS = 'setTicketLockouts';
export const SET_TICKET_POINTS = 'setTicketPoints';
export const SET_TICKET_NOTES = 'setTicketNotes';
export const SET_TICKET_PERMITS = 'setTicketPermits';
export const SET_TICKET_DOCUMENTS = 'setTicketDocuments';
export const SET_TICKET_DOCUMENT = 'setTicketDocument';
export const SET_TICKET_USERS = 'setTicketUsers';
export const SET_TICKET_FILTER = 'setTicketFilter';
export const FETCH_TICKET_PROCESS = 'fetchTicketProcess';
export const SET_TICKET_PROCESS = 'setTicketProcess';
export const SET_TICKET_PROCESS_LOADED = 'setTicketProcessLoaded';
export const FETCH_TICKET_POINT_PROCESS = 'fetchTicketPointProcess';
export const SET_TICKET_POINT_PROCESS = 'setTicketPointProcess';
export const FETCH_TICKET_HIERARCHY = 'fetchTicketHierarchy';
export const SET_TICKET_HIERARCHY = 'setTicketHierarchy';
export const SEARCH_TICKETS = 'searchTickets';
export const SEARCH_PARENT_TICKETS = 'searchParentTickets';
export const SET_PARENT_TICKET = 'setParentTicket';
export const FETCH_TICKETS_BY_PARENT_ID = 'fetchTicketsByParentId';
export const SET_SEARCHED_TICKETS = 'setSearchedTickets';
export const SET_PARENT_TICKETS = 'setParentTickets';
export const SET_TICKET_SEARCH_TERMS = 'setTicketSearchTerms';
export const CREATE_TICKET_AIC = 'createTicketAIC';
export const TRANSFER_TICKET_AIC = 'transferTicketAIC';
export const PULL_TICKET_AIC = 'pullTicketAIC';
export const ADD_LOCKOUT = 'addLockout';
export const REMOVE_LOCKOUT = 'removeLockout';
export const FETCH_ACTIVE_LOCKOUTS = 'fetchActiveLockouts';
export const SET_ACTIVE_LOCKOUTS = 'setActiveLockouts';

// USERS
export const FETCH_USERS_LIST = 'fetchUsersList';
export const FETCH_USERS_LIST_AIC = 'fetchUsersListAIC';
export const FETCH_USER_BY_ID = 'fetchUserById';
export const FETCH_USER_NOTIFICATIONS = 'fetchUserNotifications';
export const FETCH_USER_UNREAD_NOTIFICATIONS = 'fetchUserUnreadNotifications';
export const ACKNOWLEDGE_NOTIFICATION = 'acknowledgeNotification';
export const DELETE_NOTIFICATION = 'deleteNotification';
export const SET_CHECKED_NOTIFICATIONS = 'setCheckedNotifications';
export const CREATE_USER = 'createUser';
export const EDIT_USER = 'editUser';
export const EDIT_SELF = 'editSelf';
export const DELETE_USER = 'deleteUser';
export const FILTER_USERS = 'filterUsers';
export const SET_USERS = 'setUsers';
export const SET_USER = 'setUser';
export const SET_USER_NOTIFICATIONS = 'setUserNotifications';
export const SET_USER_UNREAD_NOTIFICATIONS = 'setUserUnreadNotifications';
export const SET_USER_FILTER = 'setUserFilter';
export const FETCH_USER_ACTIVITY = 'fetchUserActivity';
export const SET_USER_ACTIVITY = 'setUserActivity';
export const SEARCH_USERS = 'searchUsers';
export const SET_SEARCHED_USERS = 'setSearchedUsers';
export const SET_USER_SEARCH_TERMS = 'setUserSearchTerms';

// MACHINES
export const FETCH_MACHINES_LIST = 'fetchMachinesList';
export const FETCH_ACTIVE_MACHINES_LIST = 'fetchActiveMachinesList';
export const FETCH_MACHINES_LIST_SELECT = 'fetchMachinesListSelect';
export const FETCH_MACHINE_BY_ID = 'fetchMachineById';
export const CREATE_MACHINE = 'createMachine';
export const EDIT_MACHINE = 'editMachine';
export const DELETE_MACHINE = 'deleteMachine';
export const FILTER_MACHINES = 'filterMachines';
export const FILTER_MACHINES_LIST = 'filterMachinesList';
export const SEARCH_MACHINES_LIST = 'searchMachinesList';
export const SET_FILTER_MACHINES = 'setFilterMachines';
export const SET_MACHINES = 'setMachines';
export const SET_ACTIVE_MACHINES = 'setActiveMachines';
export const SET_MACHINE = 'setMachine';
export const SET_MACHINE_FILTER = 'setMachineFilter';
export const FETCH_MACHINES_BY_SITE_ID = 'fetchMachinesBySiteId';
export const FETCH_MACHINE_DOCUMENTS = 'fetchMachineDocuments';
export const FETCH_MACHINE_DOCUMENT_BY_ID = 'fetchMachineDocumentById';
export const CREATE_MACHINE_DOCUMENT = 'createMachineDocument';
export const SET_SITE_MACHINES = 'setSiteMachines';
export const FETCH_MACHINE_ACTIVITY = 'fetchMachineActivity';
export const SET_MACHINE_ACTIVITY = 'setMachineActivity';
export const SEARCH_MACHINES = 'searchMachines';
export const SET_SEARCHED_MACHINES = 'setSearchedMachines';
export const SEARCH_JOB_TEMPLATES = 'searchJobTemplates';
export const FETCH_JOB_TEMPLATES_SELECT = 'fetchJobTemplatesSelect';
export const APPLY_JOB_TEMPLATE = 'applyJobTemplate';
export const SET_JOB_TEMPLATES_SELECT = 'setJobTemplatesSelect';
export const SET_JOB_TEMPLATES = 'setJobTemplates';
export const SET_MACHINE_DOCUMENTS = 'setMachineDocuments';
export const SET_MACHINE_DOCUMENT = 'setMachineDocument';
export const SET_MACHINE_SEARCH_TERMS = 'setMachineSearchTerms';

export const SET_MACHINES_SELECT = 'setMachinesSelect';
export const FETCH_JOBS_BY_MACHINE_ID = 'fetchJobsByMachineId';
export const SET_MACHINE_JOBS = 'setMachineJobs';
export const CREATE_MACHINE_JOB = 'createMachineJob';
export const CREATE_MACHINE_JOB_TEMPLATE = 'createMachineJobTemplate';
export const SET_MACHINE_POINTS = 'setMachinePoints';
export const FETCH_LOTO_DEVICE_SELECT = 'fetchLotoDeviceSelect';
export const SET_LOTO_DEVICE_SELECT = 'setLotoDeviceSelect';
export const CREATE_MACHINE_POINT = 'createMachinePoint';
export const UPDATE_POINT_HAZARDS = 'updatePointHazards';
export const UPDATE_POINT_EQUIPMENT = 'updatePointEquipment';
export const FETCH_POINT_HAZARDS = 'fetchPointHazards';
export const SET_POINT_HAZARDS = 'setPointHazards';
export const FETCH_POINT_EQUIPMENT = 'fetchPointEquipment';
export const SET_POINT_EQUIPMENT = 'setPointEquipment';
export const FETCH_MACHINE_POINT_BY_ID = 'fetchMachinePointById';
export const SET_MACHINE_POINT = 'setMachinePoint';
export const FETCH_MACHINE_JOB_BY_ID = 'fetchMachineJobById';
export const SET_MACHINE_JOB = 'setMachineJob';
export const FETCH_MACHINE_JOB_TEMPLATE_BY_ID = 'fetchMachineJobTemplateById';
export const SET_MACHINE_JOB_TEMPLATE = 'setMachineJobTemplate';
export const FETCH_MACHINE_JOB_ACTIONS = 'fetchMachineJobActions';
export const SET_MACHINE_JOB_ACTIONS = 'setMachineJobActions';
export const FETCH_MACHINE_POINT_ACTIONS = 'fetchMachinePointActions';
export const SET_MACHINE_POINT_ACTIONS = 'setMachinePointActions';
export const CREATE_MACHINE_POINT_ACTION = 'createMachinePointAction';
export const EDIT_MACHINE_POINT_ACTION = 'editMachinePointAction';
export const FETCH_MACHINE_POINT_ACTION_BY_ID = 'fetchMachinePointActionById';
export const SET_MACHINE_POINT_ACTION = 'setMachinePointAction';
export const DELETE_MACHINE_POINT_ACTION = 'deleteMachinePointAction';
export const CREATE_MACHINE_JOB_ACTION = 'createMachineJobAction';
export const DELETE_MACHINE_JOB_ACTION = 'deleteMachineJobAction';
export const FETCH_MACHINE_JOB_ACTION_BY_ID = 'fetchMachineJobActionById';
export const CREATE_MACHINE_JOB_TEMPLATE_ACTION = 'createMachineJobTemplateAction';
export const SET_MACHINE_JOB_ACTION = 'setMachineJobAction';
export const EDIT_MACHINE_JOB_ACTION = 'editMachineJobAction';
export const EDIT_MACHINE_JOB_TEMPLATE_ACTION = 'editMachineJobTemplateAction';
export const EDIT_MACHINE_JOB_TEMPLATE_PROCESS = 'editMachineJobTemplateProcess';
export const EDIT_MACHINE_JOB = 'editMachineJob';
export const EDIT_MACHINE_POINT = 'editMachinePoint';
export const EDIT_MACHINE_POINT_PROCESS = 'editMachinePointProcess';
export const FETCH_MACHINE_JOB_PROCESS = 'fetchMachineJobProcess';
export const SET_MACHINE_JOB_PROCESS = 'setMachineJobProcess';
export const EDIT_MACHINE_JOB_PROCESS = 'editMachineJobProcess';
export const CREATE_MACHINE_JOB_PROCESS = 'createMachineJobProcess';
export const DELETE_MACHINE_JOB_PROCESS = 'deleteMachineJobProcess';
export const DELETE_MACHINE_JOB_TEMPLATE_ACTION = 'deleteMachineJobTemplateAction';
export const FETCH_POINT_ACTIVITY = 'fetchPointActivity';
export const SET_POINT_ACTIVITY = 'setPointActivity';
export const FETCH_MACHINE_POINT_ACTION_LIST = 'fetchMachinePointActionList';
export const SET_MACHINE_POINT_ACTION_LIST = 'setMachinePointActionList';
export const SEARCH_MACHINE_POINT_LIST = 'searchMachinePointList';

// CLIENT
export const FETCH_CLIENT_BY_ID = 'fetchClientById';
export const FETCH_CLIENT_LIST = 'fetchClientList';
export const FETCH_CLIENT_LOGIN_LIST = 'fetchClientLoginList';
export const SET_CLIENT_OBJECT = 'setClientObject';
export const SET_CLIENT_LIST = 'setClientList';
export const SET_CLIENT_LOGIN_LIST = 'setClientLoginList';
export const CREATE_CLIENT = 'createClient';
export const CREATE_CLIENT_NOTE = 'createClientNote';
export const EDIT_CLIENT = 'editClient';
export const DELETE_CLIENT = 'deleteClient';
export const SET_CLIENT_FILTER = 'setClientFilter';
export const SET_ACTIVE_CLIENT = 'setActiveClient';
export const CREATE_LOCK_API = 'createLockApi';

// SETTINGS
export const FETCH_SETTINGS = 'fetchSettings';
export const FETCH_ROLES_LIST = 'fetchRolesList';
export const FETCH_ROLE = 'fetchRole';
export const FETCH_PERMISSIONS_LIST = 'fetchPermissionsList';
export const CREATE_ROLE = 'createRole';
export const EDIT_SETTINGS = 'editSettings';
export const EDIT_ROLE = 'editRole';
export const DELETE_ROLE = 'deleteRole';
export const SET_SETTINGS = 'setSettings';
export const SET_ROLES_LIST = 'setRolesList';
export const SET_ROLE = 'setRole';
export const SET_PERMISSIONS_LIST = 'setPermissionsList';
export const FETCH_ISOLATION_EQUIPMENT_LIST = 'fetchIsolationEquipmentList';
export const SET_ISOLATION_EQUIPMENT_LIST = 'setIsolationEquipmentList';
export const SET_ISOLATION_EQUIPMENT_FILTER = 'setIsolationEquipmentFilter';
export const FETCH_ISOLATION_HAZARDS_LIST = 'fetchIsolationHazardsList';
export const SET_ISOLATION_HAZARDS_LIST = 'setIsolationHazardsList';
export const SET_ISOLATION_HAZARDS_FILTER = 'setIsolationHazardsFilter';
export const FETCH_SITES_LIST = 'fetchSitesList';
export const FETCH_ACTIVE_SITES_LIST = 'fetchActiveSitesList';
export const SET_SITES_LIST = 'setSitesList';
export const SET_SITES_FILTER = 'setSitesFilter';
export const FETCH_SITE_BY_ID = 'fetchSiteById';
export const SET_SITE = 'setSite';
export const SET_ACTIVE_SETTING = 'setActiveSetting';
export const CREATE_SITE = 'createSite';
export const EDIT_SITE = 'editSite';
export const SET_ACTIVE_ROLE = 'setActiveRole';
export const SET_ACTIVE_APP_SETTING = 'setActiveAppSetting';
export const SET_ACTIVE_PERSONAL_SETTING = 'setActivePersonalSetting';
export const FETCH_TERMS = 'fetchTerms';
export const SET_TERMS = 'setTerms';
export const UPDATE_TERMS = 'updateTerms';
export const CREATE_EQUIPMENT = 'createEquipment';
export const UPLOAD_IMAGE = 'uploadImage';
export const CREATE_HAZARD = 'createHazard';
export const FETCH_CLASSIFICATIONS_LIST = 'fetchClassificationsList';
export const SET_CLASSIFICATIONS_LIST = 'setClassificationsList';
export const CREATE_CLASSIFICATION = 'createClassification';
export const SET_CLASSIFICATION_FILTER = 'setClassificationFilter';
export const FETCH_PERMITS_LIST = 'fetchPermitsList';
export const SET_PERMITS_LIST = 'setPermitsList';
export const CREATE_PERMIT = 'createPermit';
export const SET_PERMIT_FILTER = 'setPermitFilter';
export const FETCH_CLASSIFICATION_BY_ID = 'fetchClassificationById';
export const SET_CLASSIFICATION = 'setClassification';
export const FETCH_PERMIT_BY_ID = 'fetchPermitById';
export const SET_PERMIT = 'setPermit';
export const FETCH_EQUIPMENT_BY_ID = 'fetchEquipmentById';
export const SET_EQUIPMENT = 'setEquipment';
export const FETCH_HAZARD_BY_ID = 'fetchHazardById';
export const SET_HAZARD = 'setHazard';
export const SET_HAZARD_INACTIVE = 'setHazardInactive';
export const SET_EQUIPMENT_INACTIVE = 'setEquipmentInactive';
export const SET_CLASSIFICATION_INACTIVE = 'setClassificationInactive';
export const SET_PERMIT_INACTIVE = 'setPermitInactive';
export const SET_SITE_INACTIVE = 'setSiteInactive';
export const EDIT_CLASSIFICATION = 'editClassification';
export const EDIT_PERMIT = 'editPermit';
export const EDIT_EQUIPMENT = 'editEquipment';
export const EDIT_HAZARD = 'editHazard';
export const FETCH_CLASSIFICATIONS_LIST_SELECT = 'fetchClassificationsListSelect';
export const SET_CLASSIFICATIONS_LIST_SELECT = 'setClassificationsListSelect';
export const TEAM_MEMBER_ADD = 'teamMemberAdd';
export const TEAM_MEMBER_REMOVE = 'teamMemberRemove';

// CAR SEALS
export const FETCH_TEAMS = 'fetchTeams';
export const SET_TEAMS = 'setTeams';
export const FETCH_TEAMS_SELECT = 'fetchTeamsSelect';
export const SET_TEAMS_SELECT = 'setTeamsSelect';
export const FETCH_UNITS = 'fetchUnits';
export const SET_UNITS = 'setUnits';
export const FETCH_UNITS_SELECT = 'fetchUnitsSelect';
export const SET_UNITS_SELECT = 'setUnitsSelect';
export const CREATE_TEAM = 'createTeam';
export const CREATE_UNIT = 'createUnit';
export const FETCH_SEALS = 'fetchSeals';
export const SET_SEALS = 'setSeals';
export const FETCH_SEAL_BY_ID = 'fetchSealById';
export const SET_SEAL = 'setSeal';
export const FETCH_SITES_SEALS = 'fetchSitesSeals';
export const SET_SITES_SEALS = 'setSitesSeals';
export const FETCH_POSITIONS = 'fetchPositions';
export const SET_POSITIONS = 'setPositions';
export const CREATE_POSITION = 'createPosition';
export const FETCH_SEAL_SNAPSHOT = 'fetchSealSnapshot';
export const SET_SEAL_SNAPSHOT = 'setSealSnapshot';
export const CREATE_SEAL = 'createSeal';
export const FETCH_TEAM_BY_ID = 'fetchTeamById';
export const SET_TEAM = 'setTeam';
export const FETCH_UNIT_BY_ID = 'fetchUnitById';
export const SET_UNIT = 'setUnit';
export const FETCH_POSITION_BY_ID = 'fetchPositionById';
export const SET_POSITION = 'setPosition';
export const EDIT_TEAM = 'editTeam';
export const EDIT_UNIT = 'editUnit';
export const EDIT_POSITION = 'editPosition';
export const FETCH_SEALS_ACTIVITY = 'fetchSealsActivity';
export const SET_SEALS_ACTIVITY = 'setSealsActivity';
export const SET_SEAL_ACTIVITY_FILTER = 'setSealActivityFilter';
export const SET_ACTIVE_SEAL_TAB = 'setActiveSealTab';
export const FETCH_SEAL_ACTIVITY = 'fetchSealActivity';
export const SET_SEAL_ACTIVITY = 'setSealActivity';
export const EDIT_SEAL = 'editSeal';
export const SEARCH_SEALS = 'searchSeals';
export const SET_SEARCHED_SEALS = 'setSearchedSeals';
export const SET_SEAL_SEARCH_TERMS = 'setSealSearchTerms';
export const SEARCH_SEAL_AUDIT = 'searchSealAudit';
export const SET_SEAL_AUDIT_LIST = 'setSealAuditList';
export const SET_AUDIT_SEARCH_TERMS = 'setAuditSearchTerms';
export const SET_SNAPSHOT_SEARCH_TERMS = 'setSnapshotSearchTerms';
export const SET_SEAL_FILTER = 'setSealFilter';
export const FETCH_POSITIONS_SELECT = 'fetchPositionsSelect';
export const SET_POSITIONS_SELECT = 'setPositionsSelect';
export const FETCH_SEAL_SEARCH_SELECT = 'fetchSealSearchSelect';
export const SET_SEAL_SEARCH_USERS = 'setSealSearchUsers';
export const SET_SEAL_SEARCH_POSITIONS = 'setSealSearchPositions';
export const FETCH_SEAL_ACTIVITY_SEARCH_SELECT = 'fetchSealActivitySearchSelect';
export const SET_SEAL_ACTIVITY_SEARCH_ACTIVITY_TYPES = 'setSealActivitySearchActivityTypes';
export const SEARCH_SEAL_ACTIVITY = 'searchSealActivity';
export const SET_SEAL_ACTIVITY_SEARCH_LIST = 'setSealActivitySearchList';
export const SET_SEAL_ACTIVITY_SEARCH_TERMS = 'setSealActivitySearchTerms';
export const SET_SEAL_SEARCH_SEALS = 'setSealSearchSeals';
export const SET_SEAL_SEARCH_DEVICES = 'setSealSearchDevices';
export const FETCH_SEAL_SEARCH_DEVICES = 'fetchSealSearchDevices';
export const FETCH_SEAL_DEVICE_BY_ID = 'fetchSealDeviceById';
export const SET_SEAL_LOCK = 'setSealLock';
export const DELETE_SEAL_LOCK = 'deleteSealLock';
export const CREATE_SEAL_LOCK = 'createSealLock';
export const EDIT_SEAL_LOCK = 'editSealLock';

// APP
export const SET_SEARCH = 'setSearch';
export const SET_FILTER = 'setFilter';
export const SET_LOADER = 'setLoader';
export const SET_NEWS = 'setNews';
export const SET_SIDEBAR = 'setSidebar';
export const SET_SHOW_HELP = 'setShowHelp';
export const SET_HELP_ANCHOR = 'setHelpAnchor';

// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'toggle_collapse_menu';
export const DRAWER_TYPE = 'drawer_type';
export const FIXED_DRAWER = 'fixed_drawer';
export const COLLAPSED_DRAWER = 'collapsible';
export const MINI_DRAWER = 'mini_drawer';
export const SET_THEME_MODE = 'setThemeMode';
export const SET_THEME_MODE_CONTRAST = 'setThemeModeContrast';
export const SET_THEME_COLORS = 'setThemeColor';
export const DARK_THEME = 'dark_theme';
export const WINDOW_WIDTH = 'window-width';
export const WINDOW_HEIGHT = 'window-height';
export const SWITCH_LANGUAGE = 'switch-language';
export const SET_TERMS_MESSAGES = 'setTermsMessages';

export const HORIZONTAL_NAVIGATION = 'horizontal_navigation';
export const VERTICAL_NAVIGATION = 'vertical_navigation';

export const HORIZONTAL_MENU_POSITION = 'horizontal_menu_position';
export const ABOVE_THE_HEADER = 'above_the_header';
export const INSIDE_THE_HEADER = 'inside_the_header';
export const BELOW_THE_HEADER = 'below_the_header';

//Contact Module const

export const SHOW_MESSAGE = 'show_message';
export const HIDE_MESSAGE = 'hide_message';

export const FETCH_ALL_CONTACT = 'fetch_all_contact';
export const FETCH_ALL_CONTACT_SUCCESS = 'fetch_all_contact_success';
export const ADD_FAVOURITE = 'add_favourite';
export const ON_CONTACT_SELECT = 'on_contact_select';
export const ON_ADD_CONTACT = 'on_add_contact';
export const ON_CONTACT_CLOSE = 'on_contact_close';
export const ON_FILTER_OPTION_SELECT = 'on_filter_option_select';
export const ON_SAVE_CONTACT = 'on_save_contact';
export const ON_DELETE_CONTACT = 'on_delete_contact';
export const ON_DELETE_SELECTED_CONTACT = 'on_delete_selected_contact';
export const FILTER_CONTACT = 'filter_contact';
export const GET_ALL_CONTACT = 'get_all_contact';
export const GET_UNSELECTED_ALL_CONTACT = 'get_unselected_all_contact';
export const ON_ALL_CONTACT_SELECT = 'on_all_contact_select';
export const UPDATE_SEARCH_USER = 'update_search_user';
export const ON_TOGGLE_DRAWER = 'on_toggle_drawer';
export const HANDLE_REQUEST_CLOSE = 'handle_request_close';
export const HIDE_CONTACT_LOADER = 'hide_contact_loader';

//Auth const
export const SIGNIN_USER = 'signin_user';
export const SUBMIT_CODE = 'submit_code';
export const GET_COMPANIES = 'get_companies';
export const SET_COMPANY_LIST = 'setCompanyList';
export const SHOW_AUTH = 'showAuth';
export const SIGNIN_USER_SUCCESS = 'signin_user_success';
export const SIGNOUT_USER = 'signout_user';
export const SIGNOUT_USER_SUCCESS = 'signout_user_success';
export const FORGOT_PASSWORD = 'forgotPassword';
export const RESET_PASSWORD = 'resetPassword';
export const RESET_PIN = 'resetPin';
export const FORGOT_USER_PASSWORD = 'forgotUserPassword'
export const RESET_USER_PASSWORD = 'resetUserPassword';
export const RESET_USER_PIN = 'resetUserPin';
export const USER_TERMS_ACK = 'userTermsAck';
export const SET_AUTH_USER = 'setAuthUser';
export const SET_WELCOME_MESSAGE = 'setWelcomeMessage';
export const SUBMIT_REQUEST_INFO_FORM = 'submitRequestInfoForm';

// Chat Module const
export const FETCH_ALL_CHAT_USER = 'fetch_all_chat_user';
export const FETCH_ALL_CHAT_USER_CONVERSATION = 'fetch_all_chat_user_conversation';
export const FETCH_ALL_CHAT_USER_SUCCESS = 'fetch_all_chat_user_success';
export const FETCH_ALL_CHAT_USER_CONVERSATION_SUCCESS = 'fetch_all_chat_user_conversation_success';
// export const FILTER_USERS = 'filter_users';
export const ON_SELECT_USER = 'on_select_user';
export const ON_SHOW_LOADER = 'on_show_loader';
export const ON_HIDE_LOADER = 'on_hide_loader';
export const USER_INFO_STATE = 'user_info_state';
export const SUBMIT_COMMENT = 'submit_comment';
export const UPDATE_MESSAGE_VALUE = 'update_message_value';
export const UPDATE_SEARCH_CHAT_USER = 'update_search_chat_user';

//// Mail Module const
export const ADD_LABEL = 'ADD_LABEL';
export const GET_ALL_MAIL = 'get_all_mail';
export const FETCH_ALL_MAIL = 'fetch_all_mail';
export const FETCH_ALL_MAIL_SUCCESS = 'fetch_all_mail_success';
export const GET_IMPORTANT_MAIL = 'get_important_mail';
export const GET_NAV_FILTERS = 'get_nav_filters';
export const GET_NAV_LABELS = 'get_nav_labels';
export const GET_NAV_FOLDER = 'get_nav_folder';
export const GET_READ_MAIL = 'get_read_mail';
export const GET_STARRED_MAIL = 'get_starred_mail';
export const GET_UNIMPORTANT_MAIL = 'get_unimportant_mail';
export const GET_UNREAD_MAIL = 'get_unread_mail';
export const GET_UNSELECTED_ALL_MAIL = 'get_unselected_all_mail';
export const GET_UNSTARRED_MAIL = 'get_unstarred_mail';
export const ON_ALL_MAIL_SELECT = 'on_all_mail_select';
export const ON_FOLDER_MENU_ITEM_SELECT = 'on_folder_menu_item_select';
export const ON_FOLDER_SELECT = 'on_folder_select';
export const ON_IMPORTANT_SELECT = 'on_important_select';
export const ON_LABEL_MENU_ITEM_SELECT = 'on_label_menu_item_select';
export const GET_MARK_AS_START = 'get_mark_as_start';
export const ON_LABEL_SELECT = 'on_label_select';
export const ON_MAIL_CHECKED = 'on_mail_checked';
export const ON_MAIL_SELECT = 'on_mail_select';
export const ON_MAIL_SEND = 'on_mail_send';
export const ON_OPTION_MENU_ITEM_SELECT = 'on_option_menu_item_select';
export const ON_OPTION_MENU_SELECT = 'on_option_menu_select';
export const ON_START_SELECT = 'on_start_select';
export const SEARCH_MAIL = 'search_mail';
export const ON_DELETE_MAIL = 'on_delete_mail';
export const SET_CURRENT_MAIL_NULL = 'set_current_mail_null';
export const ON_COMPOSE_MAIL = 'on_compose_mail';

//// TO-DO Module const
export const ON_SORTEND = 'on_sortend';
export const FETCH_ALL_TODO = 'fetch_all_todo';
export const FETCH_ALL_TODO_SUCCESS = 'fetch_all_todo-success';
export const FETCH_ALL_TODO_CONVERSATION = 'fetch_all_todo_conversation';
export const FETCH_ALL_TODO_CONVERSATION_SUCCESS = 'fetch_all_todo_conversation_success';
export const SELECT_ALL_TODO = 'select_all_todo';
export const GET_ALL_TODO = 'get_all_todo';
export const GET_UNSELECTED_ALL_TODO = 'get_unselected_all_todo';
export const GET_STARRED_TODO = 'get_starred_todo';
export const GET_UNSTARRED_TODO = 'get_unstarred_todo';
export const GET_IMPORTANT_TODO = 'get_important_todo';
export const GET_UNIMPORTANT_TODO = 'get_unimportant_todo';
export const ON_LABEL_UPDATE = 'on_label_update';
export const ON_TODO_UPDATE = 'on_todo_update';
export const ON_DELETE_TODO = 'on_delete_todo';
export const SEARCH_TODO = 'search_todo';
export const SHOW_TODOS = 'show_todos';
export const GET_TODO_CONVERSATION = 'get_todo_conversation';
export const ON_TODO_CHECKED = 'on_todo_checked';
export const ON_TODO_ADD = 'on_todo_add';
export const ON_TODO_SELECT = 'on_todo_select';
export const SET_CURRENT_TODO_NULL = 'set_current_todo_null';
export const REMOVE_LABEL = 'remove_label';
export const UPDATE_SEARCH = 'update_search';

// HELP
export const FETCH_ACTIVITY = 'fetchActivity';
export const SET_ACTIVITY = 'setActivity';
export const FETCH_SNAPSHOT = 'fetchSnapshot';
export const SET_SNAPSHOT = 'setSnapshot';
export const FETCH_SNAPSHOT_WORKORDER = 'fetchSnapshotWorkorder';
export const SET_SNAPSHOT_WORKORDER = 'setSnapshotWorkorder';
export const SET_ACTIVITY_SEARCH_TERMS = 'setActivitySearchTerms';
export const SEARCH_ACTIVITY = 'searchActivity';
export const FETCH_REPORTS = 'fetchReports';
export const SET_REPORTS = 'setReports';
export const FETCH_TICKETS_NOT_STARTED = 'fetchTicketsNotStarted';
export const SET_TICKETS_NOT_STARTED = 'setTicketsNotStarted';

// FEEDBACK
export const CREATE_FEEDBACK = 'createFeedback';
export const FETCH_FEEDBACK = 'fetchFeedback';
export const SET_FEEDBACK = 'setFeedback';
export const FETCH_FEEDBACK_ITEM = 'fetchFeedbackItem';
export const ARCHIVE_FEEDBACK = 'archiveFeedback';
export const SET_FEEDBACK_ITEM = 'setFeedbackItem';

// REQUEST INFO

export const FETCH_REQUEST_INFO = 'fetchRequestInfo';
export const SET_REQUEST_INFO = 'setRequestInfo';
export const FETCH_REQUEST_INFO_ITEM = 'fetchRequestInfoItem';
export const ARCHIVE_REQUEST = 'archiveRequest';
export const SET_REQUEST_INFO_ITEM = 'setRequestInfoItem';
