import {
    FETCH_LOCKS_LIST_WORK_ORDER,
    FETCH_LOCKS_BY_ID,
    FETCH_LOCKS_BY_MAC,
    FETCH_LOCKS_BY_SERIAL,
    FETCH_LOCKS_LIST_AVAILABLE,
    FETCH_LOCKS_SEARCH_SELECT,
    CREATE_LOCK,
    EDIT_LOCK,
    DELETE_LOCK,
    FILTER_LOCKS,
    FILTER_LOCKS_LIST,
    SET_FILTER_LOCKS,
    SET_LOCKS,
    SET_LOCK,
    SET_LOCK_FILTER,
    FETCH_LOCK_ACTIVITY,
    SET_LOCK_ACTIVITY,
    SEARCH_LOCKS,
    SET_SEARCHED_LOCKS,
    SET_LOCK_SEARCH_TERMS,
    SET_LOCKS_SEARCH_SELECT
} from 'constants/ActionTypes';

let authUser = JSON.parse(sessionStorage.getItem('user_data'));

export const fetchLocksListWorkOrder = () => {
    if (!authUser) {
        authUser = JSON.parse(sessionStorage.getItem('user_data'));
    }
    if(authUser.Settings.Product.includes('isolation')){
    return {
        type: FETCH_LOCKS_LIST_WORK_ORDER,
        payload: {
            url: '/isolation/device/list/',
            data: {}
        }
    };
    } else {
        return {
            type: FETCH_LOCKS_LIST_WORK_ORDER,
            payload: {
                url: '/lockout/device/list/',
                data: {}
            }
        };
    }
};

export const fetchLocksById = (Id) => {
    if (!authUser) {
        authUser = JSON.parse(sessionStorage.getItem('user_data'));
    }
    if(authUser.Settings.Product.includes('isolation')){
    return {
        type: FETCH_LOCKS_BY_ID,
        payload: {
            url: '/isolation/device/byid/',
            data: {
                Id,
            }
        }
    };
    } else {
        return {
            type: FETCH_LOCKS_BY_ID,
            payload: {
                url: '/lockout/device/byid/',
                data: {
                    Id,
                }
            }
        };
    }
};
export const fetchLocksByMac = (Id) => {
    if (!authUser) {
        authUser = JSON.parse(sessionStorage.getItem('user_data'));
    }
    if(authUser.Settings.Product.includes('isolation')){
    return {
        type: FETCH_LOCKS_BY_MAC,
        payload: {
            url: '/isolation/device/bymac/',
            data: {
                Id,
            }
        }
    };
    } else {
        return {
            type: FETCH_LOCKS_BY_MAC,
            payload: {
                url: '/lockout/device/bymac/',
                data: {
                    Id,
                }
            }
        };  
    }
};
export const fetchLocksBySerial = (Id) => {
    if (!authUser) {
        authUser = JSON.parse(sessionStorage.getItem('user_data'));
    }
    if(authUser.Settings.Product.includes('isolation')){
    return {
        type: FETCH_LOCKS_BY_SERIAL,
        payload: {
            url: '/isolation/device/byserial/',
            data: {
                Id,
            }
        }
    };
    } else {
        return {
            type: FETCH_LOCKS_BY_SERIAL,
            payload: {
                url: '/lockout/device/byserial/',
                data: {
                    Id,
                }
            }
        };
}
};
export const fetchLocksListAvailable = () => {
    if (!authUser) {
        authUser = JSON.parse(sessionStorage.getItem('user_data'));
    }
    if(authUser.Settings.Product.includes('isolation')){
    return {
        type: FETCH_LOCKS_LIST_AVAILABLE,
        payload: {
            url: '/isolation/device/list/available/',
            data: {}
        }
    };
    } else {
        return {
            type: FETCH_LOCKS_LIST_AVAILABLE,
            payload: {
                url: '/lockout/device/list/available/',
                data: {}
            }
        };
    }
};
export const fetchLocksSearchSelect = (Id = "") => {
    if (!authUser) {
        authUser = JSON.parse(sessionStorage.getItem('user_data'));
    }
    if(authUser.Settings.Product.includes('isolation')){
    return {
        type: FETCH_LOCKS_SEARCH_SELECT,
        payload: {
            url: '/isolation/device/list/search/select/',
            data: {
                Id
            }
        }
    };
    } else {
        return {
            type: FETCH_LOCKS_SEARCH_SELECT,
            payload: {
                url: '/lockout/device/list/search/select/',
                data: {
                    Id
                }
            }
        };
    }
};
export const createLock = (data, another) => {
    if (!authUser) {
        authUser = JSON.parse(sessionStorage.getItem('user_data'));
    }
    if(authUser.Settings.Product.includes('isolation')){
    return {
        type: CREATE_LOCK,
        payload: {
            url: '/isolation/device/create/',
            another,
            data
        }
    };
    } else {
        return {
            type: CREATE_LOCK,
            payload: {
                url: '/lockout/device/create/',
                another,
                data
            }
        };
    }
};
export const editLock = (data) => {
    if (!authUser) {
        authUser = JSON.parse(sessionStorage.getItem('user_data'));
    }
    if(authUser.Settings.Product.includes('isolation')){
    return {
        type: EDIT_LOCK,
        payload: {
            url: '/isolation/device/update/',
            data
        }
    };
    } else {
        return {
            type: EDIT_LOCK,
            payload: {
                url: '/lockout/device/update/',
                data
            }
        };
    }
};
export const deleteLock = (Id) => {
    if (!authUser) {
        authUser = JSON.parse(sessionStorage.getItem('user_data'));
    }
    if(authUser.Settings.Product.includes('isolation')){
    return {
        type: DELETE_LOCK,
        payload: {
            url: '/isolation/device/delete/',
            data: {
                Id,
            }
        }
    };
    } else {
        return {
            type: DELETE_LOCK,
            payload: {
                url: '/lockout/device/delete/',
                data: {
                    Id,
                }
            }
        };
    }
};
export const filterLocks = (filter) => {
    return {
        type: FILTER_LOCKS,
        payload: filter
    }
};
export const filterLocksList = (searchValue, list) => {
    return {
        type: FILTER_LOCKS_LIST,
        payload: {
            searchValue,
            list
        }
    }
};
export const setLockFilter = (data) => {
    return {
        type: SET_LOCK_FILTER,
        payload: data
    }
}
export const setFilterLocks = (data) => {
    return {
        type: SET_FILTER_LOCKS,
        payload: data
    };
}
export const setLocks = (data) => {
    return {
        type: SET_LOCKS,
        payload: data
    };
};

export const setLock = (data) => {
    return {
        type: SET_LOCK,
        payload: data
    };
};

export const fetchLockActivity= (Id) => {
    return {
        type: FETCH_LOCK_ACTIVITY,
        payload: {
            url: '/activity/list/lock/',
            data: {
                Id,
            }
        }
    };
};

export const setLockActivity = (data) => {
    return {
        type: SET_LOCK_ACTIVITY,
        payload: data
    };
};

export const searchLocks = (data) => {
    if (!authUser) {
        authUser = JSON.parse(sessionStorage.getItem('user_data'));
    }
    if(authUser.Settings.Product.includes('isolation')){
    return {
        type: SEARCH_LOCKS,
        payload: {
            url: '/isolation/device/list/search/',
            data
        }
    };
    } else {
        return {
            type: SEARCH_LOCKS,
            payload: {
                url: '/lockout/device/list/search/',
                data
            }
        };
    }
};

export const setSearchedLocks = (data) => {
    return {
        type: SET_SEARCHED_LOCKS,
        payload: data
    };
};

export const setLockSearchTerms = (data) => {
    return {
        type: SET_LOCK_SEARCH_TERMS,
        payload: data
    };
};

export const setLocksSearchSelect = (data) => {
    return {
        type: SET_LOCKS_SEARCH_SELECT,
        payload: data
    };
};