import React from "react";
import { connect } from "react-redux";
import { StyleSheet, css } from "aphrodite";
import { injectIntl } from "react-intl";

class DropdownHeader extends React.Component {
    render() {
        const { title, theme, intl } = this.props;
        this.styles = styles(theme);
        return (
            <div className={`d-flex ${css(this.styles.wrapper)}`}>
                <div className="mr-auto">
                    <h3 className={css(this.styles.title)}>
                        {intl.formatMessage({ id: title })}
                    </h3>
                </div>
                <span className="icon-btn size-30">
                    <i className={`material-icons`}>arrow_drop_down</i>
                </span>
            </div>
        );
    }
}

DropdownHeader.defaultProps = {
    title: "",
    description: "",
};

const mapStateToProps = ({ app }) => {
    const { theme } = app;
    return { theme };
};

export default injectIntl(connect(mapStateToProps)(DropdownHeader));

const styles = () =>
    StyleSheet.create({
        wrapper: {
            borderBottom: "solid 1px #eee",
        },
        title: {
            color: "#aaa",
            fontSize: 12,
        },
    });
