import {
    SET_SETTINGS,
    SET_ROLES_LIST,
    SET_ROLE,
    SET_PERMISSIONS_LIST,
    SET_ISOLATION_EQUIPMENT_LIST,
    SET_ISOLATION_EQUIPMENT_FILTER,
    SET_ISOLATION_HAZARDS_LIST,
    SET_ISOLATION_HAZARDS_FILTER,
    SET_SITES_LIST,
    SET_SITES_FILTER,
    SET_SITE,
    SET_ACTIVE_SETTING,
    SET_ACTIVE_ROLE,
    SET_ACTIVE_APP_SETTING,
    SET_ACTIVE_PERSONAL_SETTING,
    SET_TERMS,
    SET_CLASSIFICATIONS_LIST,
    SET_PERMITS_LIST,
    SET_CLASSIFICATION_FILTER,
    SET_PERMIT_FILTER,
    SET_CLASSIFICATION,
    SET_PERMIT,
    SET_EQUIPMENT,
    SET_HAZARD,
    SET_CLASSIFICATIONS_LIST_SELECT,
} from "constants/ActionTypes";

const INIT_STATE = {
    settingsData: null,
    rolesList: [],
    roleData: null,
    activeRole: 0,
    activeAppSetting: 0,
    permissionsList: [],
    isolationEquipmentList: [],
    isolationHazardsList: [],
    sitesList: [],
    termsList: [],
    siteObject: null,
    activeSetting: 0,
    activePersonalSetting: 0,
    classificationsList: [],
    permitsList: [],
    classificationObject: null,
    permitObject: null,
    equipmentObject: null,
    hazardObject: null,
    classificationsListSelect: [],
    isolationEquipmentFilterList: [
        {
            title: "comp.table.header.equipmentName",
            width: "25%",
            active: true,
            sortable: true,
            sortTitle: "TypeName",
            order: false,
            mobile: true,
        },
        {
            title: "comp.table.header.active",
            width: "25%",
            active: false,
            sortable: true,
            sortTitle: "IsActive",
            order: false,
            mobile: true,
        },
        {
            title: "comp.table.header.createdBy",
            width: "25%",
            active: false,
            sortable: true,
            sortTitle: "CreatedByName",
            order: false,
            mobile: false,
        },
        {
            title: "comp.table.header.dateCreated",
            width: "25%",
            active: false,
            sortable: true,
            sortTitle: "CreatedDate",
            style: { textAlign: "right" },
            order: false,
            mobile: true,
        },
    ],
    isolationHazardsFilterList: [
        {
            title: "comp.table.header.hazardName",
            width: "20%",
            active: true,
            sortable: true,
            sortTitle: "TypeName",
            order: false,
            mobile: true,
        },
        {
            title: "form.letterSymbol",
            width: "20%",
            active: false,
            sortable: true,
            sortTitle: "LetterSymbol",
            order: false,
            mobile: true,
        },
        {
            title: "comp.table.header.active",
            width: "20%",
            active: false,
            sortable: true,
            sortTitle: "IsActive",
            order: false,
            mobile: true,
        },
        {
            title: "comp.table.header.createdBy",
            width: "20%",
            active: false,
            sortable: true,
            sortTitle: "CreatedByName",
            order: false,
            mobile: false,
        },
        {
            title: "comp.table.header.dateCreated",
            width: "20%",
            active: false,
            sortable: true,
            sortTitle: "CreatedDate",
            style: { textAlign: "right" },
            order: false,
            mobile: true,
        },
    ],
    sitesFilterList: [
        {
            title: "comp.table.header.siteName",
            width: "25%",
            active: true,
            sortable: true,
            sortTitle: "SiteName",
            order: false,
            mobile: true,
        },
        {
            title: "comp.table.header.active",
            width: "25%",
            active: false,
            sortable: true,
            sortTitle: "IsActive",
            order: false,
            mobile: true,
        },
        {
            title: "comp.table.header.createdBy",
            width: "25%",
            active: false,
            sortable: true,
            sortTitle: "CreatedByName",
            order: false,
            mobile: false,
        },
        {
            title: "comp.table.header.dateCreated",
            width: "25%",
            active: false,
            sortable: true,
            sortTitle: "CreatedDate",
            style: { textAlign: "right" },
            order: false,
            mobile: true,
        },
    ],
    classificationsFilterList: [
        {
            title: "pages.Classification.Type",
            width: "25%",
            active: true,
            sortable: true,
            sortTitle: "TypeName",
            order: false,
            mobile: true,
        },
        {
            title: "comp.table.header.active",
            width: "25%",
            active: false,
            sortable: true,
            sortTitle: "IsActive",
            order: false,
            mobile: true,
        },
        {
            title: "comp.table.header.createdBy",
            width: "25%",
            active: false,
            sortable: true,
            sortTitle: "CreatedByName",
            order: false,
            mobile: false,
        },
        {
            title: "comp.table.header.dateCreated",
            width: "25%",
            active: false,
            sortable: true,
            sortTitle: "CreatedDate",
            style: { textAlign: "right" },
            order: false,
            mobile: true,
        },
    ],
    permitsFilterList: [
        {
            title: "pages.Permit.Type",
            width: "25%",
            active: true,
            sortable: true,
            sortTitle: "TypeName",
            order: false,
            mobile: true,
        },
        {
            title: "comp.table.header.active",
            width: "25%",
            active: false,
            sortable: true,
            sortTitle: "IsActive",
            order: false,
            mobile: true,
        },
        {
            title: "comp.table.header.createdBy",
            width: "25%",
            active: false,
            sortable: true,
            sortTitle: "CreatedByName",
            order: false,
            mobile: false,
        },
        {
            title: "comp.table.header.dateCreated",
            width: "25%",
            active: false,
            sortable: true,
            sortTitle: "CreatedDate",
            style: { textAlign: "right" },
            order: false,
            mobile: true,
        },
    ],
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_SETTINGS: {
            return {
                ...state,
                settingsData: action.payload,
            };
        }
        case SET_ROLES_LIST: {
            return {
                ...state,
                rolesList: action.payload,
            };
        }
        case SET_ROLE: {
            return {
                ...state,
                roleData: action.payload,
            };
        }
        case SET_PERMISSIONS_LIST: {
            return {
                ...state,
                permissionsList: action.payload,
            };
        }
        case SET_ISOLATION_EQUIPMENT_LIST: {
            return {
                ...state,
                isolationEquipmentList: action.payload,
            };
        }
        case SET_ISOLATION_EQUIPMENT_FILTER: {
            return {
                ...state,
                isolationEquipmentFilterList: action.payload,
            };
        }
        case SET_ISOLATION_HAZARDS_LIST: {
            return {
                ...state,
                isolationHazardsList: action.payload,
            };
        }
        case SET_ISOLATION_HAZARDS_FILTER: {
            return {
                ...state,
                isolationHazardsFilterList: action.payload,
            };
        }
        case SET_SITES_LIST: {
            return {
                ...state,
                sitesList: action.payload,
            };
        }
        case SET_SITES_FILTER: {
            return {
                ...state,
                sitesFilterList: action.payload,
            };
        }
        case SET_SITE: {
            return {
                ...state,
                siteObject: action.payload,
            };
        }
        case SET_ACTIVE_SETTING: {
            return {
                ...state,
                activeSetting: action.payload,
            };
        }
        case SET_ACTIVE_ROLE: {
            return {
                ...state,
                activeRole: action.payload,
            };
        }
        case SET_ACTIVE_APP_SETTING: {
            return {
                ...state,
                activeAppSetting: action.payload,
            };
        }
        case SET_ACTIVE_PERSONAL_SETTING: {
            return {
                ...state,
                activePersonalSetting: action.payload,
            };
        }
        case SET_TERMS: {
            return {
                ...state,
                termsList: action.payload,
            };
        }
        case SET_CLASSIFICATIONS_LIST: {
            return {
                ...state,
                classificationsList: action.payload,
            };
        }
        case SET_PERMITS_LIST: {
            return {
                ...state,
                permitsList: action.payload,
            };
        }
        case SET_CLASSIFICATION_FILTER: {
            return {
                ...state,
                classificationsFilterList: action.payload,
            };
        }
        case SET_PERMIT_FILTER: {
            return {
                ...state,
                permitsFilterList: action.payload,
            };
        }
        case SET_CLASSIFICATION: {
            return {
                ...state,
                classificationObject: action.payload,
            };
        }
        case SET_PERMIT: {
            return {
                ...state,
                permitObject: action.payload,
            };
        }
        case SET_EQUIPMENT: {
            return {
                ...state,
                equipmentObject: action.payload,
            };
        }
        case SET_HAZARD: {
            return {
                ...state,
                hazardObject: action.payload,
            };
        }
        case SET_CLASSIFICATIONS_LIST_SELECT: {
            return {
                ...state,
                classificationsListSelect: action.payload,
            };
        }
        default:
            return state;
    }
};
