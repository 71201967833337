import { all, call, fork, put, takeEvery, select } from "redux-saga/effects";
import { history } from "store/history";
import { API } from "api";
import {
    MachineIcon,
    DigIcon,
    TicketIcon,
    LockIcon,
    UserIcon,
} from "util/icons";
import {
    CREATE_MACHINE,
    CREATE_MACHINE_DOCUMENT,
    CREATE_MACHINE_JOB,
    CREATE_MACHINE_JOB_ACTION,
    CREATE_MACHINE_JOB_PROCESS,
    CREATE_MACHINE_POINT,
    CREATE_MACHINE_POINT_ACTION,
    DELETE_MACHINE,
    DELETE_MACHINE_JOB_ACTION,
    DELETE_MACHINE_JOB_PROCESS,
    DELETE_MACHINE_POINT_ACTION,
    EDIT_MACHINE,
    EDIT_MACHINE_JOB,
    EDIT_MACHINE_JOB_ACTION,
    EDIT_MACHINE_JOB_PROCESS,
    EDIT_MACHINE_POINT,
    EDIT_MACHINE_POINT_ACTION,
    EDIT_MACHINE_POINT_PROCESS,
    FETCH_ACTIVE_MACHINES_LIST,
    FETCH_JOBS_BY_MACHINE_ID,
    FETCH_LOTO_DEVICE_SELECT,
    FETCH_MACHINES_BY_SITE_ID,
    FETCH_MACHINES_LIST,
    FETCH_MACHINES_LIST_SELECT,
    FETCH_MACHINE_ACTIVITY,
    FETCH_MACHINE_BY_ID,
    FETCH_MACHINE_DOCUMENTS,
    FETCH_MACHINE_DOCUMENT_BY_ID,
    FETCH_MACHINE_JOB_ACTIONS,
    FETCH_MACHINE_JOB_ACTION_BY_ID,
    FETCH_MACHINE_JOB_BY_ID,
    FETCH_MACHINE_JOB_TEMPLATE_BY_ID,
    FETCH_MACHINE_JOB_PROCESS,
    FETCH_MACHINE_POINT_ACTIONS,
    FETCH_MACHINE_POINT_ACTION_BY_ID,
    FETCH_MACHINE_POINT_ACTION_LIST,
    FETCH_MACHINE_POINT_BY_ID,
    FETCH_POINT_ACTIVITY,
    FETCH_POINT_EQUIPMENT,
    FETCH_POINT_HAZARDS,
    FILTER_MACHINES,
    FILTER_MACHINES_LIST,
    SEARCH_MACHINES,
    SEARCH_MACHINES_LIST,
    SEARCH_JOB_TEMPLATES,
    SEARCH_MACHINE_POINT_LIST,
    UPDATE_POINT_EQUIPMENT,
    UPDATE_POINT_HAZARDS,
    CREATE_MACHINE_JOB_TEMPLATE_ACTION,
    DELETE_MACHINE_JOB_TEMPLATE_ACTION,
    EDIT_MACHINE_JOB_TEMPLATE_ACTION,
    CREATE_MACHINE_JOB_TEMPLATE,
    EDIT_MACHINE_JOB_TEMPLATE_PROCESS,
    FETCH_JOB_TEMPLATES_SELECT,
    APPLY_JOB_TEMPLATE
} from "constants/ActionTypes";
import {
    fetchMachineJobProcess,
    fetchMachinePointActions,
    fetchMachinesList,
    fetchMachinesListSelect,
    setActiveMachines,
    setFilterMachines,
    setLotoDeviceSelect,
    setMachine,
    setMachineActivity,
    setMachineDocument,
    setMachineDocuments,
    setMachineJob,
    setMachineJobTemplate,
    setMachineJobAction,
    setMachineJobActions,
    setMachineJobProcess,
    setMachineJobs,
    setMachinePoint,
    setMachinePointAction,
    setMachinePointActionList,
    setMachinePointActions,
    setMachinePoints,
    setMachines,
    setMachineSearchTerms,
    setJobTemplates,
    setMachinesSelect,
    setPointActivity,
    setPointEquipment,
    setPointHazards,
    setSearchedMachines,
    setSiteMachines,
    updatePointEquipment,
    updatePointHazards,
    fetchMachineJobTemplateById,
    setJobTemplatesSelect,
    fetchMachineJobById
} from "actions/Machines";
import { userSignOut } from "actions/Auth";
import { showMessage, setLoader } from "actions/App";
import { sortList } from "util";

const getHeaders = (state) => state.machines.machineFilterList;

let authUser = JSON.parse(sessionStorage.getItem("user_data"));

function* requestMachines({ payload }) {
    try {
        const machines = yield call(API, payload.url, payload.data);
        if (machines.message) {
            if (machines.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(machines.message, "message"));
            }
        } else if (!machines.data) {
            yield put(setMachines([]));
        } else {
            machines.data = sortList(true, machines.data, "WorkOrderId");
            machines.data.map((item) => {
                item.Icon = DigIcon;
            });
            yield put(setLoader(false));
            const headers = yield select(getHeaders);
            const activeHeader = headers.find((item) => item.active);
            machines.data = sortList(
                activeHeader.order,
                machines.data,
                activeHeader.sortTitle
            );
            yield put(setMachines(machines.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestActiveMachines({ payload }) {
    try {
        const machines = yield call(API, payload.url, payload.data);
        if (machines.message) {
            if (machines.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(machines.message, "message"));
            }
        } else if (!machines.data) {
            yield put(setActiveMachines([]));
        } else {
            machines.data = machines.data.slice(0, 3);
            machines.data.map((item) => {
                item.Icon = DigIcon;
            });
            yield put(setActiveMachines(machines.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestMachine({ payload }) {
    try {
        yield put(setMachine(null));
        const machine = yield call(API, payload.url, payload.data);
        if (machine.message) {
            if (machine.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(machine.message));
            }
        } else if (machine.data === "") {
            yield put(showMessage("notifications.error.fail.notClientMachine"));
            yield history.push("/app/machines/all");
        } else {
            if (machine.data.LockoutStatus === "Red") {
                machine.data.image =
                    "assets/images/Icons/lock/dangerousDefault.png";
            } else if (machine.data.LockoutStatus === "Green") {
                machine.data.image = "assets/images/Icons/lock/safe@3x.png";
            } else {
                machine.data.image = "assets/images/Icons/lock/unsafe@3x.png";
            }
            if (!authUser) {
                authUser = JSON.parse(sessionStorage.getItem("user_data"));
            }
            if (authUser.Settings.Product === "isolation") {
                const image = yield call(API, "/image/", {
                    Filename: machine.data.Id,
                });
                image.data
                    ? (machine.data.Image = `data:image/png;base64, ${image.data}`)
                    : (machine.data.Icon = DigIcon);
            } else {
                const image = yield call(API, "/image/", {
                    Filename: machine.data.Id,
                });
                image.data
                    ? (machine.data.Image = `data:image/png;base64, ${image.data}`)
                    : (machine.data.Icon = DigIcon);
            }
            yield put(setMachine(machine.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* handleFilter({ payload }) {
    yield put(setMachines(null));
    switch (payload.title) {
        case "filters.machines.all":
            yield put(fetchMachinesList());
            return;
        case "filters.machines.select":
            yield put(fetchMachinesListSelect());
            return;
        default:
            yield put(fetchMachinesList());
            return;
    }
}

function* handleFilterList({ payload }) {
    try {
        let newList = payload.list.filter((machine) => {
            return (
                machine.Name.toLowerCase().search(
                    payload.searchValue.toLowerCase()
                ) !== -1
            );
        });
        yield put(setFilterMachines(newList));
    } catch (error) {
        console.warn(error);
    }
}

function* requestCreateMachine({ payload }) {
    try {
        const required = {
            SiteId: payload.data.SiteId,
        };
        for (var key in required) {
            if (required[key] === undefined || required[key] === "") {
                yield put(
                    showMessage(`notifications.error.blank.${key}`, "warning")
                );
                return;
            }
        }
        payload.data.LocksRequired = Number(payload.data.LocksRequired);
        payload.data.Floor = Number(payload.data.Floor);
        const machine = yield call(API, payload.url, payload.data);
        if (machine.message) {
            if (machine.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(machine.message, "message"));
            }
        } else if (machine.validationError) {
            var error = Object.keys(machine.validationError).map(function (
                key
            ) {
                return machine.validationError[key];
            });
            yield put(showMessage(error, "message"));
        } else {
            if (!authUser) {
                authUser = JSON.parse(sessionStorage.getItem("user_data"));
            }
            if (authUser.Settings.Product === "isolation") {
                yield history.push(
                    `/app/machines/create/machine/point/${machine.data.Id}`
                );
            } else {
                yield put(showMessage("notifications.success.add", "success"));
                yield history.push(`/app/machines/display/${machine.data.Id}`);
            }
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestEditMachine({ payload }) {
    try {
        const required = {
            Name: payload.data.Name,
        };
        for (var key in required) {
            if (required[key] === undefined || required[key] === "") {
                yield put(
                    showMessage(`notifications.error.blank.${key}`, "warning")
                );
                return;
            }
        }
        payload.data.LocksRequired = Number(payload.data.LocksRequired);
        payload.data.MachineId = payload.data.Id;
        payload.data.Floor = Number(payload.data.Floor);
        const machine = yield call(API, payload.url, payload.data);
        if (machine.message) {
            if (machine.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(machine.message, "message"));
            }
        } else {
            yield put(showMessage("notifications.success.edit", "success"));
            yield history.push(`/app/machines/display/${machine.data.Id}`);
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestDeleteMachine({ payload }) {
    try {
        if (!payload.data) {
            yield put(showMessage(`notifications.error.permission.deny`));
            return;
        }
        const machine = yield call(API, payload.url, payload.data);
        if (machine.message) {
            if (machine.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(machine.message, "message"));
            }
        } else {
            yield put(showMessage("notifications.success.delete", "success"));
            yield history.push("/app/machines/all");
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestSiteMachines({ payload }) {
    try {
        const machines = yield call(API, payload.url, payload.data);
        if (machines.message) {
            if (machines.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(machines.message, "message"));
            }
        } else if (!machines.data) {
            yield put(setSiteMachines([]));
        } else {
            machines.data.map((item) => {
                item.Icon = DigIcon;
            });
            yield put(setSiteMachines(machines.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestMachineActivity({ payload }) {
    try {
        yield put(setMachineActivity(null));
        const activity = yield call(API, payload.url, payload.data);
        if (activity.message) {
            if (activity.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(activity.message));
            }
        } else if (activity.data === "") {
            yield put(setMachineActivity([]));
        } else {
            activity.data = sortList(true, activity.data, "Date");
            const images = yield all(
                activity.data.map(
                    (item) =>
                        item.ImageId !== "" &&
                        call(API, "/image/", { Filename: item.ImageId })
                )
            );
            activity.data.map((item, i) => {
                if (item.Event === "Ticket") {
                    item.Icon = TicketIcon;
                } else if (item.Event === "Machine") {
                    item.Icon = MachineIcon;
                } else if (item.Event === "Lock") {
                    item.Icon = LockIcon;
                } else if (item.Event === "User") {
                    item.Icon = UserIcon;
                } else if (item.Event === "Point") {
                    item.Icon = LockIcon;
                } else if (item.Event === "Job") {
                    item.Icon = TicketIcon;
                } else if (item.Event === "Template") {
                    item.Icon = MachineIcon;
                } else {
                    item.Icon = UserIcon;
                }
                item.Image = images[i].data
                    ? `data:image/png;base64, ${images[i].data}`
                    : null;
                // item.Icon = TicketIcon;
            });
            yield put(setMachineActivity(activity.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestSearchMachines({ payload }) {
    try {
        payload.data.LocksRequired = Number(payload.data.LocksRequired);
        const machines = yield call(API, payload.url, payload.data);
        if (machines.message) {
            if (machines.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(machines.message, "message"));
            }
        } else if (!machines.data) {
            yield put(setSearchedMachines([]));
        } else {
            yield put(setSearchedMachines(machines.data));
            yield put(setMachineSearchTerms(payload.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestSearchJobTemplates({ payload }) {
    try {
        const jobTemplates = yield call(API, payload.url, payload.data);
        if (jobTemplates.message) {
            if (jobTemplates.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(jobTemplates.message, "message"));
            }
        } else if (!jobTemplates.data) {
            yield put(setJobTemplates([]));
        } else {
            yield put(setJobTemplates(jobTemplates.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFetchJobTemplatesSelect({ payload }) {
    try {
        const jobTemplates = yield call(API, payload.url, payload.data);
        if (jobTemplates.message) {
            if (jobTemplates.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(jobTemplates.message, "message"));
            }
        } else if (!jobTemplates.data) {
            yield put(setJobTemplatesSelect([]));
        } else {
            yield put(setJobTemplatesSelect(jobTemplates.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestMachineDocuments({ payload }) {
    try {
        const documents = yield call(API, payload.url, payload.data);
        if (documents.message) {
            if (documents.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(documents.message, "message"));
            }
        } else if (!documents.data || documents.data === null) {
            yield put(setMachineDocuments([]));
        } else {
            yield put(setMachineDocuments(documents.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestMachineDocumentById({ payload }) {
    try {
        yield put(setMachineDocument(null));
        const document = yield call(API, payload.url, payload.data);
        if (document.message) {
            if (notes.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(document.message, "message"));
            }
        } else if (document.data === "" || document.data === null) {
            yield put(setMachineDocument(null));
        } else {
            yield put(setMachineDocument(document.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestCreateMachineDocument({ payload }) {
    try {
        const formData = new FormData();
        formData.append("file", payload.data.file);
        formData.append("description", payload.data.description);
        formData.append("workorderid", payload.data.workorderid);
        formData.append("machineId", payload.data.machineId);
        const machine = yield call(API, payload.url, formData);
        if (machine.message) {
            if (machine.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(machine.message, "message"));
            }
        } else if (machine.validationError) {
            var error = Object.keys(machine.validationError).map(function (
                key
            ) {
                return machine.validationError[key];
            });
            yield put(showMessage(error, "message"));
        } else {
            yield put(showMessage("notifications.success.add", "success"));
            yield history.push(
                `/app/machines/display/${payload.data.machineId}`
            );
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestMachinesSelect({ payload }) {
    try {
        const machines = yield call(API, payload.url, payload.data);
        if (machines.message) {
            if (machines.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(machines.message, "message"));
            }
        } else if (!machines.data) {
            yield put(setMachinesSelect([]));
        } else {
            machines.data.map((machine) => {
                if (machine.LockoutStatus === "Green") {
                    machine.image = "assets/images/Icons/lock/safe@3x.png";
                } else if (machine.LockoutStatus === "Red") {
                    machine.image =
                        "assets/images/Icons/lock/dangerousDefault.png";
                } else {
                    machine.image = "assets/images/Icons/lock/unsafe@3x.png";
                }
            });
            machines.data.map((item, i) => {
                item.Icon = TemplateIcon;
            });
            yield put(setMachinesSelect(machines.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestMachineJobs({ payload }) {
    try {
        const jobs = yield call(API, payload.url, payload.data);
        if (jobs.message) {
            if (jobs.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(jobs.message));
            }
        } else if (jobs.data === "") {
            yield put(setMachineJobs([]));
        } else {
            yield put(setMachineJobs(jobs.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestCreateMachineJob({ payload }) {
    try {
        const required = {
            Name: payload.data.JobName,
        };
        for (var key in required) {
            if (required[key] === undefined || required[key] === "") {
                yield put(
                    showMessage(`notifications.error.blank.${key}`, "warning")
                );
                return;
            }
        }
        const job = yield call(API, payload.url, payload.data);
        if (job.message) {
            if (job.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(job.message, "message"));
            }
        } else if (job.validationError) {
            var error = Object.keys(job.validationError).map(function (key) {
                return job.validationError[key];
            });
            yield put(showMessage(error, "message"));
        } else {
            yield put(showMessage("notifications.success.add", "success"));
            if (!payload.addAnother) {
                history.push(
                    `/app/machines/display/job/${job.data.Id}/${payload.machineId}`
                );
            }
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestCreateMachineJobTemplate({ payload }) {
    try {
        const required = {
            Name: payload.data.Name,
        };
        for (var key in required) {
            if (required[key] === undefined || required[key] === "") {
                yield put(
                    showMessage(`notifications.error.blank.${key}`, "warning")
                );
                return;
            }
        }
        const jobTemplate = yield call(API, payload.url, payload.data);
        if (jobTemplate.message) {
            if (jobTemplate.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(jobTemplate.message, "message"));
            }
        } else if (jobTemplate.validationError) {
            var error = Object.keys(jobTemplate.validationError).map(function (key) {
                return jobTemplate.validationError[key];
            });
            yield put(showMessage(error, "message"));
        } else {
            yield put(showMessage("notifications.success.add", "success"));
            if (!payload.addAnother) {
                history.push(
                    `/app/machines/display/jobTemplate/${jobTemplate.data.Id}`
                );
            }
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestSearchMachinePoints({ payload }) {
    try {
        const points = yield call(API, payload.url, payload.data);
        if (points.message) {
            if (points.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(points.message));
            }
        } else if (points.data === "") {
            yield put(setMachinePoints([]));
        } else {
            yield put(setMachinePoints(points.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestLotoDeviceSelect({ payload }) {
    try {
        const devices = yield call(API, payload.url, payload.data);
        if (devices.message) {
            if (devices.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(devices.message));
            }
        } else if (devices.data === "") {
            yield put(setLotoDeviceSelect([]));
        } else {
            yield put(setLotoDeviceSelect(devices.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestCreateMachinePoint({ payload }) {
    try {
        const required = {
            Description: payload.data.Description,
        };
        for (var key in required) {
            if (required[key] === undefined || required[key] === "") {
                yield put(
                    showMessage(`notifications.error.blank.${key}`, "warning")
                );
                return;
            }
        }
        payload.data.TypeHazardId = Number(payload.data.TypeHazardId);
        const point = yield call(API, payload.url, payload.data);
        if (point.message) {
            if (point.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(point.message, "message"));
            }
        } else if (point.validationError) {
            var error = Object.keys(point.validationError).map(function (key) {
                return point.validationError[key];
            });
            yield put(showMessage(error, "message"));
        } else {
            if (payload.hazards && payload.hazards.length > 0) {
                yield put(
                    updatePointHazards({
                        PointId: point.data.Id,
                        Hazards: payload.hazards,
                    })
                );
            }
            if (payload.equipment && payload.equipment.length > 0) {
                yield put(
                    updatePointEquipment({
                        PointId: point.data.Id,
                        Equipment: payload.equipment,
                    })
                );
            }
            if (payload.data.repeat || payload.data.addAnother) {
                yield put(showMessage("notifications.success.add", "success"));
            } else if (payload.data.wizard) {
                yield history.push(
                    `/app/machines/create/machine/job/${payload.data.MachineId}`
                );
            } else {
                yield history.push(
                    `/app/machines/display/point/${point.data.Id}/${payload.machineId}`
                );
            }
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestUpdatePointHazards({ payload }) {
    try {
        const hazards = yield call(API, payload.url, payload.data);
        if (hazards.message) {
            if (hazards.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(hazards.message));
            }
        } else {
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestUpdatePointEquipment({ payload }) {
    try {
        const equipment = yield call(API, payload.url, payload.data);
        if (equipment.message) {
            if (equipment.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(equipment.message));
            }
        } else {
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFetchPointHazards({ payload }) {
    try {
        yield put(setPointHazards([]));
        const hazards = yield call(API, payload.url, payload.data);
        if (hazards.message) {
            if (hazards.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(hazards.message));
            }
        } else if (hazards.data === "") {
            yield put(setPointHazards([]));
        } else {
            yield put(setPointHazards(hazards.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFetchPointEquipment({ payload }) {
    try {
        yield put(setPointEquipment([]));
        const equipment = yield call(API, payload.url, payload.data);
        if (equipment.message) {
            if (equipment.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(equipment.message));
            }
        } else if (equipment.data === "") {
            yield put(setPointEquipment([]));
        } else {
            yield put(setPointEquipment(equipment.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestMachinePoint({ payload }) {
    try {
        const point = yield call(API, payload.url, payload.data);
        if (point.message) {
            if (point.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(point.message));
            }
        } else if (point.data === "") {
            yield put(showMessage("notifications.error.fail.notClientPoint"));
            yield history.push(`/app/machines/display/${payload.data.Id}`);
        } else {
            const image = yield call(API, "/image/", {
                Filename: point.data.Id,
            });
            image.data
                ? (point.data.Image = `data:image/png;base64, ${image.data}`)
                : (point.data.Icon = LockIcon);
            yield put(setMachinePoint(point.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestMachineJob({ payload }) {
    try {
        const job = yield call(API, payload.url, payload.data);
        if (job.message) {
            if (job.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(job.message));
            }
        } else if (job.data === "") {
            yield put(showMessage("notifications.error.fail.notClientJob"));
            yield history.push(`/app/machines/display/${payload.data.Id}`);
        } else {
            job.data.Icon = TicketIcon;
            yield put(setMachineJob(job.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestMachineJobTemplate({ payload }) {
    try {
        const jobTemplate = yield call(API, payload.url, payload.data);
        if (jobTemplate.message) {
            if (jobTemplate.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(jobTemplate.message));
            }
        } else {
            jobTemplate.data.TemplateProcess && jobTemplate.data.TemplateProcess.sort((a,b) => (a.ProcessOrder > b.ProcessOrder) ? 1: -1);
            yield put(setMachineJobTemplate(jobTemplate.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestMachineJobActions({ payload }) {
    try {
        const action = yield call(API, payload.url, payload.data);
        if (action.message) {
            if (action.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(action.message));
            }
        } else if (action.data === "") {
            yield put(setMachineJobActions([]));
        } else {
            const images = yield all(
                action.data.map((item) =>
                    call(API, "/image/", { Filename: item.Id })
                )
            );
            action.data.map((item, i) => {
                item.Image = images[i].data
                    ? `data:image/png;base64, ${images[i].data}`
                    : "assets/images/Icons/machine/machine@4x.png";
                item.Icon = TicketIcon;
            });
            yield put(setMachineJobActions(action.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestMachinePointActions({ payload }) {
    try {
        const action = yield call(API, payload.url, payload.data);
        if (action.message) {
            if (action.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(action.message));
            }
        } else if (action.data === "") {
            yield put(setMachinePointActions([]));
        } else {
            action.data = sortList(false, action.data, "ProcessOrder");
            const images = yield all(
                action.data.map((item) =>
                    call(API, "/image/", { Filename: item.Id })
                )
            );
            action.data.map((item, i) => {
                item.Image = images[i].data
                    ? `data:image/png;base64, ${images[i].data}`
                    : "assets/images/Icons/machine/machine@4x.png";
                item.Icon = TicketIcon;
            });
            yield put(setMachinePointActions(action.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestCreateMachinePointAction({ payload }) {
    try {
        const required = {
            Description: payload.data.Description,
        };
        for (var key in required) {
            if (required[key] === undefined || required[key] === "") {
                yield put(
                    showMessage(`notifications.error.blank.${key}`, "warning")
                );
                return;
            }
        }
        const point = yield call(API, payload.url, payload.data);
        if (point.message) {
            if (point.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(point.message, "message"));
            }
        } else if (point.validationError) {
            var error = Object.keys(point.validationError).map(function (key) {
                return point.validationError[key];
            });
            yield put(showMessage(error, "message"));
        } else {
            yield put(fetchMachinePointActions(point.data.MachinePointId));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestEditMachinePointAction({ payload }) {
    try {
        const required = {
            Description: payload.data.Description,
        };
        for (var key in required) {
            if (required[key] === undefined || required[key] === "") {
                yield put(
                    showMessage(`notifications.error.blank.${key}`, "warning")
                );
                return;
            }
        }
        const point = yield call(API, payload.url, payload.data);
        if (point.message) {
            if (point.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(point.message, "message"));
            }
        } else {
            yield put(fetchMachinePointActions(point.data.MachinePointId));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestMachinePointAction({ payload }) {
    try {
        const action = yield call(API, payload.url, payload.data);
        if (action.message) {
            if (action.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(action.message));
            }
        } else if (action.data === "") {
            yield put(
                showMessage("notifications.error.fail.notClientTemplate")
            );
        } else {
            const image = yield call(API, "/image/", {
                Filename: action.data.Id,
            });
            image.data
                ? (action.data.Image = `data:image/png;base64, ${image.data}`)
                : (action.data.Icon = TicketIcon);
            yield put(setMachinePointAction(action.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestdeleteMachinePointAction({ payload }) {
    try {
        if (!payload.data) {
            yield put(showMessage(`notifications.error.permission.deny`));
            return;
        }
        const action = yield call(API, payload.url, payload.data);
        if (action.message) {
            if (action.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(action.message, "message"));
            }
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestCreateMachineJobAction({ payload }) {
    try {
        const required = {
            Description: payload.data.Description,
        };
        for (var key in required) {
            if (required[key] === undefined || required[key] === "") {
                yield put(
                    showMessage(`notifications.error.blank.${key}`, "warning")
                );
                return;
            }
        }
        const job = yield call(API, payload.url, payload.data);
        if (job.message) {
            if (job.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(job.message, "message"));
            }
        } else if (job.validationError) {
            var error = Object.keys(job.validationError).map(function (key) {
                return job.validationError[key];
            });
            yield put(showMessage(error, "message"));
        } else {
            yield put(fetchMachineJobProcess(payload.data.MachineJobId, true));
            return;
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestdeleteMachineJobAction({ payload }) {
    try {
        if (!payload.data) {
            yield put(showMessage(`notifications.error.permission.deny`));
            return;
        }
        const action = yield call(API, payload.url, payload.data);
        if (action.message) {
            if (action.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(action.message, "message"));
            }
        } else {
            yield put(showMessage("notifications.success.delete", "success"));
            yield history.push(
                `/app/machines/display/job/${payload.data.MachineJobId}`
            );
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestCreateMachineJobTemplateAction({ payload }) {
    try {
        const jobTemplate = yield call(API, payload.url, payload.data);
        if (jobTemplate.message) {
            if (jobTemplate.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(jobTemplate.message, "message"));
            }
        } else if (jobTemplate.validationError) {
            var error = Object.keys(jobTemplate.validationError).map(function (key) {
                return jobTemplate.validationError[key];
            });
            yield put(showMessage(error, "message"));
        } else {
            yield put(fetchMachineJobTemplateById(payload.data.MachineJobTemplateId));
            return;
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestMachineJobAction({ payload }) {
    try {
        const action = yield call(API, payload.url, payload.data);
        if (action.message) {
            if (action.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(action.message));
            }
        } else if (action.data === "") {
            yield put(
                showMessage("notifications.error.fail.notClientTemplate")
            );
        } else {
            const image = yield call(API, "/image/", {
                Filename: action.data.Id,
            });
            image.data
                ? (action.data.Image = `data:image/png;base64, ${image.data}`)
                : (action.data.Icon = TicketIcon);
            yield put(setMachineJobAction(action.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestEditMachineJobAction({ payload }) {
    try {
        const required = {
            Description: payload.data.Description,
        };
        for (var key in required) {
            if (required[key] === undefined || required[key] === "") {
                yield put(
                    showMessage(`notifications.error.blank.${key}`, "warning")
                );
                return;
            }
        }
        const action = yield call(API, "/isolation/machine/job/action/byid/", {
            Id: payload.data.Id,
        });
        action.data.Description = payload.data.Description;
        action.data.SystemTypeActionId = payload.data.SystemTypeActionId;
        const job = yield call(API, payload.url, action.data);
        if (job.message) {
            if (job.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(job.message, "message"));
            }
        } else {
            yield put(fetchMachineJobProcess(job.data.MachineJobId, true));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestEditMachineJobTemplateAction({ payload }) {
    try {
        const jobTemplate = yield call(API, payload.url, payload.data);
        if (jobTemplate.message) {
            if (jobTemplate.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(jobTemplate.message, "message"));
            }
        } else {
            yield put(fetchMachineJobTemplateById(payload.data.MachineJobTemplateId));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestEditMachineJobTemplateProcess({ payload }) {
    try {
        const jobTemplate = yield call(API, payload.url, payload.data);
        if (jobTemplate.message) {
            if (jobTemplate.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(jobTemplate.message, "message"));
            }
        } else {
            yield put(fetchMachineJobTemplateById(payload.MachineJobTemplateId));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestApplyJobTemplate({ payload }) {
    try {
        const jobTemplate = yield call(API, payload.url, payload.data);
        if (jobTemplate.message) {
            if (jobTemplate.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(jobTemplate.message, "message"));
            }
        } else {
            yield put(fetchMachineJobById(payload.data.MachineJobId));
            yield put(fetchMachineJobProcess(payload.data.MachineJobId));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestEditMachineJob({ payload }) {
    try {
        const job = yield call(API, payload.url, payload.data);
        if (job.message) {
            if (job.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(job.message, "message"));
            }
        } else {
            yield put(showMessage("notifications.success.edit", "success"));
            yield history.push(
                `/app/machines/display/job/${payload.data.Id}/${payload.machineId}`
            );
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestEditMachinePoint({ payload }) {
    try {
        payload.data.TypeHazardId = Number(payload.data.TypeHazardId);
        const point = yield call(API, payload.url, payload.data);
        if (point.message) {
            if (point.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(point.message, "message"));
            }
        } else {
            if (payload.hazards && payload.hazards.length > 0) {
                yield put(
                    updatePointHazards({
                        PointId: point.data.Id,
                        Hazards: payload.hazards,
                    })
                );
            }
            if (payload.equipment && payload.equipment.length > 0) {
                yield put(
                    updatePointEquipment({
                        PointId: point.data.Id,
                        Equipment: payload.equipment,
                    })
                );
            }
            yield put(showMessage("notifications.success.edit", "success"));
            yield history.push(
                `/app/machines/display/point/${payload.data.Id}/${payload.machineId}`
            );
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestEditMachinePointProcess({ payload }) {
    try {
        const process = yield call(API, payload.url, payload.data);
        if (process.message) {
            if (process.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(process.message, "message"));
            }
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestMachineJobProcess({ payload }) {
    try {
        if (!payload.dontRefresh) {
            yield put(setMachineJobProcess(null));
        }
        const process = yield call(API, payload.url, payload.data);
        if (process.message) {
            if (process.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(process.message));
            }
        } else if (process.data === "") {
            yield put(setMachineJobProcess([]));
        } else {
            !process.data.Actions ? (process.data.Actions = []) : "";
            !process.data.Points ? (process.data.Points = []) : "";
            let processList = process.data.Actions.concat(process.data.Points);
            let processedList = sortList(false, processList, "ProcessOrder");
            yield put(setMachineJobProcess(processedList));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestEditMachineJobProcess({ payload }) {
    try {
        const process = yield call(API, payload.url, payload.data);
        if (process.message) {
            if (process.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(process.message, "message"));
            }
        } else {
            yield put(fetchMachineJobProcess(payload.Id, true));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestCreateMachineJobProcess({ payload }) {
    try {
        const process = yield call(API, payload.url, payload.data);
        if (process.message) {
            if (process.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(process.message, "message"));
            }
        } else {
            yield payload.data.Wizard
                ? history.push(
                      `/app/machines/create/machines/${payload.data.MachineJobId}`
                  )
                : put(fetchMachineJobProcess(payload.data.MachineJobId, true));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestDeleteMachineJobProcess({ payload }) {
    try {
        const process = yield call(API, payload.url, payload.data);
        if (process.message) {
            if (process.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(process.message, "message"));
            }
        } else {
            yield put(fetchMachineJobProcess(payload.data.MachineJobId, true));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestDeleteMachineJobTemplateAction({ payload }) {
    try {
        const jobTemplate = yield call(API, payload.url, payload.data);
        if (jobTemplate.message) {
            if (jobTemplate.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(jobTemplate.message, "message"));
            }
        } else {
            yield put(fetchMachineJobTemplateById(payload.data.MachineJobTemplateId));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestPointActivity({ payload }) {
    try {
        const activity = yield call(API, payload.url, payload.data);
        if (activity.message) {
            if (activity.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(activity.message));
            }
        } else if (activity.data === "") {
            yield put(setPointActivity([]));
        } else {
            activity.data = sortList(true, activity.data, "Date");
            const images = yield all(
                activity.data.map(
                    (item) =>
                        item.ImageId !== "" &&
                        call(API, "/image/", { Filename: item.ImageId })
                )
            );
            activity.data.map((item, i) => {
                if (item.Event === "Ticket") {
                    item.Icon = TicketIcon;
                } else if (item.Event === "Machine") {
                    item.Icon = MachineIcon;
                } else if (item.Event === "Lock") {
                    item.Icon = LockIcon;
                } else if (item.Event === "User") {
                    item.Icon = UserIcon;
                } else if (item.Event === "Point") {
                    item.Icon = LockIcon;
                } else if (item.Event === "Job") {
                    item.Icon = TicketIcon;
                } else {
                    item.Icon = UserIcon;
                }
                item.Image = images[i].data
                    ? `data:image/png;base64, ${images[i].data}`
                    : null;
            });
            yield put(setPointActivity(activity.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestMachinePointActionList({ payload }) {
    try {
        yield put(setMachinePointActionList(null));
        const actions = yield call(API, payload.url, payload.data);
        if (actions.message) {
            if (actions.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(actions.message));
            }
        } else if (actions.data === "") {
            yield put(setMachinePointActionList([]));
        } else {
            actions.data = sortList(false, actions.data, "ProcessOrder");
            yield put(setMachinePointActionList(actions.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

export function* selectMachinesList() {
    yield takeEvery(FETCH_MACHINES_LIST, requestMachines);
}

export function* selectActiveMachinesList() {
    yield takeEvery(FETCH_ACTIVE_MACHINES_LIST, requestActiveMachines);
}

export function* selectMachinesListSelect() {
    yield takeEvery(FETCH_MACHINES_LIST_SELECT, requestMachines);
}

export function* selectMachineById() {
    yield takeEvery(FETCH_MACHINE_BY_ID, requestMachine);
}

export function* createMachine() {
    yield takeEvery(CREATE_MACHINE, requestCreateMachine);
}

export function* editMachine() {
    yield takeEvery(EDIT_MACHINE, requestEditMachine);
}

export function* deleteMachine() {
    yield takeEvery(DELETE_MACHINE, requestDeleteMachine);
}

export function* selectFilterMachine() {
    yield takeEvery(FILTER_MACHINES, handleFilter);
}

export function* selectFilterMachineList() {
    yield takeEvery(FILTER_MACHINES_LIST, handleFilterList);
}

export function* selectSearchMachinesList() {
    yield takeEvery(SEARCH_MACHINES_LIST, handleFilterList);
}

export function* selectSiteMachinesList() {
    yield takeEvery(FETCH_MACHINES_BY_SITE_ID, requestSiteMachines);
}

export function* selectFetchMachineDocuments() {
    yield takeEvery(FETCH_MACHINE_DOCUMENTS, requestMachineDocuments);
}

export function* selectFetchMachineDocumentById() {
    yield takeEvery(FETCH_MACHINE_DOCUMENT_BY_ID, requestMachineDocumentById);
}

export function* selectCreateMachineDocument() {
    yield takeEvery(CREATE_MACHINE_DOCUMENT, requestCreateMachineDocument);
}

export function* selectMachineActivity() {
    yield takeEvery(FETCH_MACHINE_ACTIVITY, requestMachineActivity);
}

export function* selectSearchMachines() {
    yield takeEvery(SEARCH_MACHINES, requestSearchMachines);
}

export function* selectSearchJobTemplates() {
    yield takeEvery(SEARCH_JOB_TEMPLATES, requestSearchJobTemplates);
}

export function* selectFetchJobTemplatesSelect() {
    yield takeEvery(FETCH_JOB_TEMPLATES_SELECT, requestFetchJobTemplatesSelect);
}

export function* selectJobsByMachineId() {
    yield takeEvery(FETCH_JOBS_BY_MACHINE_ID, requestMachineJobs);
}

export function* createMachineJob() {
    yield takeEvery(CREATE_MACHINE_JOB, requestCreateMachineJob);
}

export function* createMachineJobTemplate() {
    yield takeEvery(CREATE_MACHINE_JOB_TEMPLATE, requestCreateMachineJobTemplate);
}

export function* selectSearchMachinePoints() {
    yield takeEvery(SEARCH_MACHINE_POINT_LIST, requestSearchMachinePoints);
}

export function* selectLotoDeviceSelect() {
    yield takeEvery(FETCH_LOTO_DEVICE_SELECT, requestLotoDeviceSelect);
}

export function* createMachinePoint() {
    yield takeEvery(CREATE_MACHINE_POINT, requestCreateMachinePoint);
}

export function* selectUpdatePointHazards() {
    yield takeEvery(UPDATE_POINT_HAZARDS, requestUpdatePointHazards);
}

export function* selectUpdatePointEquipment() {
    yield takeEvery(UPDATE_POINT_EQUIPMENT, requestUpdatePointEquipment);
}

export function* selectFetchPointHazards() {
    yield takeEvery(FETCH_POINT_HAZARDS, requestFetchPointHazards);
}

export function* selectFetchPointEquipment() {
    yield takeEvery(FETCH_POINT_EQUIPMENT, requestFetchPointEquipment);
}

export function* selectMachinePointById() {
    yield takeEvery(FETCH_MACHINE_POINT_BY_ID, requestMachinePoint);
}

export function* selectMachineJobById() {
    yield takeEvery(FETCH_MACHINE_JOB_BY_ID, requestMachineJob);
}

export function* selectMachineJobTemplateById() {
    yield takeEvery(FETCH_MACHINE_JOB_TEMPLATE_BY_ID, requestMachineJobTemplate);
}

export function* selectMachineJobActions() {
    yield takeEvery(FETCH_MACHINE_JOB_ACTIONS, requestMachineJobActions);
}

export function* selectMachinePointActions() {
    yield takeEvery(FETCH_MACHINE_POINT_ACTIONS, requestMachinePointActions);
}

export function* createMachinePointAction() {
    yield takeEvery(
        CREATE_MACHINE_POINT_ACTION,
        requestCreateMachinePointAction
    );
}

export function* editMachinePointAction() {
    yield takeEvery(EDIT_MACHINE_POINT_ACTION, requestEditMachinePointAction);
}

export function* selectMachinePointAction() {
    yield takeEvery(
        FETCH_MACHINE_POINT_ACTION_BY_ID,
        requestMachinePointAction
    );
}

export function* selectDeleteMachinePointAction() {
    yield takeEvery(
        DELETE_MACHINE_POINT_ACTION,
        requestdeleteMachinePointAction
    );
}

export function* createMachineJobAction() {
    yield takeEvery(CREATE_MACHINE_JOB_ACTION, requestCreateMachineJobAction);
}

export function* selectdeleteMachineJobAction() {
    yield takeEvery(DELETE_MACHINE_JOB_ACTION, requestdeleteMachineJobAction);
}

export function* createMachineJobTemplateAction() {
    yield takeEvery(CREATE_MACHINE_JOB_TEMPLATE_ACTION, requestCreateMachineJobTemplateAction);
}

export function* deleteMachineJobTemplateAction() {
    yield takeEvery(DELETE_MACHINE_JOB_TEMPLATE_ACTION, requestDeleteMachineJobTemplateAction);
}

export function* selectMachineJobAction() {
    yield takeEvery(FETCH_MACHINE_JOB_ACTION_BY_ID, requestMachineJobAction);
}

export function* editMachineJobAction() {
    yield takeEvery(EDIT_MACHINE_JOB_ACTION, requestEditMachineJobAction);
}

export function* editMachineJobTemplateAction() {
    yield takeEvery(EDIT_MACHINE_JOB_TEMPLATE_ACTION, requestEditMachineJobTemplateAction);
}

export function* editMachineJobTemplateProcess() {
    yield takeEvery(EDIT_MACHINE_JOB_TEMPLATE_PROCESS, requestEditMachineJobTemplateProcess);
}

export function* selectApplyJobTemplate() {
    yield takeEvery(APPLY_JOB_TEMPLATE, requestApplyJobTemplate);
}

export function* editMachineJob() {
    yield takeEvery(EDIT_MACHINE_JOB, requestEditMachineJob);
}

export function* editMachinePoint() {
    yield takeEvery(EDIT_MACHINE_POINT, requestEditMachinePoint);
}

export function* editMachinePointProcess() {
    yield takeEvery(EDIT_MACHINE_POINT_PROCESS, requestEditMachinePointProcess);
}

export function* selectJobProcessById() {
    yield takeEvery(FETCH_MACHINE_JOB_PROCESS, requestMachineJobProcess);
}

export function* editMachineJobProcess() {
    yield takeEvery(EDIT_MACHINE_JOB_PROCESS, requestEditMachineJobProcess);
}

export function* createMachineJobProcess() {
    yield takeEvery(CREATE_MACHINE_JOB_PROCESS, requestCreateMachineJobProcess);
}

export function* deleteMachineJobProcess() {
    yield takeEvery(DELETE_MACHINE_JOB_PROCESS, requestDeleteMachineJobProcess);
}

export function* selectPointActivity() {
    yield takeEvery(FETCH_POINT_ACTIVITY, requestPointActivity);
}

export function* selectMachinePointActionList() {
    yield takeEvery(
        FETCH_MACHINE_POINT_ACTION_LIST,
        requestMachinePointActionList
    );
}

export default function* rootSaga() {
    yield all([
        fork(selectMachinesList),
        fork(selectActiveMachinesList),
        fork(selectMachinesListSelect),
        fork(selectMachineById),
        fork(createMachine),
        fork(editMachine),
        fork(deleteMachine),
        fork(selectFilterMachine),
        fork(selectFilterMachineList),
        fork(selectSearchMachinesList),
        fork(selectSiteMachinesList),
        fork(selectMachineActivity),
        fork(selectSearchMachines),
        fork(selectSearchJobTemplates),
        fork(selectFetchMachineDocuments),
        fork(selectFetchMachineDocumentById),
        fork(selectCreateMachineDocument),
        fork(selectJobsByMachineId),
        fork(createMachineJob),
        fork(selectSearchMachinePoints),
        fork(selectLotoDeviceSelect),
        fork(createMachinePoint),
        fork(selectUpdatePointHazards),
        fork(selectUpdatePointEquipment),
        fork(selectFetchPointHazards),
        fork(selectFetchPointEquipment),
        fork(selectMachinePointById),
        fork(selectMachineJobById),
        fork(selectMachineJobTemplateById),
        fork(selectMachineJobActions),
        fork(selectMachinePointActions),
        fork(createMachinePointAction),
        fork(editMachinePointAction),
        fork(selectMachinePointAction),
        fork(selectDeleteMachinePointAction),
        fork(createMachineJobAction),
        fork(selectdeleteMachineJobAction),
        fork(selectMachineJobAction),
        fork(editMachineJobAction),
        fork(editMachineJob),
        fork(editMachinePoint),
        fork(editMachinePointProcess),
        fork(selectJobProcessById),
        fork(editMachineJobProcess),
        fork(createMachineJobProcess),
        fork(deleteMachineJobProcess),
        fork(selectPointActivity),
        fork(selectMachinePointActionList),
        fork(createMachineJobTemplateAction),
        fork(deleteMachineJobTemplateAction),
        fork(editMachineJobTemplateAction),
        fork(createMachineJobTemplate),
        fork(editMachineJobTemplateProcess),
        fork(selectFetchJobTemplatesSelect),
        fork(selectApplyJobTemplate)
    ]);
}
