import "@formatjs/intl-pluralrules/polyfill";
import "@formatjs/intl-pluralrules/locale-data/en"; // Add locale data for en

import "@formatjs/intl-relativetimeformat/polyfill";
import "@formatjs/intl-relativetimeformat/locale-data/en"; // Add locale data for en
import enMessages from "../locales/en_US.json";

var userObject = JSON.parse(sessionStorage.getItem("user_data"));
userObject &&
    userObject.Terms.sort(function (a, b) {
        return a.Id - b.Id;
    });
userObject &&
    Object.keys(enMessages).forEach((k) => {
        if (userObject.Terms[0]) {
            enMessages[k] = enMessages[k].replace(
            /tickets/gi,
            `${userObject.Terms[0].Plural}`
            );
            enMessages[k] = enMessages[k].replace(
            /ticket/gi,
            `${userObject.Terms[0].Singular}`
            );
        }
        if (userObject.Terms[1]) {
            enMessages[k] = enMessages[k].replace(
            /last lockouts/gi,
            `${userObject.Terms[1].Plural}`
            );
            enMessages[k] = enMessages[k].replace(
            /last lockout/gi,
            `${userObject.Terms[1].Singular}`
            );
        }
        if (userObject.Terms[2]) {
            enMessages[k] = enMessages[k].replace(
            /machines/gi,
            `${userObject.Terms[2].Plural}`
            );
            enMessages[k] = enMessages[k].replace(
            /machine/gi,
            `${userObject.Terms[2].Singular}`
            );
        }
        if (userObject.Terms[3]) {
            enMessages[k] = enMessages[k].replace(
            /job descriptions/gi,
            `${userObject.Terms[3].Plural}`
            );
            enMessages[k] = enMessages[k].replace(
            /job description/gi,
            `${userObject.Terms[3].Singular}`
            );
        }
        if (userObject.Terms[4]) {
            enMessages[k] = enMessages[k].replace(
            /jobs/gi,
            `${userObject.Terms[4].Plural}`
            );
            enMessages[k] = enMessages[k].replace(
                /job/gi,
                `${userObject.Terms[4].Singular}`
            );
        }
    });

const EnLang = {
    messages: { ...enMessages },
    locale: "en-US",
};

export default EnLang;
