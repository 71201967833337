import colors from "themes/colors";
import AppLocale from "../lngProvider";
const subdomain = window.location.host.split(".");
var site = subdomain[0];
if (subdomain[0] === "www") {
    site = subdomain[1];
}
if (site.includes("localhost")) {
    site = "demo";
}
if (site.includes("loto-go-dev")) {
    site = "demo";
}
if (site.includes("smartloto")) {
    site = "demo";
}
if (!site) {
    site = "demo";
}

let mode =
    JSON.parse(sessionStorage.getItem("themeColor")) ||
    colors[site] ||
    colors.demo;

export default {
    demo: {
        companyId: 1,
        title: "Smart Loto",
        logo: "assets/images/Themes/Demo/logo.png",
        menuLogo: "assets/images/Themes/Demo/lotomenu.png",
        favicon: "assets/images/Themes/Demo/favicon.ico",
        footer: "assets/images/Themes/Demo/footer.png",
        auth: "assets/images/Themes/Demo/auth.png",
        color: mode,
        locale: {
            languageId: "english",
            locale: "en",
            name: "English",
            icon: "us",
        },
        appLocale: AppLocale.en.locale,
        messages: AppLocale.en.messages,
    },
};
