import "@formatjs/intl-pluralrules/polyfill";
import "@formatjs/intl-pluralrules/locale-data/he"; // Add locale data for he

import "@formatjs/intl-relativetimeformat/polyfill";
import "@formatjs/intl-relativetimeformat/locale-data/he"; // Add locale data for he
import heMessages from "../locales/he_HE.json";

const heLang = {
    messages: {
        ...heMessages,
    },
    locale: "he-HE",
};

export default heLang;
