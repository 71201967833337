import {
    SET_ACTIVITY,
    SET_SNAPSHOT,
    SET_SNAPSHOT_WORKORDER,
    SET_ACTIVITY_SEARCH_TERMS,
    SET_REPORTS,
    SET_TICKETS_NOT_STARTED,
} from "constants/ActionTypes";
import moment from "moment";

const INIT_STATE = {
    activity: [],
    snapshot: [],
    snapshotWorkorder: [],
    searchActivityTerms: {
        Activity: "",
        WorkOrderId: 0,
        UserId: "",
        UserName: "",
        SiteId: "",
        MachineId: "",
        JobId: "",
        LockId: "",
        StartDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
        EndDate: moment().format("YYYY-MM-DD"),
    },
    reports: [],
    ticketsNotStarted: null,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_ACTIVITY: {
            return {
                ...state,
                activity: action.payload,
            };
        }
        case SET_SNAPSHOT: {
            return {
                ...state,
                snapshot: action.payload,
            };
        }
        case SET_SNAPSHOT_WORKORDER: {
            return {
                ...state,
                snapshotWorkorder: action.payload,
            };
        }
        case SET_ACTIVITY_SEARCH_TERMS: {
            return {
                ...state,
                searchActivityTerms: action.payload,
            };
        }
        case SET_REPORTS: {
            return {
                ...state,
                reports: action.payload,
            };
        }
        case SET_TICKETS_NOT_STARTED: {
            return {
                ...state,
                ticketsNotStarted: action.payload,
            };
        }
        default:
            return state;
    }
};
