import React from "react";
import { connect } from "react-redux";
import { StyleSheet, css } from "aphrodite";
import { injectIntl } from "react-intl";
import { CSVLink } from "react-csv";

class DownloadCSV extends React.Component {
    render() {
        const { data, theme, margin, padding, color, icon } = this.props;
        this.styles = styles(theme, margin, padding, color);
        return (
            <CSVLink
                data={data ? data : []}
                filename={"loto-export.csv"}
                className={css(this.styles.csv)}
            >
                <i
                    id="exportFile"
                    className={`material-icons ${css(this.styles.searchIcon)}`}
                >
                    {icon}
                </i>
            </CSVLink>
        );
    }
}

DownloadCSV.defaultProps = {
    margin: 0,
    padding: 0,
    color: null,
    icon: "get_app",
};

const mapStateToProps = ({ auth, app }) => {
    const { authUser } = auth;
    const { theme } = app;
    return { authUser, theme };
};

export default injectIntl(connect(mapStateToProps)(DownloadCSV));

const styles = (theme, margin, padding, color) =>
    StyleSheet.create({
        csv: {
            margin,
            padding,
            color: color ? color : theme.color.buttonFontColor,
        },
        searchIcon: {
            color: color ? color : theme.color.themeColor,
            margin: 0,
            padding: 0,
            ":hover": {
                cursor: "pointer",
                opacity: ".8",
            },
        },
    });
