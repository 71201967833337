import React from "react";
import { injectIntl } from "react-intl";

import Box from "components/Box";

class Error404 extends React.Component {
    render() {
        return (
            <Box height={300} margin={"0 auto"} width={"50vw"}>
                <div className="app-wrapper page-error-container animated slideInUpTiny animation-duration-3">
                    <h2 className="text-center fw-regular title bounceIn animation-delay-10 animated">
                        {this.props.intl.formatMessage({
                            id: "pages.403.header",
                        })}
                    </h2>
                </div>
            </Box>
        );
    }
}

export default injectIntl(Error404);
