import React from "react";
import { connect } from "react-redux";
import { StyleSheet, css } from "aphrodite";
import { injectIntl } from "react-intl";
import { fadeInLeft } from "react-animations";
import { version } from "../../../../package.json";

import LanguageSwitcher from "components/LanguageSwitcher";
class MenuFooter extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { src, hideSidebar, theme, width, height, padding } = this.props;
        this.styles = styles(theme, width, height, padding);
        return (
            <div className={css(this.styles.wrapper, this.styles.fadeInLeft)}>
                {!hideSidebar && (
                    <div
                        className={css(
                            hideSidebar
                                ? this.styles.langWrapperShort
                                : this.styles.langWrapper
                        )}
                    >
                        <LanguageSwitcher />
                    </div>
                )}
                <div className={css(this.styles.imgWrapper)}>
                    {!hideSidebar && (
                        <img
                            alt="Noke"
                            src={src}
                            className={css(this.styles.image)}
                        />
                    )}
                </div>
                {!hideSidebar && (
                    <div className={css(this.styles.contentWrapper)}>
                        <span>
                            {this.props.intl.formatMessage({
                                id: "comp.footer.version",
                            })}{" "}
                            {version}
                        </span>
                    </div>
                )}
            </div>
        );
    }
}

MenuFooter.defaultProps = {
    padding: "10px 20px",
    height: 120,
    width: "100%",
    src: "assets/images/Themes/Demo/footer.png",
};

const mapStateToProps = ({ app }) => {
    const { theme, hideSidebar } = app;
    return { theme, hideSidebar };
};

export default injectIntl(connect(mapStateToProps)(MenuFooter));

const styles = (theme, width, height, padding) =>
    StyleSheet.create({
        wrapper: {
            backgroundColor: theme.color.menuColor,
            height,
            padding,
            width,
            color: theme.color.fontColor,
        },
        langWrapper: {
            display: "block",
            marginBottom: 5,
        },
        langWrapperShort: {
            display: "block",
            float: "left",
        },
        imgWrapper: {
            marginBottom: 10,
        },
        contentWrapper: {
            marginBottom: 0,
        },
        image: {
            width: 100,
        },
        fadeInLeft: {
            animationName: fadeInLeft,
            animationDuration: "1.2s",
        },
    });
