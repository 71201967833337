import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
    NotificationContainer,
    NotificationManager,
} from "react-notifications";
import { injectIntl } from "react-intl";
import { hideMessage, setLoader } from "actions/App";

class Notification extends React.Component {
    componentDidUpdate() {
        if (this.props.showMessage) {
            this.props.setLoader(false);
            this.filterMessage();
            this.props.hideMessage();
        }
    }
    filterMessage = () => {
        const { contentMessage, typeMessage } = this.props;
        switch (typeMessage) {
            case "info":
                return NotificationManager.info(
                    this.props.intl.formatMessage({ id: `${contentMessage}` })
                );
            case "success":
                return NotificationManager.success(
                    this.props.intl.formatMessage({ id: `${contentMessage}` })
                );
            case "warning":
                return NotificationManager.warning(
                    this.props.intl.formatMessage({ id: `${contentMessage}` })
                );
            case "message":
                return NotificationManager.error(contentMessage);
            case "error":
                return NotificationManager.error(
                    this.props.intl.formatMessage({ id: `${contentMessage}` })
                );
        }
    };
    render() {
        return <NotificationContainer />;
    }
}

const mapStateToProps = ({ app }) => {
    const { contentMessage, showMessage, typeMessage } = app;
    return { contentMessage, showMessage, typeMessage };
};

export default injectIntl(
    withRouter(
        connect(mapStateToProps, { hideMessage, setLoader })(Notification)
    )
);
