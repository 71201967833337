import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { StyleSheet, css } from "aphrodite";
import { Row, Col } from "reactstrap";
import { injectIntl } from "react-intl";

import { setLoader } from "actions/App";
import Tooltip from "components/Tooltips";
import CSV from "components/Export";

class SearchJobTemplate extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { width, jobTemplates, theme } = this.props;
        this.styles = styles(theme);
        let exportList = jobTemplates
            ? jobTemplates.map((a) => Object.assign({}, a))
            : [];
        exportList.map((item) => {
            delete item.Icon;
            delete item.Id;
            return item;
        });
        return (
            <div>
                <div className={css(this.styles.searchWrapper)}>
                    <Col lg={12}>
                        {width < 992 && (
                            <Row>
                                <Col
                                    lg={12}
                                    className={css(this.styles.flexRow)}
                                >
                                    <div
                                        className={`material-icons ${css(
                                            this.styles.searchIcon
                                        )}`}
                                        onClick={() => this.props.action()}
                                    >
                                        <i
                                            id="refreshTables"
                                            className={`material-icons ${css(
                                                this.styles.icon
                                            )}`}
                                        >
                                            sync
                                        </i>
                                        <Tooltip
                                            message={"comp.button.refresh"}
                                            target="refreshTables"
                                        />
                                    </div>
                                </Col>
                            </Row>
                        )}
                        {width > 800 && (
                            <Row>
                                <Col lg={10}></Col>
                                <Col lg={2}>
                                    {width > 991 && (
                                        <Row>
                                            <Col
                                                lg={12}
                                                className={css(
                                                    this.styles.flexRow,
                                                    this.styles.rightAligned
                                                )}
                                            >
                                                <div
                                                    className={`material-icons ${css(
                                                        this.styles.searchIcon
                                                    )}`}
                                                    onClick={() =>
                                                        this.props.action()
                                                    }
                                                >
                                                    <i
                                                        id="refreshTables"
                                                        className={`material-icons ${css(
                                                            this.styles.icon
                                                        )}`}
                                                    >
                                                        sync
                                                    </i>
                                                    <Tooltip
                                                        message={
                                                            "comp.button.refresh"
                                                        }
                                                        target="refreshTables"
                                                    />
                                                </div>
                                                <div>
                                                    <CSV
                                                        data={exportList}
                                                        margin={"0 12px 0 0"}
                                                    />
                                                    <Tooltip
                                                        message={
                                                            "comp.button.export"
                                                        }
                                                        target="exportFile"
                                                    />
                                                </div>
                                                <div
                                                    className={`material-icons ${css(
                                                        this.styles.searchIcon
                                                    )}`}
                                                    onClick={() =>
                                                        this.props.history.push(
                                                            "/app/machines/create/jobTemplate"
                                                        )
                                                    }
                                                >
                                                    <i
                                                        id="addJobTemplate"
                                                        className={`material-icons ${css(
                                                            this.styles.icon
                                                        )}`}
                                                    >
                                                        add_circle_outline
                                                    </i>
                                                    <Tooltip
                                                        message={
                                                            "comp.form.add"
                                                        }
                                                        target="addJobTemplate"
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    )}
                                </Col>
                            </Row>
                        )}
                    </Col>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ app, auth, machines }) => {
    const { theme, width } = app;
    const { authUser } = auth;
    const { jobTemplates } = machines;
    return {
        theme,
        width,
        authUser,
        jobTemplates,
    };
};

export default injectIntl(
    withRouter(
        connect(mapStateToProps, {
            setLoader,
        })(SearchJobTemplate)
    )
);

const styles = (theme) =>
    StyleSheet.create({
        advancedSection: {
            position: "relative",
        },
        flexRow: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            height: "2.25rem",
            paddingLeft: 0,
        },
        rightAligned: {
            paddingRight: 0,
            justifyContent: "flex-end",
        },
        input: {
            border: "none",
            boxShadow: "none",
            borderRadius: 0,
            fontWeight: 400,
            fontSize: 12,
            letterSpacing: 2,
            marginBottom: 0,
            color: theme.color.themeColor,
            border: "1px solid white",
            backgroundColor: theme.color.compColor,
            "::placeholder": {
                letterSpacing: 1,
                color: theme.color.fontColor,
                fontSize: 12,
            },
        },
        inputText: {
            border: "none",
            boxShadow: "none",
            borderRadius: 0,
            fontWeight: 400,
            fontSize: 12,
            letterSpacing: 2,
            marginBottom: 0,
            padding: 0,
            width: "100%",
            marginLeft: 5,
            color: theme.color.themeColor,
            borderBottom: `solid whitesmoke 1px`,
            backgroundColor: theme.color.compColor,
            "::placeholder": {
                letterSpacing: 1,
                color: theme.color.themeColor,
                fontSize: 12,
            },
        },
        searchWrapper: {
            position: "relative",
            display: "flex",
            flexDirection: "row",
        },
        searchIcon: {
            color: theme.color.themeColor,
            marginRight: 12,
            ":hover": {
                cursor: "pointer",
                opacity: ".8",
            },
        },
        closeIcon: {
            color: theme.color.fontColor,
            position: "absolute",
            top: 5,
            right: 5,
            fontSize: 16,
            ":hover": {
                cursor: "pointer",
                opacity: ".8",
            },
        },
        labelDropdown: {
            fontSize: 12,
            color: theme.color.fontColor,
            whiteSpace: "nowrap",
        },
    });
