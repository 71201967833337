import {
    SET_USERS,
    SET_USER,
    SET_USER_NOTIFICATIONS,
    SET_USER_UNREAD_NOTIFICATIONS,
    SET_CHECKED_NOTIFICATIONS,
    SET_USER_FILTER,
    SET_USER_ACTIVITY,
    SET_SEARCHED_USERS,
    SET_USER_SEARCH_TERMS,
} from "constants/ActionTypes";

const INIT_STATE = {
    loader: false,
    usersList: [],
    userObject: null,
    notifications: null,
    unreadNotifications: null,
    checkedNotifications: [],
    userActivity: [],
    searchedUsers: [],
    userFilterList: [
        {
            title: "comp.table.header.name",
            width: "14.28%",
            active: true,
            sortable: true,
            sortTitle: "Name",
            order: false,
            mobile: true,
        },
        {
            title: "comp.table.header.role",
            width: "14.28%",
            active: false,
            sortable: true,
            sortTitle: "UserRoleName",
            order: false,
            mobile: true,
        },
        {
            title: "form.defaultSite",
            width: "14.28%",
            active: false,
            sortable: true,
            sortTitle: "SiteName",
            order: false,
            mobile: false,
        },
        {
            title: "comp.table.header.company",
            width: "14.28%",
            active: false,
            sortable: true,
            sortTitle: "VendorName",
            order: false,
            mobile: true,
        },
        {
            title: "form.title",
            width: "14.28%",
            active: false,
            sortable: true,
            sortTitle: "Title",
            order: false,
            mobile: true,
        },
        {
            title: "form.contractor",
            width: "14.28%",
            active: false,
            sortable: true,
            sortTitle: "IsExternalUser",
            order: false,
            mobile: false,
        },
        {
            title: "form.active",
            width: "14.28%",
            active: false,
            sortable: true,
            sortTitle: "IsActive",
            style: { textAlign: "right" },
            order: false,
            mobile: false,
        },
    ],
    usersFilters: [
        {
            title: "filters.users.all",
            active: true,
        },
        {
            title: "filters.users.aic",
            active: false,
        },
    ],
    ticketUsersFilterList: [
        {
            title: "pages.TicketUsers.Type",
            width: "16.667%",
            active: true,
            sortable: true,
            sortTitle: "Name",
            style: { textAlign: "left" },
            order: false,
            mobile: true,
        },
    ],
    searchUserTerms: {
        Name: "",
        SiteId: "",
        VendorName: "",
        Title: "",
        IsActive: "1",
        UserRoleId: 0,
    },
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_USERS: {
            return {
                ...state,
                usersList: action.payload,
            };
        }
        case SET_USER: {
            return {
                ...state,
                userObject: action.payload,
            };
        }
        case SET_USER_NOTIFICATIONS: {
            return {
                ...state,
                notifications: action.payload,
            };
        }
        case SET_USER_UNREAD_NOTIFICATIONS: {
            return {
                ...state,
                unreadNotifications: action.payload,
            };
        }
        case SET_CHECKED_NOTIFICATIONS: {
            return {
                ...state,
                checkedNotifications: action.payload,
            };
        }
        case SET_USER_FILTER: {
            return {
                ...state,
                userFilterList: action.payload,
            };
        }
        case SET_USER_ACTIVITY: {
            return {
                ...state,
                userActivity: action.payload,
            };
        }
        case SET_SEARCHED_USERS: {
            return {
                ...state,
                searchedUsers: action.payload,
            };
        }
        case SET_USER_SEARCH_TERMS: {
            return {
                ...state,
                searchUserTerms: action.payload,
            };
        }
        default:
            return state;
    }
};
