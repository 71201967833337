import React from "react";
import { connect } from "react-redux";
import { StyleSheet, css } from "aphrodite";
import { Input } from "reactstrap";
import { injectIntl } from "react-intl";
import { searchUsers } from "actions/Users";

class SearchUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchTerm: "",
        };
    }
    handleSearch = () => {
        const { searchUsers } = this.props;
        const { searchTerm } = this.state;
        searchUsers(searchTerm);
    };
    keyPress = (e) => {
        if (e.keyCode === 13) {
            this.handleSearch();
        }
    };
    clearField = () => {
        this.setState({ searchTerm: "" });
    };
    render() {
        const { intl, theme } = this.props;
        const { searchTerm } = this.state;
        this.styles = styles(theme);
        return (
            <div
                className={css(this.styles.searchWrapper)}
                onKeyDown={(e) => this.keyPress(e)}
            >
                <i
                    className={`material-icons ${css(this.styles.searchIcon)}`}
                    onClick={() => this.clearField()}
                >
                    search
                </i>
                {searchTerm != "" && (
                    <i
                        className={`material-icons ${css(
                            this.styles.closeIcon
                        )}`}
                        onClick={() => this.setState({ searchTerm: "" })}
                    >
                        close
                    </i>
                )}
                <Input
                    type="text"
                    className={css(this.styles.input)}
                    placeholder={`${intl.formatMessage({
                        id: `comp.search.default`,
                    })}`}
                    onChange={(e) =>
                        this.setState({ searchTerm: e.target.value })
                    }
                    value={searchTerm}
                    autoFocus
                />
            </div>
        );
    }
}

SearchUser.defaultProps = {
    searchTitle: "blank",
    searchType: ["name"],
    data: [],
};

const mapStateToProps = ({ app }) => {
    const { theme, width } = app;
    return { theme, width };
};

export default injectIntl(
    connect(mapStateToProps, { searchUsers })(SearchUser)
);

const styles = (theme) =>
    StyleSheet.create({
        input: {
            border: "none",
            boxShadow: "none",
            borderRadius: 0,
            fontWeight: 400,
            fontSize: 12,
            letterSpacing: 2,
            marginBottom: 0,
            padding: "6px 0px 6px 35px",
            width: 230,
            color: theme.color.themeColor,
            backgroundColor: theme.color.compColor,
            "::placeholder": {
                letterSpacing: 1,
                color: theme.color.fontColor,
                fontSize: 12,
            },
        },
        searchWrapper: {
            position: "relative",
        },
        searchIcon: {
            color: theme.color.themeColor,
            position: "absolute",
            top: 3,
            left: 5,
            ":hover": {
                cursor: "pointer",
                opacity: ".8",
            },
        },
        closeIcon: {
            color: theme.color.fontColor,
            position: "absolute",
            top: 5,
            right: 10,
            fontSize: 16,
            ":hover": {
                cursor: "pointer",
                opacity: ".8",
            },
        },
    });
