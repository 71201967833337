import {
    FETCH_REQUEST_INFO,
    SET_REQUEST_INFO,
    FETCH_REQUEST_INFO_ITEM,
    SET_REQUEST_INFO_ITEM,
    ARCHIVE_REQUEST,
} from "constants/ActionTypes";

export const fetchRequestInfo = () => {
    return {
        type: FETCH_REQUEST_INFO,
        payload: {
            url: "/request/list/",
            data: {},
        },
    };
};

export const setRequestInfo = (data) => {
    return {
        type: SET_REQUEST_INFO,
        payload: data,
    };
};

export const fetchRequestInfoItem = (Id) => {
    return {
        type: FETCH_REQUEST_INFO_ITEM,
        payload: {
            url: "/request/byid/",
            data: {
                Id,
            },
        },
    };
};

export const archiveRequest = (data) => {
    return {
        type: ARCHIVE_REQUEST,
        payload: {
            url: "/request/archive/",
            data,
        },
    };
};

export const setRequestInfoItem = (data) => {
    return {
        type: SET_REQUEST_INFO_ITEM,
        payload: data,
    };
};
