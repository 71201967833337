import "@formatjs/intl-pluralrules/polyfill";
import "@formatjs/intl-pluralrules/locale-data/ar"; // Add locale data for ar

import "@formatjs/intl-relativetimeformat/polyfill";
import "@formatjs/intl-relativetimeformat/locale-data/ar"; // Add locale data for ar
import saMessages from "../locales/ar_SA.json";

const saLang = {
    messages: {
        ...saMessages,
    },
    locale: "ar-SA",
};

export default saLang;
