import { SET_FEEDBACK, SET_FEEDBACK_ITEM } from "constants/ActionTypes";

const INIT_STATE = {
    feedbackList: [],
    feedbackObject: {},
    feedbackFilterList: [
        {
            title: "comp.table.header.blank",
            width: "50px",
            active: false,
            sortable: false,
            sortTitle: "",
            order: false,
            mobile: false,
        },
        {
            title: "comp.table.header.createdBy",
            width: "20%",
            active: false,
            sortable: true,
            sortTitle: "CreatedByName",
            style: { textAlign: "left" },
            order: false,
            mobile: true,
        },
        {
            title: "form.companyName",
            width: "20%",
            active: false,
            sortable: true,
            sortTitle: "CompanyName",
            style: { textAlign: "left" },
            order: false,
            mobile: true,
        },
        {
            title: "comp.table.header.subject",
            width: "35%",
            active: false,
            sortable: true,
            sortTitle: "Subject",
            style: { textAlign: "left" },
            order: false,
            mobile: false,
        },
        {
            title: "comp.table.header.dateCreated",
            width: "25%",
            active: true,
            sortable: true,
            sortTitle: "CreatedDate",
            order: false,
            mobile: false,
            style: { textAlign: "right" },
        },
    ],
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_FEEDBACK: {
            return {
                ...state,
                feedbackList: action.payload,
            };
        }
        case SET_FEEDBACK_ITEM: {
            return {
                ...state,
                feedbackObject: action.payload,
            };
        }
        default:
            return state;
    }
};
