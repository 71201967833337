import "@formatjs/intl-pluralrules/polyfill";
import "@formatjs/intl-pluralrules/locale-data/es"; // Add locale data for es

import "@formatjs/intl-relativetimeformat/polyfill";
import "@formatjs/intl-relativetimeformat/locale-data/es"; // Add locale data for es
import esMessages from "../locales/es_ES.json";

const esLang = {
    messages: {
        ...esMessages,
    },
    locale: "es",
};
export default esLang;
