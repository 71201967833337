import { all } from "redux-saga/effects";
import authSagas from "./Auth";
import locksSagas from "./Locks";
import ticketsSagas from "./Tickets";
import usersSagas from "./Users";
import machinesSagas from "./Machines";
import clientSagas from "./Client";
import settingsSagas from "./Settings";
import helpSagas from "./Help";
import carsealsSagas from "./Carseals";
import feedbackSagas from "./Feedback";
import requestInfoSagas from "./RequestInfo";

export default function* rootSaga() {
    yield all([
        authSagas(),
        locksSagas(),
        ticketsSagas(),
        machinesSagas(),
        usersSagas(),
        clientSagas(),
        settingsSagas(),
        helpSagas(),
        carsealsSagas(),
        feedbackSagas(),
        requestInfoSagas(),
    ]);
}
