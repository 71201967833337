import React from "react";
import { connect } from "react-redux";
import { StyleSheet, css } from "aphrodite";
import { Input, Label, Row, Col } from "reactstrap";
import { injectIntl } from "react-intl";

import { setLoader } from "actions/App";
import { fetchSitesList } from "actions/Settings";
import {
    setMachineSearchTerms,
    searchMachines,
    fetchMachinesListSelect,
} from "actions/Machines";

import Tooltip from "components/Tooltips";
import CSV from "components/Export";

class SearchMachine extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchMachineTerms: props.searchMachineTerms,
        };
    }
    componentDidMount() {
        const { fetchSitesList, fetchMachinesListSelect } = this.props;
        fetchMachinesListSelect();
        fetchSitesList();
    }
    handleSearch = () => {
        const { searchMachines, setLoader, setMachineSearchTerms } = this.props;
        let { searchMachineTerms } = this.state;
        setMachineSearchTerms(searchMachineTerms);
        searchMachines(searchMachineTerms);
        setLoader(false);
    };
    keyPress = (e) => {
        if (e.keyCode === 13) {
            this.handleSearch();
        }
    };
    updateData = (e) => {
        let updateData = { ...this.state.searchMachineTerms };
        if (e.target.type === "checkbox") {
            if (e.target.checked) {
                updateData[e.target.name] = true;
                this.setState({
                    searchMachineTerms: updateData,
                    changesMade: true,
                });
            } else {
                updateData[e.target.name] = false;
                this.setState({
                    searchMachineTerms: updateData,
                    changesMade: true,
                });
            }
        } else {
            updateData[e.target.name] = e.target.value;
            this.setState({
                searchMachineTerms: updateData,
                changesMade: true,
            });
        }
    };
    clearField = (target) => {
        let updateData = { ...this.state.searchMachineTerms };
        updateData[target] = "";
        this.setState({ searchMachineTerms: updateData, changesMade: true });
    };
    clearFields = () => {
        const { setMachineSearchTerms } = this.props;
        let newSearchMachineTerms = {
            Name: "",
            SiteId: "",
            TemplateId: "",
            RequireLockConnect: "",
            RequireLockConnectAic: "",
            RequireAicOnTicket: "",
            RequirePointImage: "",
            LocksRequired: "",
            IsActive: "1",
            HasWorkOrder: "",
        };
        setMachineSearchTerms(newSearchMachineTerms);
        this.setState({ searchMachineTerms: newSearchMachineTerms });
        this.props.searchMachines(newSearchMachineTerms);
    };
    render() {
        const { intl, authUser, sitesList, width, searchedMachines, theme } =
            this.props;
        let { searchMachineTerms } = this.state;
        this.styles = styles(theme);
        let exportList = searchedMachines
            ? searchedMachines.map((a) => Object.assign({}, a))
            : [];
        exportList.map((item) => {
            delete item.Icon;
            delete item.Id;
            return item;
        });
        return (
            searchMachineTerms && (
                <div>
                    <div
                        className={css(this.styles.searchWrapper)}
                        onKeyDown={(e) => this.keyPress(e)}
                    >
                        <Col lg={12}>
                            {width < 992 && (
                                <Row>
                                    <Col
                                        lg={12}
                                        className={css(this.styles.flexRow)}
                                    >
                                        <div>
                                            <i
                                                id="searchSeal"
                                                className={`material-icons ${css(
                                                    this.styles.searchIcon
                                                )}`}
                                                onClick={() =>
                                                    this.handleSearch()
                                                }
                                            >
                                                search
                                            </i>
                                            <Tooltip
                                                message={"comp.search.default"}
                                                target="searchSeal"
                                            />
                                        </div>
                                        <div>
                                            <i
                                                id="resetSealSearch"
                                                className={`material-icons ${css(
                                                    this.styles.searchIcon
                                                )}`}
                                                onClick={() =>
                                                    this.clearFields()
                                                }
                                            >
                                                low_priority
                                            </i>
                                            <Tooltip
                                                message={
                                                    "comp.resetSearch.default"
                                                }
                                                target="resetSealSearch"
                                            />
                                        </div>
                                        <div
                                            className={`material-icons ${css(
                                                this.styles.searchIcon
                                            )}`}
                                            onClick={() => this.props.action()}
                                        >
                                            <i
                                                id="refreshTables"
                                                className={`material-icons ${css(
                                                    this.styles.icon
                                                )}`}
                                            >
                                                sync
                                            </i>
                                            <Tooltip
                                                message={"comp.button.refresh"}
                                                target="refreshTables"
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            )}
                            <Row>
                                <Col
                                    lg={10}
                                    className={css(this.styles.flexRow)}
                                >
                                    {width > 800 && (
                                        <Col
                                            lg={3}
                                            className={css(this.styles.flexRow)}
                                        >
                                            <label
                                                className={css(
                                                    this.styles.labelDropdown
                                                )}
                                            >
                                                {this.props.intl.formatMessage({
                                                    id: "form.name",
                                                })}
                                            </label>
                                            <Input
                                                name="Name"
                                                type="text"
                                                className={css(
                                                    this.styles.inputText
                                                )}
                                                onChange={(e) =>
                                                    this.updateData(e)
                                                }
                                                value={searchMachineTerms.Name}
                                            />
                                            {searchMachineTerms.Name !== "" && (
                                                <i
                                                    className={`material-icons ${css(
                                                        this.styles.closeIcon
                                                    )}`}
                                                    onClick={() =>
                                                        this.clearField("Name")
                                                    }
                                                >
                                                    close
                                                </i>
                                            )}
                                        </Col>
                                    )}
                                    {width > 800 && (
                                        <Col
                                            lg={3}
                                            className={css(this.styles.flexRow)}
                                        >
                                            <label
                                                className={css(
                                                    this.styles.labelDropdown
                                                )}
                                            >
                                                {this.props.intl.formatMessage({
                                                    id: "comp.sites.Header",
                                                })}
                                            </label>
                                            <Input
                                                className={css(
                                                    this.styles.input
                                                )}
                                                name="SiteId"
                                                type="select"
                                                placeholder={intl.formatMessage(
                                                    { id: "form.selectSite" }
                                                )}
                                                onChange={this.updateData}
                                                value={
                                                    searchMachineTerms.SiteId ||
                                                    ""
                                                }
                                            >
                                                <option value={""}>
                                                    {intl.formatMessage({
                                                        id: "form.all",
                                                    })}
                                                </option>
                                                {sitesList.map((site) => (
                                                    <option
                                                        key={"site" + site.Id}
                                                        value={site.Id || ""}
                                                    >
                                                        {site.SiteName}
                                                    </option>
                                                ))}
                                            </Input>
                                        </Col>
                                    )}
                                    {authUser.Settings.Product ==
                                        "isolation" && (
                                            <Col
                                                lg={3}
                                                className={css(
                                                    this.styles.flexRow
                                                )}
                                            >
                                                <Label
                                                    className={css(
                                                        this.styles
                                                            .labelDropdown
                                                    )}
                                                >
                                                    {intl.formatMessage({
                                                        id: "pages.dashboard.openTickets",
                                                    })}
                                                </Label>
                                                <Input
                                                    className={css(
                                                        this.styles.input
                                                    )}
                                                    name="HasWorkOrder"
                                                    type="select"
                                                    onChange={this.updateData}
                                                    value={
                                                        searchMachineTerms.HasWorkOrder ||
                                                        ""
                                                    }
                                                >
                                                    <option value={""}>
                                                        {intl.formatMessage({
                                                            id: "form.all",
                                                        })}
                                                    </option>
                                                    <option value={"1"}>
                                                        {intl.formatMessage({
                                                            id: "form.true",
                                                        })}
                                                    </option>
                                                    <option value={"0"}>
                                                        {intl.formatMessage({
                                                            id: "form.false",
                                                        })}
                                                    </option>
                                                </Input>
                                            </Col>
                                        )}
                                    {width > 800 && (
                                        <Col
                                            lg={3}
                                            className={css(this.styles.flexRow)}
                                        >
                                            <label
                                                className={css(
                                                    this.styles.labelDropdown
                                                )}
                                            >
                                                {this.props.intl.formatMessage({
                                                    id: "form.active",
                                                })}
                                            </label>
                                            <Input
                                                className={css(
                                                    this.styles.input
                                                )}
                                                name="IsActive"
                                                type="select"
                                                placeholder={intl.formatMessage(
                                                    { id: "form.select" }
                                                )}
                                                onChange={this.updateData}
                                                value={
                                                    searchMachineTerms.IsActive ||
                                                    ""
                                                }
                                            >
                                                <option value={""}>
                                                    {intl.formatMessage({
                                                        id: "comp.button.all",
                                                    })}
                                                </option>
                                                <option value={"1"}>
                                                    {intl.formatMessage({
                                                        id: "comp.button.active",
                                                    })}
                                                </option>
                                                <option value={"0"}>
                                                    {intl.formatMessage({
                                                        id: "comp.button.inactive",
                                                    })}
                                                </option>
                                            </Input>
                                        </Col>
                                    )}
                                </Col>
                                {width > 800 && (
                                    <Col lg={2}>
                                        {width > 991 && (
                                            <Row>
                                                <Col
                                                    lg={12}
                                                    className={css(
                                                        this.styles.flexRow,
                                                        this.styles.rightAligned
                                                    )}
                                                >
                                                    <div>
                                                        <i
                                                            id="searchSeal"
                                                            className={`material-icons ${css(
                                                                this.styles
                                                                    .searchIcon
                                                            )}`}
                                                            onClick={() =>
                                                                this.handleSearch()
                                                            }
                                                        >
                                                            search
                                                        </i>
                                                        <Tooltip
                                                            message={
                                                                "comp.search.default"
                                                            }
                                                            target="searchSeal"
                                                        />
                                                    </div>
                                                    <div>
                                                        <i
                                                            id="resetSealSearch"
                                                            className={`material-icons ${css(
                                                                this.styles
                                                                    .searchIcon
                                                            )}`}
                                                            onClick={() =>
                                                                this.clearFields()
                                                            }
                                                        >
                                                            low_priority
                                                        </i>
                                                        <Tooltip
                                                            message={
                                                                "comp.resetSearch.default"
                                                            }
                                                            target="resetSealSearch"
                                                        />
                                                    </div>
                                                    <div
                                                        className={`material-icons ${css(
                                                            this.styles
                                                                .searchIcon
                                                        )}`}
                                                        onClick={() =>
                                                            this.props.action()
                                                        }
                                                    >
                                                        <i
                                                            id="refreshTables"
                                                            className={`material-icons ${css(
                                                                this.styles.icon
                                                            )}`}
                                                        >
                                                            sync
                                                        </i>
                                                        <Tooltip
                                                            message={
                                                                "comp.button.refresh"
                                                            }
                                                            target="refreshTables"
                                                        />
                                                    </div>
                                                    {authUser.Permissions.includes(
                                                        "ExportMachineList"
                                                    ) && (
                                                        <div>
                                                            <CSV
                                                                data={
                                                                    exportList
                                                                }
                                                                margin={
                                                                    "0 12px 0 0"
                                                                }
                                                            />
                                                            <Tooltip
                                                                message={
                                                                    "comp.button.export"
                                                                }
                                                                target="exportFile"
                                                            />
                                                        </div>
                                                    )}
                                                </Col>
                                            </Row>
                                        )}
                                    </Col>
                                )}
                            </Row>
                        </Col>
                    </div>
                </div>
            )
        );
    }
}

SearchMachine.defaultProps = {
    searchTitle: "blank",
    searchType: ["name"],
    data: [],
};

const mapStateToProps = ({ app, auth, settings, machines }) => {
    const { theme, width } = app;
    const { authUser } = auth;
    const { sitesList } = settings;
    const { searchMachineTerms, searchedMachines } = machines;
    return {
        theme,
        width,
        authUser,
        searchMachineTerms,
        searchedMachines,
        sitesList,
        searchMachineTerms,
    };
};

export default injectIntl(
    connect(mapStateToProps, {
        searchMachines,
        setLoader,
        fetchSitesList,
        fetchMachinesListSelect,
        setMachineSearchTerms,
    })(SearchMachine)
);

const styles = (theme) =>
    StyleSheet.create({
        advancedSection: {
            position: "relative",
        },
        flexRow: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            height: "2.25rem",
            paddingLeft: 0,
        },
        rightAligned: {
            paddingRight: 0,
            justifyContent: "flex-end",
        },
        input: {
            border: "none",
            boxShadow: "none",
            borderRadius: 0,
            fontWeight: 400,
            fontSize: 12,
            letterSpacing: 2,
            marginBottom: 0,
            color: theme.color.themeColor,
            border: "1px solid white",
            backgroundColor: theme.color.compColor,
            "::placeholder": {
                letterSpacing: 1,
                color: theme.color.fontColor,
                fontSize: 12,
            },
        },
        inputText: {
            border: "none",
            boxShadow: "none",
            borderRadius: 0,
            fontWeight: 400,
            fontSize: 12,
            letterSpacing: 2,
            marginBottom: 0,
            padding: 0,
            width: "100%",
            marginLeft: 5,
            color: theme.color.themeColor,
            borderBottom: `solid whitesmoke 1px`,
            backgroundColor: theme.color.compColor,
            "::placeholder": {
                letterSpacing: 1,
                color: theme.color.themeColor,
                fontSize: 12,
            },
        },
        searchWrapper: {
            position: "relative",
            display: "flex",
            flexDirection: "row",
        },
        searchIcon: {
            color: theme.color.themeColor,
            marginRight: 12,
            ":hover": {
                cursor: "pointer",
                opacity: ".8",
            },
        },
        closeIcon: {
            color: theme.color.fontColor,
            position: "absolute",
            top: 5,
            right: 5,
            fontSize: 16,
            ":hover": {
                cursor: "pointer",
                opacity: ".8",
            },
        },
        labelDropdown: {
            fontSize: 12,
            color: theme.color.fontColor,
            whiteSpace: "nowrap",
        },
    });
