import { all, call, fork, put, takeEvery, select } from "redux-saga/effects";
import { history } from "../store/history";
import { ADMIN_API } from "../api";
import { validMail, sortList } from "util";
import {
    FETCH_CLIENT_LIST,
    FETCH_CLIENT_LOGIN_LIST,
    FETCH_CLIENT_BY_ID,
    CREATE_CLIENT,
    CREATE_CLIENT_NOTE,
    EDIT_CLIENT,
    DELETE_CLIENT,
    CREATE_LOCK_API,
} from "constants/ActionTypes";
import {
    fetchClientById,
    setClientLoginList,
    setClientList,
    setClientObject,
} from "actions/Client";
import { userSignOut } from "actions/Auth";
import { showMessage, setLoader } from "actions/App";

const getHeaders = (state) => state.client.clientFilterList;

function* requestClientList({ payload }) {
    try {
        const clients = yield call(ADMIN_API, payload.url, payload.data);
        if (clients.message) {
            if (clients.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(selectClientList.message, "message"));
            }
        } else if (!clients.data) {
            yield put(setClientList([]));
        } else {
            if (payload.param === "active") {
                clients.data = clients.data.filter(
                    (client) => client.Users > 0
                );
            }
            clients.data.map((client) => {
                client.image = "assets/images/Icons/user/userIconDefault.png";
                client.name = client.CompanyName;
                client.description = client.AccountManager;
            });
            yield put(setLoader(false));
            const headers = yield select(getHeaders);
            const activeHeader = headers.find((item) => item.active);
            clients.data = sortList(
                activeHeader.order,
                clients.data,
                activeHeader.sortTitle
            );
            yield put(setClientList(clients.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestClientLoginList({ payload }) {
    try {
        const logins = yield call(ADMIN_API, payload.url, payload.data);
        if (logins.message) {
            if (logins.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(selectClientList.message, "message"));
            }
        } else if (!logins.data || logins.data === "") {
            yield put(setClientLoginList([]));
        } else {
            yield put(setClientLoginList(logins.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestClientObject({ payload }) {
    try {
        const client = yield call(ADMIN_API, payload.url, payload.data);
        if (client.message) {
            if (client.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(client.message, "message"));
                yield history.push("/app/client/all");
            }
        } else if (client.data === "") {
            yield put(showMessage("notifications.error.fail.noClient"));
            yield history.push("/app/client/all");
        } else {
            client.data.image = "assets/images/Icons/user/userIconDefault.png";
            client.data.Notes = !client.data.Notes
                ? []
                : client.data.Notes.sort(function (a, b) {
                      return (
                          b.CreatedDate.length - a.CreatedDate.length ||
                          b.CreatedDate.localeCompare(a.CreatedDate)
                      );
                  });
            yield put(setClientObject(client.data));
        }
    } catch (error) {
        console.warn(error);
        yield history.push("/app/client/all");
    }
}

function* requestCreateClient({ payload }) {
    try {
        const required = {
            CompanyName: payload.data.CompanyName,
            Description: payload.data.Description,
            FirstName: payload.data.FirstName,
            LastName: payload.data.LastName,
            Title: payload.data.Title,
            Email: payload.data.Email,
            PhoneNumber: payload.data.PhoneNumber,
            Address1: payload.data.Address1,
            City: payload.data.City,
            Region: payload.data.Region,
            PostalCode: payload.data.PostalCode,
            Country: payload.data.Country,
            AccountManager: payload.data.AccountManager,
        };
        for (var key in required) {
            if (required[key] === undefined || required[key] === "") {
                yield put(
                    showMessage(`notifications.error.blank.${key}`, "warning")
                );
                return;
            }
        }
        if (!validMail(payload.data.Email)) {
            yield put(showMessage("notifications.error.fail.email", "warning"));
            return;
        }
        const client = yield call(ADMIN_API, payload.url, payload.data);
        if (client.message) {
            if (client.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(client.message, "message"));
            }
        } else if (client.validationError) {
            var error = Object.keys(client.validationError).map(function (key) {
                return client.validationError[key];
            });
            yield put(showMessage(error, "message"));
        } else {
            yield put(showMessage("notifications.success.add", "success"));
            yield history.push("/app/client/all");
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestCreateClientNote({ payload }) {
    try {
        const required = {
            Note: payload.data.Note,
        };
        for (var key in required) {
            if (required[key] === undefined || required[key] === "") {
                yield put(
                    showMessage(`notifications.error.blank.${key}`, "warning")
                );
                return;
            }
        }
        const client = yield call(ADMIN_API, payload.url, payload.data);
        if (client.message) {
            if (client.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(client.message, "message"));
            }
        } else if (client.validationError) {
            var error = Object.keys(client.validationError).map(function (key) {
                return client.validationError[key];
            });
            yield put(showMessage(error, "message"));
        } else {
            yield put(showMessage("notifications.success.add", "success"));
            yield put(fetchClientById(payload.data.Id));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestEditClient({ payload }) {
    try {
        const required = {
            CompanyName: payload.data.CompanyName,
            Description: payload.data.Description,
            FirstName: payload.data.FirstName,
            LastName: payload.data.LastName,
            Title: payload.data.Title,
            Email: payload.data.Email,
            PhoneNumber: payload.data.PhoneNumber,
            Address1: payload.data.Address1,
            City: payload.data.City,
            Region: payload.data.Region,
            PostalCode: payload.data.PostalCode,
            Country: payload.data.Country,
            AccountManager: payload.data.AccountManager,
        };
        for (var key in required) {
            if (required[key] === undefined || required[key] === "") {
                yield put(
                    showMessage(`notifications.error.blank.${key}`, "warning")
                );
                return;
            }
        }
        if (!validMail(payload.data.Email)) {
            yield put(showMessage("notifications.error.fail.email", "warning"));
            return;
        }
        const client = yield call(ADMIN_API, payload.url, payload.data);
        if (client.message) {
            if (client.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(client.message, "message"));
            }
        } else {
            yield put(showMessage("notifications.success.edit", "success"));
            yield history.push("/app/client/all");
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestDeleteClient({ payload }) {
    try {
        if (!payload.data) {
            yield put(showMessage(`notifications.error.permission.deny`));
            return;
        }
        const client = yield call(ADMIN_API, payload.url, payload.data);
        if (client.message) {
            if (client.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(client.message, "message"));
            }
        } else {
            yield put(showMessage("notifications.success.delete", "success"));
            yield history.push("/app/client/all");
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestCreateLockApi({ payload }) {
    try {
        const required = {
            SerialNumber: payload.data.SerialNumber,
            MacAddress: payload.data.MacAddress,
        };
        for (var key in required) {
            if (required[key] === undefined || required[key] === "") {
                yield put(
                    showMessage(`notifications.error.blank.${key}`, "warning")
                );
                return;
            }
        }
        const lock = yield call(ADMIN_API, payload.url, payload.data);
        if (lock.message) {
            if (lock.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(lock.message, "message"));
            }
        } else if (lock.validationError) {
            var error = Object.keys(lock.validationError).map(function (key) {
                return lock.validationError[key];
            });
            yield put(showMessage(error, "message"));
        } else {
            yield put(showMessage("notifications.success.add", "success"));
            yield history.goBack();
        }
    } catch (error) {
        console.warn(error);
    }
}

export function* selectClientList() {
    yield takeEvery(FETCH_CLIENT_LIST, requestClientList);
}

export function* selectClientLoginList() {
    yield takeEvery(FETCH_CLIENT_LOGIN_LIST, requestClientLoginList);
}

export function* selectClientById() {
    yield takeEvery(FETCH_CLIENT_BY_ID, requestClientObject);
}

export function* createClient() {
    yield takeEvery(CREATE_CLIENT, requestCreateClient);
}

export function* createClientNote() {
    yield takeEvery(CREATE_CLIENT_NOTE, requestCreateClientNote);
}

export function* editClient() {
    yield takeEvery(EDIT_CLIENT, requestEditClient);
}

export function* deleteClient() {
    yield takeEvery(DELETE_CLIENT, requestDeleteClient);
}

export function* createLockApi() {
    yield takeEvery(CREATE_LOCK_API, requestCreateLockApi);
}

export default function* rootSaga() {
    yield all([
        fork(selectClientList),
        fork(selectClientLoginList),
        fork(selectClientById),
        fork(createClient),
        fork(createClientNote),
        fork(editClient),
        fork(deleteClient),
        fork(createLockApi),
    ]);
}
