import React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { StyleSheet, css } from "aphrodite";
import { injectIntl } from "react-intl";

class MenuAdd extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { intl, authUser, toggle, theme } = this.props;
        this.styles = styles(theme);
        return (
            <ul className="list-unstyled">
                <Link to={"/app/tickets/create"} onClick={toggle}>
                    <li className={css(this.styles.addList)}>
                        <p className={css(this.styles.addTitle)}>
                            {" "}
                            {intl.formatMessage({ id: "comp.tickets.Header" })}
                        </p>
                        <div className={css(this.styles.addArrow)}>
                            <i className="material-icons">arrow_forward_ios</i>
                        </div>
                    </li>
                </Link>
                {authUser.Settings.Product === "isolation" && (
                    <Link to={"/app/machines/create"} onClick={toggle}>
                        <li className={css(this.styles.addList)}>
                            <p className={css(this.styles.addTitle)}>
                                {" "}
                                {intl.formatMessage({
                                    id: "pages.machines.Type",
                                })}
                            </p>
                            <div className={css(this.styles.addArrow)}>
                                <i className="material-icons">
                                    arrow_forward_ios
                                </i>
                            </div>
                        </li>
                    </Link>
                )}
                <Link to={"/app/locks/create"} onClick={toggle}>
                    <li className={css(this.styles.addList)}>
                        <p className={css(this.styles.addTitle)}>
                            {" "}
                            {intl.formatMessage({ id: "pages.locks.Type" })}
                        </p>
                        <div className={css(this.styles.addArrow)}>
                            <i className="material-icons">arrow_forward_ios</i>
                        </div>
                    </li>
                </Link>
                {authUser.Permissions.includes("AdminUserCreate") && (
                    <Link to={"/app/users/create"} onClick={toggle}>
                        <li className={css(this.styles.addList)}>
                            <p className={css(this.styles.addTitle)}>
                                {" "}
                                {intl.formatMessage({ id: "pages.users.Type" })}
                            </p>
                            <div className={css(this.styles.addArrow)}>
                                <i className="material-icons">
                                    arrow_forward_ios
                                </i>
                            </div>
                        </li>
                    </Link>
                )}
            </ul>
        );
    }
}

const mapStateToProps = ({ app, auth }) => {
    const { authUser } = auth;
    const { theme } = app;
    return { theme, authUser };
};

export default injectIntl(withRouter(connect(mapStateToProps)(MenuAdd)));

const styles = (theme) =>
    StyleSheet.create({
        addList: {
            borderBottom: `solid 1px ${theme.color.borderColor}`,
            width: "100%",
            color: theme.color.themeColor,
            paddingTop: 10,
            ":hover": {
                textDecoration: "none",
                opacity: ".8",
                cursor: "pointer",
            },
        },
        addTitle: {
            float: "left",
            margin: 0,
            padding: "10px 0 1px 0",
            fontWeight: 400,
            fontFamily: "Roboto",
            fontSize: 16,
        },
        addArrow: {
            display: "block",
            float: "right",
            padding: 8,
        },
    });
