import {
    FETCH_SETTINGS,
    FETCH_ROLES_LIST,
    FETCH_ROLE,
    FETCH_PERMISSIONS_LIST,
    CREATE_ROLE,
    EDIT_SETTINGS,
    EDIT_ROLE,
    DELETE_ROLE,
    SET_SETTINGS,
    SET_ROLES_LIST,
    SET_ROLE,
    SET_PERMISSIONS_LIST,
    FETCH_ISOLATION_EQUIPMENT_LIST,
    SET_ISOLATION_EQUIPMENT_LIST,
    SET_ISOLATION_EQUIPMENT_FILTER,
    FETCH_ISOLATION_HAZARDS_LIST,
    SET_ISOLATION_HAZARDS_LIST,
    SET_ISOLATION_HAZARDS_FILTER,
    FETCH_SITES_LIST,
    SET_SITES_LIST,
    SET_SITES_FILTER,
    FETCH_SITE_BY_ID,
    SET_SITE,
    SET_ACTIVE_SETTING,
    CREATE_SITE,
    EDIT_SITE,
    SET_ACTIVE_ROLE,
    SET_ACTIVE_APP_SETTING,
    SET_ACTIVE_PERSONAL_SETTING,
    FETCH_TERMS,
    SET_TERMS,
    UPDATE_TERMS,
    CREATE_EQUIPMENT,
    UPLOAD_IMAGE,
    CREATE_HAZARD,
    FETCH_CLASSIFICATIONS_LIST,
    SET_CLASSIFICATIONS_LIST,
    CREATE_CLASSIFICATION,
    FETCH_PERMITS_LIST,
    SET_PERMITS_LIST,
    CREATE_PERMIT,
    SET_CLASSIFICATION_FILTER,
    SET_PERMIT_FILTER,
    FETCH_CLASSIFICATION_BY_ID,
    SET_CLASSIFICATION,
    FETCH_PERMIT_BY_ID,
    SET_PERMIT,
    FETCH_EQUIPMENT_BY_ID,
    SET_EQUIPMENT,
    EDIT_CLASSIFICATION,
    EDIT_PERMIT,
    EDIT_EQUIPMENT,
    FETCH_CLASSIFICATIONS_LIST_SELECT,
    SET_CLASSIFICATIONS_LIST_SELECT,
    FETCH_HAZARD_BY_ID,
    SET_HAZARD,
    SET_HAZARD_INACTIVE,
    SET_EQUIPMENT_INACTIVE,
    SET_CLASSIFICATION_INACTIVE,
    SET_PERMIT_INACTIVE,
    SET_SITE_INACTIVE,
    EDIT_HAZARD,
} from "constants/ActionTypes";

export const fetchSettings = () => {
    return {
        type: FETCH_SETTINGS,
        payload: {
            url: "/",
            data: {},
        },
    };
};

export const fetchRolesList = () => {
    return {
        type: FETCH_ROLES_LIST,
        payload: {
            url: "/admin/role/",
            data: {},
        },
    };
};

export const fetchRole = (Id) => {
    return {
        type: FETCH_ROLE,
        payload: {
            url: "/admin/role/byid/",
            data: { Id },
        },
    };
};

export const fetchPermissionsList = () => {
    return {
        type: FETCH_PERMISSIONS_LIST,
        payload: {
            url: "/admin/permission/",
            data: {},
        },
    };
};

export const createRole = (data, another) => {
    return {
        type: CREATE_ROLE,
        payload: {
            url: "/admin/role/create/",
            data,
            another,
        },
    };
};

export const editSettings = (data) => {
    return {
        type: EDIT_SETTINGS,
        payload: {
            url: "/admin/setting/update/",
            data,
        },
    };
};

export const editRole = (data) => {
    return {
        type: EDIT_ROLE,
        payload: {
            url: "/admin/role/update/",
            data,
        },
    };
};

export const deleteRole = (Id) => {
    return {
        type: DELETE_ROLE,
        payload: {
            url: "/admin/role/delete/",
            data: { Id },
        },
    };
};

export const setSettings = (data) => {
    return {
        type: SET_SETTINGS,
        payload: data,
    };
};

export const setRolesList = (data) => {
    return {
        type: SET_ROLES_LIST,
        payload: data,
    };
};

export const setRole = (data) => {
    return {
        type: SET_ROLE,
        payload: data,
    };
};

export const setPermissionsList = (data) => {
    return {
        type: SET_PERMISSIONS_LIST,
        payload: data,
    };
};

export const fetchIsolationEquipmentList = () => {
    return {
        type: FETCH_ISOLATION_EQUIPMENT_LIST,
        payload: {
            url: "/type/equipment/list/",
            data: {},
        },
    };
};

export const setIsolationEquipmentList = (data) => {
    return {
        type: SET_ISOLATION_EQUIPMENT_LIST,
        payload: data,
    };
};

export const setIsolationEquipmentFilter = (data) => {
    return {
        type: SET_ISOLATION_EQUIPMENT_FILTER,
        payload: data,
    };
};

export const fetchIsolationHazardsList = () => {
    return {
        type: FETCH_ISOLATION_HAZARDS_LIST,
        payload: {
            url: "/type/hazard/list/",
            data: {},
        },
    };
};

export const setIsolationHazardsList = (data) => {
    return {
        type: SET_ISOLATION_HAZARDS_LIST,
        payload: data,
    };
};

export const setIsolationHazardsFilter = (data) => {
    return {
        type: SET_ISOLATION_HAZARDS_FILTER,
        payload: data,
    };
};

export const fetchSitesList = () => {
    return {
        type: FETCH_SITES_LIST,
        payload: {
            url: "/type/site/list/",
            data: {},
        },
    };
};

export const fetchActiveSitesList = () => {
    return {
        type: FETCH_SITES_LIST,
        payload: {
            url: "/type/site/list/select/",
            data: {},
        },
    };
};

export const fetchSiteById = (Id) => {
    return {
        type: FETCH_SITE_BY_ID,
        payload: {
            url: "/type/site/byid/",
            data: {
                Id,
            },
        },
    };
};

export const setSitesList = (data) => {
    return {
        type: SET_SITES_LIST,
        payload: data,
    };
};

export const setSitesFilter = (data) => {
    return {
        type: SET_SITES_FILTER,
        payload: data,
    };
};

export const setSite = (data) => {
    return {
        type: SET_SITE,
        payload: data,
    };
};

export const setActiveSetting = (data) => {
    return {
        type: SET_ACTIVE_SETTING,
        payload: data,
    };
};

export const createSite = (data, another) => {
    return {
        type: CREATE_SITE,
        payload: {
            url: "/type/site/create/",
            data,
            another,
        },
    };
};

export const editSite = (data) => {
    return {
        type: EDIT_SITE,
        payload: {
            url: "/type/site/update/",
            data,
        },
    };
};

export const setActiveRole = (data) => {
    return {
        type: SET_ACTIVE_ROLE,
        payload: data,
    };
};

export const setActiveAppSetting = (data) => {
    return {
        type: SET_ACTIVE_APP_SETTING,
        payload: data,
    };
};

export const setActivePersonalSetting = (data) => {
    return {
        type: SET_ACTIVE_PERSONAL_SETTING,
        payload: data,
    };
};

export const fetchTerms = () => {
    return {
        type: FETCH_TERMS,
        payload: {
            url: "/admin/term/list/",
            data: {},
        },
    };
};

export const setTerms = (data) => {
    return {
        type: SET_TERMS,
        payload: data,
    };
};

export const updateTerms = (data) => {
    return {
        type: UPDATE_TERMS,
        payload: {
            url: "/admin/term/update/",
            data: data,
        },
    };
};

export const createEquipment = (data, another) => {
    return {
        type: CREATE_EQUIPMENT,
        payload: {
            url: "/type/equipment/create/",
            data,
            another,
        },
    };
};

export const uploadImage = (data) => {
    return {
        type: UPLOAD_IMAGE,
        payload: {
            url: "/image/create/",
            data,
        },
    };
};

export const createHazard = (data, another) => {
    return {
        type: CREATE_HAZARD,
        payload: {
            url: "/type/hazard/create/",
            data,
            another,
        },
    };
};

export const fetchClassificationsList = () => {
    return {
        type: FETCH_CLASSIFICATIONS_LIST,
        payload: {
            url: "/type/classification/list/",
            data: {},
        },
    };
};

export const setClassificationsList = (data) => {
    return {
        type: SET_CLASSIFICATIONS_LIST,
        payload: data,
    };
};

export const createClassification = (data, another) => {
    return {
        type: CREATE_CLASSIFICATION,
        payload: {
            url: "/type/classification/create/",
            data,
            another,
        },
    };
};

export const fetchPermitsList = () => {
    return {
        type: FETCH_PERMITS_LIST,
        payload: {
            url: "/type/permit/list/",
            data: {},
        },
    };
};

export const setPermitsList = (data) => {
    return {
        type: SET_PERMITS_LIST,
        payload: data,
    };
};

export const createPermit = (data, another) => {
    return {
        type: CREATE_PERMIT,
        payload: {
            url: "/type/permit/create/",
            data,
            another,
        },
    };
};

export const setClassificationFilter = (data) => {
    return {
        type: SET_CLASSIFICATION_FILTER,
        payload: data,
    };
};

export const setPermitFilter = (data) => {
    return {
        type: SET_PERMIT_FILTER,
        payload: data,
    };
};

export const fetchClassificationById = (Id) => {
    return {
        type: FETCH_CLASSIFICATION_BY_ID,
        payload: {
            url: "/type/classification/byid/",
            data: {
                Id,
            },
        },
    };
};

export const setClassification = (data) => {
    return {
        type: SET_CLASSIFICATION,
        payload: data,
    };
};

export const fetchPermitById = (Id) => {
    return {
        type: FETCH_PERMIT_BY_ID,
        payload: {
            url: "/type/permit/byid/",
            data: {
                Id,
            },
        },
    };
};

export const setPermit = (data) => {
    return {
        type: SET_PERMIT,
        payload: data,
    };
};

export const fetchEquipmentById = (Id) => {
    return {
        type: FETCH_EQUIPMENT_BY_ID,
        payload: {
            url: "/type/equipment/byid/",
            data: {
                Id,
            },
        },
    };
};

export const setEquipment = (data) => {
    return {
        type: SET_EQUIPMENT,
        payload: data,
    };
};

export const fetchHazardById = (Id) => {
    return {
        type: FETCH_HAZARD_BY_ID,
        payload: {
            url: "/type/hazard/byid/",
            data: {
                Id,
            },
        },
    };
};

export const setHazard = (data) => {
    return {
        type: SET_HAZARD,
        payload: data,
    };
};

export const setHazardInactive = (data) => {
    return {
        type: SET_HAZARD_INACTIVE,
        payload: {
            url: "/type/hazard/set/isactive/",
            data,
        },
    };
};

export const setEquipmentInactive = (data) => {
    return {
        type: SET_EQUIPMENT_INACTIVE,
        payload: {
            url: "/type/equipment/set/isactive/",
            data,
        },
    };
};

export const setClassificationInactive = (data) => {
    return {
        type: SET_CLASSIFICATION_INACTIVE,
        payload: {
            url: "/type/classification/set/isactive/",
            data,
        },
    };
};

export const setPermitInactive = (data) => {
    return {
        type: SET_PERMIT_INACTIVE,
        payload: {
            url: "/type/permit/set/isactive/",
            data,
        },
    };
};

export const setSiteInactive = (data) => {
    return {
        type: SET_SITE_INACTIVE,
        payload: {
            url: "/type/site/set/isactive/",
            data,
        },
    };
};

export const editClassification = (data) => {
    return {
        type: EDIT_CLASSIFICATION,
        payload: {
            url: "/type/classification/update/",
            data,
        },
    };
};

export const editPermit = (data) => {
    return {
        type: EDIT_PERMIT,
        payload: {
            url: "/type/permit/update/",
            data,
        },
    };
};

export const editEquipment = (data) => {
    return {
        type: EDIT_EQUIPMENT,
        payload: {
            url: "/type/equipment/update/",
            data,
        },
    };
};

export const editHazard = (data) => {
    return {
        type: EDIT_HAZARD,
        payload: {
            url: "/type/hazard/update/",
            data,
        },
    };
};

export const fetchClassificationsListSelect = () => {
    return {
        type: FETCH_CLASSIFICATIONS_LIST_SELECT,
        payload: {
            url: "/type/classification/list/select/",
            data: {},
        },
    };
};

export const setClassificationsListSelect = (data) => {
    return {
        type: SET_CLASSIFICATIONS_LIST_SELECT,
        payload: data,
    };
};
