import {
    FETCH_TEAMS,
    SET_TEAMS,
    FETCH_TEAMS_SELECT,
    SET_TEAMS_SELECT,
    FETCH_UNITS,
    SET_UNITS,
    FETCH_UNITS_SELECT,
    SET_UNITS_SELECT,
    CREATE_TEAM,
    CREATE_UNIT,
    FETCH_SEALS,
    SET_SEALS,
    FETCH_SEAL_BY_ID,
    SET_SEAL,
    FETCH_SITES_SEALS,
    SET_SITES_SEALS,
    FETCH_POSITIONS,
    SET_POSITIONS,
    CREATE_POSITION,
    FETCH_SEAL_SNAPSHOT,
    SET_SEAL_SNAPSHOT,
    CREATE_SEAL,
    FETCH_TEAM_BY_ID,
    SET_TEAM,
    FETCH_UNIT_BY_ID,
    SET_UNIT,
    FETCH_POSITION_BY_ID,
    SET_POSITION,
    EDIT_TEAM,
    EDIT_UNIT,
    EDIT_POSITION,
    FETCH_SEALS_ACTIVITY,
    SET_SEALS_ACTIVITY,
    SET_SEAL_ACTIVITY_FILTER,
    SET_ACTIVE_SEAL_TAB,
    TEAM_MEMBER_ADD,
    TEAM_MEMBER_REMOVE,
    FETCH_SEAL_ACTIVITY,
    SET_SEAL_ACTIVITY,
    EDIT_SEAL,
    SEARCH_SEALS,
    SET_SEARCHED_SEALS,
    SET_SEAL_SEARCH_TERMS,
    SEARCH_SEAL_AUDIT,
    SET_SEAL_AUDIT_LIST,
    SET_AUDIT_SEARCH_TERMS,
    SET_SNAPSHOT_SEARCH_TERMS,
    SET_SEAL_FILTER,
    FETCH_POSITIONS_SELECT,
    SET_POSITIONS_SELECT,
    FETCH_SEAL_SEARCH_SELECT,
    SET_SEAL_SEARCH_USERS,
    SET_SEAL_SEARCH_POSITIONS,
    FETCH_SEAL_ACTIVITY_SEARCH_SELECT,
    SET_SEAL_ACTIVITY_SEARCH_ACTIVITY_TYPES,
    SEARCH_SEAL_ACTIVITY,
    SET_SEAL_ACTIVITY_SEARCH_LIST,
    SET_SEAL_ACTIVITY_SEARCH_TERMS,
    SET_SEAL_SEARCH_SEALS,
    SET_SEAL_SEARCH_DEVICES,
    FETCH_SEAL_SEARCH_DEVICES,
    FETCH_SEAL_DEVICE_BY_ID,
    SET_SEAL_LOCK,
    DELETE_SEAL_LOCK,
    CREATE_SEAL_LOCK,
    EDIT_SEAL_LOCK,
} from "constants/ActionTypes";

export const fetchTeams = () => {
    return {
        type: FETCH_TEAMS,
        payload: {
            url: "/isolation/seal/team/list/",
            data: {},
        },
    };
};

export const setTeams = (data) => {
    return {
        type: SET_TEAMS,
        payload: data,
    };
};

export const fetchTeamsSelect = () => {
    return {
        type: FETCH_TEAMS_SELECT,
        payload: {
            url: "/isolation/seal/team/list/select/",
            data: {},
        },
    };
};

export const setTeamsSelect = (data) => {
    return {
        type: SET_TEAMS_SELECT,
        payload: data,
    };
};

export const fetchUnits = () => {
    return {
        type: FETCH_UNITS,
        payload: {
            url: "/isolation/seal/unit/list/",
            data: {},
        },
    };
};

export const setUnits = (data) => {
    return {
        type: SET_UNITS,
        payload: data,
    };
};

export const fetchUnitsSelect = () => {
    return {
        type: FETCH_UNITS_SELECT,
        payload: {
            url: "/isolation/seal/unit/list/select/",
            data: {},
        },
    };
};

export const setUnitsSelect = (data) => {
    return {
        type: SET_UNITS_SELECT,
        payload: data,
    };
};

export const createTeam = (data) => {
    return {
        type: CREATE_TEAM,
        payload: {
            url: "/isolation/seal/team/create/",
            data,
        },
    };
};

export const createUnit = (data) => {
    return {
        type: CREATE_UNIT,
        payload: {
            url: "/isolation/seal/unit/create/",
            data,
        },
    };
};

export const fetchSeals = (Id) => {
    return {
        type: FETCH_SEALS,
        payload: {
            url: "/isolation/seal/list/site/",
            data: {
                Id,
            },
        },
    };
};

export const setSeals = (data) => {
    return {
        type: SET_SEALS,
        payload: data,
    };
};

export const fetchSealById = (Id) => {
    return {
        type: FETCH_SEAL_BY_ID,
        payload: {
            url: "/isolation/seal/byid/",
            data: {
                Id,
            },
        },
    };
};

export const setSeal = (data) => {
    return {
        type: SET_SEAL,
        payload: data,
    };
};

export const fetchSitesSeals = () => {
    return {
        type: FETCH_SITES_SEALS,
        payload: {
            url: "/type/site/list/",
            data: {},
        },
    };
};

export const setSitesSeals = (data) => {
    return {
        type: SET_SITES_SEALS,
        payload: data,
    };
};

export const fetchPositions = () => {
    return {
        type: FETCH_POSITIONS,
        payload: {
            url: "/isolation/seal/position/list/",
            data: {},
        },
    };
};

export const setPositions = (data) => {
    return {
        type: SET_POSITIONS,
        payload: data,
    };
};

export const createPosition = (data) => {
    return {
        type: CREATE_POSITION,
        payload: {
            url: "/isolation/seal/position/create/",
            data,
        },
    };
};

export const fetchSealSnapshot = (data) => {
    return {
        type: FETCH_SEAL_SNAPSHOT,
        payload: {
            url: "/isolation/seal/snapshot/",
            data,
        },
    };
};

export const setSealSnapshot = (data) => {
    return {
        type: SET_SEAL_SNAPSHOT,
        payload: data,
    };
};

export const createSeal = (data) => {
    return {
        type: CREATE_SEAL,
        payload: {
            url: "/isolation/seal/create/",
            data,
        },
    };
};

export const fetchTeamById = (Id) => {
    return {
        type: FETCH_TEAM_BY_ID,
        payload: {
            url: "/isolation/seal/team/byid/",
            data: {
                Id,
            },
        },
    };
};

export const setTeam = (data) => {
    return {
        type: SET_TEAM,
        payload: data,
    };
};

export const fetchUnitById = (Id) => {
    return {
        type: FETCH_UNIT_BY_ID,
        payload: {
            url: "/isolation/seal/unit/byid/",
            data: {
                Id,
            },
        },
    };
};

export const setUnit = (data) => {
    return {
        type: SET_UNIT,
        payload: data,
    };
};

export const fetchPositionById = (Id) => {
    return {
        type: FETCH_POSITION_BY_ID,
        payload: {
            url: "/isolation/seal/position/byid/",
            data: {
                Id,
            },
        },
    };
};

export const setPosition = (data) => {
    return {
        type: SET_POSITION,
        payload: data,
    };
};

export const editTeam = (data) => {
    return {
        type: EDIT_TEAM,
        payload: {
            url: "/isolation/seal/team/update/",
            data,
        },
    };
};

export const editUnit = (data) => {
    return {
        type: EDIT_UNIT,
        payload: {
            url: "/isolation/seal/unit/update/",
            data,
        },
    };
};

export const editPosition = (data) => {
    return {
        type: EDIT_POSITION,
        payload: {
            url: "/isolation/seal/position/update/",
            data,
        },
    };
};

export const fetchSealsActivity = (Id) => {
    return {
        type: FETCH_SEALS_ACTIVITY,
        payload: {
            url: "/isolation/seal/activity/list/",
            data: {
                Id,
            },
        },
    };
};

export const setSealsActivity = (data) => {
    return {
        type: SET_SEALS_ACTIVITY,
        payload: data,
    };
};

export const setSealActivityFilter = (data) => {
    return {
        type: SET_SEAL_ACTIVITY_FILTER,
        payload: data,
    };
};

export const setActiveSealTab = (data) => {
    sessionStorage.setItem("activeSealTab", data);
    return {
        type: SET_ACTIVE_SEAL_TAB,
        payload: data,
    };
};

export const teamMemberAdd = (data) => {
    return {
        type: TEAM_MEMBER_ADD,
        payload: {
            url: "/isolation/seal/team/add/",
            data,
        },
    };
};

export const teamMemberRemove = (data) => {
    return {
        type: TEAM_MEMBER_REMOVE,
        payload: {
            url: "/isolation/seal/team/remove/",
            data,
        },
    };
};

export const fetchSealActivity = (Id) => {
    return {
        type: FETCH_SEAL_ACTIVITY,
        payload: {
            url: "/isolation/seal/activity/byid/",
            data: {
                Id,
            },
        },
    };
};

export const setSealActivity = (data) => {
    return {
        type: SET_SEAL_ACTIVITY,
        payload: data,
    };
};

export const editSeal = (data) => {
    return {
        type: EDIT_SEAL,
        payload: {
            url: "/isolation/seal/update/",
            data,
        },
    };
};

export const searchSeals = (data) => {
    return {
        type: SEARCH_SEALS,
        payload: {
            url: "/isolation/seal/list/search/",
            data,
        },
    };
};

export const setSearchedSeals = (data) => {
    return {
        type: SET_SEARCHED_SEALS,
        payload: data,
    };
};

export const setSealSearchTerms = (data) => {
    return {
        type: SET_SEAL_SEARCH_TERMS,
        payload: data,
    };
};

export const searchSealAudit = (data) => {
    return {
        type: SEARCH_SEAL_AUDIT,
        payload: {
            url: "/isolation/seal/list/audit/search/",
            data,
        },
    };
};

export const setSealAuditList = (data) => {
    return {
        type: SET_SEAL_AUDIT_LIST,
        payload: data,
    };
};

export const setAuditSearchTerms = (data) => {
    return {
        type: SET_AUDIT_SEARCH_TERMS,
        payload: data,
    };
};

export const setSnapshotSearchTerms = (data) => {
    return {
        type: SET_SNAPSHOT_SEARCH_TERMS,
        payload: data,
    };
};

export const setSealFilter = (data) => {
    return {
        type: SET_SEAL_FILTER,
        payload: data,
    };
};

export const fetchPositionsSelect = () => {
    return {
        type: FETCH_POSITIONS_SELECT,
        payload: {
            url: "/isolation/seal/position/list/select/",
            data: {},
        },
    };
};

export const setPositionsSelect = (data) => {
    return {
        type: SET_POSITIONS_SELECT,
        payload: data,
    };
};

export const fetchSealSearchSelect = () => {
    return {
        type: FETCH_SEAL_SEARCH_SELECT,
        payload: {
            url: "/isolation/seal/list/search/select/",
            data: {},
        },
    };
};

export const setSealSearchUsers = (data) => {
    return {
        type: SET_SEAL_SEARCH_USERS,
        payload: data,
    };
};

export const setSealSearchPositions = (data) => {
    return {
        type: SET_SEAL_SEARCH_POSITIONS,
        payload: data,
    };
};

export const fetchSealActivitySearchSelect = () => {
    return {
        type: FETCH_SEAL_ACTIVITY_SEARCH_SELECT,
        payload: {
            url: "/isolation/seal/activity/search/select/",
            data: {},
        },
    };
};

export const setSealActivitySearchActivityTypes = (data) => {
    return {
        type: SET_SEAL_ACTIVITY_SEARCH_ACTIVITY_TYPES,
        payload: data,
    };
};

export const searchSealActivity = (data) => {
    return {
        type: SEARCH_SEAL_ACTIVITY,
        payload: {
            url: "/isolation/seal/activity/search/",
            data,
        },
    };
};

export const setSealActivitySearchList = (data) => {
    return {
        type: SET_SEAL_ACTIVITY_SEARCH_LIST,
        payload: data,
    };
};

export const setSealActivitySearchTerms = (data) => {
    return {
        type: SET_SEAL_ACTIVITY_SEARCH_TERMS,
        payload: data,
    };
};

export const setSealSearchSeals = (data) => {
    return {
        type: SET_SEAL_SEARCH_SEALS,
        payload: data,
    };
};

export const setSealSearchDevices = (data) => {
    return {
        type: SET_SEAL_SEARCH_DEVICES,
        payload: data,
    };
};

export const fetchSealSearchDevices = (data) => {
    return {
        type: FETCH_SEAL_SEARCH_DEVICES,
        payload: {
            url: "/isolation/seal/device/list/search/",
            data,
        },
    };
};

export const fetchSealDeviceById = (data) => {
    return {
        type: FETCH_SEAL_DEVICE_BY_ID,
        payload: {
            url: "/isolation/seal/device/byid/",
            data,
        },
    };
};

export const setSealLock = (data) => {
    return {
        type: SET_SEAL_LOCK,
        payload: data,
    };
};

export const deleteSealLock = (Id) => {
    return {
        type: DELETE_SEAL_LOCK,
        payload: {
            url: "/isolation/seal/device/delete/",
            data: {
                Id,
            },
        },
    };
};

export const createSealLock = (data) => {
    return {
        type: CREATE_SEAL_LOCK,
        payload: {
            url: "/isolation/seal/device/create/",
            data,
        },
    };
};

export const editSealLock = (data) => {
    return {
        type: EDIT_SEAL_LOCK,
        payload: {
            url: "/isolation/seal/device/update/",
            data,
        },
    };
};
