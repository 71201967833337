import "@formatjs/intl-pluralrules/polyfill";
import "@formatjs/intl-pluralrules/locale-data/nl"; // Add locale data for nl

import "@formatjs/intl-relativetimeformat/polyfill";
import "@formatjs/intl-relativetimeformat/locale-data/nl"; // Add locale data for nl
import nlMessages from "../locales/nl_NL.json";

const nlLang = {
    messages: {
        ...nlMessages,
    },
    locale: "nl-NL",
};
export default nlLang;
