import React from "react";
import { injectIntl } from "react-intl";
import { UncontrolledTooltip } from "reactstrap";

class ToolTip extends React.Component {
    render() {
        const { intl, placement, delay, target, message, modifiers } =
            this.props;
        return (
            <UncontrolledTooltip
                placement={placement}
                target={target}
                delay={delay}
                className="my-tooltip"
                modifiers={modifiers}
            >
                {intl.formatMessage({ id: message })}
            </UncontrolledTooltip>
        );
    }
}

ToolTip.defaultProps = {
    placement: "bottom",
    delay: { show: 700, hide: 250 },
    target: "id",
    message: "blank",
};

export default injectIntl(ToolTip);
