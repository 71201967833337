import React from "react";
import { connect } from "react-redux";
import { StyleSheet, css } from "aphrodite";
import { Input, Row, Col } from "reactstrap";
import { injectIntl } from "react-intl";

import { searchUsers, setUserSearchTerms } from "actions/Users";
import { fetchRolesList } from "actions/Settings";
import { setLoader } from "actions/App";
import { fetchSitesList } from "actions/Settings";

import Tooltip from "components/Tooltips";
import CSV from "components/Export";

class SearchUsers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchUserTerms: props.searchUserTerms,
        };
    }
    componentDidMount() {
        const { fetchSitesList, fetchRolesList } = this.props;
        fetchSitesList();
        fetchRolesList();
    }
    handleSearch = () => {
        const { searchUsers, setLoader, setUserSearchTerms } = this.props;
        let { searchUserTerms } = this.state;
        setUserSearchTerms(searchUserTerms);
        searchUsers(searchUserTerms);
        setLoader(false);
    };
    keyPress = (e) => {
        if (e.keyCode === 13) {
            this.handleSearch();
        }
    };
    updateData = (e) => {
        let updateData = { ...this.state.searchUserTerms };
        if (e.target.type === "checkbox") {
            if (e.target.checked) {
                updateData[e.target.name] = true;
                this.setState({
                    searchUserTerms: updateData,
                    changesMade: true,
                });
            } else {
                updateData[e.target.name] = false;
                this.setState({
                    searchUserTerms: updateData,
                    changesMade: true,
                });
            }
        } else {
            updateData[e.target.name] = e.target.value;
            this.setState({ searchUserTerms: updateData, changesMade: true });
        }
    };
    clearField = (target) => {
        let updateData = { ...this.state.searchUserTerms };
        updateData[target] = "";
        this.setState({ searchUserTerms: updateData, changesMade: true });
    };
    clearFields = () => {
        let newUserSearchTerms = {
            Name: "",
            SiteId: "",
            VendorName: "",
            Title: "",
            IsActive: "1",
            UserRoleId: 0,
        };
        this.setState({ searchUserTerms: newUserSearchTerms });
        this.props.searchUsers(newUserSearchTerms);
    };
    render() {
        let { searchUserTerms } = this.state;
        const {
            intl,
            sitesList,
            width,
            searchUsersSmall,
            rolesList,
            searchedUsers,
            authUser,
            theme,
        } = this.props;
        this.styles = styles(theme);
        let exportList = searchedUsers
            ? searchedUsers.map((a) => Object.assign({}, a))
            : [];
        exportList.map((item) => {
            delete item.Icon;
            delete item.Id;
            return item;
        });
        return (
            searchUserTerms && (
                <div>
                    <div
                        className={css(this.styles.searchWrapper)}
                        onKeyDown={(e) => this.keyPress(e)}
                    >
                        <Col lg={12}>
                            {width < 992 && (
                                <Row>
                                    <Col
                                        lg={12}
                                        className={css(this.styles.flexRow)}
                                    >
                                        <div>
                                            <i
                                                id="searchSeal"
                                                className={`material-icons ${css(
                                                    this.styles.searchIcon
                                                )}`}
                                                onClick={() =>
                                                    this.handleSearch()
                                                }
                                            >
                                                search
                                            </i>
                                            <Tooltip
                                                message={"comp.search.default"}
                                                target="searchSeal"
                                            />
                                        </div>
                                        <div>
                                            <i
                                                id="resetSealSearch"
                                                className={`material-icons ${css(
                                                    this.styles.searchIcon
                                                )}`}
                                                onClick={() =>
                                                    this.clearFields()
                                                }
                                            >
                                                low_priority
                                            </i>
                                            <Tooltip
                                                message={
                                                    "comp.resetSearch.default"
                                                }
                                                target="resetSealSearch"
                                            />
                                        </div>
                                        <div
                                            className={`material-icons ${css(
                                                this.styles.searchIcon
                                            )}`}
                                            onClick={() => this.props.action()}
                                        >
                                            <i
                                                id="refreshTables"
                                                className={`material-icons ${css(
                                                    this.styles.icon
                                                )}`}
                                            >
                                                sync
                                            </i>
                                            <Tooltip
                                                message={"comp.button.refresh"}
                                                target="refreshTables"
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            )}
                            {searchUsersSmall && width > 991 && (
                                <div className={css(this.styles.floatTopRight)}>
                                    <div
                                        className={css(this.styles.topRightRow)}
                                    >
                                        <div>
                                            <i
                                                id="searchSeal"
                                                className={`material-icons ${css(
                                                    this.styles.searchIcon
                                                )}`}
                                                onClick={() =>
                                                    this.handleSearch()
                                                }
                                            >
                                                search
                                            </i>
                                            <Tooltip
                                                message={"comp.search.default"}
                                                target="searchSeal"
                                            />
                                        </div>
                                        <div>
                                            <i
                                                id="resetSealSearch"
                                                className={`material-icons ${css(
                                                    this.styles.searchIcon
                                                )}`}
                                                onClick={() =>
                                                    this.clearFields()
                                                }
                                            >
                                                low_priority
                                            </i>
                                            <Tooltip
                                                message={
                                                    "comp.resetSearch.default"
                                                }
                                                target="resetSealSearch"
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                            <Row>
                                {!searchUsersSmall && (
                                    <Col
                                        lg={10}
                                        className={css(this.styles.flexRow)}
                                    >
                                        {width > 800 && (
                                            <Col
                                                lg={4}
                                                className={css(
                                                    this.styles.flexRow
                                                )}
                                            >
                                                <label
                                                    className={css(
                                                        this.styles
                                                            .labelDropdown
                                                    )}
                                                >
                                                    {this.props.intl.formatMessage(
                                                        { id: "form.name" }
                                                    )}
                                                </label>
                                                <Input
                                                    name="Name"
                                                    type="text"
                                                    className={css(
                                                        this.styles.inputText
                                                    )}
                                                    onChange={(e) =>
                                                        this.updateData(e)
                                                    }
                                                    value={searchUserTerms.Name}
                                                />
                                                {searchUserTerms.Name !==
                                                    "" && (
                                                    <i
                                                        className={`material-icons ${css(
                                                            this.styles
                                                                .closeIcon
                                                        )}`}
                                                        onClick={() =>
                                                            this.clearField(
                                                                "Name"
                                                            )
                                                        }
                                                    >
                                                        close
                                                    </i>
                                                )}
                                            </Col>
                                        )}
                                        {width > 800 && (
                                            <Col
                                                lg={4}
                                                className={css(this.styles.flexRow)}
                                            >
                                                <label
                                                    className={css(
                                                        this.styles.labelDropdown
                                                    )}
                                                >
                                                    {this.props.intl.formatMessage({
                                                        id: "form.role",
                                                    })}
                                                </label>
                                                <Input
                                                    className={css(this.styles.input)}
                                                    name="UserRoleId"
                                                    type="select"
                                                    placeholder={intl.formatMessage({
                                                        id: "form.selectSite",
                                                    })}
                                                    onChange={this.updateData}
                                                    value={
                                                        searchUserTerms.UserRoleId || 0
                                                    }
                                                >
                                                    <option value={""}>
                                                        {intl.formatMessage({
                                                            id: "form.all",
                                                        })}
                                                    </option>
                                                    {rolesList.map((role) => (
                                                        <option
                                                            key={"site" + role.Id}
                                                            value={role.Id || 0}
                                                        >
                                                            {role.Name}
                                                        </option>
                                                    ))}
                                                </Input>
                                            </Col>
                                        )}
                                        {width > 800 && (
                                            <Col
                                                lg={4}
                                                className={css(
                                                    this.styles.flexRow
                                                )}
                                            >
                                                <label
                                                    className={css(
                                                        this.styles
                                                            .labelDropdown
                                                    )}
                                                >
                                                    {this.props.intl.formatMessage(
                                                        {
                                                            id: "form.defaultSite",
                                                        }
                                                    )}
                                                </label>
                                                <Input
                                                    className={css(
                                                        this.styles.input
                                                    )}
                                                    name="SiteId"
                                                    type="select"
                                                    placeholder={intl.formatMessage(
                                                        {
                                                            id: "form.selectSite",
                                                        }
                                                    )}
                                                    onChange={this.updateData}
                                                    value={
                                                        searchUserTerms.SiteId ||
                                                        ""
                                                    }
                                                >
                                                    <option value={""}>
                                                        {intl.formatMessage({
                                                            id: "form.all",
                                                        })}
                                                    </option>
                                                    {sitesList.map((site) => (
                                                        <option
                                                            key={
                                                                "site" + site.Id
                                                            }
                                                            value={
                                                                site.Id || ""
                                                            }
                                                        >
                                                            {site.SiteName}
                                                        </option>
                                                    ))}
                                                </Input>
                                            </Col>
                                        )}
                                    </Col>
                                )}
                                {width > 800 && !searchUsersSmall && (
                                    <Col lg={2}>
                                        {width > 991 && (
                                            <Row>
                                                <Col
                                                    lg={12}
                                                    className={css(
                                                        this.styles.flexRow,
                                                        this.styles.rightAligned
                                                    )}
                                                >
                                                    <div>
                                                        <i
                                                            id="searchSeal"
                                                            className={`material-icons ${css(
                                                                this.styles
                                                                    .searchIcon
                                                            )}`}
                                                            onClick={() =>
                                                                this.handleSearch()
                                                            }
                                                        >
                                                            search
                                                        </i>
                                                        <Tooltip
                                                            message={
                                                                "comp.search.default"
                                                            }
                                                            target="searchSeal"
                                                        />
                                                    </div>
                                                    <div>
                                                        <i
                                                            id="resetSealSearch"
                                                            className={`material-icons ${css(
                                                                this.styles
                                                                    .searchIcon
                                                            )}`}
                                                            onClick={() =>
                                                                this.clearFields()
                                                            }
                                                        >
                                                            low_priority
                                                        </i>
                                                        <Tooltip
                                                            message={
                                                                "comp.resetSearch.default"
                                                            }
                                                            target="resetSealSearch"
                                                        />
                                                    </div>
                                                    <div
                                                        className={`material-icons ${css(
                                                            this.styles
                                                                .searchIcon
                                                        )}`}
                                                        onClick={() =>
                                                            this.props.action()
                                                        }
                                                    >
                                                        <i
                                                            id="refreshTables"
                                                            className={`material-icons ${css(
                                                                this.styles.icon
                                                            )}`}
                                                        >
                                                            sync
                                                        </i>
                                                        <Tooltip
                                                            message={
                                                                "comp.button.refresh"
                                                            }
                                                            target="refreshTables"
                                                        />
                                                    </div>
                                                    {authUser.Permissions.includes(
                                                        "ExportUserList"
                                                    ) && (
                                                        <div>
                                                            <CSV
                                                                data={
                                                                    exportList
                                                                }
                                                                margin={
                                                                    "0 12px 0 0"
                                                                }
                                                            />
                                                            <Tooltip
                                                                message={
                                                                    "comp.button.export"
                                                                }
                                                                target="exportFile"
                                                            />
                                                        </div>
                                                    )}
                                                </Col>
                                            </Row>
                                        )}
                                    </Col>
                                )}
                                {searchUsersSmall && width > 800 && (
                                    <Col lg={12}>
                                        <Row>
                                            <Col
                                                lg={12}
                                                className={css(
                                                    this.styles.flexRow
                                                )}
                                            >
                                                <label
                                                    className={css(
                                                        this.styles
                                                            .labelDropdown
                                                    )}
                                                >
                                                    {this.props.intl.formatMessage(
                                                        { id: "form.name" }
                                                    )}
                                                </label>
                                                <Input
                                                    name="Name"
                                                    type="text"
                                                    className={css(
                                                        this.styles.inputText
                                                    )}
                                                    onChange={(e) =>
                                                        this.updateData(e)
                                                    }
                                                    value={searchUserTerms.Name}
                                                />
                                                {searchUserTerms.Name !==
                                                    "" && (
                                                    <i
                                                        className={`material-icons ${css(
                                                            this.styles
                                                                .closeIcon
                                                        )}`}
                                                        onClick={() =>
                                                            this.clearField(
                                                                "Name"
                                                            )
                                                        }
                                                    >
                                                        close
                                                    </i>
                                                )}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col
                                                lg={12}
                                                className={css(
                                                    this.styles.flexRow
                                                )}
                                            >
                                                <label
                                                    className={css(
                                                        this.styles
                                                            .labelDropdown
                                                    )}
                                                >
                                                    {this.props.intl.formatMessage(
                                                        {
                                                            id: "form.defaultSite",
                                                        }
                                                    )}
                                                </label>
                                                <Input
                                                    className={css(
                                                        this.styles.input
                                                    )}
                                                    name="SiteId"
                                                    type="select"
                                                    placeholder={intl.formatMessage(
                                                        {
                                                            id: "form.selectSite",
                                                        }
                                                    )}
                                                    onChange={this.updateData}
                                                    value={
                                                        searchUserTerms.SiteId ||
                                                        ""
                                                    }
                                                >
                                                    <option value={""}>
                                                        {intl.formatMessage({
                                                            id: "form.all",
                                                        })}
                                                    </option>
                                                    {sitesList.map((site) => (
                                                        <option
                                                            key={
                                                                "site" + site.Id
                                                            }
                                                            value={
                                                                site.Id || ""
                                                            }
                                                        >
                                                            {site.SiteName}
                                                        </option>
                                                    ))}
                                                </Input>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col
                                                lg={12}
                                                className={css(
                                                    this.styles.flexRow
                                                )}
                                            >
                                                <label
                                                    className={css(
                                                        this.styles
                                                            .labelDropdown
                                                    )}
                                                >
                                                    {this.props.intl.formatMessage(
                                                        { id: "form.status" }
                                                    )}
                                                </label>
                                                <Input
                                                    className={css(
                                                        this.styles.input
                                                    )}
                                                    name="IsActive"
                                                    type="select"
                                                    placeholder={intl.formatMessage(
                                                        { id: "form.select" }
                                                    )}
                                                    onChange={this.updateData}
                                                    value={
                                                        searchUserTerms.IsActive ||
                                                        ""
                                                    }
                                                >
                                                    <option value={""}>
                                                        {intl.formatMessage({
                                                            id: "comp.button.all",
                                                        })}
                                                    </option>
                                                    <option value={"1"}>
                                                        {intl.formatMessage({
                                                            id: "comp.button.active",
                                                        })}
                                                    </option>
                                                    <option value={"0"}>
                                                        {intl.formatMessage({
                                                            id: "comp.button.inactive",
                                                        })}
                                                    </option>
                                                </Input>
                                            </Col>
                                        </Row>
                                    </Col>
                                )}
                            </Row>
                        </Col>
                    </div>
                    {!searchUsersSmall && (
                        <Row className={css(this.styles.advancedRow)}>
                            <Col lg={10} className={css(this.styles.flexRow)}>
                                {width > 800 && (
                                    <Col
                                        lg={4}
                                        className={css(this.styles.flexRow)}
                                    >
                                        <label
                                            className={css(
                                                this.styles.labelDropdown
                                            )}
                                        >
                                            {this.props.intl.formatMessage({
                                                id: "form.company",
                                            })}
                                        </label>
                                        <Input
                                            name="VendorName"
                                            type="text"
                                            className={css(
                                                this.styles.inputText
                                            )}
                                            onChange={(e) => this.updateData(e)}
                                            value={searchUserTerms.VendorName}
                                        />
                                        {searchUserTerms.VendorName !== "" && (
                                            <i
                                                className={`material-icons ${css(
                                                    this.styles.closeIcon
                                                )}`}
                                                onClick={() =>
                                                    this.clearField(
                                                        "VendorName"
                                                    )
                                                }
                                            >
                                                close
                                            </i>
                                        )}
                                    </Col>
                                )}
                                {width > 800 && (
                                    <Col
                                        lg={4}
                                        className={css(this.styles.flexRow)}
                                    >
                                        <label
                                            className={css(
                                                this.styles.labelDropdown
                                            )}
                                        >
                                            {this.props.intl.formatMessage({
                                                id: "form.title",
                                            })}
                                        </label>
                                        <Input
                                            name="Title"
                                            type="text"
                                            className={css(
                                                this.styles.inputText
                                            )}
                                            onChange={(e) => this.updateData(e)}
                                            value={searchUserTerms.Title}
                                        />
                                        {searchUserTerms.Title !== "" && (
                                            <i
                                                className={`material-icons ${css(
                                                    this.styles.closeIcon
                                                )}`}
                                                onClick={() =>
                                                    this.clearField("Title")
                                                }
                                            >
                                                close
                                            </i>
                                        )}
                                    </Col>
                                )}
                                {width > 800 && (
                                    <Col
                                        lg={4}
                                        className={css(
                                            this.styles.flexRow
                                        )}
                                    >
                                        <label
                                            className={css(
                                                this.styles
                                                    .labelDropdown
                                            )}
                                        >
                                            {this.props.intl.formatMessage(
                                                { id: "comp.table.header.active" }
                                            )}
                                        </label>
                                        <Input
                                            className={css(
                                                this.styles.input
                                            )}
                                            name="IsActive"
                                            type="select"
                                            placeholder={intl.formatMessage(
                                                { id: "form.select" }
                                            )}
                                            onChange={this.updateData}
                                            value={
                                                searchUserTerms.IsActive ||
                                                ""
                                            }
                                        >
                                            <option value={""}>
                                                {intl.formatMessage({
                                                    id: "comp.button.all",
                                                })}
                                            </option>
                                            <option value={"1"}>
                                                {intl.formatMessage({
                                                    id: "comp.button.active",
                                                })}
                                            </option>
                                            <option value={"0"}>
                                                {intl.formatMessage({
                                                    id: "comp.button.inactive",
                                                })}
                                            </option>
                                        </Input>
                                    </Col>
                                )}
                            </Col>
                        </Row>
                    )}
                </div>
            )
        );
    }
}

SearchUsers.defaultProps = {
    searchTitle: "blank",
    searchType: ["name"],
    data: [],
};

const mapStateToProps = ({ app, auth, users, settings }) => {
    const { theme, width } = app;
    const { authUser } = auth;
    const { sitesList, rolesList } = settings;
    const { searchUserTerms, searchedUsers } = users;
    return {
        theme,
        width,
        authUser,
        searchedUsers,
        sitesList,
        searchUserTerms,
        rolesList,
    };
};

export default injectIntl(
    connect(mapStateToProps, {
        searchUsers,
        setLoader,
        setUserSearchTerms,
        fetchSitesList,
        fetchRolesList,
    })(SearchUsers)
);

const styles = (theme) =>
    StyleSheet.create({
        flexRow: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            height: "2.25rem",
            paddingLeft: 0,
        },
        advancedRow: {
            margin: 0,
            padding: 0,
        },
        rightAligned: {
            paddingRight: 0,
            justifyContent: "flex-end",
        },
        input: {
            border: "none",
            boxShadow: "none",
            borderRadius: 0,
            fontWeight: 400,
            fontSize: 12,
            letterSpacing: 2,
            marginBottom: 0,
            color: theme.color.themeColor,
            border: "1px solid white",
            backgroundColor: theme.color.compColor,
            "::placeholder": {
                letterSpacing: 1,
                color: theme.color.fontColor,
                fontSize: 12,
            },
        },
        inputText: {
            border: "none",
            boxShadow: "none",
            borderRadius: 0,
            fontWeight: 400,
            fontSize: 12,
            letterSpacing: 2,
            marginBottom: 0,
            padding: 0,
            width: "100%",
            marginLeft: 5,
            color: theme.color.themeColor,
            borderBottom: `solid whitesmoke 1px`,
            backgroundColor: theme.color.compColor,
            "::placeholder": {
                letterSpacing: 1,
                color: theme.color.themeColor,
                fontSize: 12,
            },
        },
        searchWrapper: {
            position: "relative",
            display: "flex",
            flexDirection: "row",
        },
        searchIcon: {
            color: theme.color.themeColor,
            marginRight: 12,
            ":hover": {
                cursor: "pointer",
                opacity: ".8",
            },
        },
        closeIcon: {
            color: theme.color.fontColor,
            position: "absolute",
            top: 5,
            right: 5,
            fontSize: 16,
            ":hover": {
                cursor: "pointer",
                opacity: ".8",
            },
        },
        labelDropdown: {
            fontSize: 12,
            color: theme.color.fontColor,
            whiteSpace: "nowrap",
        },
        topRightRow: {
            display: "flex",
            flexDirection: "row",
        },
        floatTopRight: {
            position: "absolute",
            right: 0,
            top: "-35px",
        },
    });
