import {
    CREATE_MACHINE,
    CREATE_MACHINE_DOCUMENT,
    CREATE_MACHINE_JOB,
    CREATE_MACHINE_JOB_ACTION,
    CREATE_MACHINE_JOB_PROCESS,
    CREATE_MACHINE_POINT,
    CREATE_MACHINE_POINT_ACTION,
    DELETE_MACHINE,
    DELETE_MACHINE_JOB_ACTION,
    DELETE_MACHINE_JOB_PROCESS,
    DELETE_MACHINE_POINT_ACTION,
    EDIT_MACHINE,
    EDIT_MACHINE_JOB,
    EDIT_MACHINE_JOB_ACTION,
    EDIT_MACHINE_JOB_PROCESS,
    EDIT_MACHINE_POINT,
    EDIT_MACHINE_POINT_ACTION,
    EDIT_MACHINE_POINT_PROCESS,
    FETCH_ACTIVE_MACHINES_LIST,
    FETCH_JOBS_BY_MACHINE_ID,
    FETCH_LOTO_DEVICE_SELECT,
    FETCH_MACHINES_BY_SITE_ID,
    FETCH_MACHINES_LIST,
    FETCH_MACHINES_LIST_SELECT,
    FETCH_MACHINE_ACTIVITY,
    FETCH_MACHINE_BY_ID,
    FETCH_MACHINE_DOCUMENTS,
    FETCH_MACHINE_DOCUMENT_BY_ID,
    FETCH_MACHINE_JOB_ACTIONS,
    FETCH_MACHINE_JOB_ACTION_BY_ID,
    FETCH_MACHINE_JOB_BY_ID,
    FETCH_MACHINE_JOB_PROCESS,
    FETCH_MACHINE_POINT_ACTIONS,
    FETCH_MACHINE_POINT_ACTION_BY_ID,
    FETCH_MACHINE_POINT_ACTION_LIST,
    FETCH_MACHINE_POINT_BY_ID,
    FETCH_POINT_ACTIVITY,
    FETCH_POINT_EQUIPMENT,
    FETCH_POINT_HAZARDS,
    FILTER_MACHINES,
    FILTER_MACHINES_LIST,
    SEARCH_MACHINES,
    SEARCH_MACHINES_LIST,
    SEARCH_JOB_TEMPLATES,
    SEARCH_MACHINE_POINT_LIST,
    SET_ACTIVE_MACHINES,
    SET_FILTER_MACHINES,
    SET_LOTO_DEVICE_SELECT,
    SET_MACHINE,
    SET_MACHINES,
    SET_MACHINES_SELECT,
    SET_JOB_TEMPLATES,
    SET_MACHINE_ACTIVITY,
    SET_MACHINE_DOCUMENT,
    SET_MACHINE_DOCUMENTS,
    SET_MACHINE_FILTER,
    SET_MACHINE_JOB,
    SET_MACHINE_JOBS,
    SET_MACHINE_JOB_ACTION,
    SET_MACHINE_JOB_ACTIONS,
    SET_MACHINE_JOB_PROCESS,
    SET_MACHINE_POINT,
    SET_MACHINE_POINTS,
    SET_MACHINE_POINT_ACTION,
    SET_MACHINE_POINT_ACTIONS,
    SET_MACHINE_POINT_ACTION_LIST,
    SET_MACHINE_SEARCH_TERMS,
    SET_POINT_ACTIVITY,
    SET_POINT_EQUIPMENT,
    SET_POINT_HAZARDS,
    SET_SEARCHED_MACHINES,
    SET_SITE_MACHINES,
    UPDATE_POINT_EQUIPMENT,
    UPDATE_POINT_HAZARDS,
    FETCH_MACHINE_JOB_TEMPLATE_BY_ID,
    SET_MACHINE_JOB_TEMPLATE,
    CREATE_MACHINE_JOB_TEMPLATE_ACTION,
    DELETE_MACHINE_JOB_TEMPLATE_ACTION,
    EDIT_MACHINE_JOB_TEMPLATE_ACTION,
    CREATE_MACHINE_JOB_TEMPLATE,
    EDIT_MACHINE_JOB_TEMPLATE_PROCESS,
    FETCH_JOB_TEMPLATES_SELECT,
    SET_JOB_TEMPLATES_SELECT,
    APPLY_JOB_TEMPLATE
} from "constants/ActionTypes";

let authUser = JSON.parse(sessionStorage.getItem("user_data"));

export const fetchMachinesList = () => {
    if (!authUser) {
        authUser = JSON.parse(sessionStorage.getItem("user_data"));
    }
    if (authUser.Settings.Product.includes("isolation")) {
        return {
            type: FETCH_MACHINES_LIST,
            payload: {
                url: "/isolation/machine/list/",
                data: {},
            },
        };
    } else {
        return {
            type: FETCH_MACHINES_LIST,
            payload: {
                url: "/lockout/machine/list/",
                data: {},
            },
        };
    }
};
export const fetchActiveMachinesList = () => {
    // this might be fetched from home page before authUser is present in local
    if (!authUser) {
        authUser = JSON.parse(sessionStorage.getItem("user_data"));
    }
    if (authUser.Settings.Product.includes("isolation")) {
        return {
            type: FETCH_ACTIVE_MACHINES_LIST,
            payload: {
                url: "/isolation/machine/list/",
                data: {},
            },
        };
    } else {
        return {
            type: FETCH_ACTIVE_MACHINES_LIST,
            payload: {
                url: "/lockout/machine/list/",
                data: {},
            },
        };
    }
};
export const fetchMachinesListSelect = (Id) => {
    if (!authUser) {
        authUser = JSON.parse(sessionStorage.getItem("user_data"));
    }
    if (authUser.Settings.Product.includes("isolation")) {
        return {
            type: FETCH_MACHINES_LIST_SELECT,
            payload: {
                url: "/isolation/machine/list/select/",
                data: {
                    Id,
                },
            },
        };
    } else {
        return {
            type: FETCH_MACHINES_LIST_SELECT,
            payload: {
                url: "/lockout/machine/list/select/",
                data: {
                    Id,
                },
            },
        };
    }
};
export const fetchMachineById = (Id) => {
    if (!authUser) {
        authUser = JSON.parse(sessionStorage.getItem("user_data"));
    }
    if (authUser.Settings.Product.includes("isolation")) {
        return {
            type: FETCH_MACHINE_BY_ID,
            payload: {
                url: "/isolation/machine/byid/",
                data: {
                    Id,
                },
            },
        };
    } else {
        return {
            type: FETCH_MACHINE_BY_ID,
            payload: {
                url: "/lockout/machine/byid/",
                data: {
                    Id,
                },
            },
        };
    }
};
export const createMachine = (data) => {
    if (!authUser) {
        authUser = JSON.parse(sessionStorage.getItem("user_data"));
    }
    if (authUser.Settings.Product.includes("isolation")) {
        return {
            type: CREATE_MACHINE,
            payload: {
                url: "/isolation/machine/create/",
                data,
            },
        };
    } else {
        return {
            type: CREATE_MACHINE,
            payload: {
                url: "/lockout/machine/create/",
                data,
            },
        };
    }
};
export const editMachine = (data) => {
    if (!authUser) {
        authUser = JSON.parse(sessionStorage.getItem("user_data"));
    }
    if (authUser.Settings.Product.includes("isolation")) {
        return {
            type: EDIT_MACHINE,
            payload: {
                url: "/isolation/machine/update/",
                data,
            },
        };
    } else {
        return {
            type: EDIT_MACHINE,
            payload: {
                url: "/lockout/machine/update/",
                data,
            },
        };
    }
};
export const deleteMachine = (Id) => {
    if (!authUser) {
        authUser = JSON.parse(sessionStorage.getItem("user_data"));
    }
    if (authUser.Settings.Product.includes("isolation")) {
        return {
            type: DELETE_MACHINE,
            payload: {
                url: "/isolation/machine/delete/",
                data: {
                    Id,
                },
            },
        };
    } else {
        return {
            type: DELETE_MACHINE,
            payload: {
                url: "/lockout/machine/delete/",
                data: {
                    Id,
                },
            },
        };
    }
};
export const filterMachines = (filter) => {
    return {
        type: FILTER_MACHINES,
        payload: filter,
    };
};
export const filterMachinesList = (searchValue, list) => {
    return {
        type: FILTER_MACHINES_LIST,
        payload: {
            searchValue,
            list,
        },
    };
};
export const searchMachinesList = (Name) => {
    if (!authUser) {
        authUser = JSON.parse(sessionStorage.getItem("user_data"));
    }
    if (authUser.Settings.Product.includes("isolation")) {
        return {
            type: SEARCH_MACHINES_LIST,
            payload: {
                url: "/isolation/machine/list/search/",
                data: {
                    Name,
                },
            },
        };
    } else {
        return {
            type: SEARCH_MACHINES_LIST,
            payload: {
                url: "/lockout/machine/list/search/",
                data: {
                    Name,
                },
            },
        };
    }
};
export const setFilterMachines = (data) => {
    return {
        type: SET_FILTER_MACHINES,
        payload: data,
    };
};
export const setMachines = (data) => {
    return {
        type: SET_MACHINES,
        payload: data,
    };
};
export const setActiveMachines = (data) => {
    return {
        type: SET_ACTIVE_MACHINES,
        payload: data,
    };
};
export const setMachine = (data) => {
    return {
        type: SET_MACHINE,
        payload: data,
    };
};

export const setMachineFilter = (data) => {
    return {
        type: SET_MACHINE_FILTER,
        payload: data,
    };
};

export const fetchMachinesBySiteId = (Id) => {
    if (!authUser) {
        authUser = JSON.parse(sessionStorage.getItem("user_data"));
    }
    if (authUser.Settings.Product.includes("isolation")) {
        return {
            type: FETCH_MACHINES_BY_SITE_ID,
            payload: {
                url: "/isolation/machine/list/bysite/select/",
                data: {
                    Id,
                },
            },
        };
    } else {
        return {
            type: FETCH_MACHINES_BY_SITE_ID,
            payload: {
                url: "/lockout/machine/list/bysite/select/available/",
                data: {
                    Id,
                },
            },
        };
    }
};

export const fetchMachineDocuments = (data) => {
    return {
        type: FETCH_MACHINE_DOCUMENTS,
        payload: {
            url: "/image/doc/search/",
            data,
        },
    };
};

export const fetchMachineDocumentById = (data) => {
    return {
        type: FETCH_MACHINE_DOCUMENT_BY_ID,
        payload: {
            url: "/image/doc/byid/",
            data,
        },
    };
};

export const createMachineDocument = (data) => {
    return {
        type: CREATE_MACHINE_DOCUMENT,
        payload: {
            url: "/image/doc/create/",
            data,
        },
    };
};

export const setSiteMachines = (data) => {
    return {
        type: SET_SITE_MACHINES,
        payload: data,
    };
};

export const fetchMachineActivity = (Id) => {
    return {
        type: FETCH_MACHINE_ACTIVITY,
        payload: {
            url: "/activity/list/machine/",
            data: {
                Id,
            },
        },
    };
};

export const setMachineActivity = (data) => {
    return {
        type: SET_MACHINE_ACTIVITY,
        payload: data,
    };
};

export const searchMachines = (data) => {
    if (!authUser) {
        authUser = JSON.parse(sessionStorage.getItem("user_data"));
    }
    if (authUser.Settings.Product.includes("isolation")) {
        return {
            type: SEARCH_MACHINES,
            payload: {
                url: "/isolation/machine/list/search/",
                data,
            },
        };
    } else {
        return {
            type: SEARCH_MACHINES,
            payload: {
                url: "/lockout/machine/list/search/",
                data,
            },
        };
    }
};

export const setSearchedMachines = (data) => {
    return {
        type: SET_SEARCHED_MACHINES,
        payload: data,
    };
};

export const searchJobTemplates = () => {
    return {
        type: SEARCH_JOB_TEMPLATES,
        payload: {
            url: "/isolation/machine/job/template/list/",
            data: {},
        },
    };
};

export const fetchJobTemplatesSelect = () => {
    return {
        type: FETCH_JOB_TEMPLATES_SELECT,
        payload: {
            url: "/isolation/machine/job/template/list/select/",
            data: {},
        },
    };
};

export const applyJobTemplate = (data) => {
    return {
        type: APPLY_JOB_TEMPLATE,
        payload: {
            url: "/isolation/machine/jobprocess/template/apply/",
            data,
        },
    };
};

export const setJobTemplatesSelect = (data) => {
    return {
        type: SET_JOB_TEMPLATES_SELECT,
        payload: data,
    };
};

export const setMachineDocuments = (data) => {
    return {
        type: SET_MACHINE_DOCUMENTS,
        payload: data,
    };
};

export const setMachineDocument = (data) => {
    return {
        type: SET_MACHINE_DOCUMENT,
        payload: data,
    };
};

export const setJobTemplates = (data) => {
    return {
        type: SET_JOB_TEMPLATES,
        payload: data,
    };
};

export const setMachineSearchTerms = (data) => {
    return {
        type: SET_MACHINE_SEARCH_TERMS,
        payload: data,
    };
};

export const setMachinesSelect = (data) => {
    return {
        type: SET_MACHINES_SELECT,
        payload: data,
    };
};

export const fetchJobsByMachineId = (Id) => {
    return {
        type: FETCH_JOBS_BY_MACHINE_ID,
        payload: {
            url: "/isolation/machine/job/list/",
            data: {
                Id,
            },
        },
    };
};

export const fetchAvailableJobsByMachineId = (Id) => {
    return {
        type: FETCH_JOBS_BY_MACHINE_ID,
        payload: {
            url: "/isolation/machine/job/list/select/available/",
            data: {
                Id,
            },
        },
    };
};

export const setMachineJobs = (data) => {
    return {
        type: SET_MACHINE_JOBS,
        payload: data,
    };
};

export const createMachineJob = (data, machineId, addAnother) => {
    return {
        type: CREATE_MACHINE_JOB,
        payload: {
            url: "/isolation/machine/job/create/",
            data,
            machineId,
            addAnother,
        },
    };
};

export const createMachineJobTemplate = (data, addAnother) => {
    return {
        type: CREATE_MACHINE_JOB_TEMPLATE,
        payload: {
            url: "/isolation/machine/job/template/create/",
            data,
            addAnother,
        },
    };
};

export const searchMachinePointList = (Id, Description) => {
    return {
        type: SEARCH_MACHINE_POINT_LIST,
        payload: {
            url: "/isolation/machine/point/list/search/",
            data: {
                Id,
                Description,
            },
        },
    };
};

export const setMachinePoints = (data) => {
    return {
        type: SET_MACHINE_POINTS,
        payload: data,
    };
};

export const fetchLotoDeviceSelect = () => {
    return {
        type: FETCH_LOTO_DEVICE_SELECT,
        payload: {
            url: "/type/lotodevice/list/select/",
            data: {},
        },
    };
};

export const setLotoDeviceSelect = (data) => {
    return {
        type: SET_LOTO_DEVICE_SELECT,
        payload: data,
    };
};

export const createMachinePoint = (data, machineId, hazards, equipment) => {
    return {
        type: CREATE_MACHINE_POINT,
        payload: {
            url: "/isolation/machine/point/create/",
            data,
            machineId,
            hazards,
            equipment,
        },
    };
};

export const updatePointHazards = (data) => {
    return {
        type: UPDATE_POINT_HAZARDS,
        payload: {
            url: "/isolation/machine/point/hazards/update/",
            data,
        },
    };
};

export const updatePointEquipment = (data) => {
    return {
        type: UPDATE_POINT_EQUIPMENT,
        payload: {
            url: "/isolation/machine/point/equipment/update/",
            data,
        },
    };
};

export const fetchPointHazards = (data) => {
    return {
        type: FETCH_POINT_HAZARDS,
        payload: {
            url: "/isolation/machine/point/hazards/byid/",
            data,
        },
    };
};

export const setPointHazards = (data) => {
    return {
        type: SET_POINT_HAZARDS,
        payload: data,
    };
};

export const fetchPointEquipment = (data) => {
    return {
        type: FETCH_POINT_EQUIPMENT,
        payload: {
            url: "/isolation/machine/point/equipment/byid/",
            data,
        },
    };
};

export const setPointEquipment = (data) => {
    return {
        type: SET_POINT_EQUIPMENT,
        payload: data,
    };
};

export const fetchMachinePointById = (Id) => {
    return {
        type: FETCH_MACHINE_POINT_BY_ID,
        payload: {
            url: "/isolation/machine/point/byid/",
            data: {
                Id,
            },
        },
    };
};

export const setMachinePoint = (data) => {
    return {
        type: SET_MACHINE_POINT,
        payload: data,
    };
};

export const fetchMachineJobById = (Id) => {
    return {
        type: FETCH_MACHINE_JOB_BY_ID,
        payload: {
            url: "/isolation/machine/job/byid/",
            data: {
                Id,
            },
        },
    };
};

export const setMachineJob = (data) => {
    return {
        type: SET_MACHINE_JOB,
        payload: data,
    };
};

export const fetchMachineJobTemplateById = (Id) => {
    return {
        type: FETCH_MACHINE_JOB_TEMPLATE_BY_ID,
        payload: {
            url: "/isolation/machine/job/template/byid/",
            data: {
                Id,
            },
        },
    };
};

export const setMachineJobTemplate = (data) => {
    return {
        type: SET_MACHINE_JOB_TEMPLATE,
        payload: data,
    };
};

export const fetchMachineJobActions = (Id) => {
    return {
        type: FETCH_MACHINE_JOB_ACTIONS,
        payload: {
            url: "/isolation/machine/job/action/list/",
            data: {
                Id,
            },
        },
    };
};

export const setMachineJobActions = (data) => {
    return {
        type: SET_MACHINE_JOB_ACTIONS,
        payload: data,
    };
};

export const fetchMachinePointActions = (Id) => {
    return {
        type: FETCH_MACHINE_POINT_ACTIONS,
        payload: {
            url: "/isolation/machine/point/action/list/",
            data: {
                Id,
            },
        },
    };
};

export const setMachinePointActions = (data) => {
    return {
        type: SET_MACHINE_POINT_ACTIONS,
        payload: data,
    };
};

export const createMachinePointAction = (data, another, machineId) => {
    return {
        type: CREATE_MACHINE_POINT_ACTION,
        payload: {
            url: "/isolation/machine/point/action/create/",
            data,
            another,
            machineId,
        },
    };
};

export const editMachinePointAction = (data, machineId) => {
    return {
        type: EDIT_MACHINE_POINT_ACTION,
        payload: {
            url: "/isolation/machine/point/action/update/",
            data,
            machineId,
        },
    };
};

export const fetchMachinePointActionById = (Id) => {
    return {
        type: FETCH_MACHINE_POINT_ACTION_BY_ID,
        payload: {
            url: "/isolation/machine/point/action/byid/",
            data: {
                Id,
            },
        },
    };
};

export const setMachinePointAction = (data) => {
    return {
        type: SET_MACHINE_POINT_ACTION,
        payload: data,
    };
};

export const deleteMachinePointAction = (Id, MachinePointId, machineId) => {
    return {
        type: DELETE_MACHINE_POINT_ACTION,
        payload: {
            url: "/isolation/machine/point/action/delete/",
            data: { Id, MachinePointId },
            machineId,
        },
    };
};

export const createMachineJobAction = (data, another) => {
    return {
        type: CREATE_MACHINE_JOB_ACTION,
        payload: {
            url: "/isolation/machine/job/action/create/",
            data,
            another,
        },
    };
};

export const deleteMachineJobAction = (Id, MachineJobId) => {
    return {
        type: DELETE_MACHINE_JOB_ACTION,
        payload: {
            url: "/isolation/machine/job/action/delete/",
            data: { Id, MachineJobId },
        },
    };
};

export const fetchMachineJobActionById = (Id) => {
    return {
        type: FETCH_MACHINE_JOB_ACTION_BY_ID,
        payload: {
            url: "/isolation/machine/job/action/byid/",
            data: {
                Id,
            },
        },
    };
};

export const createMachineJobTemplateAction = (data) => {
    return {
        type: CREATE_MACHINE_JOB_TEMPLATE_ACTION,
        payload: {
            url: "/isolation/machine/job/template/action/create/",
            data,
        },
    };
};

export const setMachineJobAction = (data) => {
    return {
        type: SET_MACHINE_JOB_ACTION,
        payload: data,
    };
};

export const editMachineJobAction = (data) => {
    return {
        type: EDIT_MACHINE_JOB_ACTION,
        payload: {
            url: "/isolation/machine/job/action/update/",
            data,
        },
    };
};

export const editMachineJobTemplateAction = (data) => {
    return {
        type: EDIT_MACHINE_JOB_TEMPLATE_ACTION,
        payload: {
            url: "/isolation/machine/job/template/action/update/",
            data,
        },
    };
};

export const editMachineJobTemplateProcess = (data, MachineJobTemplateId) => {
    return {
        type: EDIT_MACHINE_JOB_TEMPLATE_PROCESS,
        payload: {
            url: "/isolation/machine/job/template/process/update/",
            data,
            MachineJobTemplateId
        },
    };
};

export const editMachineJob = (data, machineId) => {
    return {
        type: EDIT_MACHINE_JOB,
        payload: {
            url: "/isolation/machine/job/update/",
            data,
            machineId,
        },
    };
};

export const editMachinePoint = (data, machineId, hazards, equipment) => {
    return {
        type: EDIT_MACHINE_POINT,
        payload: {
            url: "/isolation/machine/point/update/",
            data,
            machineId,
            hazards,
            equipment,
        },
    };
};

export const editMachinePointProcess = (data, machineId) => {
    return {
        type: EDIT_MACHINE_POINT_PROCESS,
        payload: {
            url: "/isolation/machine/point/process/update/",
            data,
            machineId,
        },
    };
};

export const fetchMachineJobProcess = (Id, dontRefresh) => {
    return {
        type: FETCH_MACHINE_JOB_PROCESS,
        payload: {
            url: "/isolation/machine/jobprocess/byid/",
            dontRefresh,
            data: {
                Id,
            },
        },
    };
};

export const setMachineJobProcess = (data) => {
    return {
        type: SET_MACHINE_JOB_PROCESS,
        payload: data,
    };
};

export const editMachineJobProcess = (data, Id) => {
    return {
        type: EDIT_MACHINE_JOB_PROCESS,
        payload: {
            url: "/isolation/machine/jobprocess/update/",
            data,
            Id,
        },
    };
};

export const createMachineJobProcess = (data) => {
    return {
        type: CREATE_MACHINE_JOB_PROCESS,
        payload: {
            url: "/isolation/machine/jobprocess/create/",
            data,
        },
    };
};

export const deleteMachineJobProcess = (data) => {
    return {
        type: DELETE_MACHINE_JOB_PROCESS,
        payload: {
            url: "/isolation/machine/jobprocess/delete/",
            data,
        },
    };
};

export const deleteMachineJobTemplateAction = (data) => {
    return {
        type: DELETE_MACHINE_JOB_TEMPLATE_ACTION,
        payload: {
            url: "/isolation/machine/job/template/action/delete/",
            data,
        },
    };
};

export const fetchPointActivity = (Id) => {
    return {
        type: FETCH_POINT_ACTIVITY,
        payload: {
            url: "/activity/list/search/",
            data: {
                PointId: Id,
            },
        },
    };
};

export const setPointActivity = (data) => {
    return {
        type: SET_POINT_ACTIVITY,
        payload: data,
    };
};

export const fetchMachinePointActionList = (Id) => {
    return {
        type: FETCH_MACHINE_POINT_ACTION_LIST,
        payload: {
            url: "/isolation/machine/point/action/list/",
            data: {
                Id,
            },
        },
    };
};

export const setMachinePointActionList = (data) => {
    return {
        type: SET_MACHINE_POINT_ACTION_LIST,
        payload: data,
    };
};
