import React from "react";
import { connect } from "react-redux";
import { StyleSheet, css } from "aphrodite";
import { Input, Row, Col } from "reactstrap";
import { injectIntl } from "react-intl";
import { validNumber } from "util";

import { setLoader } from "actions/App";
import { fetchSitesList } from "actions/Settings";
import { fetchMachinesListSelect } from "actions/Machines";
import { searchTickets, setTicketSearchTerms } from "actions/Tickets";

import Tooltip from "components/Tooltips";
import CSV from "components/Export";

class SearchTicket extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchTicketTerms: props.searchTicketTerms,
        };
    }
    componentDidMount() {
        const { fetchSitesList, fetchMachinesListSelect } = this.props;
        fetchMachinesListSelect();
        fetchSitesList();
    }
    handleSearch = () => {
        const { searchTickets, setLoader, setTicketSearchTerms } = this.props;
        let { searchTicketTerms } = this.state;
        setTicketSearchTerms(searchTicketTerms);
        searchTickets(searchTicketTerms);
        setLoader(false);
    };
    keyPress = (e) => {
        if (e.keyCode === 13) {
            this.handleSearch();
        }
    };
    updateData = (e) => {
        let updateData = { ...this.state.searchTicketTerms };
        if (e.target.type === "checkbox") {
            if (e.target.checked) {
                updateData[e.target.name] = true;
                this.setState({
                    searchTicketTerms: updateData,
                    changesMade: true,
                });
            } else {
                updateData[e.target.name] = false;
                this.setState({
                    searchTicketTerms: updateData,
                    changesMade: true,
                });
            }
        } else {
            if (e.target.name === "WorkOrderId") {
                updateData[e.target.name] = Number(e.target.value);
            } else {
                updateData[e.target.name] = e.target.value;
            }
            this.setState({ searchTicketTerms: updateData, changesMade: true });
        }
    };
    clearField = (target) => {
        let updateData = { ...this.state.searchTicketTerms };
        updateData[target] = "";
        this.setState({ searchTicketTerms: updateData, changesMade: true });
    };
    clearFields = () => {
        const { setTicketSearchTerms } = this.props;
        let newSearchTicketTerms = {
            WorkOrderId: 0,
            MachineName: "",
            Name: "",
            SiteId: "",
            SystemTypeWorkOrderStatusId: 1,
            StartDate: "",
            EndDate: "",
            CompanyWorkOrder: "",
            LockoutStatus: "",
            JobName: ""
        };
        setTicketSearchTerms(newSearchTicketTerms);
        this.setState({ searchTicketTerms: newSearchTicketTerms });
        this.props.searchTickets(newSearchTicketTerms);
    };
    render() {
        const { intl, sitesList, width, searchedTickets, authUser, theme } =
            this.props;
        let { searchTicketTerms } = this.state;
        this.styles = styles(theme);
        let exportList = searchedTickets
            ? searchedTickets.map((a) => Object.assign({}, a))
            : [];
        exportList.map((item) => {
            delete item.Icon;
            delete item.Id;
            return item;
        });
        return (
            searchTicketTerms && (
                <div>
                    <div
                        className={css(this.styles.searchWrapper)}
                        onKeyDown={(e) => this.keyPress(e)}
                    >
                        <Col lg={12}>
                            {width < 992 && (
                                <Row>
                                    <Col
                                        lg={12}
                                        className={css(this.styles.flexRow)}
                                    >
                                        <div>
                                            <i
                                                id="searchSeal"
                                                className={`material-icons ${css(
                                                    this.styles.searchIcon
                                                )}`}
                                                onClick={() =>
                                                    this.handleSearch()
                                                }
                                            >
                                                search
                                            </i>
                                            <Tooltip
                                                message={"comp.search.default"}
                                                target="searchSeal"
                                            />
                                        </div>
                                        <div>
                                            <i
                                                id="resetSealSearch"
                                                className={`material-icons ${css(
                                                    this.styles.searchIcon
                                                )}`}
                                                onClick={() =>
                                                    this.clearFields()
                                                }
                                            >
                                                low_priority
                                            </i>
                                            <Tooltip
                                                message={
                                                    "comp.resetSearch.default"
                                                }
                                                target="resetSealSearch"
                                            />
                                        </div>
                                        <div
                                            className={`material-icons ${css(
                                                this.styles.searchIcon
                                            )}`}
                                            onClick={() => this.props.action()}
                                        >
                                            <i
                                                id="refreshTables"
                                                className={`material-icons ${css(
                                                    this.styles.icon
                                                )}`}
                                            >
                                                sync
                                            </i>
                                            <Tooltip
                                                message={"comp.button.refresh"}
                                                target="refreshTables"
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            )}
                            <Row>
                                    {width > 800 && (
                                        <Col
                                        style={{width: '20%'}}
                                            className={css(this.styles.flexRow)}
                                        >
                                            <label
                                                className={css(
                                                    this.styles.labelDropdown
                                                )}
                                            >
                                                {this.props.intl.formatMessage({
                                                    id: "comp.table.header.ticketNumber",
                                                })}
                                            </label>
                                            <Input
                                                name="WorkOrderId"
                                                type="text"
                                                className={css(
                                                    this.styles.inputText
                                                )}
                                                onChange={(e) =>
                                                    validNumber(e) && this.updateData(e)
                                                }
                                                value={
                                                    searchTicketTerms.WorkOrderId !==
                                                    0
                                                        ? searchTicketTerms.WorkOrderId
                                                        : ""
                                                }
                                            />
                                            {searchTicketTerms.WorkOrderId !==
                                                0 && (
                                                <i
                                                    className={`material-icons ${css(
                                                        this.styles.closeIcon
                                                    )}`}
                                                    onClick={() =>
                                                        this.clearField(
                                                            "WorkOrderId"
                                                        )
                                                    }
                                                >
                                                    close
                                                </i>
                                            )}
                                        </Col>
                                    )}
                                    {width > 800 && (
                                        <Col
                                            style={{width: '20%'}}
                                            className={css(this.styles.flexRow)}
                                        >
                                            <label
                                                className={css(
                                                    this.styles.labelDropdown
                                                )}
                                            >
                                                {this.props.intl.formatMessage({
                                                    id: "form.status",
                                                })}
                                            </label>
                                            <Input
                                                className={css(
                                                    this.styles.input
                                                )}
                                                name="SystemTypeWorkOrderStatusId"
                                                type="select"
                                                placeholder={intl.formatMessage(
                                                    { id: "form.selectSite" }
                                                )}
                                                onChange={this.updateData}
                                                value={
                                                    searchTicketTerms.SystemTypeWorkOrderStatusId ||
                                                    ""
                                                }
                                            >
                                                <option value={0}>
                                                    {intl.formatMessage({
                                                        id: "form.all",
                                                    })}
                                                </option>
                                                <option value={1}>
                                                    {intl.formatMessage({
                                                        id: "comp.button.open",
                                                    })}
                                                </option>
                                                <option value={2}>
                                                    {intl.formatMessage({
                                                        id: "comp.button.closed",
                                                    })}
                                                </option>
                                            </Input>
                                        </Col>
                                    )}
                                    {width > 800 && (
                                        <Col
                                            style={{width: '20%'}}
                                            className={css(this.styles.flexRow)}
                                        >
                                            <label
                                                className={css(
                                                    this.styles.labelDropdown
                                                )}
                                            >
                                                {this.props.intl.formatMessage({
                                                    id: "form.companyWorkOrder",
                                                })}
                                            </label>
                                            <Input
                                                name="CompanyWorkOrder"
                                                type="text"
                                                className={css(
                                                    this.styles.inputText
                                                )}
                                                onChange={(e) =>
                                                    this.updateData(e)
                                                }
                                                value={
                                                    searchTicketTerms.CompanyWorkOrder
                                                }
                                            />
                                            {searchTicketTerms.CompanyWorkOrder !==
                                                "" && (
                                                <i
                                                    className={`material-icons ${css(
                                                        this.styles.closeIcon
                                                    )}`}
                                                    onClick={() =>
                                                        this.clearField(
                                                            "CompanyWorkOrder"
                                                        )
                                                    }
                                                >
                                                    close
                                                </i>
                                            )}
                                        </Col>
                                    )}
                                    {width > 800 && (
                                        <Col style={{width: '20%'}}></Col>
                                    )}
                                    {/* {width > 800 && (
                                        <Col
                                            style={{width: '20%'}}
                                            className={css(this.styles.flexRow)}
                                        >
                                            <label
                                                className={css(
                                                    this.styles.labelDropdown
                                                )}
                                            >
                                                {this.props.intl.formatMessage({
                                                    id: "comp.table.header.lockoutStatus",
                                                })}
                                            </label>
                                            <Input
                                                className={css(
                                                    this.styles.input
                                                )}
                                                name="LockoutStatus"
                                                type="select"
                                                placeholder={intl.formatMessage(
                                                    { id: "form.selectSite" }
                                                )}
                                                onChange={this.updateData}
                                                value={
                                                    searchTicketTerms.LockoutStatus ||
                                                    ""
                                                }
                                            >
                                                <option value={""}>
                                                    {intl.formatMessage({
                                                        id: "form.all",
                                                    })}
                                                </option>
                                                <option value={"Red"}>
                                                    {intl.formatMessage({
                                                        id: "pages.dashboard.batteryRed",
                                                    })}
                                                </option>
                                                <option value={"Yellow"}>
                                                    {intl.formatMessage({
                                                        id: "pages.dashboard.batteryYellow",
                                                    })}
                                                </option>
                                                <option value={"Green"}>
                                                    {intl.formatMessage({
                                                        id: "pages.dashboard.batteryGreen",
                                                    })}
                                                </option>
                                            </Input>
                                        </Col>
                                    )} */}
                                    {width > 800 &&
                                        authUser.Settings.Product ===
                                            "lockout" && (
                                            <Col
                                                style={{width: '20%'}}
                                                className={css(
                                                    this.styles.flexRow
                                                )}
                                            >
                                                <label
                                                    className={css(
                                                        this.styles
                                                            .labelDropdown
                                                    )}
                                                >
                                                    {this.props.intl.formatMessage(
                                                        {
                                                            id: "comp.table.header.name",
                                                        }
                                                    )}
                                                </label>
                                                <Input
                                                    name="Name"
                                                    type="text"
                                                    className={css(
                                                        this.styles.inputText
                                                    )}
                                                    onChange={(e) =>
                                                        this.updateData(e)
                                                    }
                                                    value={
                                                        searchTicketTerms.Name
                                                    }
                                                />
                                                {searchTicketTerms.Name !==
                                                    "" && (
                                                    <i
                                                        className={`material-icons ${css(
                                                            this.styles
                                                                .closeIcon
                                                        )}`}
                                                        onClick={() =>
                                                            this.clearField(
                                                                "Name"
                                                            )
                                                        }
                                                    >
                                                        close
                                                    </i>
                                                )}
                                            </Col>
                                        )}
                                {width > 800 && (
                                    <Col style={{width: '20%'}}>
                                        {width > 991 && (
                                            <Row>
                                                <Col
                                                    lg={12}
                                                    className={css(
                                                        this.styles.flexRow,
                                                        this.styles.rightAligned
                                                    )}
                                                >
                                                    <div>
                                                        <i
                                                            id="searchSeal"
                                                            className={`material-icons ${css(
                                                                this.styles
                                                                    .searchIcon
                                                            )}`}
                                                            onClick={() =>
                                                                this.handleSearch()
                                                            }
                                                        >
                                                            search
                                                        </i>
                                                        <Tooltip
                                                            message={
                                                                "comp.search.default"
                                                            }
                                                            target="searchSeal"
                                                        />
                                                    </div>
                                                    <div>
                                                        <i
                                                            id="resetSealSearch"
                                                            className={`material-icons ${css(
                                                                this.styles
                                                                    .searchIcon
                                                            )}`}
                                                            onClick={() =>
                                                                this.clearFields()
                                                            }
                                                        >
                                                            low_priority
                                                        </i>
                                                        <Tooltip
                                                            message={
                                                                "comp.resetSearch.default"
                                                            }
                                                            target="resetSealSearch"
                                                        />
                                                    </div>
                                                    <div
                                                        className={`material-icons ${css(
                                                            this.styles
                                                                .searchIcon
                                                        )}`}
                                                        onClick={() =>
                                                            this.props.action()
                                                        }
                                                    >
                                                        <i
                                                            id="refreshTables"
                                                            className={`material-icons ${css(
                                                                this.styles.icon
                                                            )}`}
                                                        >
                                                            sync
                                                        </i>
                                                        <Tooltip
                                                            message={
                                                                "comp.button.refresh"
                                                            }
                                                            target="refreshTables"
                                                        />
                                                    </div>
                                                    {authUser.Permissions.includes(
                                                        "ExportWorkOrderList"
                                                    ) && (
                                                        <div>
                                                            <CSV
                                                                data={
                                                                    exportList
                                                                }
                                                                margin={
                                                                    "0 12px 0 0"
                                                                }
                                                            />
                                                            <Tooltip
                                                                message={
                                                                    "comp.button.export"
                                                                }
                                                                target="exportFile"
                                                            />
                                                        </div>
                                                    )}
                                                </Col>
                                            </Row>
                                        )}
                                    </Col>
                                )}
                            </Row>
                            <Row>
                                <Col
                                    lg={12}
                                    className={css(this.styles.flexRow)}
                                >
                                    {width > 800 && (
                                        <Col
                                            style={{width: '20%'}}
                                            className={css(this.styles.flexRow)}
                                        >
                                            <label
                                                className={css(
                                                    this.styles.labelDropdown
                                                )}
                                            >
                                                {this.props.intl.formatMessage({
                                                    id: "comp.sites.Header",
                                                })}
                                            </label>
                                            <Input
                                                className={css(
                                                    this.styles.input
                                                )}
                                                name="SiteId"
                                                type="select"
                                                placeholder={intl.formatMessage(
                                                    { id: "form.selectSite" }
                                                )}
                                                onChange={this.updateData}
                                                value={
                                                    searchTicketTerms.SiteId ||
                                                    ""
                                                }
                                            >
                                                <option value={""}>
                                                    {intl.formatMessage({
                                                        id: "form.all",
                                                    })}
                                                </option>
                                                {sitesList.map((site) => (
                                                    <option
                                                        key={"site" + site.Id}
                                                        value={site.Id || ""}
                                                    >
                                                        {site.SiteName}
                                                    </option>
                                                ))}
                                            </Input>
                                        </Col>
                                    )}
                                    {width > 800 &&
                                        authUser.Settings.Product ===
                                            "isolation" && (
                                            <Col
                                                style={{width: '20%'}}
                                                className={css(
                                                    this.styles.flexRow
                                                )}
                                            >
                                                <label
                                                    className={css(
                                                        this.styles
                                                            .labelDropdown
                                                    )}
                                                >
                                                    {this.props.intl.formatMessage(
                                                        {
                                                            id: "pages.machines.Type",
                                                        }
                                                    )}
                                                </label>
                                                <Input
                                                    name="MachineName"
                                                    type="text"
                                                    className={css(
                                                        this.styles.inputText
                                                    )}
                                                    onChange={(e) =>
                                                        this.updateData(e)
                                                    }
                                                    value={
                                                        searchTicketTerms.MachineName
                                                    }
                                                />
                                                {searchTicketTerms.MachineName !==
                                                    "" && (
                                                    <i
                                                        className={`material-icons ${css(
                                                            this.styles
                                                                .closeIcon
                                                        )}`}
                                                        onClick={() =>
                                                            this.clearField(
                                                                "MachineName"
                                                            )
                                                        }
                                                    >
                                                        close
                                                    </i>
                                                )}
                                            </Col>
                                        )}
                                        {width > 800 &&
                                        authUser.Settings.Product ===
                                            "isolation" && (
                                            <Col
                                                style={{width: '20%'}}
                                                className={css(
                                                    this.styles.flexRow
                                                )}
                                            >
                                                <label
                                                    className={css(
                                                        this.styles
                                                            .labelDropdown
                                                    )}
                                                >
                                                    {this.props.intl.formatMessage(
                                                        {
                                                            id: "comp.job.Header",
                                                        }
                                                    )}
                                                </label>
                                                <Input
                                                    name="JobName"
                                                    type="text"
                                                    className={css(
                                                        this.styles.inputText
                                                    )}
                                                    onChange={(e) =>
                                                        this.updateData(e)
                                                    }
                                                    value={
                                                        searchTicketTerms.JobName
                                                    }
                                                />
                                                {searchTicketTerms.JobName !==
                                                    "" && (
                                                    <i
                                                        className={`material-icons ${css(
                                                            this.styles
                                                                .closeIcon
                                                        )}`}
                                                        onClick={() =>
                                                            this.clearField(
                                                                "JobName"
                                                            )
                                                        }
                                                    >
                                                        close
                                                    </i>
                                                )}
                                            </Col>
                                        )}
                                    {width > 800 && (
                                        <Col
                                            style={{width: '20%'}}
                                            className={css(this.styles.flexRow)}
                                        >
                                            <label
                                                className={css(
                                                    this.styles.labelDropdown
                                                )}
                                            >
                                                {this.props.intl.formatMessage({
                                                    id: "form.start",
                                                })}
                                            </label>
                                            <Input
                                                onChange={this.updateData}
                                                type="date"
                                                name="StartDate"
                                                id="StartDate"
                                                placeholder="date placeholder"
                                                className={css(
                                                    this.styles.inputSelect,
                                                    this.styles.dateInput
                                                )}
                                                value={
                                                    searchTicketTerms.StartDate
                                                }
                                            />
                                        </Col>
                                    )}
                                    {width > 800 && (
                                        <Col
                                            style={{width: '20%'}}
                                            className={css(this.styles.flexRow)}
                                        >
                                            <label
                                                className={css(
                                                    this.styles.labelDropdown
                                                )}
                                            >
                                                {this.props.intl.formatMessage({
                                                    id: "form.end",
                                                })}
                                            </label>
                                            <Input
                                                onChange={this.updateData}
                                                type="date"
                                                name="EndDate"
                                                id="EndDate"
                                                placeholder="date placeholder"
                                                className={css(
                                                    this.styles.inputSelect,
                                                    this.styles.dateInput,
                                                    this.styles.secondDateInput
                                                )}
                                                value={
                                                    searchTicketTerms.EndDate
                                                }
                                            />
                                        </Col>
                                    )}
                                </Col>
                            </Row>
                        </Col>
                    </div>
                </div>
            )
        );
    }
}

SearchTicket.defaultProps = {
    searchTitle: "blank",
    searchType: ["name"],
    data: [],
};

const mapStateToProps = ({ app, auth, settings, tickets }) => {
    const { theme, width } = app;
    const { authUser } = auth;
    const { sitesList } = settings;
    const { searchTicketTerms, searchedTickets } = tickets;
    return {
        theme,
        width,
        authUser,
        sitesList,
        searchTicketTerms,
        searchedTickets,
    };
};

export default injectIntl(
    connect(mapStateToProps, {
        searchTickets,
        setLoader,
        setTicketSearchTerms,
        fetchSitesList,
        fetchMachinesListSelect,
    })(SearchTicket)
);

const styles = (theme) =>
    StyleSheet.create({
        flexRow: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            height: "2.25rem",
            paddingLeft: 0,
        },
        rightAligned: {
            paddingRight: 0,
            justifyContent: "flex-end",
        },
        input: {
            border: "none",
            boxShadow: "none",
            borderRadius: 0,
            fontWeight: 400,
            fontSize: 12,
            letterSpacing: 2,
            marginBottom: 0,
            color: theme.color.themeColor,
            border: "1px solid white",
            backgroundColor: theme.color.compColor,
            "::placeholder": {
                letterSpacing: 1,
                color: theme.color.fontColor,
                fontSize: 12,
            },
        },
        inputSelect: {
            border: "none",
            boxShadow: "none",
            borderRadius: 0,
            fontWeight: 400,
            fontSize: 12,
            letterSpacing: 2,
            border: "1px solid white",
            paddingTop: 0,
            paddingRight: 0,
            paddingBottom: 0,
            color: theme.color.themeColor,
            backgroundColor: theme.color.compColor,
            "::placeholder": {
                letterSpacing: 1,
                color: theme.color.fontColor,
                fontSize: 12,
            },
        },
        dateInput: {
            marginBottom: 4,
            marginLeft: 4,
        },
        secondDateInput: {
            paddingRight: 6,
        },
        inputText: {
            border: "none",
            boxShadow: "none",
            borderRadius: 0,
            fontWeight: 400,
            fontSize: 12,
            letterSpacing: 2,
            marginBottom: 0,
            padding: 0,
            width: "100%",
            marginLeft: 5,
            color: theme.color.themeColor,
            borderBottom: `solid whitesmoke 1px`,
            backgroundColor: theme.color.compColor,
            "::placeholder": {
                letterSpacing: 1,
                color: theme.color.themeColor,
                fontSize: 12,
            },
        },
        searchWrapper: {
            position: "relative",
            display: "flex",
            flexDirection: "row",
        },
        searchIcon: {
            color: theme.color.themeColor,
            marginRight: 12,
            ":hover": {
                cursor: "pointer",
                opacity: ".8",
            },
        },
        closeIcon: {
            color: theme.color.fontColor,
            position: "absolute",
            top: 5,
            right: 5,
            fontSize: 16,
            ":hover": {
                cursor: "pointer",
                opacity: ".8",
            },
        },
        label: {
            marginLeft: 6,
        },
        labelDropdown: {
            fontSize: 12,
            color: theme.color.fontColor,
            whiteSpace: "nowrap",
        },
    });
