import React from "react";

export const HelpIcon = (props) => (
    <svg
        width={props.width || 23}
        height={props.height || 23}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 33.24 32.88"
    >
        <defs></defs>
        <title></title>
        <path
            stroke={props.color || "none"}
            fill={props.fill || "none"}
            strokeMiterlimit="10"
            strokeWidth="1.2"
            className="cls-1"
            d="M29.83,11A6.62,6.62,0,0,0,24.4,8.68a7.48,7.48,0,0,0-7.47,7.47v4.58H14.17a.74.74,0,0,0-.75.75V40.41a.74.74,0,0,0,.75.75H34.63a.75.75,0,0,0,.75-.75V30.83"
            transform="translate(-13.22 -8.48)"
        />
        <path
            stroke={props.color || "none"}
            fill={props.fill || "none"}
            strokeMiterlimit="10"
            strokeWidth="1.2"
            className="cls-1"
            d="M24.82,20.75h-4.7v-4.7a4.28,4.28,0,0,1,4.28-4.28,4.46,4.46,0,0,1,3,1.24"
            transform="translate(-13.22 -8.48)"
        />
        <circle
            stroke={props.color || "none"}
            fill={props.fill || "none"}
            strokeMiterlimit="10"
            strokeWidth="1.2"
            className="cls-1"
            cx="22.33"
            cy="11.65"
            r="10.7"
        />
        <path
            stroke={props.color || "none"}
            fill={props.fill || "none"}
            strokeMiterlimit="10"
            strokeWidth="1.2"
            className="cls-2"
            d="M37.47,26a1.76,1.76,0,1,1-1.76-1.77A1.76,1.76,0,0,1,37.47,26Z"
            transform="translate(-13.22 -8.48)"
        />
        <path
            stroke={props.color || "none"}
            fill={props.fill || "none"}
            strokeMiterlimit="10"
            strokeWidth="1.2"
            className="cls-2"
            d="M32.81,13.33a5,5,0,0,1,2.73-.71,5.85,5.85,0,0,1,3.53,1,3.46,3.46,0,0,1,1.41,3,3.46,3.46,0,0,1-.61,2.06A7.34,7.34,0,0,1,38.5,20l-.67.52a2,2,0,0,0-.72,1A4.16,4.16,0,0,0,37,22.62H34.44a7.63,7.63,0,0,1,.3-2.2A3.88,3.88,0,0,1,36,19l.68-.53a2.19,2.19,0,0,0,.54-.56,1.88,1.88,0,0,0,.38-1.13,2.2,2.2,0,0,0-.42-1.31,1.74,1.74,0,0,0-1.52-.58,1.7,1.7,0,0,0-1.55.72,2.79,2.79,0,0,0-.45,1.5H30.94A4.42,4.42,0,0,1,32.81,13.33Z"
            transform="translate(-13.22 -8.48)"
        />
    </svg>
);

export const LockIcon = (props) => (
    <svg
        width={props.width || 17}
        height={props.height || 22}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24.98 36.76"
    >
        <defs></defs>
        <title></title>
        <path
            stroke={props.color || "none"}
            fill={props.fill || "none"}
            strokeMiterlimit="10"
            strokeWidth="1.2"
            className="cls-1"
            d="M37,19.31H34V14.19a8.36,8.36,0,0,0-16.72,0v5.12H14.14a.84.84,0,0,0-.84.84v21.2a.84.84,0,0,0,.84.84H37a.85.85,0,0,0,.84-.84V20.15A.85.85,0,0,0,37,19.31Z"
            transform="translate(-13.1 -5.63)"
        />
        <path
            stroke={props.color || "none"}
            fill={props.fill || "none"}
            strokeMiterlimit="10"
            strokeWidth="1.2"
            className="cls-1"
            d="M30.38,19.34H20.8V14.07a4.79,4.79,0,1,1,9.58,0Z"
            transform="translate(-13.1 -5.63)"
        />
    </svg>
);

export const UnlockIcon = (props) => (
    <svg
        width={props.width || 25}
        height={props.height || 25}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 150 150"
    >
        <defs></defs>
        <title>unlock</title>
        <g
            id="Page-1"
            stroke={props.color || "none"}
            strokeWidth="4"
            fill={props.fill || "none"}
            fillRule="evenodd"
        >
            <g id="unlock" fillRule="nonzero" fill={props.fill || "none"}>
                <g id="Group" transform="translate(32.000000, 1.000000)">
                    <path
                        d="M105.812506,29.2852306 C106.201477,22.9084819 103.01728,16.8425871 97.545565,13.5367093 C92.0738502,10.2308316 85.2180976,10.2308316 79.7463828,13.5367093 C74.2746681,16.8425871 71.0904712,22.9084819 71.4794422,29.2852306 L71.4794422,61.1050593 L59.822762,61.1050593 L59.822762,30.4307444 C59.3233396,19.8196197 64.7051726,9.79386475 73.828828,4.33900481 C82.9524833,-1.11585513 94.3394645,-1.11585513 103.46312,4.33900481 C112.586775,9.79386475 117.968608,19.8196197 117.469186,30.4307444 L117.469186,44.4951087 L105.812506,44.4951087 L105.812506,29.2852306 Z"
                        id="Shape"
                    ></path>
                    <path
                        d="M82.1806568,59.9595455 L2.68591993,59.9595455 C1.19207083,60.0895768 0.0375554128,61.3254304 0.0106162843,62.82333 L0.0106162843,135.881657 C0.0452617597,137.473522 1.34701688,138.745818 2.94071075,138.745441 L81.7984705,138.745441 C83.3815378,138.745441 84.6648673,137.463281 84.6648673,135.881657 L84.6648673,62.6324111 C84.5852093,61.2616733 83.5429935,60.1403099 82.1806568,59.9595455 Z"
                        id="Shape"
                    ></path>
                </g>
            </g>
        </g>
    </svg>
);

export const VerificationFailedIcon = (props) => (
    <svg
        width={props.width || 25}
        height={props.height || 25}
        viewBox="0 0 125 125"
        xmlns="http://www.w3.org/2000/svg"
    >
        <defs></defs>
        <g
            id="Page-1"
            stroke={props.color || "none"}
            strokeWidth="4"
            fill={props.fill || "none"}
            fillRule="evenodd"
        >
            <path
                d="M71.9788732,54.4094744 L71.9788732,29.8672209 C71.9788732,24.9083261 67.9588948,20.8883476 63,20.8883476 L63,20.8883476 L63,20.8883476 C58.0411052,20.8883476 54.0211268,24.9083261 54.0211268,29.8672209 L54.0211268,29.8672209 L54.0211268,54.4094744 L29.4788732,54.4094744 C24.5199785,54.4094744 20.5,58.4294529 20.5,63.3883476 C20.5,68.3472424 24.5199785,72.3672209 29.4788732,72.3672209 L54.0211268,72.3672209 L54.0211268,96.9094744 C54.0211268,101.868369 58.0411052,105.888348 63,105.888348 C67.9588948,105.888348 71.9788732,101.868369 71.9788732,96.9094744 L71.9788732,72.3672209 L96.5211268,72.3672209 C101.480022,72.3672209 105.5,68.3472424 105.5,63.3883476 L105.5,63.3883476 C105.5,58.4294529 101.480022,54.4094744 96.5211268,54.4094744 L96.5211268,54.4094744 L71.9788732,54.4094744 Z M63,125.888348 C28.4822031,125.888348 0.5,97.9061445 0.5,63.3883476 C0.5,28.8705508 28.4822031,0.888347648 63,0.888347648 C97.5177969,0.888347648 125.5,28.8705508 125.5,63.3883476 C125.5,97.9061445 97.5177969,125.888348 63,125.888348 Z"
                id="Combined-Shape"
                fill="#EC1C24"
                transform="translate(63.000000, 63.388348) rotate(45.000000) translate(-63.000000, -63.388348) "
            ></path>
        </g>
    </svg>
);

export const VerificationNeededIcon = (props) => (
    <svg
        width={props.width || 25}
        height={props.height || 25}
        viewBox="0 0 125 125"
        xmlns="http://www.w3.org/2000/svg"
    >
        <defs></defs>
        <g
            id="Page-1"
            stroke={props.color || "none"}
            strokeWidth="4"
            fill={props.fill || "none"}
            fillRule="evenodd"
        >
            <path
                d="M63.1116524,125 C28.5938555,125 0.611652352,97.0177969 0.611652352,62.5 C0.611652352,27.9822031 28.5938555,0 63.1116524,0 C97.6294492,0 125.611652,27.9822031 125.611652,62.5 C125.611652,97.0177969 97.6294492,125 63.1116524,125 Z M58.9206443,28 C58.8207565,28 58.7208912,28.0029933 58.6211828,28.0089758 C55.8647162,28.1743638 53.764232,30.5429949 53.92962,33.2994615 L56.32962,73.2994615 C56.4880423,75.9398329 58.6755245,78 61.3206443,78 L64.9026604,78 C67.5477802,78 69.7352624,75.9398329 69.8936847,73.2994615 L72.2936847,33.2994615 C72.2996672,33.199753 72.3026604,33.0998877 72.3026604,33 C72.3026604,30.2385763 70.0640842,28 67.3026604,28 L58.9206443,28 Z M63.1116524,97 C67.253788,97 70.6116524,93.6421356 70.6116524,89.5 C70.6116524,85.3578644 67.253788,82 63.1116524,82 C58.9695167,82 55.6116524,85.3578644 55.6116524,89.5 C55.6116524,93.6421356 58.9695167,97 63.1116524,97 Z"
                id="Combined-Shape"
                fill="#FBB216"
            ></path>
        </g>
    </svg>
);

export const VerificationPassedIcon = (props) => (
    <svg
        width={props.width || 25}
        height={props.height || 25}
        viewBox="0 0 125 125"
        xmlns="http://www.w3.org/2000/svg"
    >
        <defs></defs>
        <g
            id="Page-1"
            stroke={props.color || "none"}
            strokeWidth="4"
            fill={props.fill || "none"}
            fillRule="evenodd"
        >
            <path
                d="M51.1126984,96.1426586 C53.2240575,96.1426586 55.3354166,95.3372023 56.9463293,93.7262896 L99.7262896,50.9463293 C102.948115,47.7245039 102.948115,42.5008928 99.7262896,39.2790674 C96.5044642,36.057242 91.2808531,36.057242 88.0590277,39.2790674 L88.0590277,39.2790674 L51.1126984,76.2253967 L37.5008928,62.6135912 C34.2790674,59.3917658 29.0554564,59.3917658 25.8336309,62.6135912 L25.8336309,62.6135912 L25.8336309,62.6135912 C22.6118055,65.8354166 22.6118055,71.0590277 25.8336309,74.2808531 L45.2790674,93.7262896 C46.8899801,95.3372023 49.0013393,96.1426586 51.1126984,96.1426586 Z M62.5,125 C27.9822031,125 0,97.0177969 0,62.5 C0,27.9822031 27.9822031,0 62.5,0 C97.0177969,0 125,27.9822031 125,62.5 C125,97.0177969 97.0177969,125 62.5,125 Z"
                id="Combined-Shape"
                fill="#77BF43"
            ></path>
        </g>
    </svg>
);

export const SettingsIcon = (props) => (
    <svg
        width={props.width || 21}
        height={props.height || 19}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 37.66 37.17"
    >
        <defs></defs>
        <title></title>
        <path
            stroke={props.color || "none"}
            fill={props.fill || "none"}
            strokeMiterlimit="10"
            strokeWidth="1.2"
            className="cls-1"
            d="M44.1,22.5l-4.27-1.39a.86.86,0,0,1-.56-.58,13.94,13.94,0,0,0-.69-1.84.91.91,0,0,1,.05-.82l2.27-3.71a.86.86,0,0,0-.1-1L38.45,10.6a.84.84,0,0,0-1-.18l-3.86,2a.88.88,0,0,1-.79,0,13.28,13.28,0,0,0-2-.92.84.84,0,0,1-.53-.61l-1-4.07a.85.85,0,0,0-.8-.65l-1.73-.07L25,6a.87.87,0,0,0-.85.59l-1.29,4a.86.86,0,0,1-.57.56,13.36,13.36,0,0,0-2.06.76.85.85,0,0,1-.79-.05l-3.7-2.26a.84.84,0,0,0-1,.1L12.22,12A.87.87,0,0,0,12,13l2,3.88a.86.86,0,0,1,0,.81,13.7,13.7,0,0,0-.82,1.78.87.87,0,0,1-.61.54L8.21,21.1a.84.84,0,0,0-.65.8l-.14,3.45a.85.85,0,0,0,.59.84l4.25,1.38a.87.87,0,0,1,.56.59,14.34,14.34,0,0,0,.62,1.74.87.87,0,0,1,0,.8L11,34.6a.87.87,0,0,0,.11,1l2.34,2.53a.86.86,0,0,0,1,.19l4-2a.83.83,0,0,1,.81,0,14.34,14.34,0,0,0,2.06,1,.86.86,0,0,1,.54.59l1,4.09a.84.84,0,0,0,.79.65l1.73.07,1.73.06a.84.84,0,0,0,.84-.58l1.3-4a.84.84,0,0,1,.58-.55,13.93,13.93,0,0,0,2.13-.8.86.86,0,0,1,.81.05l3.79,2.32a.84.84,0,0,0,1-.1l2.54-2.34a.87.87,0,0,0,.18-1l-2.08-4.08a.87.87,0,0,1,0-.8A12.7,12.7,0,0,0,39,29.18a.91.91,0,0,1,.61-.55l4.34-1a.85.85,0,0,0,.65-.8l.13-3.45A.83.83,0,0,0,44.1,22.5Z"
            transform="translate(-7.22 -5.79)"
        />
        <circle
            stroke={props.color || "none"}
            fill={props.fill || "none"}
            strokeMiterlimit="10"
            strokeWidth="1.2"
            className="cls-1"
            cx="26.04"
            cy="24.78"
            r="5.44"
            transform="translate(-12.03 37.45) rotate(-74.49)"
        />
    </svg>
);

export const TicketIcon = (props) => (
    <svg
        width={props.width || 21}
        height={props.height || 19}
        viewBox="0 0 21 19"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink"
    >
        <title></title>
        <desc>Created with sketchtool.</desc>
        <g
            id="Page-1"
            stroke="none"
            strokeWidth="1"
            fill={props.fill || "none"}
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <g
                id="Noke_LOTO_Web_Home"
                transform="translate(-30.000000, -168.000000)"
                stroke={props.color || "#3D5F7B"}
                strokeWidth="0.863636364"
            >
                <g id="Side-Menu">
                    <g id="Group-4">
                        <g transform="translate(27.799999, 114.000000)">
                            <g
                                id="sites/on"
                                transform="translate(1.200001, 51.000000)"
                            >
                                <g
                                    id="Group"
                                    transform="translate(2.000000, 3.000000)"
                                >
                                    <g id="office-note-notebook-paper-piece">
                                        <g id="XMLID_1344_">
                                            <path
                                                d="M18.5682065,14.25 L18.5681571,1.72727273 C18.5681571,1.01181296 17.9881617,0.431818182 17.2727026,0.431818182 L1.72724803,0.431818182 C1.01178826,0.431818182 0.431793482,1.01181296 0.431793482,1.72727273 L0.431793482,17.2727273 C0.431793482,17.9881864 1.01178826,18.5681818 1.72724803,18.5681818 L14.2499753,18.5681818 L18.5682065,14.25 Z"
                                                id="XMLID_1359_"
                                            ></path>
                                            <path
                                                d="M14.2499753,18.5681818 L14.2499753,15.5454545 C14.2499753,14.8299954 14.829969,14.25 15.5454298,14.25 L18.5682065,14.25"
                                                id="XMLID_1354_"
                                            ></path>
                                        </g>
                                        <path
                                            d="M4.75002513,2.15909091 L4.75002513,16.8409091"
                                            id="Path"
                                        ></path>
                                        <path
                                            d="M2.15911604,4.75 L16.8409338,4.75"
                                            id="Path"
                                        ></path>
                                        <path
                                            d="M2.15911604,9.5 L16.8409338,9.5"
                                            id="Path"
                                        ></path>
                                        <path
                                            d="M2.15911604,14.25 L12.5227528,14.25"
                                            id="Path"
                                        ></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export const LockoutIcon = (props) => (
    <svg
        width={props.width || 23}
        height={props.height || 22}
        viewBox="0 0 23 22"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink"
    >
        <title></title>
        <desc>Created with sketchtool.</desc>
        <g
            id="Page-1"
            stroke="none"
            strokeWidth="1"
            fill={props.fill || "none"}
            fillRule="evenodd"
        >
            <g
                id="Noke_LOTO_Web_Home"
                transform="translate(-30.000000, -323.000000)"
                fill={props.color || "#3D5F7B"}
                fillRule="nonzero"
            >
                <g id="Side-Menu">
                    <g id="Group-4">
                        <g transform="translate(27.799999, 114.000000)">
                            <g
                                id="users/off"
                                transform="translate(2.400001, 209.000000)"
                            >
                                <g id="Group">
                                    <g id="Tab-3">
                                        <g id="action-people-user-access-key">
                                            <path
                                                d="M19.25,14.6483155 C18.7446696,14.6483155 18.3333333,15.0596517 18.3333333,15.5649821 C18.3333333,16.0703125 18.7446696,16.4816488 19.25,16.4816488 C19.7553304,16.4816488 20.1666667,16.0703125 20.1666667,15.5649821 C20.1666667,15.0596517 19.7553304,14.6483155 19.25,14.6483155 Z"
                                                id="Shape"
                                            ></path>
                                            <path
                                                d="M18.7916667,12.8333333 C18.0679678,12.8333333 17.4068756,13.0832011 16.8694865,13.4891103 C15.2070771,12.7468363 12.7579701,11.9166667 10.0833333,11.9166667 C6.94213868,11.9166667 4.09277339,13.040568 2.25585938,13.9836426 C0.864298508,14.6979981 0,16.1079101 0,17.6623942 L0,18.3333333 C0,19.3444417 0.822224975,20.1666667 1.83333333,20.1666667 L12.8333333,20.1666667 L12.8333333,21.5416667 C12.8333333,21.7950033 13.0383301,22 13.2916667,22 L14.6666667,22 C14.7884115,22 14.9047851,21.9516601 14.9907226,21.8657226 L17.7756347,19.0808106 C18.105957,19.1931559 18.4465739,19.25 18.7916667,19.25 C20.5605469,19.25 22,17.8105469 22,16.0416667 C22,14.2727865 20.5605469,12.8333333 18.7916667,12.8333333 Z M1.83333333,19.25 C1.32800296,19.25 0.916666667,18.8386637 0.916666667,18.3333333 L0.916666667,17.6623942 C0.916666667,16.453003 1.59029136,15.355957 2.67480473,14.7991536 C4.42175296,13.902181 7.12520343,12.8333333 10.0833333,12.8333333 C12.4344177,12.8333333 14.6182709,13.5225678 16.1823781,14.1882477 C15.8085276,14.7128245 15.5833333,15.3499705 15.5833333,16.0416667 C15.5833333,16.3867594 15.6401774,16.7273763 15.7525228,17.0576985 L13.5602214,19.25 L1.83333333,19.25 Z M18.7916667,18.3333333 C18.4720866,18.3333333 18.1556396,18.2639567 17.8521729,18.1269938 C17.6802979,18.049113 17.4748535,18.0858154 17.3396811,18.2205403 L14.4768881,21.0833333 L13.75,21.0833333 L13.75,20.3564453 L16.612793,17.4936523 C16.747518,17.3589273 16.7851156,17.1548258 16.7063396,16.9811604 C16.5693766,16.6776937 16.5,16.3612467 16.5,16.0416667 C16.5,14.7781168 17.5281168,13.75 18.7916667,13.75 C20.0552165,13.75 21.0833333,14.7781168 21.0833333,16.0416667 C21.0833333,17.3052165 20.0552165,18.3333333 18.7916667,18.3333333 Z"
                                                id="Shape"
                                            ></path>
                                            <path
                                                d="M10.0833333,11 C13.116211,11 15.5833333,8.53287756 15.5833333,5.5 C15.5833333,2.46712244 13.1162109,0 10.0833333,0 C7.05045578,0 4.58333333,2.46712244 4.58333333,5.5 C4.58333333,8.53287756 7.05045578,11 10.0833333,11 Z M10.0833333,0.916666667 C12.6104329,0.916666667 14.6666667,2.97290043 14.6666667,5.5 C14.6666667,8.02709958 12.6104329,10.0833333 10.0833333,10.0833333 C7.55623376,10.0833333 5.5,8.02709958 5.5,5.5 C5.5,2.97290043 7.55623376,0.916666667 10.0833333,0.916666667 Z"
                                                id="Shape"
                                            ></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export const UserIcon = (props) => (
    <svg
        width={props.width || 20}
        height={props.height || 19}
        xmlns="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 25.96 24.47"
    >
        <style></style>
        <path
            stroke={props.color || "none"}
            fill={props.fill || "none"}
            strokeMiterlimit="10"
            strokeWidth=".7"
            className="st0"
            d="M20.59,19.02L17.06,18c-0.38-0.11-0.65-0.46-0.65-0.86v-1.02c1.88-1.52,2.61-4.88,2.61-7.17
        c0-3.34-2.72-6.06-6.06-6.06c-3.34,0-6.06,2.72-6.06,6.06c0,2.26,0.7,5.54,2.53,7.1v1.09c0,0.4-0.27,0.75-0.65,0.86l-3.5,1.01
        c-1.41,0.23-2.44,1.44-2.44,2.88c0,0.19,0.15,0.34,0.34,0.34c0.19,0,0.34-0.15,0.34-0.34c0-1.1,0.79-2.03,1.91-2.22l3.53-1.02
        c0.67-0.19,1.14-0.81,1.14-1.51v-0.63c0.28,0.15,0.58,0.27,0.9,0.34c0.02,0.01,0.05,0.01,0.07,0.01c0.16,0,0.3-0.11,0.33-0.27
        c0.04-0.18-0.08-0.36-0.26-0.4C8.82,15.7,7.57,12.1,7.57,8.96c0-2.97,2.41-5.38,5.38-5.38c2.97,0,5.38,2.41,5.38,5.38
        c0,3.13-1.25,6.72-3.55,7.23c-0.18,0.04-0.3,0.22-0.26,0.4c0.04,0.18,0.22,0.3,0.4,0.26c0.29-0.06,0.55-0.17,0.8-0.3v0.59
        c0,0.7,0.47,1.32,1.14,1.51l3.57,1.03c1.09,0.18,1.87,1.11,1.87,2.21c0,0.19,0.15,0.34,0.34,0.34S23,22.08,23,21.89
        C23,20.46,21.97,19.25,20.59,19.02z"
        />
    </svg>
);

export const MachineIcon = (props) => (
    <svg
        width={props.width || 24}
        height={props.height || 20}
        viewBox="0 0 24 20"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink"
    >
        <title></title>
        <desc>Created with sketchtool.</desc>
        <g
            id="Page-1"
            stroke="none"
            strokeWidth="1"
            fill={props.fill || "none"}
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <g
                id="Noke_LOTO_Web_Home"
                transform="translate(-985.000000, -869.000000)"
                stroke={props.color || "#3D5F7B"}
            >
                <g
                    id="farm-tractor-farm-vehicle-tractor-agriculture-farming"
                    transform="translate(986.000000, 870.000000)"
                >
                    <path
                        d="M16.9583333,6.75 L16.9583333,2.2499991 C16.9583333,1.75294332 17.3687377,1.35 17.8750017,1.35 L17.8750017,1.35"
                        id="Path"
                    ></path>
                    <path
                        d="M11.4583333,6.75 L0.916666667,6.75000261"
                        id="Path"
                    ></path>
                    <g id="Group">
                        <path
                            d="M20.5787862,14.85 L21.5416667,14.85 L21.5416667,8.1 C21.5416667,7.35441543 20.9260575,6.75 20.1666667,6.75 L11.4583333,6.75 L9.90993107,1.42912647 C9.74127669,0.84956931 9.20174576,0.45 8.58783512,0.45 L2.65528881,0.45 C1.93391064,0.45 1.33517927,0.99735408 1.28378323,1.70381637 L0.458333333,13.05"
                            id="Path"
                        ></path>
                        <path
                            d="M9.23805749,14.85 L14.2545471,14.85"
                            id="Path"
                        ></path>
                        <path
                            d="M0.505274458,13.5072509 C0.489384958,13.3547607 0.458333333,13.2066101 0.458333333,13.05 L0.458333333,13.5 L0.505274458,13.5072509 Z"
                            id="Path"
                        ></path>
                    </g>
                    <ellipse
                        id="Oval"
                        cx="5.04166667"
                        cy="13.05"
                        rx="1.83333333"
                        ry="1.8"
                    ></ellipse>
                    <ellipse
                        id="Oval"
                        cx="17.4166667"
                        cy="14.4"
                        rx="1"
                        ry="1"
                    ></ellipse>
                    <ellipse
                        id="Oval"
                        cx="5.04166667"
                        cy="13.05"
                        rx="4.58333333"
                        ry="4.5"
                    ></ellipse>
                    <ellipse
                        id="Oval"
                        cx="17.4166667"
                        cy="14.4"
                        rx="3.20833333"
                        ry="3.15"
                    ></ellipse>
                </g>
            </g>
        </g>
    </svg>
);

export const DigIcon = (props) => (
    <svg
        width={props.width || 24}
        height={props.height || 20}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 40.33 32.82"
    >
        <defs></defs>
        <title></title>
        <path
            stroke={props.color || "none"}
            fill={props.fill || "none"}
            strokeMiterlimit="10"
            strokeWidth="1.2"
            className="cls-1"
            d="M7.87,23.43l-1.78,2.7a2.83,2.83,0,0,0-.26,2.7,3.47,3.47,0,0,0,3.31,2l5.8-.23a.58.58,0,0,0,.29-1.06L12,27.87a1.38,1.38,0,0,1-.52-.47l-2.67-4A.58.58,0,0,0,7.87,23.43Z"
            transform="translate(-5.4 -8.27)"
        />
        <rect
            stroke={props.color || "none"}
            fill={props.fill || "none"}
            strokeMiterlimit="10"
            strokeWidth="1.2"
            className="cls-1"
            x="14.37"
            y="24.8"
            width="25.76"
            height="7.81"
            rx="3.35"
        />
        <path
            stroke={props.color || "none"}
            fill={props.fill || "none"}
            strokeMiterlimit="10"
            strokeWidth="1.2"
            className="cls-1"
            d="M43,32.93V24.58a.77.77,0,0,0-.77-.77H35.85a.77.77,0,0,0-.77.77v2.73a.77.77,0,0,1-.48.71L22.82,33"
            transform="translate(-5.4 -8.27)"
        />
        <path
            stroke={props.color || "none"}
            fill={props.fill || "none"}
            strokeMiterlimit="10"
            strokeWidth="1.2"
            className="cls-1"
            d="M39.86,23.5c0-.17,0-3.52,0-5.19a2.3,2.3,0,0,0-2.29-2.26H33.17A10.69,10.69,0,0,0,22.48,26.74v6.05"
            transform="translate(-5.4 -8.27)"
        />
        <line
            stroke={props.color || "none"}
            fill={props.fill || "none"}
            strokeMiterlimit="10"
            strokeWidth="1.2"
            className="cls-1"
            x1="26.15"
            y1="8.07"
            x2="26.15"
            y2="20.95"
        />
        <path
            stroke={props.color || "none"}
            fill={props.fill || "none"}
            strokeMiterlimit="10"
            strokeWidth="1.2"
            className="cls-1"
            d="M28.42,17,22.06,9.65a3.31,3.31,0,0,0-5.3.3L7.87,23.4"
            transform="translate(-5.4 -8.27)"
        />
        <path
            stroke={props.color || "none"}
            fill={props.fill || "none"}
            strokeMiterlimit="10"
            strokeWidth="1.2"
            className="cls-1"
            d="M25,19.8l-3.32-4.42a2.25,2.25,0,0,0-3.6.2L11,26.43"
            transform="translate(-5.4 -8.27)"
        />
        <line
            stroke={props.color || "none"}
            fill={props.fill || "none"}
            strokeMiterlimit="10"
            strokeWidth="1.2"
            className="cls-1"
            x1="20.31"
            y1="28.71"
            x2="34.18"
            y2="28.71"
        />
    </svg>
);

export const TemplateIcon = (props) => (
    <svg
        width={props.width || 21}
        height={props.height || 19}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 41.93 29.55"
    >
        <defs></defs>
        <title></title>
        <path
            stroke={props.color || "none"}
            fill={props.fill || "none"}
            strokeMiterlimit="10"
            strokeWidth="1.2"
            className="cls-1"
            d="M5.68,25.13,4.2,27.36A2.34,2.34,0,0,0,4,29.59a2.85,2.85,0,0,0,2.74,1.69l4.8-.18a.48.48,0,0,0,.24-.88L9.1,28.81a1.28,1.28,0,0,1-.44-.39L6.45,25.09A.48.48,0,0,0,5.68,25.13Z"
            transform="translate(-3.6 -10.22)"
        />
        <rect
            stroke={props.color || "none"}
            fill={props.fill || "none"}
            strokeMiterlimit="10"
            strokeWidth="1.2"
            className="cls-1"
            x="11.93"
            y="22.88"
            width="21.32"
            height="6.47"
            rx="2.78"
        />
        <line
            stroke={props.color || "none"}
            fill={props.fill || "none"}
            strokeMiterlimit="10"
            strokeWidth="1.2"
            className="cls-1"
            x1="22.69"
            y1="19.27"
            x2="14.46"
            y2="22.82"
        />
        <line
            stroke={props.color || "none"}
            fill={props.fill || "none"}
            strokeMiterlimit="10"
            strokeWidth="1.2"
            className="cls-1"
            x1="31.17"
            y1="22.99"
            x2="31.17"
            y2="19.74"
        />
        <path
            stroke={props.color || "none"}
            fill={props.fill || "none"}
            strokeMiterlimit="10"
            strokeWidth="1.2"
            className="cls-1"
            d="M25.74,19.06a8.85,8.85,0,0,0-8,8.81v5.35"
            transform="translate(-3.6 -10.22)"
        />
        <path
            stroke={props.color || "none"}
            fill={props.fill || "none"}
            strokeMiterlimit="10"
            strokeWidth="1.2"
            className="cls-1"
            d="M22.66,20l-5.24-6.29A2.74,2.74,0,0,0,13,14L5.67,25.1"
            transform="translate(-3.6 -10.22)"
        />
        <path
            stroke={props.color || "none"}
            fill={props.fill || "none"}
            strokeMiterlimit="10"
            strokeWidth="1.2"
            className="cls-1"
            d="M19.87,22.12l-2.75-3.66a1.86,1.86,0,0,0-3,.17l-5.91,9"
            transform="translate(-3.6 -10.22)"
        />
        <line
            stroke={props.color || "none"}
            fill={props.fill || "none"}
            strokeMiterlimit="10"
            strokeWidth="1.2"
            className="cls-1"
            x1="16.85"
            y1="26.12"
            x2="28.33"
            y2="26.12"
        />
        <rect
            stroke={props.color || "none"}
            fill={props.fill || "none"}
            strokeMiterlimit="10"
            strokeWidth="1.2"
            className="cls-1"
            x="22.18"
            y="0.2"
            width="19.54"
            height="19.54"
            rx="1.91"
        />
        <rect
            stroke={props.color || "none"}
            fill={props.fill || "none"}
            strokeMiterlimit="10"
            strokeWidth="1.2"
            className="cls-1"
            x="30.57"
            y="8.31"
            width="2.86"
            height="8.34"
            rx="0.26"
        />
        <circle
            stroke={props.color || "none"}
            fill={props.fill || "none"}
            strokeMiterlimit="10"
            strokeWidth="1.2"
            className="cls-1"
            cx="32"
            cy="4.98"
            r="1.76"
        />
    </svg>
);

export const BoltCutterIcon = (props) => (
    <svg
        width={props.width || 22}
        height={props.height || 25}
        viewBox="25 0 110 255"
        xmlns="http://www.w3.org/2000/svg"
        transform="rotate(45)"
    >
        <defs></defs>
        <rect
            stroke={props.color || "none"}
            strokeWidth="4.2"
            fill={props.fill || "none"}
            strokeMiterlimit="10"
            id="Rectangle-8"
            transform="translate(28.742026, 110.333487) rotate(-83.000000) translate(-28.742026, -110.333487) "
            x="-10.2174337"
            y="99.6968746"
            width="77.9189189"
            height="21.273224"
        ></rect>
        <path
            stroke={props.color || "none"}
            strokeWidth="4.2"
            fill={props.fill || "none"}
            strokeMiterlimit="10"
            d="M65.8882044,167.339773 C65.4935756,167.305766 65.094187,167.288409 64.6907446,167.288409 L64.6907446,167.288409 L-13.3467683,167.288409 L-13.3467683,167.288409 C-20.948983,167.288409 -27.1117956,173.451222 -27.1117956,181.053437 C-27.1117956,188.655651 -20.948983,194.818464 -13.3467683,194.818464 L-13.3467683,194.818464 L64.6907446,194.818464 C65.094187,194.818464 65.4935756,194.801108 65.8882044,194.767101 L65.8882044,167.339773 Z"
            id="Combined-Shape"
            transform="translate(19.388204, 181.053437) rotate(-83.000000) translate(-19.388204, -181.053437) "
        ></path>
        <rect
            stroke={props.color || "none"}
            strokeWidth="4.2"
            fill={props.fill || "none"}
            strokeMiterlimit="10"
            id="Rectangle-8"
            transform="translate(63.088792, 110.333487) scale(-1, 1) rotate(-83.000000) translate(-63.088792, -110.333487) "
            x="24.1293329"
            y="99.6968746"
            width="77.9189189"
            height="21.273224"
        ></rect>
        <path
            stroke={props.color || "none"}
            strokeWidth="4.2"
            fill={props.fill || "none"}
            strokeMiterlimit="10"
            d="M118.942614,167.339773 C118.547985,167.305766 118.148596,167.288409 117.745154,167.288409 L117.745154,167.288409 L39.7076411,167.288409 L39.7076411,167.288409 C32.1054264,167.288409 25.9426138,173.451222 25.9426138,181.053437 C25.9426138,188.655651 32.1054264,194.818464 39.7076411,194.818464 L39.7076411,194.818464 L117.745154,194.818464 C118.148596,194.818464 118.547985,194.801108 118.942614,194.767101 L118.942614,167.339773 Z"
            id="Combined-Shape"
            transform="translate(72.442614, 181.053437) scale(-1, 1) rotate(-83.000000) translate(-72.442614, -181.053437) "
        ></path>
        <rect
            stroke={props.color || "none"}
            strokeWidth="4.2"
            fill={props.fill || "none"}
            strokeMiterlimit="10"
            id="Rectangle-5"
            transform="translate(45.871622, 78.836066) rotate(90.000000) translate(-45.871622, -78.836066) "
            x="32.0472973"
            y="44.4234973"
            width="27.6486486"
            height="68.8251366"
            rx="13.8243243"
        ></rect>
        <path
            stroke={props.color || "none"}
            strokeWidth="4.2"
            fill={props.fill || "none"}
            strokeMiterlimit="10"
            d="M35.5897781,0 L45.702504,65.1805032 C28.9266767,66.4967774 20.7836568,66.8258459 21.2734444,66.1677088 C22.0081258,65.1805032 -3.37095284,18.0569045 35.5897781,0"
            id="Path-2"
        ></path>
        <path
            stroke={props.color || "none"}
            strokeWidth="4.2"
            fill={props.fill || "none"}
            strokeMiterlimit="10"
            d="M68.2654538,0 L78.3781797,65.1805032 C61.6023524,66.4967774 53.4593325,66.8258459 53.9491201,66.1677088 C54.6838014,65.1805032 29.3047228,18.0569045 68.2654538,0"
            id="Path-2-Copy"
            transform="translate(62.439090, 33.248389) scale(-1, 1) translate(-62.439090, -33.248389) "
        ></path>
    </svg>
);

export const LogoIcon = (props) => (
    <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 344.08 38.57"
        height={props.height || "35"}
    >
        <title></title>
        <g
            id="cls-1"
            stroke="none"
            strokeWidth="2"
            fill={props.fill || "#0071ce"}
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <g
                id="cls-2"
                stroke="none"
                strokeWidth="2"
                fill={props.fill || "#0071ce"}
                fillRule="evenodd"
                strokeLinecap="round"
                strokeLinejoin="round"
            >
                <path
                    id="cls-1"
                    d="M58,78a10.94,10.94,0,0,1-4.67,4,16.91,16.91,0,0,1-7.39,1.49,17.55,17.55,0,0,1-10.75-3.14,11.42,11.42,0,0,1-4.6-8.92h11.1a4.24,4.24,0,0,0,1.23,2.92,3.71,3.71,0,0,0,2.6,1,3.06,3.06,0,0,0,2.15-.7,2.5,2.5,0,0,0,.77-2,2.91,2.91,0,0,0-1.54-2.63A26.58,26.58,0,0,0,42,68a46.6,46.6,0,0,1-5.87-2.39,10.92,10.92,0,0,1-4-3.5,10.05,10.05,0,0,1-1.68-6,10.15,10.15,0,0,1,1.81-6,11.18,11.18,0,0,1,4.94-3.82A18.37,18.37,0,0,1,44.35,45Q50.83,45,54.68,48a11.38,11.38,0,0,1,4.23,8.58H47.7A4.21,4.21,0,0,0,46.61,54a3.18,3.18,0,0,0-2.31-.85,2.7,2.7,0,0,0-1.92.64,2.47,2.47,0,0,0-.69,1.91,2.8,2.8,0,0,0,1.52,2.5,27.11,27.11,0,0,0,4.75,2,48,48,0,0,1,5.9,2.5,11.75,11.75,0,0,1,4,3.56,10.09,10.09,0,0,1,1.7,6.06A10.43,10.43,0,0,1,58,78Z"
                    transform="translate(-30.48 -44.98)"
                />
                <path
                    id="cls-1"
                    d="M108.16,45.72V83.18H97.8V61.92l-7,21.26h-9l-7-21.26V83.18H64.43V45.72H77.24l9.24,24.33,9-24.33Z"
                    transform="translate(-30.48 -44.98)"
                />
                <path
                    id="cls-1"
                    d="M138,76.91H124.63l-2.07,6.27h-11l13.76-37.46h12.06l13.71,37.46H140ZM135.36,69l-4-12.22L127.23,69Z"
                    transform="translate(-30.48 -44.98)"
                />
                <path
                    id="cls-1"
                    d="M173.56,83.18,166,69.31H164.9V83.18H154.43V45.56H171a17,17,0,0,1,7.67,1.57,10.87,10.87,0,0,1,4.76,4.33A12.21,12.21,0,0,1,185,57.67a11,11,0,0,1-2.18,6.83,11.5,11.5,0,0,1-6.27,4.07l8.56,14.61ZM164.9,62.3h5.15q4.37,0,4.36-3.94a3.89,3.89,0,0,0-1.09-2.92,4.52,4.52,0,0,0-3.27-1.06H164.9Z"
                    transform="translate(-30.48 -44.98)"
                />
                <path
                    id="cls-1"
                    d="M217.71,45.56V53.9H207.56V83.18H197.15V53.9h-10V45.56Z"
                    transform="translate(-30.48 -44.98)"
                />
                <path
                    id="cls-1"
                    d="M242,75.26h11.74v7.92H231.52V45.56H242Z"
                    transform="translate(-30.48 -44.98)"
                />
                <path
                    id="cls-1"
                    d="M284.09,47.45A17.91,17.91,0,0,1,291,54.33a20.74,20.74,0,0,1,0,19.87A18.24,18.24,0,0,1,284,81.08a20.11,20.11,0,0,1-19.31,0,18.18,18.18,0,0,1-7-6.88,20.63,20.63,0,0,1,0-19.87,18.11,18.11,0,0,1,7-6.88,20.2,20.2,0,0,1,19.36,0Zm-16,9.77a10.22,10.22,0,0,0-2.29,7,10.12,10.12,0,0,0,2.29,7,8.76,8.76,0,0,0,12.48,0,10.12,10.12,0,0,0,2.29-7,10.35,10.35,0,0,0-2.26-7,8.9,8.9,0,0,0-12.51,0Z"
                    transform="translate(-30.48 -44.98)"
                />
                <path
                    id="cls-1"
                    d="M325.13,45.56V53.9H315V83.18H304.57V53.9h-10V45.56Z"
                    transform="translate(-30.48 -44.98)"
                />
                <path
                    id="cls-1"
                    d="M355,47.45a18.05,18.05,0,0,1,6.91,6.88,20.74,20.74,0,0,1,0,19.87A18.24,18.24,0,0,1,355,81.08a20.12,20.12,0,0,1-19.32,0,18.18,18.18,0,0,1-7-6.88,20.63,20.63,0,0,1,0-19.87,18.11,18.11,0,0,1,7-6.88,20.22,20.22,0,0,1,19.37,0Zm-16,9.77a10.26,10.26,0,0,0-2.28,7,10.17,10.17,0,0,0,2.28,7,8.78,8.78,0,0,0,12.49,0,10.17,10.17,0,0,0,2.28-7,10.35,10.35,0,0,0-2.25-7,7.87,7.87,0,0,0-6.25-2.57A8,8,0,0,0,339.05,57.22Z"
                    transform="translate(-30.48 -44.98)"
                />
                <path
                    id="cls-1"
                    d="M372.09,73.9a4.52,4.52,0,0,1,1.83,1.77,5.52,5.52,0,0,1,0,5.2,4.57,4.57,0,0,1-1.83,1.79,6.08,6.08,0,0,1-5.39,0,4.51,4.51,0,0,1-1.83-1.79,5.51,5.51,0,0,1,0-5.19,4.42,4.42,0,0,1,1.83-1.78,6.08,6.08,0,0,1,5.39,0Zm-.42,8a3.55,3.55,0,0,0,1.5-1.47,4.43,4.43,0,0,0,.52-2.19,4.66,4.66,0,0,0-.51-2.22,3.68,3.68,0,0,0-1.49-1.46,5.4,5.4,0,0,0-4.58,0,3.62,3.62,0,0,0-1.49,1.46,4.66,4.66,0,0,0-.52,2.22,4.54,4.54,0,0,0,.52,2.19,3.52,3.52,0,0,0,1.49,1.47,4.75,4.75,0,0,0,2.29.52A4.67,4.67,0,0,0,371.67,81.94Zm0-3.83a1.78,1.78,0,0,1-.95.67l1.36,2.35h-1.8L369,78.9h-.08v2.23h-1.68V75.27h2.51a2.24,2.24,0,0,1,1.65.53,1.7,1.7,0,0,1,.54,1.27A1.87,1.87,0,0,1,371.64,78.11Zm-2.71-.25h.65c.44,0,.67-.18.67-.54s-.23-.54-.67-.54h-.65Z"
                    transform="translate(-30.48 -44.98)"
                />
            </g>
        </g>
    </svg>
);

export const KeyIcon = (props) => (
    <svg
        width={props.width || "35"}
        height={props.height || "75"}
        viewBox="0 0 35 75"
        version="1.1"
    >
        <title></title>
        <desc>Created with Sketch.</desc>
        <defs></defs>
        <g
            id="Page-1"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <path
                d="M24.9218665,66.494351 L16.4162174,75 L16.151914,75 L8.66666667,67.5147526 L8.66666667,32.4660053 C3.48189231,29.4419273 0,23.8370674 0,17.4225664 C0,7.80034866 7.83501688,0 17.5,0 C27.1649831,0 35,7.80034866 35,17.4225664 C35,23.6986161 31.6667926,29.1995983 26.6666667,32.2665971 L26.6666667,39.3748624 L20.6969116,45.3446174 L25.0110709,49.6587767 L20.6969116,53.972936 L24.8451417,58.1211661 L20.6969116,62.2693961 L24.9218665,66.494351 Z M17.5,14.6017699 C20.1693763,14.6017699 22.3333333,12.4473879 22.3333333,9.78982301 C22.3333333,7.13225812 20.1693763,4.97787611 17.5,4.97787611 C14.8306237,4.97787611 12.6666667,7.13225812 12.6666667,9.78982301 C12.6666667,12.4473879 14.8306237,14.6017699 17.5,14.6017699 Z"
                id="Combined-Shape"
                fill={props.fill || "#0071ce"}
            ></path>
        </g>
    </svg>
);

export const CarsealIcon = (props) => (
    <svg
        width={props.width || 22}
        height={props.height || 25}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 23.26 42.96"
    >
        <defs></defs>
        <title></title>
        <path
            stroke={props.color || "none"}
            strokeWidth="0.8"
            fill={props.fill || "none"}
            strokeMiterlimit="10"
            className="cls-1"
            d="M27.38,49H11.62a.78.78,0,0,1-.78-.78V33.66a.78.78,0,0,1,.78-.78h1.92V28.76a6,6,0,1,1,11.91,0v4.12h1.93a.78.78,0,0,1,.77.78V48.24A.78.78,0,0,1,27.38,49ZM11.62,33.28a.38.38,0,0,0-.38.38V48.24a.38.38,0,0,0,.38.38H27.38a.38.38,0,0,0,.37-.38V33.66a.38.38,0,0,0-.37-.38H25.05V28.76a5.56,5.56,0,1,0-11.11,0v4.52Z"
            transform="translate(-7.87 -6.06)"
        />
        <path
            stroke={props.color || "none"}
            strokeWidth="0.8"
            fill={props.fill || "none"}
            strokeMiterlimit="10"
            className="cls-1"
            d="M22.87,33.27H16.12V28.71a3.38,3.38,0,0,1,6.75,0Zm-6.35-.4h5.95V28.71a3,3,0,0,0-5.95,0Z"
            transform="translate(-7.87 -6.06)"
        />
        <path
            stroke={props.color || "none"}
            strokeWidth="0.8"
            fill={props.fill || "none"}
            strokeMiterlimit="10"
            className="cls-1"
            d="M19.5,29.32a11.87,11.87,0,0,1-3.31-.47l.12-.39a11,11,0,0,0,3.19.46,11.22,11.22,0,1,0-5.56-1.47l-.2.35a11.63,11.63,0,1,1,5.76,1.52Z"
            transform="translate(-7.87 -6.06)"
        />
        <rect
            stroke={props.color || "none"}
            strokeWidth="0.8"
            fill={props.fill || "none"}
            strokeMiterlimit="10"
            className="cls-1"
            x="11.46"
            y="0.43"
            width="0.4"
            height="10.87"
        />
        <polygon
            stroke={props.color || "none"}
            strokeWidth="0.8"
            fill={props.fill || "none"}
            strokeMiterlimit="10"
            className="cls-1"
            points="2.4 18.02 2.17 17.7 11.52 10.9 11.76 11.23 2.4 18.02"
        />
        <rect
            stroke={props.color || "none"}
            strokeWidth="0.8"
            fill={props.fill || "none"}
            strokeMiterlimit="10"
            className="cls-1"
            x="23.96"
            y="14.75"
            width="0.4"
            height="11.56"
            transform="translate(-14.51 21.96) rotate(-54.03)"
        />
    </svg>
);

export const LowBatteryIcon = (props) => (
    <svg
        id="Layer_1"
        width={props.width || 24}
        height={props.height || 24}
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 26.79 32.37"
    >
        <rect
            stroke={props.themeColor || "none"}
            strokeWidth="1.2"
            fill={"none"}
            strokeMiterlimit="2"
            className="cls-1"
            x="6.96"
            y="5.42"
            width="12.88"
            height="23.23"
            rx="1.17"
        />
        <path
            stroke={props.themeColor || "none"}
            strokeWidth="1.2"
            fill={"none"}
            strokeMiterlimit="2"
            className="cls-1"
            d="M10.7,5.56v-1a.85.85,0,0,1,.84-.84h3.71a.85.85,0,0,1,.84.84v.89"
        />
        <path
            stroke={props.color || "none"}
            strokeWidth="1.2"
            fill={props.fill || "none"}
            strokeMiterlimit="2"
            className="cls-2"
            d="M9.5,23.64h7.86a0,0,0,0,1,0,0v2.15a.35.35,0,0,1-.35.35H9.85a.35.35,0,0,1-.35-.35V23.64A0,0,0,0,1,9.5,23.64Z"
        />
    </svg>
);

export const MedBatteryIcon = (props) => (
    <svg
        width={props.width || 24}
        height={props.height || 24}
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 26.79 32.37"
    >
        <rect
            stroke={props.themeColor || "none"}
            strokeWidth="1.2"
            fill={"none"}
            strokeMiterlimit="2"
            className="cls-1"
            x="6.96"
            y="5.42"
            width="12.88"
            height="23.23"
            rx="1.17"
        />
        <path
            stroke={props.themeColor || "none"}
            strokeWidth="1.2"
            fill={"none"}
            strokeMiterlimit="2"
            className="cls-1"
            d="M10.7,5.56v-1a.85.85,0,0,1,.84-.84h3.71a.85.85,0,0,1,.84.84v.89"
        />
        <path
            stroke={props.color || "none"}
            strokeWidth="1.2"
            fill={props.fill || "none"}
            strokeMiterlimit="2"
            className="cls-2"
            d="M9.5,17h7.86a0,0,0,0,1,0,0v8.75a.35.35,0,0,1-.35.35H9.85a.35.35,0,0,1-.35-.35V17A0,0,0,0,1,9.5,17Z"
        />
    </svg>
);

export const HighBatteryIcon = (props) => (
    <svg
        width={props.width || 24}
        height={props.height || 24}
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 26.79 32.37"
    >
        <rect
            stroke={props.themeColor || "none"}
            strokeWidth="1.2"
            fill={"none"}
            strokeMiterlimit="2"
            className="cls-1"
            x="6.96"
            y="5.42"
            width="12.88"
            height="23.23"
            rx="1.17"
        />
        <path
            stroke={props.themeColor || "none"}
            strokeWidth="1.2"
            fill={"none"}
            strokeMiterlimit="2"
            className="cls-1"
            d="M10.7,5.56v-1a.85.85,0,0,1,.84-.84h3.71a.85.85,0,0,1,.84.84v.89"
        />
        <path
            stroke={props.color || "none"}
            strokeWidth="1.2"
            fill={props.fill || "none"}
            strokeMiterlimit="2"
            className="cls-2"
            d="M9.9,8H17a.4.4,0,0,1,.4.4V25.79a.35.35,0,0,1-.35.35H9.85a.35.35,0,0,1-.35-.35V8.44A.4.4,0,0,1,9.9,8Z"
        />
    </svg>
);
