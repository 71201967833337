import "@formatjs/intl-pluralrules/polyfill";
import "@formatjs/intl-pluralrules/locale-data/fr"; // Add locale data for fr

import "@formatjs/intl-relativetimeformat/polyfill";
import "@formatjs/intl-relativetimeformat/locale-data/fr"; // Add locale data for fr
import frMessages from "../locales/fr_FR.json";

const frLang = {
    messages: {
        ...frMessages,
    },
    locale: "fr-FR",
};
export default frLang;
