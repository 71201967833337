import React from "react";
import { connect } from "react-redux";
import { StyleSheet, css } from "aphrodite";
import { Input, Row, Col, Label } from "reactstrap";
import { injectIntl } from "react-intl";

import {
    searchSealActivity,
    setSealActivitySearchTerms,
    fetchSealActivitySearchSelect,
} from "actions/Carseals";
import { setLoader } from "actions/App";
import { fetchSitesList } from "actions/Settings";

import Tooltip from "components/Tooltips";
import CSV from "components/Export";

class SearchSealActivity extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sealActivityTerms: props.sealActivityTerms,
        };
    }
    componentDidMount() {
        const { fetchSitesList, fetchSealActivitySearchSelect } = this.props;
        fetchSitesList();
        fetchSealActivitySearchSelect();
    }
    handleSearch = () => {
        const { searchSealActivity, setLoader } = this.props;
        let { sealActivityTerms } = this.state;
        searchSealActivity(sealActivityTerms);
        setLoader(false);
    };
    keyPress = (e) => {
        if (e.keyCode === 13) {
            this.handleSearch();
        }
    };
    updateData = (e) => {
        const { setSealActivitySearchTerms } = this.props;
        let updateData = { ...this.state.sealActivityTerms };
        if (e.target.type === "checkbox") {
            if (e.target.checked) {
                updateData[e.target.name] = true;
                setSealActivitySearchTerms(updateData);
                this.setState({
                    sealActivityTerms: updateData,
                    changesMade: true,
                });
            } else {
                updateData[e.target.name] = false;
                setSealActivitySearchTerms(updateData);
                this.setState({
                    sealActivityTerms: updateData,
                    changesMade: true,
                });
            }
        } else {
            updateData[e.target.name] = e.target.value;
            setSealActivitySearchTerms(updateData);
            this.setState({ sealActivityTerms: updateData, changesMade: true });
        }
    };
    clearField = (target) => {
        const { setSealActivitySearchTerms } = this.props;
        let updateData = { ...this.state.sealActivityTerms };
        updateData[target] = "";
        setSealActivitySearchTerms(updateData);
        this.setState({ sealActivityTerms: updateData, changesMade: true });
    };
    clearFields = () => {
        const { setSealActivitySearchTerms } = this.props;
        let newSealActivitySearchTerms = {
            Id: "",
            SealId: "",
            SystemTypeSealActivityId: 0,
            ActivityById: "",
            LockLabel: "",
            Location: "",
            PositionId: 0,
            StartDate: "",
            EndDate: "",
        };
        setSealActivitySearchTerms(newSealActivitySearchTerms);
        this.setState({ sealActivityTerms: newSealActivitySearchTerms });
        this.props.searchSealActivity(newSealActivitySearchTerms);
    };
    render() {
        let { sealActivityTerms } = this.state;
        const {
            intl,
            width,
            sealSearchPositions,
            sealActivitySearchTypes,
            searchedSealActivity,
            authUser,
            theme,
        } = this.props;
        this.styles = styles(theme);
        let exportList = searchedSealActivity
            ? searchedSealActivity.map((a) => Object.assign({}, a))
            : [];
        exportList.map((item) => {
            delete item.Icon;
            delete item.Id;
            return item;
        });
        return (
            sealActivityTerms && (
                <div>
                    <div
                        className={css(this.styles.searchWrapper)}
                        onKeyDown={(e) => this.keyPress(e)}
                    >
                        <Col lg={12}>
                            {width < 992 && (
                                <Row>
                                    <Col
                                        lg={12}
                                        className={css(this.styles.flexRow)}
                                    >
                                        <div>
                                            <i
                                                id="searchSeal"
                                                className={`material-icons ${css(
                                                    this.styles.searchIcon
                                                )}`}
                                                onClick={() =>
                                                    this.handleSearch()
                                                }
                                            >
                                                search
                                            </i>
                                            <Tooltip
                                                message={"comp.search.default"}
                                                target="searchSeal"
                                            />
                                        </div>
                                        <div>
                                            <i
                                                id="resetSealSearch"
                                                className={`material-icons ${css(
                                                    this.styles.searchIcon
                                                )}`}
                                                onClick={() =>
                                                    this.clearFields()
                                                }
                                            >
                                                low_priority
                                            </i>
                                            <Tooltip
                                                message={
                                                    "comp.resetSearch.default"
                                                }
                                                target="resetSealSearch"
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            )}
                            <Row>
                                <Col
                                    lg={10}
                                    className={css(this.styles.flexRow)}
                                >
                                    {width > 800 && (
                                        <Col
                                            lg={4}
                                            className={css(this.styles.flexRow)}
                                        >
                                            <Label
                                                className={css(
                                                    this.styles.labelText
                                                )}
                                            >
                                                {this.props.intl.formatMessage({
                                                    id: "form.location",
                                                })}
                                            </Label>
                                            <Input
                                                name="Location"
                                                type="text"
                                                className={css(
                                                    this.styles.inputText
                                                )}
                                                onChange={(e) =>
                                                    this.updateData(e)
                                                }
                                                value={
                                                    sealActivityTerms.Location
                                                }
                                            />
                                            {sealActivityTerms.Location !==
                                                "" && (
                                                <i
                                                    className={`material-icons ${css(
                                                        this.styles.closeIcon
                                                    )}`}
                                                    onClick={() =>
                                                        this.clearField(
                                                            "Location"
                                                        )
                                                    }
                                                >
                                                    close
                                                </i>
                                            )}
                                        </Col>
                                    )}
                                    {width > 800 && (
                                        <Col
                                            lg={4}
                                            className={css(this.styles.flexRow)}
                                        >
                                            <Label
                                                className={css(
                                                    this.styles.labelDropdown
                                                )}
                                            >
                                                {this.props.intl.formatMessage({
                                                    id: "comp.table.cell.activityType",
                                                })}
                                            </Label>
                                            <Input
                                                className={css(
                                                    this.styles.inputSelect
                                                )}
                                                name="SystemTypeSealActivityId"
                                                type="select"
                                                placeholder={intl.formatMessage(
                                                    { id: "form.selectUnit" }
                                                )}
                                                onChange={this.updateData}
                                                value={
                                                    sealActivityTerms.SystemTypeSealActivityId ||
                                                    ""
                                                }
                                            >
                                                <option value={""}>
                                                    {intl.formatMessage({
                                                        id: "form.all",
                                                    })}
                                                </option>
                                                {sealActivitySearchTypes.map(
                                                    (user) => (
                                                        <option
                                                            key={
                                                                "user" + user.Id
                                                            }
                                                            value={
                                                                user.Id || ""
                                                            }
                                                        >
                                                            {user.TypeName}
                                                        </option>
                                                    )
                                                )}
                                            </Input>
                                        </Col>
                                    )}
                                    {width > 800 && (
                                        <Col
                                            lg={4}
                                            className={css(this.styles.flexRow)}
                                        >
                                            <Label
                                                className={css(
                                                    this.styles.labelDropdown
                                                )}
                                            >
                                                {this.props.intl.formatMessage({
                                                    id: "form.label",
                                                })}
                                            </Label>
                                            <Input
                                                className={css(
                                                    this.styles.inputText
                                                )}
                                                name="LockLabel"
                                                type="text"
                                                onChange={this.updateData}
                                                value={
                                                    sealActivityTerms.LockLabel ||
                                                    ""
                                                }
                                            ></Input>
                                            {sealActivityTerms.LockLabel !==
                                                "" && (
                                                <i
                                                    className={`material-icons ${css(
                                                        this.styles.closeIcon
                                                    )}`}
                                                    onClick={() =>
                                                        this.clearField(
                                                            "LockLabel"
                                                        )
                                                    }
                                                >
                                                    close
                                                </i>
                                            )}
                                        </Col>
                                    )}
                                </Col>
                                {width > 800 && (
                                    <Col lg={2}>
                                        {width > 991 && (
                                            <Row>
                                                <Col
                                                    lg={12}
                                                    className={css(
                                                        this.styles.flexRow,
                                                        this.styles.rightAligned
                                                    )}
                                                >
                                                    {width > 800 && (
                                                        <div>
                                                            <i
                                                                id="searchSeal"
                                                                className={`material-icons ${css(
                                                                    this.styles
                                                                        .searchIcon
                                                                )}`}
                                                                onClick={() =>
                                                                    this.handleSearch()
                                                                }
                                                            >
                                                                search
                                                            </i>
                                                            <Tooltip
                                                                message={
                                                                    "comp.search.default"
                                                                }
                                                                target="searchSeal"
                                                            />
                                                        </div>
                                                    )}
                                                    {width > 800 && (
                                                        <div>
                                                            <i
                                                                id="resetSealSearch"
                                                                className={`material-icons ${css(
                                                                    this.styles
                                                                        .searchIcon
                                                                )}`}
                                                                onClick={() =>
                                                                    this.clearFields()
                                                                }
                                                            >
                                                                low_priority
                                                            </i>
                                                            <Tooltip
                                                                message={
                                                                    "comp.resetSearch.default"
                                                                }
                                                                target="resetSealSearch"
                                                            />
                                                        </div>
                                                    )}
                                                    {width > 800 &&
                                                        authUser.Permissions.includes(
                                                            "ExportSecureActivity"
                                                        ) && (
                                                            <div>
                                                                <CSV
                                                                    data={
                                                                        exportList
                                                                    }
                                                                    margin={
                                                                        "0 12px 0 0"
                                                                    }
                                                                />
                                                                <Tooltip
                                                                    message={
                                                                        "comp.button.export"
                                                                    }
                                                                    target="exportFile"
                                                                />
                                                            </div>
                                                        )}
                                                </Col>
                                            </Row>
                                        )}
                                    </Col>
                                )}
                            </Row>
                        </Col>
                    </div>
                    <div>
                        <Row className={css(this.styles.advancedRow)}>
                            <Col lg={10} className={css(this.styles.flexRow)}>
                                <Col
                                    lg={4}
                                    className={css(this.styles.flexRow)}
                                >
                                    <Label
                                        className={css(
                                            this.styles.labelDropdown
                                        )}
                                    >
                                        {this.props.intl.formatMessage({
                                            id: "pages.Position.Type",
                                        })}
                                    </Label>
                                    <Input
                                        className={css(this.styles.inputSelect)}
                                        name="PositionId"
                                        type="select"
                                        placeholder={intl.formatMessage({
                                            id: "pages.Position.Type",
                                        })}
                                        onChange={this.updateData}
                                        value={
                                            sealActivityTerms.PositionId || ""
                                        }
                                    >
                                        <option value={0}>
                                            {intl.formatMessage({
                                                id: "form.all",
                                            })}
                                        </option>
                                        {sealSearchPositions.map((position) => (
                                            <option
                                                key={"position" + position.Id}
                                                value={position.Id || ""}
                                            >
                                                {position.TypeName}
                                            </option>
                                        ))}
                                    </Input>
                                </Col>
                                <Col
                                    lg={4}
                                    className={css(this.styles.flexRow)}
                                >
                                    <Label
                                        className={css(
                                            this.styles.labelDropdown
                                        )}
                                        for="StartDate"
                                    >
                                        {intl.formatMessage({
                                            id: "form.placedStart",
                                        })}
                                        *
                                    </Label>
                                    <Input
                                        onChange={this.updateData}
                                        type="date"
                                        name="StartDate"
                                        id="StartDate"
                                        placeholder="date placeholder"
                                        className={css(
                                            this.styles.inputSelect,
                                            this.styles.dateInput
                                        )}
                                        value={sealActivityTerms.StartDate}
                                    />
                                </Col>
                                <Col
                                    lg={4}
                                    className={css(this.styles.flexRow)}
                                >
                                    <Label
                                        className={css(
                                            this.styles.labelDropdown
                                        )}
                                        for="EndDate"
                                    >
                                        {intl.formatMessage({
                                            id: "form.placedEnd",
                                        })}
                                        *
                                    </Label>
                                    <Input
                                        onChange={this.updateData}
                                        type="date"
                                        name="EndDate"
                                        id="EndDate"
                                        placeholder="date placeholder"
                                        className={css(
                                            this.styles.inputSelect,
                                            this.styles.dateInput
                                        )}
                                        value={sealActivityTerms.EndDate}
                                    />
                                </Col>
                            </Col>
                        </Row>
                    </div>
                </div>
            )
        );
    }
}

SearchSealActivity.defaultProps = {
    searchTitle: "blank",
    searchType: ["name"],
    data: [],
};

const mapStateToProps = ({ app, carseals, settings, auth }) => {
    const { theme, width } = app;
    const {
        sealActivityTerms,
        sealSearchPositions,
        sealActivitySearchTypes,
        searchedSealActivity,
    } = carseals;
    const { sitesList } = settings;
    const { authUser } = auth;
    return {
        theme,
        width,
        sealActivityTerms,
        searchedSealActivity,
        sitesList,
        sealSearchPositions,
        sealActivitySearchTypes,
        authUser,
    };
};

export default injectIntl(
    connect(mapStateToProps, {
        searchSealActivity,
        setLoader,
        setSealActivitySearchTerms,
        fetchSitesList,
        fetchSealActivitySearchSelect,
    })(SearchSealActivity)
);

const styles = (theme) =>
    StyleSheet.create({
        flexRow: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            height: "2.25rem",
            paddingLeft: 0,
        },
        rightAligned: {
            paddingRight: 0,
            justifyContent: "flex-end",
        },
        advancedRow: {
            margin: 0,
            padding: 0,
        },
        inputSelect: {
            border: "none",
            boxShadow: "none",
            borderRadius: 0,
            fontWeight: 400,
            fontSize: 12,
            letterSpacing: 2,
            border: "1px solid white",
            paddingTop: 0,
            paddingRight: 0,
            paddingBottom: 0,
            color: theme.color.themeColor,
            backgroundColor: theme.color.compColor,
            "::placeholder": {
                letterSpacing: 1,
                color: theme.color.fontColor,
                fontSize: 12,
            },
        },
        inputText: {
            border: "none",
            boxShadow: "none",
            borderRadius: 0,
            fontWeight: 400,
            fontSize: 12,
            letterSpacing: 2,
            marginBottom: 0,
            padding: 0,
            width: "100%",
            marginLeft: 5,
            color: theme.color.themeColor,
            borderBottom: `solid whitesmoke 1px`,
            backgroundColor: theme.color.compColor,
            "::placeholder": {
                letterSpacing: 1,
                color: theme.color.themeColor,
                fontSize: 12,
            },
        },
        searchWrapper: {
            position: "relative",
            display: "flex",
            flexDirection: "row",
        },
        searchIcon: {
            color: theme.color.themeColor,
            marginRight: 12,
            ":hover": {
                cursor: "pointer",
                opacity: ".8",
            },
        },
        closeIcon: {
            color: theme.color.fontColor,
            position: "absolute",
            top: 5,
            right: 5,
            fontSize: 16,
            ":hover": {
                cursor: "pointer",
                opacity: ".8",
            },
        },
        labelDropdown: {
            fontSize: 12,
            color: theme.color.fontColor,
            whiteSpace: "nowrap",
        },
        labelText: {
            fontSize: 12,
            color: theme.color.fontColor,
            whiteSpace: "nowrap",
        },
        dateInput: {
            marginBottom: 4,
        },
    });
