import { render } from "less";
import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "reactstrap";

import { getWithExpiry, setWithExpiry } from "util";

class ErrorFallback extends React.Component {
    // Handles failed lazy loading of a JS/CSS chunk.
    componentDidMount() {
        const { error } = this.props;
        const chunkFailedMessage = /Loading chunk [\d]+ failed/;
        if (error && error.message && chunkFailedMessage.test(error.message)) {
            if (!getWithExpiry("chunk_failed")) {
                setWithExpiry("chunk_failed", "true", 10000);
                window.location.reload();
            }
        }
    }
    render() {
        const { error } = this.props;
        return (
            <div
                style={{
                    textAlign: "center",
                    width: "100%",
                    margin: "0 auto",
                    paddingTop: 20,
                    color: "#252525",
                }}
            >
                <p>
                    <FormattedMessage id="errorCodes.description.1" />
                </p>
                <pre
                    style={{ color: "#252525" }}
                >
                    {error && error.message && error.message}
                </pre>
                <div>
                    <Button
                        style={{
                            color: "#ffffff",
                            backgroundColor: "#0070cd",
                            border: "none",
                        }}
                        onClick={() => window.location.reload()}
                    >
                        <FormattedMessage id="tooltip.refreshPage" />
                    </Button>
                    <Button
                        style={{
                            color: "#ffffff",
                            backgroundColor: "#0070cd",
                            border: "none",
                        }}
                        onClick={() => window.history.back()}
                    >
                        <FormattedMessage id="comp.button.goBack" />
                    </Button>
                </div>
            </div>
        );
    }
}

export default ErrorFallback;
