import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { fadeIn } from "react-animations";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { StyleSheet, css } from "aphrodite";
import { injectIntl } from "react-intl";

import DropdownHeader from "components/Dropdowns/header";

class DropdownTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
        };
    }
    render() {
        const {
            children,
            menu,
            direction,
            title,
            theme,
            width,
            height,
            padding,
        } = this.props;
        const { show } = this.state;
        this.styles = styles(theme, width, height, padding);
        const Menu = menu;
        return (
            <Dropdown
                direction={direction}
                isOpen={show}
                toggle={() => this.setState({ show: !show })}
            >
                <DropdownToggle
                    className="d-inline-block"
                    tag="span"
                    data-toggle="dropdown"
                >
                    {children}
                </DropdownToggle>
                <DropdownMenu
                    right
                    className={css(this.styles.wrapper, this.styles.fadeIn)}
                >
                    {title && <DropdownHeader title={title} />}
                    <div className={css(this.styles.menu)}>
                        <Menu toggle={() => this.setState({ show: !show })} />
                    </div>
                </DropdownMenu>
            </Dropdown>
        );
    }
}

DropdownTemplate.defaultProps = {
    direction: "down",
    width: 250,
    padding: 20,
    height: 300,
};

const mapStateToProps = ({ app }) => {
    const { theme } = app;
    return { theme };
};

export default injectIntl(
    withRouter(connect(mapStateToProps)(DropdownTemplate))
);

const styles = (theme, width, height, padding) =>
    StyleSheet.create({
        wrapper: {
            border: `solid 1px ${theme.color.borderColor}`,
            backgroundColor: theme.color.compColor,
            padding,
            width,
        },
        menu: {
            height,
            color: theme.color.fontColor,
        },
        fadeIn: {
            animationName: fadeIn,
            animationDuration: ".35s",
        },
    });
