import "@formatjs/intl-pluralrules/polyfill";
import "@formatjs/intl-pluralrules/locale-data/zh"; // Add locale data for zh

import "@formatjs/intl-relativetimeformat/polyfill";
import "@formatjs/intl-relativetimeformat/locale-data/zh"; // Add locale data for zh
import zhMessages from "../locales/zh-Hans.json";

const ZhLan = {
    messages: {
        ...zhMessages,
    },
    locale: "zh-Hans-CN",
};
export default ZhLan;
