import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { fadeInDown } from "react-animations";
import { StyleSheet, css } from "aphrodite";
import { injectIntl } from "react-intl";

import { toggleCollapsedNav } from "actions/App";
import { COLLAPSED_DRAWER, FIXED_DRAWER } from "constants/ActionTypes";

import BackButton from "components/Link/back";
import AddMenu from "components/Menus/header/add";
import Dropdown from "components/Dropdowns";
import Tooltip from "components/Tooltips";

class Header extends React.Component {
    constructor(props) {
        super(props);
    }
    onToggleCollapsedNav = () => {
        const val = !this.props.navCollapsed;
        this.props.toggleCollapsedNav(val);
    };
    render() {
        const { drawerType, theme, hideSidebar, authUser } = this.props;
        this.styles = styles(theme);
        const drawerStyle = drawerType.includes(FIXED_DRAWER)
            ? "d-flex d-xl-none"
            : drawerType.includes(COLLAPSED_DRAWER)
            ? "d-flex"
            : "d-none";
        const backgroundCol = (authUser.ClientId === "SCH20201123c6dfa96b2deba2a83cFRA" // Snyder Electric
                            || authUser.ClientId === "SCH20211109864171908742010805IND" // Snyder Electric
                            || authUser.ClientId === "SCH20210629219631eba90a80000fFRA") // Snyder Electric
                            ?
                            "#45d26b"
                            :
                            (authUser.ClientId === "NVR2021060497871519642010a80fCAD") // NouvLR
                            ?
                            "#07284f"
                            :
                            theme.color.headerColor;
        const iconCol = (authUser.ClientId === "SCH20201123c6dfa96b2deba2a83cFRA" // Snyder Electric
                        || authUser.ClientId === "SCH20211109864171908742010805IND" // Snyder Electric
                        || authUser.ClientId === "SCH20210629219631eba90a80000fFRA" // Snyder Electric
                        || authUser.ClientId === "NVR2021060497871519642010a80fCAD") // NouvLR
                        ?
                        "#ffffff"
                        :
                        theme.color.themeColor;
        const compLogo = (authUser.ClientId === "SCH20201123c6dfa96b2deba2a83cFRA" // Snyder Electric
                        || authUser.ClientId === "SCH20211109864171908742010805IND" // Snyder Electric
                        || authUser.ClientId === "SCH20210629219631eba90a80000fFRA") // Snyder Electric
                        ?
                        "assets/images/Themes/Demo/SELogo.png"
                        :
                        (authUser.ClientId === "81d3d981dcb4493aa14752c79d605b4a") // Queensland Rail
                        ?
                        "assets/images/Themes/Demo/QRLogo.jpg"
                        :
                        (authUser.ClientId === "NVR2021060497871519642010a80fCAD") // NouvLR
                        ?
                        "assets/images/Themes/Demo/nouvlr-logo-white-top.png"
                        :
                        (authUser.ClientId === "c9845ac3adda44fbbb1b54faad08d37d") // Disney
                        ?
                        "assets/images/Themes/Demo/Disney.png"
                        :
                        (authUser.ClientId === "MAH202004220f651b98a3ae54a800IND") // Mahindra
                        ?
                        "assets/images/Themes/Demo/Mahindra.jpg"
                        :
                        theme.logo;
        return (
            <div
                className={`app-main-header ${css(
                    this.styles.wrapper,
                    this.styles.fadeInDown
                )}`}
                style={{backgroundColor: backgroundCol}}
            >
                <div className="d-flex app-toolbar align-items-center">
                    <span
                        className={`jr-menu-icon pointer ${drawerStyle}`}
                        onClick={this.onToggleCollapsedNav}
                    >
                        <span
                            className={`menu-icon ${css(this.styles.menu)}`}
                        />
                    </span>
                    <ul className="header-notifications list-inline ml-0">
                        <li className="list-inline-item app-tour">
                            <div
                                className={
                                    hideSidebar
                                        ? css(this.styles.topNavShort)
                                        : css(this.styles.topNav)
                                }
                            >
                                {hideSidebar && (
                                    <img
                                        src={compLogo}
                                        className={css(this.styles.image)}
                                    />
                                )}
                                {(this.props.location && (!this.props.location.pathname.includes(
                                    "all"
                                ) &&
                                !this.props.location.pathname.includes(
                                    "home"
                                ) &&
                                !this.props.location.pathname.includes(
                                    "reports"
                                )) ||
                                this.props.location.pathname.includes(
                                    "notifications"
                                )) && <BackButton />}
                            </div>
                        </li>
                    </ul>
                    <ul className="header-notifications list-inline ml-auto">
                        <li
                            className="list-inline-item mail-tour"
                            className={css(this.styles.add)}
                        >
                            <Dropdown menu={AddMenu}>
                                <i
                                    id="addHeaderMenu"
                                    className={`material-icons ${css(
                                        this.styles.icon
                                    )}`}
                                    style={{color: iconCol}}
                                >
                                    add_circle_outline
                                </i>
                                <Tooltip
                                    message={"comp.form.add"}
                                    target="addHeaderMenu"
                                />
                            </Dropdown>
                        </li>
                        <li className="" className={css(this.styles.email)}>
                            <div
                                onClick={() =>
                                    this.props.history.push(
                                        "/app/feedback/create"
                                    )
                                }
                                className={css(this.styles.sendMail)}
                            >
                                <i
                                    id="feedbackSend"
                                    className={`material-icons ${css(
                                        this.styles.icon
                                    )}`}
                                    style={{color: iconCol}}
                                >
                                    email
                                </i>
                                <Tooltip
                                    message={"pages.feedback.head"}
                                    target="feedbackSend"
                                />
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ app, auth }) => {
    const { drawerType, width, theme, navCollapsed, hideSidebar } = app;
    const { authUser } = auth;
    return { drawerType, width, theme, navCollapsed, hideSidebar, authUser };
};

export default injectIntl(
    withRouter(connect(mapStateToProps, { toggleCollapsedNav })(Header))
);

const styles = (theme) =>
    StyleSheet.create({
        wrapper: {
            borderBottom: `solid .5px ${theme.color.borderColor}`,
        },
        menu: {
            backgroundColor: theme.color.themeColor,
            height: 2,
        },
        icon: {
            fontSize: 24,
            marginLeft: 7,
            ":hover": {
                cursor: "pointer",
                opacity: "0.8",
            },
        },
        add: {
            fontSize: 14,
            fontWeight: 400,
            color: theme.color.themeColor,
            ":hover": {
                cursor: "pointer",
            },
        },
        email: {
            ":hover": {
                cursor: "pointer",
            },
        },
        fadeInDown: {
            animationName: fadeInDown,
            animationDuration: "1.2s",
        },
        topNav: {
            marginLeft: 200,
            "@media(max-width:1200px)": {
                marginLeft: 0,
            },
        },
        topNavShort: {
            marginLeft: 75,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            "@media(max-width:1200px)": {
                marginLeft: 0,
            },
        },
        image: {
            display: "block",
            margin: "0 auto",
            marginRight: 46,
            height: 41,
        },
        sendMail: {
            outline: "none",
            ":hover": {
                outline: "none",
            },
        },
    });
