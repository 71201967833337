import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import moment from "moment";
import { LockIcon, TicketIcon, MachineIcon, UserIcon } from "util/icons";
import { API } from "../api";
import {
    FETCH_ACTIVITY,
    FETCH_SNAPSHOT,
    FETCH_SNAPSHOT_WORKORDER,
    SEARCH_ACTIVITY,
    FETCH_REPORTS,
    FETCH_TICKETS_NOT_STARTED,
} from "constants/ActionTypes";
import {
    setActivity,
    setSnapshot,
    setSnapshotWorkorder,
    setReports,
    setTicketsNotStarted,
} from "actions/Help";
import { userSignOut } from "actions/Auth";
import { showMessage, setLoader } from "actions/App";
import { sortList } from "util";

const getAuthUser = (state) => state.auth.authUser;

function* requestActivity({ payload }) {
    try {
        yield put(setActivity(null));
        const activity = yield call(API, payload.url, payload.data);
        if (activity.message) {
            if (activity.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(activity.message));
            }
        } else if (activity.data === "") {
            yield put(setActivity([]));
        } else {
            activity.data = sortList(true, activity.data, "Date");
            const images = yield all(
                activity.data.map((item) =>
                    item.ImageId
                        ? call(API, "/image/", { Filename: item.ImageId })
                        : ""
                )
            );
            activity.data.map((item, i) => {
                if (item.Event === "Ticket") {
                    item.Icon = TicketIcon;
                } else if (item.Event === "Machine") {
                    item.Icon = MachineIcon;
                } else if (item.Event === "Lock") {
                    item.Icon = LockIcon;
                } else if (item.Event === "User") {
                    item.Icon = UserIcon;
                } else if (item.Event === "Point") {
                    item.Icon = LockIcon;
                } else if (item.Event === "Job") {
                    item.Icon = TicketIcon;
                } else if (item.Event === "Template") {
                    item.Icon = MachineIcon;
                } else {
                    item.Icon = UserIcon;
                }
                if (item.ImageId) {
                    item.Image = images[i].data
                        ? `data:image/png;base64, ${images[i].data}`
                        : "assets/images/Icons/machine/machine@4x.png";
                }
            });
            yield put(setActivity(activity.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestSearchActivity({ payload }) {
    try {
        if (
            payload.data.StartDate !== "" &&
            !payload.data.StartDate.includes("T")
        ) {
            payload.data.StartDate =
                payload.data.StartDate.concat("T00:00:00+00:00");
        }
        if (
            payload.data.EndDate !== "" &&
            !payload.data.EndDate.includes("T")
        ) {
            payload.data.EndDate =
                payload.data.EndDate.concat("T00:00:00+00:00");
        }
        if (payload.data.WorkOrderId) {
            payload.data.WorkOrderId = Number(payload.data.WorkOrderId);
        }
        yield put(setActivity(null));
        const activity = yield call(API, payload.url, payload.data);
        if (activity.message) {
            if (activity.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(activity.message));
            }
        } else if (activity.data === "") {
            yield put(setActivity([]));
        } else {
            activity.data = sortList(true, activity.data, "Date");
            const images = yield all(
                activity.data.map((item) =>
                    item.ImageId
                        ? call(API, "/image/", { Filename: item.ImageId })
                        : ""
                )
            );
            activity.data.map((item, i) => {
                if (item.Event === "Ticket") {
                    item.Icon = TicketIcon;
                } else if (item.Event === "Machine") {
                    item.Icon = MachineIcon;
                } else if (item.Event === "Lock") {
                    item.Icon = LockIcon;
                } else if (item.Event === "User") {
                    item.Icon = UserIcon;
                } else if (item.Event === "Point") {
                    item.Icon = LockIcon;
                } else if (item.Event === "Job") {
                    item.Icon = TicketIcon;
                } else if (item.Event === "Template") {
                    item.Icon = MachineIcon;
                } else {
                    item.Icon = UserIcon;
                }
                if (item.ImageId) {
                    item.Image = images[i].data
                        ? `data:image/png;base64, ${images[i].data}`
                        : "assets/images/Icons/machine/machine@4x.png";
                }
            });
            yield put(setActivity(activity.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestSnapshot({ payload }) {
    try {
        payload.data.StartDate = payload.data.StartDate + "T00:00:00+00:00";
        payload.data.EndDate = payload.data.EndDate + "T00:00:00+00:00";
        yield put(setSnapshot(null));
        const snapshot = yield call(API, payload.url, payload.data);
        if (snapshot.message) {
            if (snapshot.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(snapshot.message));
            }
        } else if (snapshot.data === "") {
            yield put(setSnapshot([]));
        } else {
            const authUser = yield select(getAuthUser);
            snapshot.data.LockoutsPlaced.map((lockout) => {
                lockout.LockoutPlacedDate =
                (authUser.Settings.DateFormat && authUser.Settings.DateFormat === "daymonth")
                ?
                moment(
                    lockout.LockoutPlacedDate
                ).format("Do MMM YYYY")
                :
                moment(
                    lockout.LockoutPlacedDate
                ).format("MMM Do YYYY");
            });
            yield put(setSnapshot(snapshot.data));
            yield put(setLoader(false));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestSnapshotWorkorder({ payload }) {
    try {
        payload.data.StartDate = payload.data.StartDate + "T00:00:00+00:00";
        payload.data.EndDate = payload.data.EndDate + "T00:00:00+00:00";
        yield put(setSnapshotWorkorder(null));
        const snapshot = yield call(API, payload.url, payload.data);
        if (snapshot.message) {
            if (snapshot.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(snapshot.message));
            }
        } else if (snapshot.data === "") {
            yield put(setSnapshotWorkorder([]));
        } else {
            yield put(setSnapshotWorkorder(snapshot.data));
            yield put(setLoader(false));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFetchReports({ payload }) {
    try {
        yield put(setReports([]));
        const reports = yield call(API, payload.url, payload.data);
        if (reports.message) {
            if (reports.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(reports.message, "message"));
            }
        } else if (!reports.data) {
            yield put(setReports([]));
        } else {
            yield put(setReports(reports.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFetchTicketsNotStarted({ payload }) {
    try {
        const tickets = yield call(API, payload.url, payload.data);
        if (tickets.message) {
            if (tickets.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(tickets.message, "message"));
            }
        } else if (!tickets.data) {
            yield put(setTicketsNotStarted([]));
        } else {
            yield put(setTicketsNotStarted(tickets.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

export function* selectActivity() {
    yield takeEvery(FETCH_ACTIVITY, requestActivity);
}

export function* searchActivity() {
    yield takeEvery(SEARCH_ACTIVITY, requestSearchActivity);
}

export function* selectSnapshot() {
    yield takeEvery(FETCH_SNAPSHOT, requestSnapshot);
}

export function* selectSnapshotWorkorder() {
    yield takeEvery(FETCH_SNAPSHOT_WORKORDER, requestSnapshotWorkorder);
}

export function* selectFetchReports() {
    yield takeEvery(FETCH_REPORTS, requestFetchReports);
}

export function* selectFetchTicketsNotStarted() {
    yield takeEvery(FETCH_TICKETS_NOT_STARTED, requestFetchTicketsNotStarted);
}

export default function* rootSaga() {
    yield all([
        fork(selectActivity),
        fork(selectSnapshot),
        fork(selectSnapshotWorkorder),
        fork(searchActivity),
        fork(selectFetchReports),
        fork(selectFetchTicketsNotStarted),
    ]);
}
