import { all, call, fork, put, takeEvery, select } from "redux-saga/effects";
import { history } from "../store/history";
import {
    LockIcon,
    TicketIcon,
    MachineIcon,
    UserIcon,
    CarsealIcon,
} from "util/icons";
import { API } from "../api";
import {
    FETCH_TEAMS,
    FETCH_TEAMS_SELECT,
    FETCH_UNITS,
    FETCH_UNITS_SELECT,
    CREATE_TEAM,
    CREATE_UNIT,
    FETCH_SEALS,
    FETCH_SEAL_BY_ID,
    FETCH_SITES_SEALS,
    FETCH_POSITIONS,
    CREATE_POSITION,
    FETCH_SEAL_SNAPSHOT,
    CREATE_SEAL,
    FETCH_TEAM_BY_ID,
    FETCH_UNIT_BY_ID,
    FETCH_POSITION_BY_ID,
    EDIT_TEAM,
    EDIT_UNIT,
    EDIT_POSITION,
    FETCH_SEALS_ACTIVITY,
    TEAM_MEMBER_ADD,
    TEAM_MEMBER_REMOVE,
    FETCH_SEAL_ACTIVITY,
    EDIT_SEAL,
    SEARCH_SEALS,
    SEARCH_SEAL_AUDIT,
    FETCH_POSITIONS_SELECT,
    FETCH_SEAL_SEARCH_SELECT,
    FETCH_SEAL_ACTIVITY_SEARCH_SELECT,
    SEARCH_SEAL_ACTIVITY,
    FETCH_SEAL_SEARCH_DEVICES,
    FETCH_SEAL_DEVICE_BY_ID,
    DELETE_SEAL_LOCK,
    CREATE_SEAL_LOCK,
    EDIT_SEAL_LOCK,
} from "constants/ActionTypes";
import {
    setTeams,
    setTeamsSelect,
    setUnits,
    setUnitsSelect,
    setSeals,
    setSeal,
    setSitesSeals,
    setPositions,
    setTeam,
    setUnit,
    setPosition,
    setSealSnapshot,
    setSealsActivity,
    setSealActivity,
    setSearchedSeals,
    setSealAuditList,
    setPositionsSelect,
    setSealSearchUsers,
    setSealSearchPositions,
    setSealActivitySearchActivityTypes,
    setSealActivitySearchList,
    setSealSearchDevices,
    setSealLock,
    fetchTeamById
} from "actions/Carseals";
import { userSignOut } from "actions/Auth";
import { showMessage } from "actions/App";
import { sortList } from "util";

const getFilters = (state) => state.carseals.siteSealsList;
const getTheme = (state) => state.app.theme;

function* requestTeamList({ payload }) {
    try {
        const teams = yield call(API, payload.url, payload.data);
        if (teams.message) {
            if (teams.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(teams.message, "message"));
            }
        } else if (!teams.data) {
            yield put(setTeams([]));
        } else {
            teams.data = teams.data.sort(function (a, b) {
                a = JSON.stringify(a["TypeName"]);
                b = JSON.stringify(b["TypeName"]);
                return a.localeCompare(b);
            });
            yield put(setTeams(teams.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestTeamSelectList({ payload }) {
    try {
        const teams = yield call(API, payload.url, payload.data);
        if (teams.message) {
            if (teams.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(teams.message, "message"));
            }
        } else if (!teams.data) {
            yield put(setTeamsSelect([]));
        } else {
            teams.data = teams.data.sort(function (a, b) {
                a = JSON.stringify(a["TypeName"]);
                b = JSON.stringify(b["TypeName"]);
                return a.localeCompare(b);
            });
            yield put(setTeamsSelect(teams.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestUnitList({ payload }) {
    try {
        const units = yield call(API, payload.url, payload.data);
        if (units.message) {
            if (units.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(units.message, "message"));
            }
        } else if (!units.data) {
            yield put(setUnits([]));
        } else {
            units.data = units.data.sort(function (a, b) {
                a = JSON.stringify(a["TypeName"]);
                b = JSON.stringify(b["TypeName"]);
                return a.localeCompare(b);
            });
            yield put(setUnits(units.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestUnitSelectList({ payload }) {
    try {
        const units = yield call(API, payload.url, payload.data);
        if (units.message) {
            if (units.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(units.message, "message"));
            }
        } else if (!units.data) {
            yield put(setUnitsSelect([]));
        } else {
            units.data = units.data.sort(function (a, b) {
                a = JSON.stringify(a["TypeName"]);
                b = JSON.stringify(b["TypeName"]);
                return a.localeCompare(b);
            });
            yield put(setUnitsSelect(units.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestTeamCreate({ payload }) {
    try {
        const required = {
            TypeName: payload.data.TypeName,
        };
        for (var key in required) {
            if (required[key] === undefined || required[key] === "") {
                yield put(
                    showMessage(`notifications.error.blank.${key}`, "warning")
                );
                return;
            }
        }
        const team = yield call(API, payload.url, payload.data);
        if (team.message) {
            if (team.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(team.message, "message"));
            }
        } else {
            yield put(showMessage("notifications.success.add", "success"));
            yield history.push(`/app/carseals/all`);
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestUnitCreate({ payload }) {
    try {
        const required = {
            TypeName: payload.data.TypeName,
        };
        for (var key in required) {
            if (required[key] === undefined || required[key] === "") {
                yield put(
                    showMessage(`notifications.error.blank.${key}`, "warning")
                );
                return;
            }
        }
        const unit = yield call(API, payload.url, payload.data);
        if (unit.message) {
            if (unit.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(unit.message, "message"));
            }
        } else {
            yield put(showMessage("notifications.success.add", "success"));
            yield history.push(`/app/carseals/all`);
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestSealList({ payload }) {
    try {
        yield put(setSeals(null));
        const seals = yield call(API, payload.url, payload.data);
        if (seals.message) {
            if (seals.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(seals.message, "message"));
            }
        } else if (!seals.data) {
            yield put(setSeals([]));
        } else {
            seals.data.map((item) => {
                item.Image = item.PlacedDeviceId
                    ? "assets/images/Icons/lock/bluecheckedlock.png"
                    : "assets/images/Icons/lock/graycheckedlock.png";
            });
            seals.data = seals.data.sort(function (a, b) {
                a = JSON.stringify(a["Location"]);
                b = JSON.stringify(b["Location"]);
                return a.localeCompare(b);
            });
            yield put(setSeals(seals.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestSeal({ payload }) {
    try {
        yield put(setSeal(null));
        const seal = yield call(API, payload.url, payload.data);
        if (seal.message) {
            if (seal.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(seal.message));
            }
        } else if (seal.data === "") {
            yield put(showMessage("notifications.error.fail.notClientSeal"));
            yield history.push("/app/carseals/all");
        } else {
            const image = yield call(API, "/image/", {
                Filename: seal.data.Id,
            });
            image.data
                ? (seal.data.Image = `data:image/png;base64, ${image.data}`)
                : (seal.data.Icon = CarsealIcon);
            const theme = yield select(getTheme);
            seal.data.LocationLatLong &&
            seal.data.LocationLatLong !== "" &&
            seal.data.LocationLatLong !== "0"
                ? (seal.data.map = [
                      {
                          color: theme.color.themeColor,
                          lat: JSON.parse(
                              seal.data.LocationLatLong.split(",")[0]
                          ),
                          lng: JSON.parse(
                              seal.data.LocationLatLong.split(",")[1].split(
                                  " "
                              )[1]
                          ),
                      },
                  ])
                : null;
            yield put(setSeal(seal.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestSitesSeals({ payload }) {
    try {
        const sites = yield call(API, payload.url, payload.data);
        if (sites.message) {
            if (sites.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(sites.message, "message"));
            }
        } else if (!sites.data) {
            yield put(setSitesSeals([]));
        } else {
            sites.data = sites.data.sort(function (a, b) {
                a = JSON.stringify(a["SiteName"]);
                b = JSON.stringify(b["SiteName"]);
                return a.localeCompare(b);
            });
            const userSite = JSON.parse(
                sessionStorage.getItem("user_data")
            ).SiteId;
            const filters = yield select(getFilters);
            sites.data.map((site) => {
                site.title = site.SiteName;
            });
            filters.length == 0 &&
                sites.data.map((site) =>
                    site.Id === userSite
                        ? (site.active = true)
                        : (site.active = false)
                );
            yield filters.length == 0 && put(setSitesSeals(sites.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestPositionList({ payload }) {
    try {
        const positions = yield call(API, payload.url, payload.data);
        if (positions.message) {
            if (positions.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(positions.message, "message"));
            }
        } else if (!positions.data) {
            yield put(setPositions([]));
        } else {
            positions.data = positions.data.sort(function (a, b) {
                a = JSON.stringify(a["TypeName"]);
                b = JSON.stringify(b["TypeName"]);
                return a.localeCompare(b);
            });
            yield put(setPositions(positions.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestPositionCreate({ payload }) {
    try {
        const required = {
            TypeName: payload.data.TypeName,
        };
        for (var key in required) {
            if (required[key] === undefined || required[key] === "") {
                yield put(
                    showMessage(`notifications.error.blank.${key}`, "warning")
                );
                return;
            }
        }
        const position = yield call(API, payload.url, payload.data);
        if (position.message) {
            if (position.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(position.message, "message"));
            }
        } else {
            yield put(showMessage("notifications.success.add", "success"));
            yield history.push(`/app/carseals/all`);
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestSealSnapshot({ payload }) {
    try {
        const snapshot = yield call(API, payload.url, payload.data);
        if (snapshot.message) {
            if (snapshot.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(snapshot.message, "message"));
            }
        } else {
            yield put(setSealSnapshot(snapshot.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestSealCreate({ payload }) {
    try {
        const required = {
            SiteId: payload.data.SiteId,
            Classification: payload.data.TypeClassificationId,
            Team: payload.data.TypeTeamId,
            Unit: payload.data.TypeUnitId,
            YearInstalled: payload.data.YearInstalled,
            Location: payload.data.Location,
            Position: payload.data.DefaultSealPositionId,
        };
        for (var key in required) {
            if (required[key] === undefined || required[key] === "") {
                yield put(
                    showMessage(`notifications.error.blank.${key}`, "warning")
                );
                return;
            }
        }
        payload.data.TypeClassificationId = Number(
            payload.data.TypeClassificationId
        );
        payload.data.TypeTeamId = Number(payload.data.TypeTeamId);
        payload.data.TypeUnitId = Number(payload.data.TypeUnitId);
        payload.data.DefaultSealPositionId = Number(
            payload.data.DefaultSealPositionId
        );
        payload.data.YearInstalled = Number(payload.data.YearInstalled);
        const seal = yield call(API, payload.url, payload.data);
        if (seal.message) {
            if (seal.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(seal.message, "message"));
            }
        } else {
            yield put(showMessage("notifications.success.add", "success"));
            yield history.push(`/app/carseals/display/${seal.data.Id}`);
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestTeam({ payload }) {
    try {
        payload.data.Id = Number(payload.data.Id);
        const team = yield call(API, payload.url, payload.data);
        if (team.message) {
            if (team.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(team.message));
            }
        } else if (team.data === "") {
            yield put(showMessage("notifications.error.fail.notClientMachine"));
            yield history.push("/app/carseals/all");
        } else {
            yield put(setTeam(team.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestUnit({ payload }) {
    try {
        payload.data.Id = Number(payload.data.Id);
        const unit = yield call(API, payload.url, payload.data);
        if (unit.message) {
            if (unit.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(unit.message));
            }
        } else if (unit.data === "") {
            yield put(showMessage("notifications.error.fail.notClientMachine"));
            yield history.push("/app/carseals/all");
        } else {
            yield put(setUnit(unit.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestPosition({ payload }) {
    try {
        payload.data.Id = Number(payload.data.Id);
        const position = yield call(API, payload.url, payload.data);
        if (position.message) {
            if (position.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(position.message));
            }
        } else if (position.data === "") {
            yield put(showMessage("notifications.error.fail.notClientMachine"));
            yield history.push("/app/carseals/all");
        } else {
            yield put(setPosition(position.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestEditTeam({ payload }) {
    try {
        payload.data.Id = Number(payload.data.Id);
        const team = yield call(API, payload.url, payload.data);
        if (team.message) {
            if (team.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(team.message, "message"));
            }
        } else {
            yield put(showMessage("notifications.success.edit", "success"));
            yield history.push(`/app/carseals/display/team/${payload.data.Id}`);
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestEditUnit({ payload }) {
    try {
        payload.data.Id = Number(payload.data.Id);
        const unit = yield call(API, payload.url, payload.data);
        if (unit.message) {
            if (unit.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(unit.message, "message"));
            }
        } else {
            yield put(showMessage("notifications.success.edit", "success"));
            yield history.push(`/app/carseals/display/unit/${payload.data.Id}`);
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestEditPosition({ payload }) {
    try {
        payload.data.Id = Number(payload.data.Id);
        const position = yield call(API, payload.url, payload.data);
        if (position.message) {
            if (position.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(position.message, "message"));
            }
        } else {
            yield put(showMessage("notifications.success.edit", "success"));
            yield history.push(
                `/app/carseals/display/position/${payload.data.Id}`
            );
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestSealsActivity({ payload }) {
    try {
        yield put(setSealsActivity(null));
        const activity = yield call(API, payload.url, payload.data);
        if (activity.message) {
            if (activity.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(activity.message));
            }
        } else if (activity.data === "") {
            yield put(setSealsActivity([]));
        } else {
            const images = yield all(
                activity.data.map((item) =>
                    item.ImageId
                        ? call(API, "/image/", { Filename: item.ImageId })
                        : ""
                )
            );
            activity.data.map((item, i) => {
                if (item.Event === "Ticket") {
                    item.Icon = TicketIcon;
                } else if (item.Event === "Machine") {
                    item.Icon = MachineIcon;
                } else if (item.Event === "Lock") {
                    item.Icon = LockIcon;
                } else if (item.Event === "User") {
                    item.Icon = UserIcon;
                } else if (item.Event === "Point") {
                    item.Icon = LockIcon;
                } else if (item.Event === "Job") {
                    item.Icon = TicketIcon;
                } else if (item.Event === "Template") {
                    item.Icon = MachineIcon;
                } else {
                    item.Icon = UserIcon;
                }
                if (item.ImageId) {
                    item.Image = images[i].data
                        ? `data:image/png;base64, ${images[i].data}`
                        : "assets/images/Icons/machine/machine@4x.png";
                }
                if (!item.PositionName) {
                    item.PositionName = "";
                }
            });
            yield put(setSealsActivity(activity.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestTeamMemberAdd({ payload }) {
    payload.data.TeamId = Number(payload.data.TeamId);
    try {
        const team = yield call(API, payload.url, payload.data);
        if (team.message) {
            if (team.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(team.message, "message"));
            }
        } else {
            yield put(showMessage("notifications.success.edit", "success"));
            yield put(fetchTeamById(payload.data.TeamId));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestTeamMemberRemove({ payload }) {
    try {
        const team = yield call(API, payload.url, payload.data);
        if (team.message) {
            if (team.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(team.message, "message"));
            }
        } else {
            yield put(showMessage("notifications.success.edit", "success"));
            yield put(fetchTeamById(payload.data.TeamId));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestSealActivity({ payload }) {
    try {
        yield put(setSealActivity(null));
        const activity = yield call(API, payload.url, payload.data);
        if (activity.message) {
            if (activity.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(activity.message));
            }
        } else if (activity.data === "") {
            yield put(setSealActivity([]));
        } else {
            const images = yield all(
                activity.data.map((item) =>
                    item.ImageId
                        ? call(API, "/image/", { Filename: item.ImageId })
                        : ""
                )
            );
            activity.data.map((item, i) => {
                if (item.Event === "Ticket") {
                    item.Icon = TicketIcon;
                } else if (item.Event === "Machine") {
                    item.Icon = MachineIcon;
                } else if (item.Event === "Lock") {
                    item.Icon = LockIcon;
                } else if (item.Event === "User") {
                    item.Icon = UserIcon;
                } else if (item.Event === "Point") {
                    item.Icon = LockIcon;
                } else if (item.Event === "Job") {
                    item.Icon = TicketIcon;
                } else if (item.Event === "Template") {
                    item.Icon = MachineIcon;
                } else {
                    item.Icon = UserIcon;
                }
                if (item.ImageId) {
                    item.Image = images[i].data
                        ? `data:image/png;base64, ${images[i].data}`
                        : "assets/images/Icons/machine/machine@4x.png";
                }
                if (!item.PositionName) {
                    item.PositionName = "";
                }
            });
            yield put(setSealActivity(activity.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestEditSeal({ payload }) {
    try {
        payload.data.TypeTeamId = Number(payload.data.TypeTeamId);
        payload.data.TypeUnitId = Number(payload.data.TypeUnitId);
        payload.data.TypeClassificationId = Number(
            payload.data.TypeClassificationId
        );
        payload.data.DefaultSealPositionId = Number(
            payload.data.DefaultSealPositionId
        );
        payload.data.YearInstalled = Number(payload.data.YearInstalled);
        const seal = yield call(API, payload.url, payload.data);
        if (seal.message) {
            if (seal.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(seal.message, "message"));
            }
        } else {
            yield put(showMessage("notifications.success.edit", "success"));
            yield history.push(`/app/carseals/display/${payload.data.Id}`);
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestSearchSeals({ payload }) {
    try {
        if (payload.data.StartDate && !payload.data.EndDate) {
            yield put(
                showMessage(`notifications.error.blank.EndDate`, "warning")
            );
            return;
        }
        if (payload.data.EndDate && !payload.data.StartDate) {
            yield put(
                showMessage(`notifications.error.blank.StartDate`, "warning")
            );
            return;
        }
        payload.data.TypeTeamId = Number(payload.data.TypeTeamId);
        payload.data.TypeUnitId = Number(payload.data.TypeUnitId);
        payload.data.TypeClassificationId = Number(
            payload.data.TypeClassificationId
        );
        payload.data.PositionId = Number(payload.data.PositionId);
        let newPayload = { ...payload.data };
        newPayload.StartDate = newPayload.StartDate
            ? newPayload.StartDate + "T00:00:00+00:00"
            : "";
        newPayload.EndDate = newPayload.EndDate
            ? newPayload.EndDate + "T00:00:00+00:00"
            : "";
        const seals = yield call(API, payload.url, newPayload);
        if (seals.message) {
            if (seals.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(seals.message, "message"));
            }
        } else if (!seals.data) {
            yield put(setSearchedSeals([]));
        } else {
            seals.data.map((seal) => {
                !seal.PlacedDeviceName ? (seal.PlacedDeviceName = "") : null;
                !seal.PlacedPositionName
                    ? (seal.PlacedPositionName = "")
                    : null;
                !seal.PlacedByName ? (seal.PlacedByName = "") : null;
                !seal.PlacedDate ? (seal.PlacedDate = "") : null;
            });
            yield put(setSearchedSeals(seals.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestSearchSealAudit({ payload }) {
    payload.data.TeamId = Number(payload.data.TeamId);
    payload.data.UnitId = Number(payload.data.UnitId);
    try {
        const seals = yield call(API, payload.url, payload.data);
        if (seals.message) {
            if (seals.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(seals.message, "message"));
            }
        } else if (!seals.data) {
            yield put(setSealAuditList([]));
        } else {
            yield put(setSealAuditList(seals.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestPositionListSelect({ payload }) {
    try {
        const positions = yield call(API, payload.url, payload.data);
        if (positions.message) {
            if (positions.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(positions.message, "message"));
            }
        } else if (!positions.data) {
            yield put(setPositionsSelect([]));
        } else {
            positions.data = positions.data.sort(function (a, b) {
                a = JSON.stringify(a["TypeName"]);
                b = JSON.stringify(b["TypeName"]);
                return a.localeCompare(b);
            });
            yield put(setPositionsSelect(positions.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestSealSearchSelect({ payload }) {
    try {
        const sealData = yield call(API, payload.url, payload.data);
        if (sealData.message) {
            if (sealData.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(sealData.message, "message"));
            }
        } else if (!sealData.data) {
            yield put(setSealSearchUsers([]));
            yield put(setSealSearchPositions([]));
        } else {
            yield put(setSealSearchUsers(sealData.data.Users));
            yield put(setSealSearchPositions(sealData.data.Positions));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestSealActivitySearchSelect({ payload }) {
    try {
        const sealData = yield call(API, payload.url, payload.data);
        if (sealData.message) {
            if (sealData.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(sealData.message, "message"));
            }
        } else if (!sealData.data) {
            yield put(setSealSearchPositions([]));
            yield put(setSealActivitySearchActivityTypes([]));
        } else {
            yield put(setSealSearchPositions(sealData.data.Positions));
            yield put(
                setSealActivitySearchActivityTypes(
                    sealData.data.SystemTypeSealActivity
                )
            );
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestSearchSealActivity({ payload }) {
    try {
        if (payload.data.StartDate && !payload.data.EndDate) {
            yield put(
                showMessage(`notifications.error.blank.EndDate`, "warning")
            );
            return;
        }
        if (payload.data.EndDate && !payload.data.StartDate) {
            yield put(
                showMessage(`notifications.error.blank.StartDate`, "warning")
            );
            return;
        }
        payload.data.SystemTypeSealActivityId = Number(
            payload.data.SystemTypeSealActivityId
        );
        payload.data.PositionId = Number(payload.data.PositionId);
        let newPayload = { ...payload.data };
        newPayload.StartDate = newPayload.StartDate
            ? newPayload.StartDate + "T00:00:00+00:00"
            : "";
        newPayload.EndDate = newPayload.EndDate
            ? newPayload.EndDate + "T00:00:00+00:00"
            : "";
        const activity = yield call(API, payload.url, newPayload);
        if (activity.message) {
            if (activity.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(activity.message, "message"));
            }
        } else if (!activity.data) {
            yield put(setSealActivitySearchList([]));
        } else {
            activity.data = sortList(true, activity.data, "ActivityDate");
            yield put(setSealActivitySearchList(activity.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestSearchSealDevices({ payload }) {
    try {
        const sealDevices = yield call(API, payload.url, payload.data);
        if (sealDevices.message) {
            if (sealDevices.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(sealDevices.message, "message"));
            }
        } else if (!sealDevices.data) {
            yield put(setSealSearchDevices([]));
        } else {
            yield put(setSealSearchDevices(sealDevices.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestSealDeviceById({ payload }) {
    try {
        const sealDevice = yield call(API, payload.url, payload.data);
        if (sealDevice.message) {
            if (sealDevice.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(sealDevice.message, "message"));
            }
        } else if (!sealDevice.data) {
            yield put(setSealLock(null));
        } else {
            yield put(setSealLock(sealDevice.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestSealDeviceDelete({ payload }) {
    try {
        const lock = yield call(API, payload.url, payload.data);
        if (lock.message) {
            if (lock.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(lock.message, "message"));
            }
        } else {
            yield put(showMessage("notifications.success.delete", "success"));
            yield history.push("/app/carseals/all");
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestSealLockCreate({ payload }) {
    try {
        const required = {
            Label: payload.data.Label,
            SerialNumber: payload.data.SerialNumber,
        };
        for (var key in required) {
            if (required[key] === undefined || required[key] === "") {
                yield put(
                    showMessage(`notifications.error.blank.${key}`, "warning")
                );
                return;
            }
        }
        const lock = yield call(API, payload.url, payload.data);
        if (lock.message) {
            if (lock.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(lock.message, "message"));
            }
        } else if (lock.validationError) {
            var error = Object.keys(lock.validationError).map(function (key) {
                return lock.validationError[key];
            });
            yield put(showMessage(error, "message"));
        } else {
            yield put(showMessage("notifications.success.add", "success"));
            yield history.push(`/app/carseals/display/lock/${lock.data.Id}`);
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestSealLockEdit({ payload }) {
    try {
        const required = {
            Label: payload.data.Label,
            SerialNumber: payload.data.SerialNumber,
            SiteName: payload.data.SiteName,
        };
        for (var key in required) {
            if (required[key] === undefined || required[key] === "") {
                yield put(
                    showMessage(`notifications.error.blank.${key}`, "warning")
                );
                return;
            }
        }
        const lock = yield call(API, payload.url, payload.data);
        if (lock.message) {
            if (lock.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(lock.message, "message"));
            }
        } else {
            yield put(showMessage("notifications.success.edit", "success"));
            yield history.push(`/app/carseals/display/lock/${payload.data.Id}`);
        }
    } catch (error) {
        console.warn(error);
    }
}

export function* selectTeamList() {
    yield takeEvery(FETCH_TEAMS, requestTeamList);
}

export function* selectTeamSelectList() {
    yield takeEvery(FETCH_TEAMS_SELECT, requestTeamSelectList);
}

export function* selectUnitList() {
    yield takeEvery(FETCH_UNITS, requestUnitList);
}

export function* selectUnitSelectList() {
    yield takeEvery(FETCH_UNITS_SELECT, requestUnitSelectList);
}

export function* selectTeamCreate() {
    yield takeEvery(CREATE_TEAM, requestTeamCreate);
}

export function* selectUnitCreate() {
    yield takeEvery(CREATE_UNIT, requestUnitCreate);
}

export function* selectSealList() {
    yield takeEvery(FETCH_SEALS, requestSealList);
}

export function* selectSealById() {
    yield takeEvery(FETCH_SEAL_BY_ID, requestSeal);
}

export function* selectSitesSeals() {
    yield takeEvery(FETCH_SITES_SEALS, requestSitesSeals);
}

export function* selectPositionList() {
    yield takeEvery(FETCH_POSITIONS, requestPositionList);
}

export function* selectPositionCreate() {
    yield takeEvery(CREATE_POSITION, requestPositionCreate);
}

export function* selectSealSnapshot() {
    yield takeEvery(FETCH_SEAL_SNAPSHOT, requestSealSnapshot);
}

export function* selectSealCreate() {
    yield takeEvery(CREATE_SEAL, requestSealCreate);
}

export function* selectTeamById() {
    yield takeEvery(FETCH_TEAM_BY_ID, requestTeam);
}

export function* selectUnitById() {
    yield takeEvery(FETCH_UNIT_BY_ID, requestUnit);
}

export function* selectPositionById() {
    yield takeEvery(FETCH_POSITION_BY_ID, requestPosition);
}

export function* editTeam() {
    yield takeEvery(EDIT_TEAM, requestEditTeam);
}

export function* editUnit() {
    yield takeEvery(EDIT_UNIT, requestEditUnit);
}

export function* editPosition() {
    yield takeEvery(EDIT_POSITION, requestEditPosition);
}

export function* selectSealsActivity() {
    yield takeEvery(FETCH_SEALS_ACTIVITY, requestSealsActivity);
}

export function* selectTeamMemberAdd() {
    yield takeEvery(TEAM_MEMBER_ADD, requestTeamMemberAdd);
}

export function* selectTeamMemberRemove() {
    yield takeEvery(TEAM_MEMBER_REMOVE, requestTeamMemberRemove);
}

export function* selectSealActivity() {
    yield takeEvery(FETCH_SEAL_ACTIVITY, requestSealActivity);
}

export function* editSeal() {
    yield takeEvery(EDIT_SEAL, requestEditSeal);
}

export function* selectSearchSeals() {
    yield takeEvery(SEARCH_SEALS, requestSearchSeals);
}

export function* selectSearchSealAudit() {
    yield takeEvery(SEARCH_SEAL_AUDIT, requestSearchSealAudit);
}

export function* selectPositionListSelect() {
    yield takeEvery(FETCH_POSITIONS_SELECT, requestPositionListSelect);
}

export function* selectSealSearchSelect() {
    yield takeEvery(FETCH_SEAL_SEARCH_SELECT, requestSealSearchSelect);
}

export function* selectSealActivitySearchSelect() {
    yield takeEvery(
        FETCH_SEAL_ACTIVITY_SEARCH_SELECT,
        requestSealActivitySearchSelect
    );
}

export function* selectSearchSealActivity() {
    yield takeEvery(SEARCH_SEAL_ACTIVITY, requestSearchSealActivity);
}

export function* selectSearchSealDevices() {
    yield takeEvery(FETCH_SEAL_SEARCH_DEVICES, requestSearchSealDevices);
}

export function* selectSealDeviceById() {
    yield takeEvery(FETCH_SEAL_DEVICE_BY_ID, requestSealDeviceById);
}

export function* selectSealDeviceDelete() {
    yield takeEvery(DELETE_SEAL_LOCK, requestSealDeviceDelete);
}

export function* selectSealLockCreate() {
    yield takeEvery(CREATE_SEAL_LOCK, requestSealLockCreate);
}

export function* selectSealLockEdit() {
    yield takeEvery(EDIT_SEAL_LOCK, requestSealLockEdit);
}

export default function* rootSaga() {
    yield all([
        fork(selectTeamList),
        fork(selectTeamSelectList),
        fork(selectUnitList),
        fork(selectUnitSelectList),
        fork(selectTeamCreate),
        fork(selectUnitCreate),
        fork(selectSealList),
        fork(selectSealById),
        fork(selectSitesSeals),
        fork(selectPositionList),
        fork(selectPositionCreate),
        fork(selectSealSnapshot),
        fork(selectSealCreate),
        fork(selectTeamById),
        fork(selectUnitById),
        fork(selectPositionById),
        fork(editTeam),
        fork(editUnit),
        fork(editPosition),
        fork(selectSealsActivity),
        fork(selectTeamMemberAdd),
        fork(selectTeamMemberRemove),
        fork(selectSealActivity),
        fork(editSeal),
        fork(selectSearchSeals),
        fork(selectSearchSealAudit),
        fork(selectPositionListSelect),
        fork(selectSealSearchSelect),
        fork(selectSealActivitySearchSelect),
        fork(selectSearchSealActivity),
        fork(selectSearchSealDevices),
        fork(selectSealDeviceById),
        fork(selectSealDeviceDelete),
        fork(selectSealLockCreate),
        fork(selectSealLockEdit),
    ]);
}
