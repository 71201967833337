import {
    SET_FILTER_LOCKS,
    SET_LOCKS,
    SET_LOCK,
    SET_LOCK_FILTER,
    SET_LOCK_ACTIVITY,
    SET_SEARCHED_LOCKS,
    SET_LOCK_SEARCH_TERMS,
    SET_LOCKS_SEARCH_SELECT,
} from "constants/ActionTypes";

const INIT_STATE = {
    loader: false,
    locksList: [],
    locksListFilter: [],
    lockObject: null,
    lockActivity: [],
    searchedLocks: [],
    selectLocks: [],
    lockFilterList: [
        {
            title: "comp.table.header.blank",
            width: "24px",
            active: false,
            sortable: false,
            sortTitle: "",
            order: false,
            mobile: false,
            isolation: true,
            lockout: false,
        },
        {
            title: "comp.table.header.label",
            width: "30%",
            active: false,
            sortable: true,
            sortTitle: "Label",
            order: false,
            mobile: true,
            isolation: true,
            lockout: true,
        },
        {
            title: "pages.Site.Type",
            width: "15%",
            active: false,
            sortable: true,
            sortTitle: "SiteName",
            order: false,
            mobile: false,
            isolation: true,
            lockout: true,
        },
        {
            title: "comp.table.header.serialNumber",
            width: "15%",
            active: false,
            sortable: true,
            sortTitle: "SerialNumber",
            order: false,
            mobile: false,
            isolation: true,
            lockout: true,
        },
        {
            title: "comp.table.header.macAddress",
            width: "15%",
            active: false,
            sortable: true,
            sortTitle: "MacAddress",
            order: false,
            mobile: false,
            isolation: true,
            lockout: true,
        },
        {
            title: "comp.table.header.openTicket",
            width: "15%",
            active: true,
            sortable: true,
            sortTitle: "WorkOrderId",
            order: true,
            mobile: true,
            isolation: true,
            lockout: true,
        },
        {
            title: "comp.table.header.active",
            width: "15%",
            active: false,
            sortable: true,
            sortTitle: "IsActive",
            order: false,
            mobile: false,
            isolation: true,
            lockout: true,
            style: {textAlign: 'right'}
        },
    ],
    searchLockTerms: {
        Label: "",
        SiteId: "",
        SerialNumber: "",
        MacAddress: "",
        IsActive: "1",
        BatteryStatus: "",
        HasWorkOrder: ""
    },
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_LOCKS: {
            return {
                ...state,
                locksList: action.payload,
                locksListFilter: [],
            };
        }
        case SET_LOCKS_SEARCH_SELECT: {
            return {
                ...state,
                selectLocks: action.payload,
            };
        }
        case SET_LOCK: {
            return {
                ...state,
                lockObject: action.payload,
            };
        }
        case SET_FILTER_LOCKS: {
            return {
                ...state,
                locksListFilter: action.payload,
            };
        }
        case SET_LOCK_FILTER: {
            return {
                ...state,
                lockFilterList: action.payload,
            };
        }
        case SET_LOCK_ACTIVITY: {
            return {
                ...state,
                lockActivity: action.payload,
            };
        }
        case SET_SEARCHED_LOCKS: {
            return {
                ...state,
                searchedLocks: action.payload,
            };
        }
        case SET_LOCK_SEARCH_TERMS: {
            return {
                ...state,
                searchLockTerms: action.payload,
            };
        }
        default:
            return state;
    }
};
