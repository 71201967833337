import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { IntlProvider } from "react-intl";
import { StyleSheet, css } from "aphrodite";
import "rmc-drawer/assets/index.css";
import "styles/bootstrap.scss";
import "styles/app.scss";
import "styles/app-rtl.scss";

import { updateWindowWidth, updateWindowHeight } from "actions/App";

import MainApp from "app/index";
import AuthApp from "app/routes/Auth";
import RequestApp from "app/routes/Request";

import Notification from "components/Notifications";

class App extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        this.checkSetup();
        window.addEventListener("resize", this.updateDimensions);
    }
    componentDidUpdate() {
        this.checkSetup();
    }
    updateDimensions = () => {
        let width =
            window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth;
        let height =
            window.innerHeight ||
            document.documentElement.clientHeight ||
            document.body.clientHeight;
        this.props.updateWindowWidth(width);
        this.props.updateWindowHeight(height);
    };
    checkSetup = () => {
        const { authUser } = this.props;
        const url = window.location.href;
        if (
            authUser &&
            authUser.RequirePwdReset &&
            authUser.Login &&
            !url.includes("/setup")
        ) {
            this.props.history.push("/setup/password");
        } else if (
            authUser &&
            !authUser.RequirePwdReset &&
            authUser.RequirePinReset &&
            authUser.Login &&
            !url.includes("/setup")
        ) {
            this.props.history.push("/setup/pin");
        } else if (
            authUser &&
            !authUser.RequirePwdReset &&
            !authUser.RequirePinReset &&
            authUser.RequireTermsAck &&
            !url.includes("/setup")
        ) {
            this.props.history.push("/setup/terms");
        }
        this.updateDimensions();
    };
    checkUser = () => {
        const { authUser } = this.props;
        let userSession = JSON.parse(sessionStorage.getItem("user_data"));
        if ((!userSession && !authUser) || !userSession) {
            return false;
        } else {
            return true;
        }
    };
    checkUrl = () => {
        const subdomain = window.location.host.split(".");
        var site = subdomain[0];
        if (subdomain[0] === "www") {
            site = subdomain[1];
        }
        if (site.includes("localhost") || site.includes("loto-go-dev")) {
            return true;
        } else {
            return false;
        }
    };
    render() {
        let { match, appLocale, messages, theme } = this.props;
        this.styles = styles(theme);
        return (
            <IntlProvider locale={appLocale} messages={messages}>
                <div className={`app-main ${css(this.styles.page)}`}>
                    <Notification />
                    {this.checkUser() ? (
                        <Switch>
                            <Route
                                path={`${match.url}app`}
                                component={MainApp}
                            />
                            <Route
                                path={`${match.url}setup`}
                                component={AuthApp}
                            />
                            <Route component={MainApp} />
                        </Switch>
                    ) : (
                        <Switch>
                            <Route
                                path={`${match.url}signin`}
                                component={AuthApp}
                            />
                            <Route
                                path={`${match.url}home`}
                                render={() => {
                                    window.location.href = "home.html";
                                }}
                            />
                            <Route
                                path={`${match.url}accessories`}
                                render={() => {
                                    window.location.href = "accessories.html";
                                }}
                            />
                            <Route
                                path={`${match.url}battery-jumpstarts`}
                                render={() => {
                                    window.location.href =
                                        "battery-jumpstarts.html";
                                }}
                            />
                            <Route
                                path={`${match.url}bluetooth-locks`}
                                render={() => {
                                    window.location.href =
                                        "bluetooth-locks.html";
                                }}
                            />
                            <Route
                                path={`${match.url}covers`}
                                render={() => {
                                    window.location.href = "covers.html";
                                }}
                            />
                            <Route
                                path={`${match.url}isolation`}
                                render={() => {
                                    window.location.href = "isolation.html";
                                }}
                            />
                            <Route
                                path={`${match.url}lockout`}
                                render={() => {
                                    window.location.href = "lockout.html";
                                }}
                            />
                            <Route
                                path={`${match.url}news`}
                                render={() => {
                                    window.location.href = "news.html";
                                }}
                            />
                            <Route
                                path={`${match.url}products`}
                                render={() => {
                                    window.location.href = "products.html";
                                }}
                            />
                            <Route
                                path={`${match.url}request`}
                                component={RequestApp}
                            />
                            <Route
                                exact
                                path={`${match.url}`}
                                component={
                                    this.checkUrl()
                                        ? AuthApp
                                        : () => {
                                              window.location =
                                                  "home.html";
                                              return null;
                                          }
                                }
                            />
                            <Route component={AuthApp} />
                        </Switch>
                    )}
                </div>
            </IntlProvider>
        );
    }
}

const mapStateToProps = ({ app, auth }) => {
    const { appLocale, messages, theme } = app;
    const { authUser } = auth;
    return { appLocale, messages, theme, authUser };
};

export default connect(mapStateToProps, {
    updateWindowWidth,
    updateWindowHeight,
})(App);

const styles = (theme) =>
    StyleSheet.create({
        page: {
            backgroundColor: theme.color.pageColor,
            color: theme.color.fontColor,
        },
    });
