import React from "react";
import { connect } from "react-redux";
import { StyleSheet, css } from "aphrodite";

import languageData from "lngProvider/data";
import { switchLanguage } from "actions/App";
import "../../../public/vendors/flag/sprite-flags-24x24.css";

class LanguageList extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        this.styles = styles(this.props.theme);
        return (
            <div
                className={`messages-list language-list scrollbar ${css(
                    this.styles.wrapper
                )}`}
            >
                <ul className="list-unstyled">
                    {languageData.map((language, i) => (
                        <li
                            key={"LANG" + i}
                            className={css(this.styles.list)}
                            onClick={() => this.props.switchLanguage(language)}
                        >
                            <div className="d-flex align-items-center">
                                <h4 className="mb-0 ml-2">{language.name}</h4>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        );
    }
}

const mapStateToProps = ({ app }) => {
    const { theme } = app;
    return { theme };
};

export default connect(mapStateToProps, { switchLanguage })(LanguageList);

const styles = (theme) =>
    StyleSheet.create({
        wrapper: {
            backgroundColor: theme.color.compColor,
        },
        list: {
            color: theme.color.fontColor,
            ":hover": {
                opacity: ".7",
                cursor: "pointer",
            },
        },
    });
