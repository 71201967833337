import {
    SET_FILTER_TICKETS,
    SET_TICKETS,
    SET_TICKET,
    SET_TICKET_ACTIVITY,
    SET_TICKET_LOCKOUTS,
    SET_TICKET_POINTS,
    SET_TICKET_NOTES,
    SET_TICKET_PERMITS,
    SET_TICKET_DOCUMENTS,
    SET_TICKET_DOCUMENT,
    SET_TICKET_USERS,
    SET_TICKET_FILTER,
    SET_TICKET_PROCESS,
    SET_TICKET_PROCESS_LOADED,
    SET_TICKET_POINT_PROCESS,
    SET_SEARCHED_TICKETS,
    SET_PARENT_TICKETS,
    SET_TICKET_SEARCH_TERMS,
    SET_TICKET_HIERARCHY,
    SET_ACTIVE_LOCKOUTS,
    SET_TICKET_EQUIPMENT,
} from "constants/ActionTypes";

const INIT_STATE = {
    loader: false,
    ticketsList: [],
    ticketsListFilter: [],
    ticketObject: null,
    ticketActivity: null,
    ticketPoints: null,
    ticketNotes: [],
    ticketPermits: [],
    ticketDocuments: [],
    ticketDocument: null,
    ticketUsers: [],
    ticketLockouts: null,
    ticketProcess: [],
    ticketProcessLoaded: false,
    ticketPointProcess: [],
    searchedTickets: [],
    parentTickets: [],
    ticketHierarchy: null,
    activeLockouts: [],
    ticketEquipment: [],
    ticketFilterList: [
        {
            title: "comp.table.header.blank",
            width: "10px",
            active: false,
            sortable: false,
            sortTitle: "",
            order: false,
            mobile: false,
            isolation: true,
            lockout: true,
        },
        {
            title: "#",
            width: "10%",
            active: true,
            sortable: true,
            sortTitle: "Id",
            style: { textAlign: "left" },
            order: false,
            mobile: true,
            isolation: true,
            lockout: true,
        },
        {
            title: "comp.table.header.name",
            width: "10%",
            active: false,
            sortable: true,
            sortTitle: "Name",
            style: { textAlign: "left" },
            order: false,
            mobile: true,
            isolation: false,
            lockout: true,
        },
        {
            title: "comp.table.header.siteName",
            width: "10%",
            active: false,
            sortable: true,
            sortTitle: "SiteName",
            style: { textAlign: "left" },
            order: false,
            mobile: true,
            isolation: false,
            lockout: true,
        },
        {
            title: "comp.table.header.ticketStatus",
            width: "10%",
            active: false,
            sortable: true,
            sortTitle: "WorkOrderStatus",
            order: false,
            mobile: true,
            isolation: true,
            lockout: true,
        },
        {
            title: "form.companyWorkOrder",
            width: "10%",
            active: false,
            sortable: true,
            sortTitle: "CompanyWorkOrder",
            order: false,
            mobile: true,
            isolation: true,
            lockout: false,
        },
        {
            title: "comp.table.header.lockoutStatus",
            width: "10%",
            active: false,
            sortable: true,
            sortTitle: "LockoutStatus",
            order: false,
            mobile: true,
            isolation: true,
            lockout: false,
        },
        {
            title: "comp.sites.Header",
            width: "10%",
            active: false,
            sortable: true,
            sortTitle: "SiteName",
            order: false,
            mobile: true,
            isolation: true,
            lockout: false,
        },
        {
            title: "pages.machines.Type",
            width: "10%",
            active: false,
            sortable: true,
            sortTitle: "MachineName",
            order: false,
            mobile: false,
            isolation: true,
            lockout: false,
        },
        {
            title: "pages.Job.Type",
            width: "10%",
            active: false,
            sortable: true,
            sortTitle: "JobName",
            order: false,
            mobile: false,
            isolation: true,
            lockout: false,
        },
        {
            title: "form.locksRequired",
            width: "10%",
            active: false,
            sortable: true,
            sortTitle: "LocksRequired",
            order: false,
            mobile: false,
            isolation: true,
            lockout: false,
        },
        {
            title: "comp.table.header.locksPlaced",
            width: "10%",
            active: false,
            sortable: true,
            sortTitle: "LocksPlaced",
            order: false,
            mobile: false,
            isolation: true,
            lockout: true,
        },
        {
            title: "comp.table.header.activeLockouts",
            width: "10%",
            active: false,
            sortable: true,
            sortTitle: "ActiveLockouts",
            order: false,
            mobile: false,
            isolation: true,
            lockout: true,
        },
    ],
    ticketNotStartedFilterList: [
        {
            title: "comp.table.header.blank",
            width: "10px",
            active: false,
            sortable: false,
            sortTitle: "",
            order: false,
            mobile: false,
            isolation: true,
            lockout: true,
        },
        {
            title: "comp.table.header.ticketNumber",
            width: "20%",
            active: true,
            sortable: true,
            sortTitle: "WorkOrderId",
            style: { textAlign: "left" },
            order: false,
        },
        {
            title: "pages.machines.Type",
            width: "20%",
            active: false,
            sortable: true,
            sortTitle: "MachineName",
            order: false,
        },
        {
            title: "pages.Job.Type",
            width: "20%",
            active: false,
            sortable: true,
            sortTitle: "Job",
            order: false,
        },
        {
            title: "comp.table.header.createdBy",
            width: "20%",
            active: false,
            sortable: true,
            sortTitle: "CreatedByName",
            order: false,
        },
        {
            title: "pages.filter.tickets.openedDate",
            width: "20%",
            active: false,
            sortable: true,
            sortTitle: "CreatedDate",
            style: { textAlign: "right" },
            order: false,
        },
    ],
    ticketEquipmentFilterList: [
        {
            title: "comp.table.header.equipmentName",
            width: "33%",
            active: true,
            sortable: true,
            sortTitle: "EquipmentName",
            order: false,
            mobile: true,
        },
        {
            title: "comp.table.header.pointName",
            width: "33%",
            active: false,
            sortable: true,
            sortTitle: "PointDescription",
            order: false,
            mobile: false,
        },
        {
            title: "comp.table.header.active",
            width: "33%",
            active: false,
            sortable: true,
            sortTitle: "IsActive",
            style: { textAlign: "right" },
            order: false,
            mobile: true,
        },
    ],
    searchTicketTerms: {
        WorkOrderId: 0,
        MachineName: "",
        Name: "",
        SiteId: "",
        SystemTypeWorkOrderStatusId: 1,
        StartDate: "",
        EndDate: "",
        CompanyWorkOrder: "",
        LockoutStatus: "",
        JobName: ""
    },
    searchLockoutsTerms: {
        WorkOrderId: 0,
        LockoutAddedByName: "",
    },
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_TICKETS: {
            return {
                ...state,
                ticketsList: action.payload,
                ticketsListFilter: [],
            };
        }
        case SET_TICKET: {
            return {
                ...state,
                ticketObject: action.payload,
            };
        }
        case SET_TICKET_ACTIVITY: {
            return {
                ...state,
                ticketActivity: action.payload,
            };
        }
        case SET_TICKET_LOCKOUTS: {
            return {
                ...state,
                ticketLockouts: action.payload,
            };
        }
        case SET_TICKET_POINTS: {
            return {
                ...state,
                ticketPoints: action.payload,
            };
        }
        case SET_TICKET_NOTES: {
            return {
                ...state,
                ticketNotes: action.payload,
            };
        }
        case SET_TICKET_PERMITS: {
            return {
                ...state,
                ticketPermits: action.payload,
            };
        }
        case SET_TICKET_DOCUMENTS: {
            return {
                ...state,
                ticketDocuments: action.payload,
            };
        }
        case SET_TICKET_DOCUMENT: {
            return {
                ...state,
                ticketDocument: action.payload,
            };
        }
        case SET_TICKET_USERS: {
            return {
                ...state,
                ticketUsers: action.payload,
            };
        }
        case SET_FILTER_TICKETS: {
            return {
                ...state,
                ticketsListFilter: action.payload,
            };
        }
        case SET_TICKET_FILTER: {
            return {
                ...state,
                ticketFilterList: action.payload,
            };
        }
        case SET_TICKET_PROCESS: {
            return {
                ...state,
                ticketProcess: action.payload,
            };
        }
        case SET_TICKET_PROCESS_LOADED: {
            return {
                ...state,
                ticketProcessLoaded: action.payload,
            };
        }
        case SET_TICKET_POINT_PROCESS: {
            return {
                ...state,
                ticketPointProcess: action.payload,
            };
        }
        case SET_SEARCHED_TICKETS: {
            return {
                ...state,
                searchedTickets: action.payload,
            };
        }
        case SET_PARENT_TICKETS: {
            return {
                ...state,
                parentTickets: action.payload,
            };
        }
        case SET_TICKET_SEARCH_TERMS: {
            return {
                ...state,
                searchTicketTerms: action.payload,
            };
        }
        case SET_TICKET_HIERARCHY: {
            return {
                ...state,
                ticketHierarchy: action.payload,
            };
        }
        case SET_ACTIVE_LOCKOUTS: {
            return {
                ...state,
                activeLockouts: action.payload,
            };
        }
        case SET_TICKET_EQUIPMENT: {
            return {
                ...state,
                ticketEquipment: action.payload,
            };
        }
        default:
            return state;
    }
};
