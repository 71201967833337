import React from "react";
import { connect } from "react-redux";
import { StyleSheet, css } from "aphrodite";
import { Input, Row, Col } from "reactstrap";
import { injectIntl } from "react-intl";
import InputMask from "react-input-mask";
import { withRouter } from "react-router-dom";
import { setLoader } from "actions/App";
import { fetchSitesList } from "actions/Settings";
import { fetchSealSearchDevices } from "actions/Carseals";

import Tooltip from "components/Tooltips";
import CSV from "components/Export";

class SearchLocks extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchSealLockTerms: props.searchSealLockTerms,
        };
    }
    componentDidMount() {
        const { fetchSitesList } = this.props;
        fetchSitesList();
    }
    handleSearch = (data) => {
        const { fetchSealSearchDevices, setLoader } = this.props;
        let { searchSealLockTerms } = this.state;
        fetchSealSearchDevices(data ? data : searchSealLockTerms);
        setLoader(false);
    };
    keyPress = (e) => {
        if (e.keyCode === 13) {
            this.handleSearch();
        }
    };
    updateData = (e) => {
        let updateData = { ...this.state.searchSealLockTerms };
        if (e.target.type === "checkbox") {
            if (e.target.checked) {
                updateData[e.target.name] = true;
                this.setState({
                    searchSealLockTerms: updateData,
                    changesMade: true,
                });
            } else {
                updateData[e.target.name] = false;
                this.setState({
                    searchSealLockTerms: updateData,
                    changesMade: true,
                });
            }
        } else {
            updateData[e.target.name] = e.target.value;
            this.setState({
                searchSealLockTerms: updateData,
                changesMade: true,
            });
        }
    };
    clearField = (target) => {
        let updateData = { ...this.state.searchSealLockTerms };
        updateData[target] = "";
        this.setState({ searchSealLockTerms: updateData, changesMade: true });
    };
    clearFields = () => {
        let newSearchSealLockTerms = {
            Label: "",
            SiteId: "",
            SerialNumber: "",
            MacAddress: "",
            IsActive: "1",
        };
        this.setState({ searchSealLockTerms: newSearchSealLockTerms });
        this.handleSearch(newSearchSealLockTerms);
    };
    addLock = () => {
        this.props.history.push(`/app/carseals/create/lock`);
    };
    render() {
        let { searchSealLockTerms } = this.state;
        const { intl, sitesList, width, sealSearchDevices, authUser, theme } =
            this.props;
        this.styles = styles(theme);
        let exportList = sealSearchDevices
            ? sealSearchDevices.map((a) => Object.assign({}, a))
            : [];
        exportList.map((item) => {
            delete item.Icon;
            delete item.Id;
            return item;
        });
        return (
            searchSealLockTerms && (
                <div>
                    <div
                        className={css(this.styles.searchWrapper)}
                        onKeyDown={(e) => this.keyPress(e)}
                    >
                        <Col lg={12}>
                            {width < 992 && (
                                <Row>
                                    <Col
                                        lg={12}
                                        className={css(this.styles.flexRow)}
                                    >
                                        <div>
                                            <i
                                                id="searchSeal"
                                                className={`material-icons ${css(
                                                    this.styles.searchIcon
                                                )}`}
                                                onClick={() =>
                                                    this.handleSearch()
                                                }
                                            >
                                                search
                                            </i>
                                            <Tooltip
                                                message={"comp.search.default"}
                                                target="searchSeal"
                                            />
                                        </div>
                                        <div>
                                            <i
                                                id="resetSealSearch"
                                                className={`material-icons ${css(
                                                    this.styles.searchIcon
                                                )}`}
                                                onClick={() =>
                                                    this.clearFields()
                                                }
                                            >
                                                low_priority
                                            </i>
                                            <Tooltip
                                                message={
                                                    "comp.resetSearch.default"
                                                }
                                                target="resetSealSearch"
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            )}
                            <Row>
                                <Col
                                    lg={10}
                                    className={css(this.styles.flexRow)}
                                >
                                    {width > 800 && (
                                        <Col
                                            lg={4}
                                            className={css(this.styles.flexRow)}
                                        >
                                            <label
                                                className={css(
                                                    this.styles.labelDropdown
                                                )}
                                            >
                                                {this.props.intl.formatMessage({
                                                    id: "comp.sites.Header",
                                                })}
                                            </label>
                                            <Input
                                                className={css(
                                                    this.styles.input
                                                )}
                                                name="SiteId"
                                                type="select"
                                                placeholder={intl.formatMessage(
                                                    { id: "form.selectSite" }
                                                )}
                                                onChange={this.updateData}
                                                value={
                                                    searchSealLockTerms.SiteId ||
                                                    ""
                                                }
                                            >
                                                <option value={""}>
                                                    {intl.formatMessage({
                                                        id: "form.all",
                                                    })}
                                                </option>
                                                {sitesList.map((site) => (
                                                    <option
                                                        key={"site" + site.Id}
                                                        value={site.Id || ""}
                                                    >
                                                        {site.SiteName}
                                                    </option>
                                                ))}
                                            </Input>
                                        </Col>
                                    )}
                                    {width > 800 && (
                                        <Col
                                            lg={4}
                                            className={css(this.styles.flexRow)}
                                        >
                                            <label
                                                className={css(
                                                    this.styles.labelDropdown
                                                )}
                                            >
                                                {this.props.intl.formatMessage({
                                                    id: "form.label",
                                                })}
                                            </label>
                                            <Input
                                                name="Label"
                                                type="text"
                                                className={css(
                                                    this.styles.inputText
                                                )}
                                                onChange={(e) =>
                                                    this.updateData(e)
                                                }
                                                value={
                                                    searchSealLockTerms.Label
                                                }
                                            />
                                            {searchSealLockTerms.Label !==
                                                "" && (
                                                <i
                                                    className={`material-icons ${css(
                                                        this.styles.closeIcon
                                                    )}`}
                                                    onClick={() =>
                                                        this.clearField("Label")
                                                    }
                                                >
                                                    close
                                                </i>
                                            )}
                                        </Col>
                                    )}
                                    {width > 800 && (
                                        <Col
                                            lg={4}
                                            className={css(this.styles.flexRow)}
                                        >
                                            <label
                                                className={css(
                                                    this.styles.labelDropdown
                                                )}
                                            >
                                                {this.props.intl.formatMessage({
                                                    id: "form.serial",
                                                })}
                                            </label>
                                            <Input
                                                name="SerialNumber"
                                                type="text"
                                                className={css(
                                                    this.styles.inputText,
                                                    this.styles.uppercase
                                                )}
                                                onChange={(e) =>
                                                    this.updateData(e)
                                                }
                                                value={
                                                    searchSealLockTerms.SerialNumber
                                                }
                                                mask="aaa-aaa-aaaa"
                                                tag={InputMask}
                                            />
                                            {searchSealLockTerms.SerialNumber !==
                                                "" && (
                                                <i
                                                    className={`material-icons ${css(
                                                        this.styles.closeIcon
                                                    )}`}
                                                    onClick={() =>
                                                        this.clearField(
                                                            "SerialNumber"
                                                        )
                                                    }
                                                >
                                                    close
                                                </i>
                                            )}
                                        </Col>
                                    )}
                                </Col>
                                {width > 800 && (
                                    <Col lg={2}>
                                        {width > 991 && (
                                            <Row
                                                className={css(
                                                    this.styles.flexRow,
                                                    this.styles.rightAligned
                                                )}
                                            >
                                                <div>
                                                    <i
                                                        id="searchSeal"
                                                        className={`material-icons ${css(
                                                            this.styles
                                                                .searchIcon
                                                        )}`}
                                                        onClick={() =>
                                                            this.handleSearch()
                                                        }
                                                    >
                                                        search
                                                    </i>
                                                    <Tooltip
                                                        message={
                                                            "comp.search.default"
                                                        }
                                                        target="searchSeal"
                                                    />
                                                </div>
                                                <div>
                                                    <i
                                                        id="resetSealSearch"
                                                        className={`material-icons ${css(
                                                            this.styles
                                                                .searchIcon
                                                        )}`}
                                                        onClick={() =>
                                                            this.clearFields()
                                                        }
                                                    >
                                                        low_priority
                                                    </i>
                                                    <Tooltip
                                                        message={
                                                            "comp.resetSearch.default"
                                                        }
                                                        target="resetSealSearch"
                                                    />
                                                </div>
                                                {authUser.Permissions.includes(
                                                    "ExportSecureLocks"
                                                ) && (
                                                    <div>
                                                        <CSV
                                                            data={exportList}
                                                            margin={
                                                                "0 12px 0 0"
                                                            }
                                                        />
                                                        <Tooltip
                                                            message={
                                                                "comp.button.export"
                                                            }
                                                            target="exportFile"
                                                        />
                                                    </div>
                                                )}
                                                <div>
                                                    <i
                                                        id="addSealLock"
                                                        className={`material-icons ${css(
                                                            this.styles
                                                                .searchIcon
                                                        )}`}
                                                        onClick={() =>
                                                            this.addLock()
                                                        }
                                                    >
                                                        add_circle_outline
                                                    </i>
                                                    <Tooltip
                                                        message={
                                                            "comp.button.add.locks"
                                                        }
                                                        target="addSealLock"
                                                    />
                                                </div>
                                            </Row>
                                        )}
                                    </Col>
                                )}
                            </Row>
                        </Col>
                    </div>
                    <Row className={css(this.styles.advancedRow)}>
                        <Col lg={10} className={css(this.styles.flexRow)}>
                            {width > 800 && (
                                <Col
                                    lg={4}
                                    className={css(this.styles.flexRow)}
                                >
                                    <label
                                        className={css(
                                            this.styles.labelDropdown
                                        )}
                                    >
                                        {this.props.intl.formatMessage({
                                            id: "form.status",
                                        })}
                                    </label>
                                    <Input
                                        className={css(this.styles.input)}
                                        name="IsActive"
                                        type="select"
                                        placeholder={intl.formatMessage({
                                            id: "form.select",
                                        })}
                                        onChange={this.updateData}
                                        value={
                                            searchSealLockTerms.IsActive || ""
                                        }
                                    >
                                        <option value={""}>
                                            {intl.formatMessage({
                                                id: "comp.button.all",
                                            })}
                                        </option>
                                        <option value={"1"}>
                                            {intl.formatMessage({
                                                id: "comp.button.active",
                                            })}
                                        </option>
                                        <option value={"0"}>
                                            {intl.formatMessage({
                                                id: "comp.button.inactive",
                                            })}
                                        </option>
                                    </Input>
                                </Col>
                            )}
                            {width > 800 && (
                                <Col
                                    lg={4}
                                    className={css(this.styles.flexRow)}
                                >
                                    <label
                                        className={css(
                                            this.styles.labelDropdown
                                        )}
                                    >
                                        {this.props.intl.formatMessage({
                                            id: "comp.table.header.macAddress",
                                        })}
                                    </label>
                                    <Input
                                        name="MacAddress"
                                        type="text"
                                        className={css(
                                            this.styles.inputText,
                                            this.styles.uppercase
                                        )}
                                        onChange={(e) => this.updateData(e)}
                                        value={searchSealLockTerms.MacAddress}
                                        mask="**:**:**:**:**:**"
                                        tag={InputMask}
                                    />
                                    {searchSealLockTerms.MacAddress !== "" && (
                                        <i
                                            className={`material-icons ${css(
                                                this.styles.closeIcon
                                            )}`}
                                            onClick={() =>
                                                this.clearField("MacAddress")
                                            }
                                        >
                                            close
                                        </i>
                                    )}
                                </Col>
                            )}
                        </Col>
                    </Row>
                </div>
            )
        );
    }
}

SearchLocks.defaultProps = {
    searchTitle: "blank",
    searchType: ["name"],
    data: [],
};

const mapStateToProps = ({ app, auth, settings, carseals }) => {
    const { theme, width } = app;
    const { authUser } = auth;
    const { sitesList } = settings;
    const { searchSealLockTerms, sealSearchDevices } = carseals;
    return {
        theme,
        width,
        authUser,
        sitesList,
        searchSealLockTerms,
        sealSearchDevices,
    };
};

export default injectIntl(
    withRouter(
        connect(mapStateToProps, {
            fetchSealSearchDevices,
            setLoader,
            fetchSitesList,
        })(SearchLocks)
    )
);

const styles = (theme) =>
    StyleSheet.create({
        flexRow: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            height: "2.25rem",
            paddingLeft: 0,
        },
        advancedRow: {
            margin: 0,
            padding: 0,
        },
        rightAligned: {
            paddingRight: 0,
            justifyContent: "flex-end",
        },
        input: {
            border: "none",
            boxShadow: "none",
            borderRadius: 0,
            fontWeight: 400,
            fontSize: 12,
            letterSpacing: 2,
            marginBottom: 0,
            color: theme.color.themeColor,
            border: "1px solid white",
            backgroundColor: theme.color.compColor,
            "::placeholder": {
                letterSpacing: 1,
                color: theme.color.fontColor,
                fontSize: 12,
            },
        },
        inputText: {
            border: "none",
            boxShadow: "none",
            borderRadius: 0,
            fontWeight: 400,
            fontSize: 12,
            letterSpacing: 2,
            marginBottom: 0,
            padding: 0,
            width: "100%",
            marginLeft: 5,
            color: theme.color.themeColor,
            borderBottom: `solid whitesmoke 1px`,
            backgroundColor: theme.color.compColor,
            "::placeholder": {
                letterSpacing: 1,
                color: theme.color.themeColor,
                fontSize: 12,
            },
        },
        searchWrapper: {
            position: "relative",
            display: "flex",
            flexDirection: "row",
        },
        searchIcon: {
            color: theme.color.themeColor,
            marginRight: 12,
            ":hover": {
                cursor: "pointer",
                opacity: ".8",
            },
        },
        uppercase: {
            textTransform: "uppercase",
        },
        closeIcon: {
            color: theme.color.fontColor,
            position: "absolute",
            top: 5,
            right: 5,
            fontSize: 16,
            ":hover": {
                cursor: "pointer",
                opacity: ".8",
            },
        },
        labelDropdown: {
            fontSize: 12,
            color: theme.color.fontColor,
            whiteSpace: "nowrap",
        },
    });
