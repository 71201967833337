import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { history } from "../store/history";
import { API, ADMIN_API } from "../api";
import {
    CREATE_FEEDBACK,
    FETCH_FEEDBACK,
    FETCH_FEEDBACK_ITEM,
    ARCHIVE_FEEDBACK,
} from "constants/ActionTypes";
import { setFeedback, setFeedbackItem } from "actions/Feedback";
import { userSignOut } from "actions/Auth";
import { showMessage } from "actions/App";

function* requestCreateFeedback({ payload }) {
    try {
        const feedback = yield call(API, payload.url, payload.data);
        if (feedback.message) {
            if (feedback.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(feedback.message, "message"));
            }
        } else {
            yield put(showMessage("notifications.success.add", "success"));
            yield history.push(`/app/home`);
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFeedback({ payload }) {
    try {
        yield put(setFeedback(null));
        const feedback = yield call(ADMIN_API, payload.url, payload.data);
        if (feedback.message) {
            if (feedback.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(feedback.message));
            }
        } else if (feedback.data === "") {
            yield put(setFeedback([]));
        } else {
            yield put(setFeedback(feedback.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestArchiveFeedback({ payload }) {
    try {
        const feedback = yield call(ADMIN_API, payload.url, payload.data);
        if (feedback.message) {
            if (feedback.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(feedback.message));
            }
        } else if (feedback.data === "") {
            yield put(showMessage("notifications.success.archived", "success"));
            yield put(setFeedback([]));
        } else {
            yield put(showMessage("notifications.success.archived", "success"));
            yield put(setFeedback(feedback.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFeedbackItem({ payload }) {
    try {
        yield put(setFeedbackItem(null));
        const feedback = yield call(ADMIN_API, payload.url, payload.data);
        if (feedback.message) {
            if (feedback.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(feedback.message));
            }
        } else if (feedback.data === "") {
            yield put(setFeedbackItem([]));
        } else {
            yield put(setFeedbackItem(feedback.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

export function* selectCreateFeedback() {
    yield takeEvery(CREATE_FEEDBACK, requestCreateFeedback);
}

export function* selectFetchFeedback() {
    yield takeEvery(FETCH_FEEDBACK, requestFeedback);
}

export function* selectFetchFeedbackItem() {
    yield takeEvery(FETCH_FEEDBACK_ITEM, requestFeedbackItem);
}

export function* selectArchiveFeedback() {
    yield takeEvery(ARCHIVE_FEEDBACK, requestArchiveFeedback);
}

export default function* rootSaga() {
    yield all([
        fork(selectCreateFeedback),
        fork(selectFetchFeedback),
        fork(selectFetchFeedbackItem),
        fork(selectArchiveFeedback),
    ]);
}
