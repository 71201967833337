import {
    FETCH_TICKETS_LIST,
    FETCH_TICKETS_LIST_OPEN,
    FETCH_TICKETS_LIST_BY_USER_ID,
    FETCH_TICKET_LIST_ACTIVITY,
    FETCH_TICKET_LIST_LOCKOUTS,
    FETCH_TICKET_LIST_POINTS,
    FETCH_TICKET_LIST_NOTES,
    FETCH_TICKET_LIST_PERMITS,
    FETCH_TICKET_DOCUMENTS,
    FETCH_TICKET_DOCUMENT_BY_ID,
    FETCH_TICKET_USERS,
    ADD_TICKET_USER,
    REMOVE_TICKET_USER,
    FETCH_TICKETS_BY_ID,
    FETCH_TICKETS_BY_MACHINE_ID,
    COMPLETE_TICKET,
    CREATE_TICKET,
    CREATE_TICKET_NOTE,
    CREATE_TICKET_PERMIT,
    CREATE_TICKET_DOCUMENT,
    EDIT_TICKET,
    DELETE_TICKET,
    FILTER_TICKETS,
    FILTER_TICKETS_LIST,
    SEARCH_TICKET_LIST,
    SET_FILTER_TICKETS,
    SET_TICKETS,
    SET_TICKET,
    SET_TICKET_ACTIVITY,
    SET_TICKET_LOCKOUTS,
    SET_TICKET_POINTS,
    SET_TICKET_NOTES,
    SET_TICKET_PERMITS,
    SET_TICKET_DOCUMENTS,
    SET_TICKET_DOCUMENT,
    SET_TICKET_USERS,
    SET_TICKET_FILTER,
    CLOSE_TICKET,
    FETCH_TICKET_PROCESS,
    SET_TICKET_PROCESS,
    SET_TICKET_PROCESS_LOADED,
    FETCH_TICKET_POINT_PROCESS,
    SET_TICKET_POINT_PROCESS,
    FETCH_TICKET_HIERARCHY,
    SET_TICKET_HIERARCHY,
    SEARCH_TICKETS,
    SEARCH_PARENT_TICKETS,
    SET_PARENT_TICKET,
    FETCH_TICKETS_BY_PARENT_ID,
    SET_SEARCHED_TICKETS,
    SET_PARENT_TICKETS,
    SET_TICKET_SEARCH_TERMS,
    CREATE_TICKET_AIC,
    TRANSFER_TICKET_AIC,
    PULL_TICKET_AIC,
    ADD_LOCKOUT,
    REMOVE_LOCKOUT,
    FETCH_ACTIVE_LOCKOUTS,
    SET_ACTIVE_LOCKOUTS,
    FETCH_TICKET_EQUIPMENT,
    SET_TICKET_EQUIPMENT,
} from "constants/ActionTypes";

let authUser = JSON.parse(sessionStorage.getItem("user_data"));

export const fetchTicketsList = () => {
    if (!authUser) {
        authUser = JSON.parse(sessionStorage.getItem("user_data"));
    }
    if (authUser.Settings.Product.includes("isolation")) {
        return {
            type: FETCH_TICKETS_LIST,
            payload: {
                url: "/isolation/workorder/list/",
                data: {},
            },
        };
    } else {
        return {
            type: FETCH_TICKETS_LIST,
            payload: {
                url: "/lockout/workorder/list/",
                data: {},
            },
        };
    }
};
export const fetchTicketsListOpen = () => {
    if (!authUser) {
        authUser = JSON.parse(sessionStorage.getItem("user_data"));
    }
    if (authUser.Settings.Product.includes("isolation")) {
        return {
            type: FETCH_TICKETS_LIST_OPEN,
            payload: {
                url: "/isolation/workorder/list/open/",
                data: {},
            },
        };
    } else {
        return {
            type: FETCH_TICKETS_LIST_OPEN,
            payload: {
                url: "/lockout/workorder/list/open/",
                data: {},
            },
        };
    }
};
export const fetchTicketsListByUserId = (Id) => {
    // this might be fetched from home page before authUser is present in local
    if (!authUser) {
        authUser = JSON.parse(sessionStorage.getItem("user_data"));
    }
    if (authUser.Settings.Product.includes("isolation")) {
        return {
            type: FETCH_TICKETS_LIST_BY_USER_ID,
            payload: {
                url: "/isolation/workorder/list/openbyuserid/",
                data: {
                    Id,
                },
            },
        };
    } else {
        return {
            type: FETCH_TICKETS_LIST_BY_USER_ID,
            payload: {
                url: "/lockout/workorder/list/openbyuserid/",
                data: {
                    Id,
                },
            },
        };
    }
};
export const fetchTicketListActivity = (Id) => {
    if (!authUser) {
        authUser = JSON.parse(sessionStorage.getItem("user_data"));
    }
    if (authUser.Settings.Product.includes("isolation")) {
        return {
            type: FETCH_TICKET_LIST_ACTIVITY,
            payload: {
                url: "/isolation/workorder/list/activity/",
                data: {
                    Id,
                },
            },
        };
    } else {
        return {
            type: FETCH_TICKET_LIST_ACTIVITY,
            payload: {
                url: "/lockout/workorder/list/activity/",
                data: {
                    Id,
                },
            },
        };
    }
};
export const fetchTicketListLockouts = (Id) => {
    if (!authUser) {
        authUser = JSON.parse(sessionStorage.getItem("user_data"));
    }
    if (authUser.Settings.Product.includes("isolation")) {
        return {
            type: FETCH_TICKET_LIST_LOCKOUTS,
            payload: {
                url: "/isolation/workorder/loto/get/byworkorderid/",
                data: {
                    Id,
                },
            },
        };
    } else {
        return {
            type: FETCH_TICKET_LIST_LOCKOUTS,
            payload: {
                url: "/lockout/workorder/loto/get/byworkorderid/",
                data: {
                    Id,
                },
            },
        };
    }
};
export const fetchTicketListPoints = (Id) => {
    if (!authUser) {
        authUser = JSON.parse(sessionStorage.getItem("user_data"));
    }
    if (authUser.Settings.Product.includes("isolation")) {
        return {
            type: FETCH_TICKET_LIST_POINTS,
            payload: {
                url: "/isolation/workorder/point/list/byworkorderid/",
                data: {
                    Id,
                },
            },
        };
    } else {
        return {
            type: FETCH_TICKET_LIST_POINTS,
            payload: {
                url: "/lockout/workorder/point/list/byworkorderid/",
                data: {
                    Id,
                },
            },
        };
    }
};
export const fetchTicketListNotes = (Id) => {
    if (!authUser) {
        authUser = JSON.parse(sessionStorage.getItem("user_data"));
    }
    if (authUser.Settings.Product.includes("isolation")) {
        return {
            type: FETCH_TICKET_LIST_NOTES,
            payload: {
                url: "/isolation/workorder/note/byid/",
                data: {
                    Id,
                },
            },
        };
    } else {
        return {
            type: FETCH_TICKET_LIST_NOTES,
            payload: {
                url: "/lockout/workorder/note/byid/",
                data: {
                    Id,
                },
            },
        };
    }
};
export const fetchTicketListPermits = (Id) => {
    if (!authUser) {
        authUser = JSON.parse(sessionStorage.getItem("user_data"));
    }
    if (authUser.Settings.Product.includes("isolation")) {
        return {
            type: FETCH_TICKET_LIST_PERMITS,
            payload: {
                url: "/isolation/workorder/permit/list/byid/",
                data: {
                    Id,
                },
            },
        };
    } else {
        return {
            type: FETCH_TICKET_LIST_PERMITS,
            payload: {
                url: "/lockout/workorder/permit/list/byid/",
                data: {
                    Id,
                },
            },
        };
    }
};
export const fetchTicketEquipment = (data) => {
    return {
        type: FETCH_TICKET_EQUIPMENT,
        payload: {
            url: "/isolation/workorder/equipment/list/bywoid/",
            data,
        },
    };
};
export const fetchTicketDocuments = (data) => {
    return {
        type: FETCH_TICKET_DOCUMENTS,
        payload: {
            url: "/image/doc/search/",
            data,
        },
    };
};
export const fetchTicketDocumentById = (data) => {
    return {
        type: FETCH_TICKET_DOCUMENT_BY_ID,
        payload: {
            url: "/image/doc/byid/",
            data,
        },
    };
};
export const fetchTicketById = (Id) => {
    if (!authUser) {
        authUser = JSON.parse(sessionStorage.getItem("user_data"));
    }
    if (authUser.Settings.Product.includes("isolation")) {
        return {
            type: FETCH_TICKETS_BY_ID,
            payload: {
                url: "/isolation/workorder/byid/",
                data: {
                    Id,
                },
            },
        };
    } else {
        return {
            type: FETCH_TICKETS_BY_ID,
            payload: {
                url: "/lockout/workorder/byid/",
                data: {
                    Id,
                },
            },
        };
    }
};
export const fetchTicketByMachineId = (Id) => {
    if (!authUser) {
        authUser = JSON.parse(sessionStorage.getItem("user_data"));
    }
    if (authUser.Settings.Product.includes("isolation")) {
        return {
            type: FETCH_TICKETS_BY_MACHINE_ID,
            payload: {
                url: "/isolation/workorder/bymachineid/",
                data: {
                    Id,
                },
            },
        };
    } else {
        return {
            type: FETCH_TICKETS_BY_MACHINE_ID,
            payload: {
                url: "/lockout/workorder/bymachineid/",
                data: {
                    Id,
                },
            },
        };
    }
};
export const completeTicket = (Id) => {
    if (!authUser) {
        authUser = JSON.parse(sessionStorage.getItem("user_data"));
    }
    if (authUser.Settings.Product.includes("isolation")) {
        return {
            type: COMPLETE_TICKET,
            payload: {
                url: "/isolation/workorder/create/",
                data: {
                    Id,
                },
            },
        };
    } else {
        return {
            type: COMPLETE_TICKET,
            payload: {
                url: "/lockout/workorder/create/",
                data: {
                    Id,
                },
            },
        };
    }
};
export const createTicket = (data, another) => {
    if (!authUser) {
        authUser = JSON.parse(sessionStorage.getItem("user_data"));
    }
    if (authUser.Settings.Product.includes("isolation")) {
        return {
            type: CREATE_TICKET,
            payload: {
                url: "/isolation/workorder/create/",
                data,
                another,
            },
        };
    } else {
        return {
            type: CREATE_TICKET,
            payload: {
                url: "/lockout/workorder/create/",
                data,
                another,
            },
        };
    }
};
export const createTicketNote = (data) => {
    if (!authUser) {
        authUser = JSON.parse(sessionStorage.getItem("user_data"));
    }
    if (authUser.Settings.Product.includes("isolation")) {
        return {
            type: CREATE_TICKET_NOTE,
            payload: {
                url: "/isolation/workorder/note/create/",
                data,
            },
        };
    } else {
        return {
            type: CREATE_TICKET_NOTE,
            payload: {
                url: "/lockout/workorder/note/create/",
                data,
            },
        };
    }
};
export const createTicketPermit = (data) => {
    if (!authUser) {
        authUser = JSON.parse(sessionStorage.getItem("user_data"));
    }
    if (authUser.Settings.Product.includes("isolation")) {
        return {
            type: CREATE_TICKET_PERMIT,
            payload: {
                url: "/isolation/workorder/permit/create/",
                data,
            },
        };
    } else {
        return {
            type: CREATE_TICKET_PERMIT,
            payload: {
                url: "/lockout/workorder/permit/create/",
                data,
            },
        };
    }
};
export const createTicketDocument = (data) => {
    return {
        type: CREATE_TICKET_DOCUMENT,
        payload: {
            url: "/image/doc/create/",
            data,
        },
    };
};
export const fetchTicketUsers = (data) => {
    if (!authUser) {
        authUser = JSON.parse(sessionStorage.getItem("user_data"));
    }
    if (authUser.Settings.Product.includes("isolation")) {
        return {
            type: FETCH_TICKET_USERS,
            payload: {
                url: "/isolation/workorder/user/list/byid/",
                data,
            },
        };
    } else {
        return {
            type: FETCH_TICKET_USERS,
            payload: {
                url: "/lockout/workorder/user/list/byid/",
                data,
            },
        };
    }
};
export const addTicketUser = (data) => {
    if (!authUser) {
        authUser = JSON.parse(sessionStorage.getItem("user_data"));
    }
    if (authUser.Settings.Product.includes("isolation")) {
        return {
            type: ADD_TICKET_USER,
            payload: {
                url: "/isolation/workorder/user/assign/",
                data,
            },
        };
    } else {
        return {
            type: ADD_TICKET_USER,
            payload: {
                url: "/lockout/workorder/user/assign/",
                data,
            },
        };
    }
};
export const removeTicketUser = (data) => {
    if (!authUser) {
        authUser = JSON.parse(sessionStorage.getItem("user_data"));
    }
    if (authUser.Settings.Product.includes("isolation")) {
        return {
            type: REMOVE_TICKET_USER,
            payload: {
                url: "/isolation/workorder/user/assign/remove/",
                data,
            },
        };
    } else {
        return {
            type: REMOVE_TICKET_USER,
            payload: {
                url: "/lockout/workorder/user/assign/remove/",
                data,
            },
        };
    }
};
export const createTicketAIC = (data) => {
    if (!authUser) {
        authUser = JSON.parse(sessionStorage.getItem("user_data"));
    }
    if (authUser.Settings.Product.includes("isolation")) {
        return {
            type: CREATE_TICKET_AIC,
            payload: {
                url: "/isolation/workorder/create/aic/",
                data,
            },
        };
    } else {
        return {
            type: CREATE_TICKET_AIC,
            payload: {
                url: "/lockout/workorder/create/aic/",
                data,
            },
        };
    }
};
export const transferTicketAIC = (data) => {
    if (!authUser) {
        authUser = JSON.parse(sessionStorage.getItem("user_data"));
    }
    if (authUser.Settings.Product.includes("isolation")) {
        return {
            type: TRANSFER_TICKET_AIC,
            payload: {
                url: "/isolation/workorder/push/aic/",
                data,
            },
        };
    } else {
        return {
            type: TRANSFER_TICKET_AIC,
            payload: {
                url: "/lockout/workorder/push/aic/",
                data,
            },
        };
    }
};
export const pullTicketAIC = (data) => {
    if (!authUser) {
        authUser = JSON.parse(sessionStorage.getItem("user_data"));
    }
    if (authUser.Settings.Product.includes("isolation")) {
        return {
            type: PULL_TICKET_AIC,
            payload: {
                url: "/isolation/workorder/pull/aic/",
                data,
            },
        };
    } else {
        return {
            type: PULL_TICKET_AIC,
            payload: {
                url: "/lockout/workorder/pull/aic/",
                data,
            },
        };
    }
};
export const fetchActiveLockouts = (data) => {
    return {
        type: FETCH_ACTIVE_LOCKOUTS,
        payload: {
            url: "/isolation/workorder/list/activelockouts/",
            data,
        },
    };
};
export const setActiveLockouts = (data) => {
    return {
        type: SET_ACTIVE_LOCKOUTS,
        payload: data,
    };
};
export const editTicket = (data) => {
    if (!authUser) {
        authUser = JSON.parse(sessionStorage.getItem("user_data"));
    }
    if (authUser.Settings.Product.includes("isolation")) {
        return {
            type: EDIT_TICKET,
            payload: {
                url: "/isolation/workorder/update/",
                data,
            },
        };
    } else {
        return {
            type: EDIT_TICKET,
            payload: {
                url: "/lockout/workorder/update/",
                data,
            },
        };
    }
};
export const deleteTicket = (Id) => {
    if (!authUser) {
        authUser = JSON.parse(sessionStorage.getItem("user_data"));
    }
    if (authUser.Settings.Product.includes("isolation")) {
        return {
            type: DELETE_TICKET,
            payload: {
                url: "/isolation/workorder/remove/",
                data: {
                    Id,
                },
            },
        };
    } else {
        return {
            type: DELETE_TICKET,
            payload: {
                url: "/lockout/workorder/remove/",
                data: {
                    Id,
                },
            },
        };
    }
};
export const closeTicket = (CLientId, RequestedById, WorkOrderId) => {
    if (!authUser) {
        authUser = JSON.parse(sessionStorage.getItem("user_data"));
    }
    if (authUser.Settings.Product.includes("isolation")) {
        return {
            type: CLOSE_TICKET,
            payload: {
                url: "/isolation/workorder/complete/",
                data: {
                    CLientId,
                    RequestedById,
                    WorkOrderId,
                },
            },
        };
    } else {
        return {
            type: CLOSE_TICKET,
            payload: {
                url: "/lockout/workorder/complete/",
                data: {
                    CLientId,
                    RequestedById,
                    WorkOrderId,
                },
            },
        };
    }
};
export const filterTickets = (filter) => {
    return {
        type: FILTER_TICKETS,
        payload: filter,
    };
};
export const filterTicketsList = (searchValue, list) => {
    return {
        type: FILTER_TICKETS_LIST,
        payload: {
            searchValue,
            list,
        },
    };
};
export const searchTicketsList = (Name) => {
    if (!authUser) {
        authUser = JSON.parse(sessionStorage.getItem("user_data"));
    }
    if (authUser.Settings.Product.includes("isolation")) {
        return {
            type: SEARCH_TICKET_LIST,
            payload: {
                url: "/isolation/workorder/list/search/",
                data: {
                    Name,
                },
            },
        };
    } else {
        return {
            type: SEARCH_TICKET_LIST,
            payload: {
                url: "/lockout/workorder/list/search/",
                data: {
                    Name,
                },
            },
        };
    }
};
export const addLockout = (data) => {
    if (!authUser) {
        authUser = JSON.parse(sessionStorage.getItem("user_data"));
    }
    if (authUser.Settings.Product.includes("isolation")) {
        return {
            type: ADD_LOCKOUT,
            payload: {
                url: "/isolation/workorder/loto/create/auth/",
                data,
            },
        };
    } else {
        return {
            type: ADD_LOCKOUT,
            payload: {
                url: "/lockout/workorder/loto/create/auth/",
                data,
            },
        };
    }
};
export const removeLockout = (data, id) => {
    if (!authUser) {
        authUser = JSON.parse(sessionStorage.getItem("user_data"));
    }
    if (authUser.Settings.Product.includes("isolation")) {
        return {
            type: REMOVE_LOCKOUT,
            payload: {
                url: "/isolation/workorder/loto/complete/auth/",
                data,
                id,
            },
        };
    } else {
        return {
            type: REMOVE_LOCKOUT,
            payload: {
                url: "/lockout/workorder/loto/complete/auth/",
                data,
                id,
            },
        };
    }
};
export const setFilterTickets = (data) => {
    return {
        type: SET_FILTER_TICKETS,
        payload: data,
    };
};
export const setTicketFilter = (data) => {
    return {
        type: SET_TICKET_FILTER,
        payload: data,
    };
};
export const setTickets = (data) => {
    return {
        type: SET_TICKETS,
        payload: data,
    };
};

export const setTicket = (data) => {
    return {
        type: SET_TICKET,
        payload: data,
    };
};

export const setTicketActivity = (data) => {
    return {
        type: SET_TICKET_ACTIVITY,
        payload: data,
    };
};

export const setTicketLockouts = (data) => {
    return {
        type: SET_TICKET_LOCKOUTS,
        payload: data,
    };
};

export const setTicketPoints = (data) => {
    return {
        type: SET_TICKET_POINTS,
        payload: data,
    };
};

export const setTicketNotes = (data) => {
    return {
        type: SET_TICKET_NOTES,
        payload: data,
    };
};

export const setTicketPermits = (data) => {
    return {
        type: SET_TICKET_PERMITS,
        payload: data,
    };
};

export const setTicketEquipment = (data) => {
    return {
        type: SET_TICKET_EQUIPMENT,
        payload: data,
    };
};

export const setTicketDocuments = (data) => {
    return {
        type: SET_TICKET_DOCUMENTS,
        payload: data,
    };
};

export const setTicketDocument = (data) => {
    return {
        type: SET_TICKET_DOCUMENT,
        payload: data,
    };
};

export const setTicketUsers = (data) => {
    return {
        type: SET_TICKET_USERS,
        payload: data,
    };
};

export const fetchTicketProcess = (data) => {
    return {
        type: FETCH_TICKET_PROCESS,
        payload: {
            url: "/isolation/workorder/process/list/",
            data,
        },
    };
};

export const setTicketProcess = (data) => {
    return {
        type: SET_TICKET_PROCESS,
        payload: data,
    };
};

export const setTicketProcessLoaded = (data) => {
    return {
        type: SET_TICKET_PROCESS_LOADED,
        payload: data,
    };
};

export const fetchTicketPointProcess = (data) => {
    return {
        type: FETCH_TICKET_POINT_PROCESS,
        payload: {
            url: "/isolation/workorder/process/point/list/",
            data,
        },
    };
};

export const setTicketPointProcess = (data) => {
    return {
        type: SET_TICKET_POINT_PROCESS,
        payload: data,
    };
};

export const fetchTicketHierarchy = () => {
    return {
        type: FETCH_TICKET_HIERARCHY,
        payload: {
            url: "/isolation/workorder/list/hierarchy/",
            data: {},
        },
    };
};

export const setTicketHierarchy = (data) => {
    return {
        type: SET_TICKET_HIERARCHY,
        payload: data,
    };
};

export const searchTickets = (data) => {
    if (!authUser) {
        authUser = JSON.parse(sessionStorage.getItem("user_data"));
    }
    if (authUser.Settings.Product.includes("isolation")) {
        return {
            type: SEARCH_TICKETS,
            payload: {
                url: "/isolation/workorder/list/search/",
                data,
            },
        };
    } else {
        return {
            type: SEARCH_TICKETS,
            payload: {
                url: "/lockout/workorder/list/search/",
                data,
            },
        };
    }
};

export const searchParentTickets = (data) => {
    return {
        type: SEARCH_PARENT_TICKETS,
        payload: {
            url: "/isolation/workorder/list/parent/select/",
            data,
        },
    };
};

export const setParentTicket = (data) => {
    return {
        type: SET_PARENT_TICKET,
        payload: {
            url: "/isolation/workorder/update/parent/",
            data,
        },
    };
};

export const fetchTicketsByParentId = (id) => {
    return {
        type: FETCH_TICKETS_BY_PARENT_ID,
        payload: {
            url: "/isolation/workorder/list/byparentid/",
            data: {
                id: id,
            },
        },
    };
};

export const setSearchedTickets = (data) => {
    return {
        type: SET_SEARCHED_TICKETS,
        payload: data,
    };
};

export const setParentTickets = (data) => {
    return {
        type: SET_PARENT_TICKETS,
        payload: data,
    };
};

export const setTicketSearchTerms = (data) => {
    return {
        type: SET_TICKET_SEARCH_TERMS,
        payload: data,
    };
};
