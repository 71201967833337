export default {
    light: {
        name: "light",
        pageColor: "#f5f7f9",
        menuColor: "#f5f7f9",
        headerColor: "#ffffff",
        compColor: "#ffffff",
        themeColor: "#0070cd",
        fontColor: "#808080",
        buttonFontColor: "#ffffff",
        borderColor: "#d8dcdf",
    },
    dark: {
        name: "dark",
        pageColor: "#11161d",
        menuColor: "#11161d",
        headerColor: "#1c2938",
        compColor: "#1c2938",
        themeColor: "#1ca1f2",
        fontColor: "#ffffff",
        buttonFontColor: "#ffffff",
        borderColor: "#707f8d",
    },
    highContrastLight: {
        name: "highContrastLight",
        pageColor: "#fff",
        menuColor: "#fff",
        headerColor: "#fff",
        compColor: "#fff",
        themeColor: "#252525",
        fontColor: "#000000",
        buttonFontColor: "#ffffff",
        borderColor: "#000000",
    },
    highContrastDark: {
        name: "highContrastDark",
        pageColor: "#000000",
        menuColor: "#000000",
        headerColor: "#000000",
        compColor: "#000000",
        themeColor: "#FD1EFF",
        fontColor: "#fff",
        buttonFontColor: "#000000",
        borderColor: "#fff",
    },
    custom: {
        name: "custom",
        pageColor: "#f5f7f9",
        menuColor: "#f5f7f9",
        headerColor: "#ffffff",
        compColor: "#ffffff",
        themeColor: "#0070cd",
        fontColor: "#808080",
        buttonFontColor: "#ffffff",
        borderColor: "#d8dcdf",
    },
    demo: {
        name: "demo",
        pageColor: "#f5f7f9",
        menuColor: "#f5f7f9",
        headerColor: "#ffffff",
        compColor: "#ffffff",
        themeColor: "#0070cd",
        fontColor: "#808080",
        buttonFontColor: "#ffffff",
        borderColor: "#d8dcdf",
    },
};
