import 'intl';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/stable';
import React from 'react';
import { render } from 'react-dom';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import 'react-notifications/lib/notifications.css';
import configureStore from './store';
import { history } from './store/history';
import App from 'app/App';
import * as Sentry from '@sentry/browser';

const domain = window.location.href;
domain.includes('loto-go-dev') ? Sentry.init({dsn: "https://1ce77aab9332437f83aaefa2559211f2@sentry.io/1827017", environment: 'dev'}) : domain.includes('smartloto') ? Sentry.init({dsn: "https://1ce77aab9332437f83aaefa2559211f2@sentry.io/1827017", environment: 'prod'}) :  null;

export const store = configureStore ();

render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <Switch>
                <Route path="/" component={App} />
            </Switch>
        </ConnectedRouter>
    </Provider>,
    document.getElementById('app-site')
);
