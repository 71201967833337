import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import * as Sentry from "@sentry/browser";
import { history } from "../store/history";
import { LOGIN, API, REQUEST } from "../api";
import {
    SIGNIN_USER,
    SUBMIT_CODE,
    GET_COMPANIES,
    SIGNOUT_USER,
    FORGOT_PASSWORD,
    RESET_PASSWORD,
    RESET_PIN,
    FORGOT_USER_PASSWORD,
    RESET_USER_PASSWORD,
    RESET_USER_PIN,
    USER_TERMS_ACK,
    SUBMIT_REQUEST_INFO_FORM,
} from "constants/ActionTypes";
import {
    userSignInSuccess,
    userSignOutSuccess,
    showAuthLoader,
    setCompanyList,
    showAuth,
} from "actions/Auth";
import { setTermsMessages } from "actions/App";
import { showMessage, setLoader } from "actions/App";
import { auth } from "constants";

function* signOut() {
    try {
        yield put(setLoader(false));
        sessionStorage.removeItem("user_data");
        yield put(userSignOutSuccess(null));
        yield put(setCompanyList([]));
        yield history.push("/signin");
    } catch (error) {
        console.warn(error);
    }
}

function* signInUserWithEmailPassword({ payload }) {
    try {
        const required = {
            Username: payload.data.Username,
            Password: payload.data.Password,
        };
        for (var key in required) {
            if (required[key] === undefined || required[key] === "") {
                yield put(
                    showMessage(`notifications.error.blank.${key}`, "warning")
                );
                return;
            }
        }
        yield put(showAuthLoader());
        const signInUser = yield call(LOGIN, payload.url, payload.data);
        if (signInUser.message) {
            if (payload.switchCompany) {
                yield put(showMessage(signInUser.message, "message"));
            } else {
                yield put(showMessage(signInUser.message, "message"));
                yield put(setCompanyList([]));
                yield history.push("/signin");
            }
        } else {
            if (payload.authDevice) {
                // we know the user came from a welcome or password reset email and should thus auto-verify this device
                localStorage.setItem("auth_device", true);
            }
            // either two factor auth is not turned on or we have already authed for this device and we can proceed either way
            if (
                signInUser.data.Settings.TwoFactorAuth === false ||
                (JSON.parse(localStorage.getItem("auth_device")) &&
                    JSON.parse(localStorage.getItem("auth_device")) === true)
            ) {
                // login was successful, but they need to verify this device first
                if (!signInUser.data.Permissions) {
                    signInUser.data.Permissions = [];
                }
                if (
                    signInUser.data.RequirePinReset ||
                    signInUser.data.RequirePwdReset
                ) {
                    signInUser.data.Login = payload.data;
                } else {
                    delete signInUser.data.Login;
                }
                Sentry.configureScope(function (scope) {
                    scope.setUser({
                        email: signInUser.data.Email,
                        id: signInUser.data.Id,
                        username: `${signInUser.data.FirstName} ${signInUser.data.LastName}`,
                    });
                });
                auth.signInWithCustomToken(signInUser.data.FirebaseToken)
                    .then((userCredential) => {
                    })
                    .catch((error) => {
                        const errorCode = error.code;
                        const errorMessage = error.message;
                        console.log('firebase errorCode:', errorCode, 'firebase errorMessage', errorMessage);
                    });
                sessionStorage.setItem(
                    "user_data",
                    JSON.stringify(signInUser.data)
                );
                const image = yield call(API, "/image/", {
                    Filename: signInUser.data.Id,
                });
                signInUser.data.Image = image.data
                    ? `data:image/png;base64, ${image.data}`
                    : null;
                sessionStorage.setItem(
                    "user_data",
                    JSON.stringify(signInUser.data)
                );
                yield put(userSignInSuccess(signInUser.data));
                yield put(setTermsMessages(signInUser.data.Terms));
                yield put(setCompanyList([]));
                if (payload.setup) {
                    return;
                }
                if (payload.switchCompany) {
                    yield history.push("/app/home");
                    yield window.location.reload();
                } else {
                    yield history.push("/app/home");
                }
            } else {
                yield put(userSignInSuccess(signInUser.data));
                yield call(LOGIN, "/auth/device/code/", {
                    Username: signInUser.data.Email,
                    Source: "",
                });
                yield put(showAuth(true));
            }
        }
    } catch (error) {
        yield put(setCompanyList([]));
        console.warn(error);
    }
}

function* requestSubmitCode({ payload }) {
    try {
        const code = yield call(LOGIN, payload.url, payload.data);
        if (code.message) {
            if (code.message === "Token is expired") {
                yield put(setCompanyList([]));
                yield put(showAuth(false));
                yield call(signOut);
            } else {
                yield put(setCompanyList([]));
                yield put(showAuth(false));
                yield call(signOut);
                yield put(showMessage(code.message, "message"));
            }
        } else if (code.validationError) {
            yield put(setCompanyList([]));
            yield put(showAuth(false));
            yield call(signOut);
        } else {
            yield put(showAuth(false));
            if (!payload.user.Permissions) {
                payload.user.Permissions = [];
            }
            if (payload.user.RequirePinReset || payload.user.RequirePwdReset) {
                payload.user.Login = payload.data;
            } else {
                delete payload.user.Login;
            }
            Sentry.configureScope(function (scope) {
                scope.setUser({
                    email: payload.user.Email,
                    id: payload.user.Id,
                    username: `${payload.user.FirstName} ${payload.user.LastName}`,
                });
            });
            sessionStorage.setItem("user_data", JSON.stringify(payload.user));
            const image = yield call(API, "/image/", {
                Filename: payload.user.Id,
            });
            payload.user.Image = image.data
                ? `data:image/png;base64, ${image.data}`
                : null;
            sessionStorage.setItem("user_data", JSON.stringify(payload.user));
            localStorage.setItem("auth_device", true);
            yield put(setTermsMessages(payload.user.Terms));
            yield put(setCompanyList([]));
            history.push("/app/home");
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestSubmitRequestInfoForm({ payload }) {
    try {
        if (payload.data.FirstName === "") {
            yield put(
                showMessage("notifications.error.blank.FirstName", "warning")
            );
            return;
        }
        if (payload.data.LastName === "") {
            yield put(
                showMessage("notifications.error.blank.LastName", "warning")
            );
            return;
        }
        if (payload.data.Email === "") {
            yield put(
                showMessage("notifications.error.blank.Email", "warning")
            );
            return;
        }
        if (payload.data.CompanyName === "") {
            yield put(
                showMessage("notifications.error.blank.CompanyName", "warning")
            );
            return;
        }
        if (payload.data.Country === "") {
            yield put(
                showMessage("notifications.error.blank.Country", "warning")
            );
            return;
        }
        if (payload.data.Industry === "") {
            yield put(
                showMessage("notifications.error.blank.Industry", "warning")
            );
            return;
        }
        if (payload.data.NumberOfLocks === "") {
            yield put(
                showMessage(
                    "notifications.error.blank.NumberOfLocks",
                    "warning"
                )
            );
            return;
        }
        const request = yield call(REQUEST, payload.url, payload.data);
        if (request.message) {
            yield put(showMessage(request.message, "message"));
        } else if (request.validationError) {
            yield put(showMessage(request.validationError, "message"));
        } else {
            yield put(showMessage("notifications.success.request", "success"));
            yield (window.location = "https://smartloto.noke.com/#/request");
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestGetCompanies({ payload }) {
    try {
        if (payload.data.Username === "") {
            yield put(
                showMessage("notifications.error.blank.Username", "warning")
            );
            return;
        }
        const companies = yield call(LOGIN, payload.url, payload.data);
        if (companies.validationError) {
            if (companies.validationError.Username) {
                yield put(
                    showMessage(
                        companies.validationError.Username[0],
                        "message"
                    )
                );
            } else {
                yield put(
                    showMessage("Error Fetching Company List", "message")
                );
            }
        } else if (companies.message) {
            yield put(showMessage(companies.message, "message"));
        } else {
            if (companies.data.Companies) {
                yield put(setCompanyList(companies.data.Companies));
            } else {
                yield put(showMessage("User is not part of any company"));
                yield history.push("/signin");
            }
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestForgotPassword({ payload }) {
    try {
        if (payload.data.Username === "") {
            yield put(
                showMessage("notifications.error.blank.Username", "warning")
            );
            return;
        }
        const forgot = yield call(LOGIN, payload.url, payload.data);
        if (forgot.message) {
            if (forgot.message === "Token is expired") {
                yield call(signOut);
            } else {
                yield put(showMessage(forgot.message, "message"));
            }
        } else {
            yield put(showMessage("notifications.success.sent", "success"));
            history.push("/signin");
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestResetPassword({ payload }) {
    try {
        yield put(showAuthLoader());
        if (
            payload.data.NewUserPwd === "" ||
            payload.data.PasswordVerify === ""
        ) {
            yield put(showMessage("auth.reset.blank.password", "warning"));
            return;
        }
        if (
            payload.data.NewUserPwd.length < 8 ||
            payload.data.PasswordVerify.length < 8
        ) {
            yield put(showMessage("auth.reset.sort.password", "warning"));
            return;
        }
        if (payload.data.NewUserPwd != payload.data.PasswordVerify) {
            yield put(showMessage("auth.reset.newVerifyPassword", "warning"));
            return;
        }
        delete payload.data.PasswordVerify;
        const password = yield call(API, payload.url, payload.data);
        if (password.message) {
            if (password.message === "Token is expired") {
                yield call(signOut);
            } else {
                yield put(showMessage(password.message, "message"));
            }
        } else {
            let user = JSON.parse(sessionStorage.getItem("user_data"));
            user.RequirePwdReset = false;
            if (user.RequirePinReset) {
                user.Login.Password = payload.data.NewUserPwd;
            } else {
                delete user.Login;
            }
            sessionStorage.setItem("user_data", JSON.stringify(user));
            yield put(userSignInSuccess(user));
            yield put(showMessage("notifications.success.edit", "success"));
            yield history.push("/app/home");
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestResetPin({ payload }) {
    try {
        yield put(showAuthLoader());
        if (payload.data.NewPin === "" || payload.data.PinVerify === "") {
            yield put(showMessage("auth.reset.blank.pin", "warning"));
            return;
        }
        if (
            payload.data.NewPin.length < 4 ||
            payload.data.PinVerify.length < 4
        ) {
            yield put(showMessage("auth.reset.sort.pin", "warning"));
            return;
        }
        if (payload.data.NewPin != payload.data.PinVerify) {
            yield put(showMessage("auth.reset.newVerifyPin", "warning"));
            return;
        }
        delete payload.data.PinVerify;
        const pin = yield call(API, payload.url, payload.data);
        if (pin.message) {
            if (pin.message === "Token is expired") {
                yield call(signOut);
            } else {
                yield put(showMessage(pin.message, "message"));
            }
        } else {
            let user = JSON.parse(sessionStorage.getItem("user_data"));
            user.RequirePinReset = false;
            if (!user.RequirePwdReset) {
                delete user.Login;
            }
            sessionStorage.setItem("user_data", JSON.stringify(user));
            yield put(userSignInSuccess(user));
            yield put(showMessage("notifications.success.edit", "success"));
            yield history.push("/app/home");
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestForgotUserPassword({ payload }) {
    try {
        const forgot = yield call(LOGIN, payload.url, payload.data);
        if (forgot.message) {
            if (forgot.message === "Token is expired") {
                yield call(signOut);
            } else {
                yield put(showMessage(forgot.message, "message"));
            }
        } else {
            yield put(showMessage("notifications.success.sent", "success"));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestResetUserPassword({ payload }) {
    try {
        if (payload.data.UserPwd === "") {
            yield put(showMessage("auth.reset.blank.password"));
            return;
        }
        if (
            payload.data.NewUserPwd === "" ||
            payload.data.ConfirmNewPassword === ""
        ) {
            yield put(showMessage("auth.reset.blank.password"));
            return;
        }
        if (
            payload.data.NewUserPwd.length < 8 ||
            payload.data.ConfirmNewPassword.length < 8
        ) {
            yield put(showMessage("auth.reset.sort.password"));
            return;
        }
        if (payload.data.NewUserPwd != payload.data.ConfirmNewPassword) {
            yield put(showMessage("auth.reset.newVerifyPassword"));
            return;
        }
        delete payload.data.ConfirmNewPassword;
        const password = yield call(
            API,
            payload.url,
            payload.data,
            payload.token
        );
        if (password.message) {
            if (password.message === "Token is expired") {
                yield call(signOut);
            } else {
                yield put(showMessage(password.message, "message"));
            }
        } else {
            yield put(showMessage("notifications.success.edit", "success"));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestResetUserPin({ payload }) {
    try {
        if (payload.data.UserPwd === "") {
            yield put(showMessage("auth.reset.blank.password"));
            return;
        }
        if (payload.data.NewPin === "" || payload.data.ConfirmNewPin === "") {
            yield put(showMessage("auth.reset.blank.pin"));
            return;
        }
        if (
            payload.data.NewPin.length < 4 ||
            payload.data.ConfirmNewPin.length < 4
        ) {
            yield put(showMessage("auth.reset.sort.pin"));
            return;
        }
        if (payload.data.NewPin != payload.data.ConfirmNewPin) {
            yield put(showMessage("auth.reset.newVerifyPin"));
            return;
        }
        delete payload.data.ConfirmNewPin;
        const pin = yield call(API, payload.url, payload.data, payload.token);
        if (pin.message) {
            if (pin.message === "Token is expired") {
                yield call(signOut);
            } else {
                yield put(showMessage(pin.message, "message"));
            }
        } else {
            yield put(showMessage("notifications.success.edit", "success"));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestUserTermsAck({ payload }) {
    try {
        const user = yield call(API, payload.url, payload.data);
        if (user.message) {
            if (user.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(user.message, "message"));
            }
        } else {
            yield put(showMessage("notifications.success.terms", "success"));
            let user = JSON.parse(sessionStorage.getItem("user_data"));
            user.RequireTermsAck = false;
            sessionStorage.setItem("user_data", JSON.stringify(user));
            yield put(userSignInSuccess(user));
            yield history.push("/app/home");
        }
    } catch (error) {
        console.warn(error);
    }
}

export function* signInUser() {
    yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* selectSubmitCode() {
    yield takeEvery(SUBMIT_CODE, requestSubmitCode);
}

export function* selectSubmitRequestInfoForm() {
    yield takeEvery(SUBMIT_REQUEST_INFO_FORM, requestSubmitRequestInfoForm);
}

export function* selectGetCompanies() {
    yield takeEvery(GET_COMPANIES, requestGetCompanies);
}

export function* signOutUser() {
    yield takeEvery(SIGNOUT_USER, signOut);
}

export function* selectForgotPassword() {
    yield takeEvery(FORGOT_PASSWORD, requestForgotPassword);
}

export function* selectResetPassword() {
    yield takeEvery(RESET_PASSWORD, requestResetPassword);
}

export function* selectResetPin() {
    yield takeEvery(RESET_PIN, requestResetPin);
}

export function* selectForgotUserPassword() {
    yield takeEvery(FORGOT_USER_PASSWORD, requestForgotUserPassword);
}

export function* selectResetUserPassword() {
    yield takeEvery(RESET_USER_PASSWORD, requestResetUserPassword);
}

export function* selectResetUserPin() {
    yield takeEvery(RESET_USER_PIN, requestResetUserPin);
}

export function* selectUserTermsAck() {
    yield takeEvery(USER_TERMS_ACK, requestUserTermsAck);
}

export default function* rootSaga() {
    yield all([
        fork(signInUser),
        fork(selectSubmitCode),
        fork(selectSubmitRequestInfoForm),
        fork(selectForgotPassword),
        fork(selectGetCompanies),
        fork(selectResetPassword),
        fork(selectResetPin),
        fork(selectForgotUserPassword),
        fork(selectResetUserPassword),
        fork(selectResetUserPin),
        fork(signOutUser),
        fork(selectUserTermsAck),
    ]);
}
