//=====================================
// DEPENDENCIES IMPORTS
//=====================================
import axios from "axios";

//=====================================
// GLOBAL IMPORTS
//=====================================
import * as Constant from "../constants";
import { store } from "../index.js";
import { SIGNOUT_USER, SHOW_MESSAGE } from "../constants/ActionTypes";
import { formatMessage } from 'react-intl';

const FAILED_ERROR = (error) => {
    console.warn("FAILED ERROR: ",error);
    store.dispatch({
        type: SHOW_MESSAGE,
        payload: {
            message: `${error}`,
            type: "message",
        }
    });
    return null;
}

//=====================================
// LOGIN
//=====================================
export const LOGIN = async (url, data) =>
    await axios
        .post(Constant.URL + url, data)
        .then((res) => res)
        .catch((error) => error.response.data);

//=====================================
// RESET
//=====================================
export const RESET = async (url, data, token) =>
    await axios
        .post(Constant.URL + url, data, {
            headers: { Authorization: "Bearer " + token },
        })
        .then((res) => res)
        .catch((error) => error.response.data);

//=====================================
// API
//=====================================
export const API = async (url, data) =>
    await axios
        .post(Constant.URL + url, data, {
            headers: {
                Authorization:
                    "Bearer " +
                    JSON.parse(sessionStorage.getItem("user_data")).Token,
            },
        })
        .then((res) => res)
        .catch((error) => {
            if (error.response.data.message === "Token is expired") {
                return REFRESH(url, data);
            } else if (error.response.data.message === "Unauthorized") {
                store.dispatch({
                    type: SIGNOUT_USER,
                });
                return FAILED_ERROR(formatMessage({id:"notifications.error.fail.loginRequired"}));
            } else {
                return error.response.data;
            }
        });

const REFRESH = async (url, data, admin) =>
    await axios
        .post(
            Constant.BLANK_URL + "/refresh_token/",
            {},
            {
                headers: {
                    Authorization:
                        "Bearer " +
                        JSON.parse(sessionStorage.getItem("user_data")).Token,
                },
            }
        )
        .then((res) => {
            let userData = JSON.parse(sessionStorage.getItem("user_data"));
            userData.Token = res.data.token;
            sessionStorage.setItem("user_data", JSON.stringify(userData));
            if (admin) {
                return RETRYADMIN(url, data);
            } else {
                return RETRY(url, data);
            }
        })
        .catch((error) => {
            store.dispatch({
                type: SIGNOUT_USER,
            });
            return FAILED_ERROR(formatMessage({id:"notifications.error.fail.loginRequired"}));
        });

const RETRY = async (url, data) =>
    await axios
        .post(Constant.URL + url, data, {
            headers: {
                Authorization:
                    "Bearer " +
                    JSON.parse(sessionStorage.getItem("user_data")).Token,
            },
        })
        .then((res) => res)
        .catch((error) => {
            return FAILED_ERROR(error);
        });

const RETRYADMIN = async (url, data) =>
    await axios
        .post(Constant.ADMIN_URL + url, data, {
            headers: {
                Authorization:
                    "Bearer " +
                    JSON.parse(sessionStorage.getItem("user_data")).Token,
            },
        })
        .then((res) => res)
        .catch((error) => {
            return FAILED_ERROR(error);
        });

//=====================================
// REQUEST
//=====================================
export const REQUEST = async (url, data) =>
    await axios
        .post(Constant.URL + url, data)
        .then((res) => res)
        .catch((error) => error.response.data);

//=====================================
// ADMIN API
//=====================================
export const ADMIN_API = async (url, data) =>
    await axios
        .post(Constant.ADMIN_URL + url, data, {
            headers: {
                Authorization:
                    "Bearer " +
                    JSON.parse(sessionStorage.getItem("user_data")).Token,
            },
        })
        .then((res) => res)
        .catch((error) => {
            if (error.response.data.message === "Token is expired") {
                return REFRESH(url, data, true);
            } else {
                return error.response.data;
            }
        });

//=====================================
// BLANK API
//=====================================
export const BLANK_API = async (url, data) =>
    await axios
        .post(Constant.BLANK_URL + url, data)
        .then((res) => res)
        .catch((error) => error.response.data);
