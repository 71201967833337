import React from "react";
import { connect } from "react-redux";
import { StyleSheet, css } from "aphrodite";
import { Input, Label, Row, Col } from "reactstrap";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import {
    fetchTeamsSelect,
    fetchUnitsSelect,
    searchSeals,
    setSealSearchTerms,
    fetchSealSearchSelect,
} from "actions/Carseals";
import { setLoader } from "actions/App";
import {
    fetchClassificationsListSelect,
    fetchSitesList,
} from "actions/Settings";

import Tooltip from "components/Tooltips";
import CSV from "components/Export";

class SearchUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchSealTerms: props.searchSealTerms,
        };
    }
    componentDidMount() {
        const {
            fetchTeamsSelect,
            fetchUnitsSelect,
            fetchClassificationsListSelect,
            fetchSitesList,
            fetchSealSearchSelect,
        } = this.props;
        fetchTeamsSelect();
        fetchUnitsSelect();
        fetchClassificationsListSelect();
        fetchSitesList();
        fetchSealSearchSelect();
    }
    handleSearch = () => {
        const { searchSeals, setLoader } = this.props;
        let { searchSealTerms } = this.state;
        searchSeals(searchSealTerms);
        setLoader(false);
    };
    handleNav = (e) => {
        this.props.history.push(`/app/carseals/create`);
        e.stopPropagation();
    };
    keyPress = (e) => {
        if (e.keyCode === 13) {
            this.handleSearch();
        }
    };
    updateData = (e) => {
        const { setSealSearchTerms } = this.props;
        let updateData = { ...this.state.searchSealTerms };
        if (e.target.type === "checkbox") {
            if (e.target.checked) {
                updateData[e.target.name] = true;
                setSealSearchTerms(updateData);
                this.setState({
                    searchSealTerms: updateData,
                    changesMade: true,
                });
            } else {
                updateData[e.target.name] = false;
                setSealSearchTerms(updateData);
                this.setState({
                    searchSealTerms: updateData,
                    changesMade: true,
                });
            }
        } else if (e.target.type === "date") {
            updateData[e.target.name] = e.target.value;
            setSealSearchTerms(updateData);
            this.setState({ searchSealTerms: updateData, changesMade: true });
        } else {
            updateData[e.target.name] = e.target.value;
            setSealSearchTerms(updateData);
            this.setState({ searchSealTerms: updateData, changesMade: true });
        }
    };
    clearField = (target) => {
        const { setSealSearchTerms } = this.props;
        let updateData = { ...this.state.searchSealTerms };
        updateData[target] = "";
        setSealSearchTerms(updateData);
        this.setState({ searchSealTerms: updateData, changesMade: true });
    };
    clearFields = () => {
        let newSearchSealTerms = {
            TypeUnitId: 0,
            TypeTeamId: 0,
            Location: "",
            LocationIdentifier: "",
            TypeClassificationId: 0,
            MocPlaceName: "",
            MocPlaceLink: "",
            MocRemoveName: "",
            MocRemoveLink: "",
            Purpose: "",
            Note: "",
            PnIdName: "",
            SecondaryPnIdName: "",
            SiteId: "",
            HasFlare: "",
            HasFlareModel: "",
            HasReliefPath: "",
            IsSis: "",
            StartDate: "",
            EndDate: "",
            PlacedStatus: "",
            PlacedById: "",
            PositionId: 0,
            LockLabel: "",
        };
        setSealSearchTerms(newSearchSealTerms);
        this.setState({ searchSealTerms: newSearchSealTerms });
        this.props.searchSeals(newSearchSealTerms);
    };
    render() {
        let { searchSealTerms } = this.state;
        const {
            intl,
            teamsSelectList,
            unitsSelectList,
            classificationsListSelect,
            sitesList,
            width,
            sealSearchUsers,
            sealSearchPositions,
            searchedSeals,
            authUser,
            theme,
        } = this.props;
        this.styles = styles(theme);
        let exportList = searchedSeals
            ? searchedSeals.map((a) => Object.assign({}, a))
            : [];
        exportList.map((item) => {
            delete item.Icon;
            delete item.Id;
            return item;
        });
        return (
            searchSealTerms && (
                <div>
                    <div
                        className={css(this.styles.searchWrapper)}
                        onKeyDown={(e) => this.keyPress(e)}
                    >
                        <Col lg={12}>
                            {width < 992 && (
                                <Row>
                                    <Col
                                        lg={12}
                                        className={css(this.styles.flexRow)}
                                    >
                                        <div>
                                            <i
                                                id="searchSeal"
                                                className={`material-icons ${css(
                                                    this.styles.searchIcon
                                                )}`}
                                                onClick={() =>
                                                    this.handleSearch()
                                                }
                                            >
                                                search
                                            </i>
                                            <Tooltip
                                                message={"comp.search.default"}
                                                target="searchSeal"
                                            />
                                        </div>
                                        <div>
                                            <i
                                                id="resetSealSearch"
                                                className={`material-icons ${css(
                                                    this.styles.searchIcon
                                                )}`}
                                                onClick={() =>
                                                    this.clearFields()
                                                }
                                            >
                                                low_priority
                                            </i>
                                            <Tooltip
                                                message={
                                                    "comp.resetSearch.default"
                                                }
                                                target="resetSealSearch"
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            )}
                            <Row>
                                <Col lg={10}>
                                    <Row>
                                        {width > 800 && (
                                            <Col
                                                lg={3}
                                                className={css(
                                                    this.styles.flexRow
                                                )}
                                            >
                                                <label
                                                    className={css(
                                                        this.styles
                                                            .labelDropdown
                                                    )}
                                                >
                                                    {this.props.intl.formatMessage(
                                                        {
                                                            id: "pages.teams.Type",
                                                        }
                                                    )}
                                                </label>
                                                <Input
                                                    className={css(
                                                        this.styles.inputSelect
                                                    )}
                                                    name="TypeTeamId"
                                                    type="select"
                                                    placeholder={intl.formatMessage(
                                                        {
                                                            id: "form.selectTeam",
                                                        }
                                                    )}
                                                    onChange={this.updateData}
                                                    value={
                                                        searchSealTerms.TypeTeamId ||
                                                        ""
                                                    }
                                                >
                                                    <option value={""}>
                                                        {intl.formatMessage({
                                                            id: "form.select",
                                                        })}
                                                    </option>
                                                    {teamsSelectList.map(
                                                        (team) => (
                                                            <option
                                                                key={
                                                                    "team" +
                                                                    team.Id
                                                                }
                                                                value={
                                                                    team.Id ||
                                                                    ""
                                                                }
                                                            >
                                                                {team.TypeName}
                                                            </option>
                                                        )
                                                    )}
                                                </Input>
                                            </Col>
                                        )}
                                        {width > 800 && (
                                            <Col
                                                lg={3}
                                                className={css(
                                                    this.styles.flexRow
                                                )}
                                            >
                                                <label
                                                    className={css(
                                                        this.styles
                                                            .labelDropdown
                                                    )}
                                                >
                                                    {this.props.intl.formatMessage(
                                                        {
                                                            id: "pages.Unit.Type",
                                                        }
                                                    )}
                                                </label>
                                                <Input
                                                    className={css(
                                                        this.styles.inputSelect
                                                    )}
                                                    name="TypeUnitId"
                                                    type="select"
                                                    placeholder={intl.formatMessage(
                                                        {
                                                            id: "form.selectUnit",
                                                        }
                                                    )}
                                                    onChange={this.updateData}
                                                    value={
                                                        searchSealTerms.TypeUnitId ||
                                                        ""
                                                    }
                                                >
                                                    <option value={""}>
                                                        {intl.formatMessage({
                                                            id: "form.select",
                                                        })}
                                                    </option>
                                                    {unitsSelectList.map(
                                                        (unit) => (
                                                            <option
                                                                key={
                                                                    "unit" +
                                                                    unit.Id
                                                                }
                                                                value={
                                                                    unit.Id ||
                                                                    ""
                                                                }
                                                            >
                                                                {unit.TypeName}
                                                            </option>
                                                        )
                                                    )}
                                                </Input>
                                            </Col>
                                        )}
                                        {width > 800 && (
                                            <Col
                                                lg={3}
                                                className={css(
                                                    this.styles.flexRow
                                                )}
                                            >
                                                <label
                                                    className={css(
                                                        this.styles.labelText
                                                    )}
                                                >
                                                    {this.props.intl.formatMessage(
                                                        {
                                                            id: "form.locationIdentifier",
                                                        }
                                                    )}
                                                </label>
                                                <Input
                                                    name="LocationIdentifier"
                                                    type="text"
                                                    className={css(
                                                        this.styles.inputText
                                                    )}
                                                    onChange={(e) =>
                                                        this.updateData(e)
                                                    }
                                                    value={
                                                        searchSealTerms.LocationIdentifier
                                                    }
                                                />
                                                {searchSealTerms.LocationIdentifier !==
                                                    "" && (
                                                    <i
                                                        className={`material-icons ${css(
                                                            this.styles
                                                                .closeIcon
                                                        )}`}
                                                        onClick={() =>
                                                            this.clearField(
                                                                "LocationIdentifier"
                                                            )
                                                        }
                                                    >
                                                        close
                                                    </i>
                                                )}
                                            </Col>
                                        )}
                                        {width > 800 && (
                                            <Col
                                                lg={3}
                                                className={css(
                                                    this.styles.flexRow
                                                )}
                                            >
                                                <label
                                                    className={css(
                                                        this.styles.labelText
                                                    )}
                                                >
                                                    {this.props.intl.formatMessage(
                                                        { id: "form.location" }
                                                    )}
                                                </label>
                                                <Input
                                                    name="Location"
                                                    type="text"
                                                    className={css(
                                                        this.styles.inputText
                                                    )}
                                                    onChange={(e) =>
                                                        this.updateData(e)
                                                    }
                                                    value={
                                                        searchSealTerms.Location
                                                    }
                                                />
                                                {searchSealTerms.Location !==
                                                    "" && (
                                                    <i
                                                        className={`material-icons ${css(
                                                            this.styles
                                                                .closeIcon
                                                        )}`}
                                                        onClick={() =>
                                                            this.clearField(
                                                                "Location"
                                                            )
                                                        }
                                                    >
                                                        close
                                                    </i>
                                                )}
                                            </Col>
                                        )}
                                    </Row>
                                </Col>
                                {width > 800 && (
                                    <Col lg={2}>
                                        {width > 991 && (
                                            <Row>
                                                <Col
                                                    lg={12}
                                                    className={css(
                                                        this.styles.flexRow
                                                    )}
                                                    style={{
                                                        justifyContent:
                                                            "flex-end",
                                                    }}
                                                >
                                                    {width > 800 && (
                                                        <div>
                                                            <i
                                                                id="searchSeal"
                                                                className={`material-icons ${css(
                                                                    this.styles
                                                                        .searchIcon
                                                                )}`}
                                                                onClick={() =>
                                                                    this.handleSearch()
                                                                }
                                                            >
                                                                search
                                                            </i>
                                                            <Tooltip
                                                                message={
                                                                    "comp.search.default"
                                                                }
                                                                target="searchSeal"
                                                            />
                                                        </div>
                                                    )}
                                                    {width > 800 && (
                                                        <div>
                                                            <i
                                                                id="resetSealSearch"
                                                                className={`material-icons ${css(
                                                                    this.styles
                                                                        .searchIcon
                                                                )}`}
                                                                onClick={() =>
                                                                    this.clearFields()
                                                                }
                                                            >
                                                                low_priority
                                                            </i>
                                                            <Tooltip
                                                                message={
                                                                    "comp.resetSearch.default"
                                                                }
                                                                target="resetSealSearch"
                                                            />
                                                        </div>
                                                    )}
                                                    {width > 800 &&
                                                        authUser.Permissions.includes(
                                                            "ExportSecurePoints"
                                                        ) && (
                                                            <div>
                                                                <CSV
                                                                    data={
                                                                        exportList
                                                                    }
                                                                    margin={
                                                                        "0 12px 0 0"
                                                                    }
                                                                />
                                                                <Tooltip
                                                                    message={
                                                                        "comp.button.export"
                                                                    }
                                                                    target="exportFile"
                                                                />
                                                            </div>
                                                        )}
                                                    {width > 800 &&
                                                        authUser.Permissions.includes(
                                                            "SealCreate"
                                                        ) && (
                                                            <div
                                                                onClick={
                                                                    this
                                                                        .handleNav
                                                                }
                                                            >
                                                                <i
                                                                    id="addOne"
                                                                    className="material-icons"
                                                                    style={{
                                                                        fontSize: 24,
                                                                        color: theme
                                                                            .color
                                                                            .themeColor,
                                                                        cursor: "pointer",
                                                                    }}
                                                                >
                                                                    add_circle_outline
                                                                </i>
                                                                <Tooltip
                                                                    message={
                                                                        "comp.form.add"
                                                                    }
                                                                    target="addOne"
                                                                />
                                                            </div>
                                                        )}
                                                </Col>
                                            </Row>
                                        )}
                                    </Col>
                                )}
                            </Row>
                        </Col>
                    </div>
                    <div>
                        <Row lg={12} className={css(this.styles.advancedRow)}>
                            <Col
                                className={css(
                                    this.styles.flexRow,
                                    this.styles.fourParts
                                )}
                            >
                                <Label className={css(this.styles.labelText)}>
                                    {this.props.intl.formatMessage({
                                        id: "form.label",
                                    })}
                                </Label>
                                <Input
                                    name="LockLabel"
                                    type="text"
                                    className={css(this.styles.inputText)}
                                    onChange={(e) => this.updateData(e)}
                                    value={searchSealTerms.LockLabel}
                                />
                                {searchSealTerms.LockLabel !== "" && (
                                    <i
                                        className={`material-icons ${css(
                                            this.styles.closeIcon
                                        )}`}
                                        onClick={() =>
                                            this.clearField("LockLabel")
                                        }
                                    >
                                        close
                                    </i>
                                )}
                            </Col>
                            <Col
                                className={css(
                                    this.styles.flexRow,
                                    this.styles.fourParts
                                )}
                            >
                                <Label className={css(this.styles.labelText)}>
                                    {this.props.intl.formatMessage({
                                        id: "form.note",
                                    })}
                                </Label>
                                <Input
                                    name="Note"
                                    type="text"
                                    className={css(this.styles.inputText)}
                                    onChange={(e) => this.updateData(e)}
                                    value={searchSealTerms.Note}
                                />
                                {searchSealTerms.Note !== "" && (
                                    <i
                                        className={`material-icons ${css(
                                            this.styles.closeIcon
                                        )}`}
                                        onClick={() => this.clearField("Note")}
                                    >
                                        close
                                    </i>
                                )}
                            </Col>
                            <Col
                                className={css(
                                    this.styles.flexRow,
                                    this.styles.fourParts
                                )}
                            >
                                <Label className={css(this.styles.labelText)}>
                                    {this.props.intl.formatMessage({
                                        id: "form.purpose",
                                    })}
                                </Label>
                                <Input
                                    name="Purpose"
                                    type="text"
                                    className={css(this.styles.inputText)}
                                    onChange={(e) => this.updateData(e)}
                                    value={searchSealTerms.Purpose}
                                />
                                {searchSealTerms.Purpose !== "" && (
                                    <i
                                        className={`material-icons ${css(
                                            this.styles.closeIcon
                                        )}`}
                                        onClick={() =>
                                            this.clearField("Purpose")
                                        }
                                    >
                                        close
                                    </i>
                                )}
                            </Col>
                            <Col
                                className={css(
                                    this.styles.flexRow,
                                    this.styles.fourParts
                                )}
                            >
                                <Label
                                    className={css(this.styles.labelDropdown)}
                                >
                                    {this.props.intl.formatMessage({
                                        id: "comp.sites.Header",
                                    })}
                                </Label>
                                <Input
                                    className={css(this.styles.inputSelect)}
                                    name="SiteId"
                                    type="select"
                                    placeholder={intl.formatMessage({
                                        id: "form.selectSite",
                                    })}
                                    onChange={this.updateData}
                                    value={searchSealTerms.SiteId || ""}
                                >
                                    <option value={""}>
                                        {intl.formatMessage({
                                            id: "form.select",
                                        })}
                                    </option>
                                    {sitesList.map((site) => (
                                        <option
                                            key={"site" + site.Id}
                                            value={site.Id || ""}
                                        >
                                            {site.SiteName}
                                        </option>
                                    ))}
                                </Input>
                            </Col>
                        </Row>
                        <Row lg={12} className={css(this.styles.advancedRow)}>
                            <Col
                                className={css(
                                    this.styles.flexRow,
                                    this.styles.fourParts
                                )}
                            >
                                <Label className={css(this.styles.labelText)}>
                                    {this.props.intl.formatMessage({
                                        id: "form.PnId",
                                    })}
                                </Label>
                                <Input
                                    name="PnIdName"
                                    type="text"
                                    className={css(this.styles.inputText)}
                                    onChange={(e) => this.updateData(e)}
                                    value={searchSealTerms.PnIdName}
                                />
                                {searchSealTerms.PnIdName !== "" && (
                                    <i
                                        className={`material-icons ${css(
                                            this.styles.closeIcon
                                        )}`}
                                        onClick={() =>
                                            this.clearField("PnIdName")
                                        }
                                    >
                                        close
                                    </i>
                                )}
                            </Col>
                            <Col
                                className={css(
                                    this.styles.flexRow,
                                    this.styles.fourParts
                                )}
                            >
                                <Label className={css(this.styles.labelText)}>
                                    {this.props.intl.formatMessage({
                                        id: "form.secondaryPnId",
                                    })}
                                </Label>
                                <Input
                                    name="SecondaryPnIdName"
                                    type="text"
                                    className={css(this.styles.inputText)}
                                    onChange={(e) => this.updateData(e)}
                                    value={searchSealTerms.SecondaryPnIdName}
                                />
                                {searchSealTerms.SecondaryPnIdName !== "" && (
                                    <i
                                        className={`material-icons ${css(
                                            this.styles.closeIcon
                                        )}`}
                                        onClick={() =>
                                            this.clearField("SecondaryPnIdName")
                                        }
                                    >
                                        close
                                    </i>
                                )}
                            </Col>
                            <Col
                                className={css(
                                    this.styles.flexRow,
                                    this.styles.fourParts
                                )}
                            >
                                <Label
                                    className={css(this.styles.labelDropdown)}
                                >
                                    {this.props.intl.formatMessage({
                                        id: "pages.Classification.Type",
                                    })}
                                </Label>
                                <Input
                                    className={css(this.styles.inputSelect)}
                                    name="TypeClassificationId"
                                    type="select"
                                    placeholder={intl.formatMessage({
                                        id: "form.selectClassification",
                                    })}
                                    onChange={this.updateData}
                                    value={
                                        searchSealTerms.TypeClassificationId ||
                                        ""
                                    }
                                >
                                    <option value={""}>
                                        {intl.formatMessage({
                                            id: "form.select",
                                        })}
                                    </option>
                                    {classificationsListSelect.map(
                                        (classification) => (
                                            <option
                                                key={
                                                    "classification" +
                                                    classification.Id
                                                }
                                                value={classification.Id || ""}
                                            >
                                                {classification.TypeName}
                                            </option>
                                        )
                                    )}
                                </Input>
                            </Col>
                            <Col
                                className={css(
                                    this.styles.flexRow,
                                    this.styles.fourParts
                                )}
                            >
                                <Label
                                    className={css(this.styles.labelDropdown)}
                                    for="StartDate"
                                >
                                    {intl.formatMessage({ id: "form.start" })}*
                                </Label>
                                <Input
                                    onChange={this.updateData}
                                    type="date"
                                    name="StartDate"
                                    id="StartDate"
                                    placeholder="date placeholder"
                                    className={css(
                                        this.styles.inputSelect,
                                        this.styles.dateSelect
                                    )}
                                    value={searchSealTerms.StartDate}
                                />
                            </Col>
                        </Row>
                        <Row lg={12} className={css(this.styles.advancedRow)}>
                            <Col
                                className={css(
                                    this.styles.flexRow,
                                    this.styles.fourParts
                                )}
                            >
                                <label
                                    className={css(this.styles.labelDropdown)}
                                >
                                    {this.props.intl.formatMessage({
                                        id: "form.placedBy",
                                    })}
                                </label>
                                <Input
                                    className={css(this.styles.inputSelect)}
                                    name="PlacedById"
                                    type="select"
                                    placeholder={intl.formatMessage({
                                        id: "form.placedBy",
                                    })}
                                    onChange={this.updateData}
                                    value={searchSealTerms.PlacedById || ""}
                                >
                                    <option value={""}>
                                        {intl.formatMessage({
                                            id: "form.select",
                                        })}
                                    </option>
                                    {sealSearchUsers &&
                                        sealSearchUsers.map((user) => (
                                            <option
                                                key={"user" + user.Id}
                                                value={user.Id || ""}
                                            >
                                                {user.Name}
                                            </option>
                                        ))}
                                </Input>
                            </Col>
                            <Col
                                className={css(
                                    this.styles.flexRow,
                                    this.styles.fourParts
                                )}
                            >
                                <Label
                                    className={css(this.styles.labelDropdown)}
                                >
                                    {this.props.intl.formatMessage({
                                        id: "form.placedStatus",
                                    })}
                                </Label>
                                <Input
                                    className={css(this.styles.inputSelect)}
                                    name="PlacedStatus"
                                    type="select"
                                    placeholder={intl.formatMessage({
                                        id: "form.action",
                                    })}
                                    onChange={this.updateData}
                                    value={searchSealTerms.PlacedStatus || ""}
                                >
                                    <option value={""}>
                                        {intl.formatMessage({
                                            id: "form.select",
                                        })}
                                    </option>
                                    <option value={"inposition"}>
                                        {intl.formatMessage({
                                            id: "form.inPosition",
                                        })}
                                    </option>
                                    <option value={"outofposition"}>
                                        {intl.formatMessage({
                                            id: "form.outOfPosition",
                                        })}
                                    </option>
                                    <option value={"notplaced"}>
                                        {intl.formatMessage({
                                            id: "form.notPlaced",
                                        })}
                                    </option>
                                </Input>
                            </Col>
                            <Col
                                className={css(
                                    this.styles.flexRow,
                                    this.styles.fourParts
                                )}
                            >
                                <Label
                                    className={css(this.styles.labelDropdown)}
                                >
                                    {this.props.intl.formatMessage({
                                        id: "form.position",
                                    })}
                                </Label>
                                <Input
                                    className={css(this.styles.inputSelect)}
                                    name="PositionId"
                                    type="select"
                                    placeholder={intl.formatMessage({
                                        id: "form.position",
                                    })}
                                    onChange={this.updateData}
                                    value={searchSealTerms.PositionId || ""}
                                >
                                    <option value={""}>
                                        {intl.formatMessage({
                                            id: "form.select",
                                        })}
                                    </option>
                                    {sealSearchPositions &&
                                        sealSearchPositions.map((position) => (
                                            <option
                                                key={"position" + position.Id}
                                                value={position.Id || ""}
                                            >
                                                {position.TypeName}
                                            </option>
                                        ))}
                                </Input>
                            </Col>
                            <Col lg={3} className={css(this.styles.flexRow)}>
                                <Label
                                    className={css(this.styles.labelDropdown)}
                                    for="EndDate"
                                >
                                    {intl.formatMessage({ id: "form.end" })}*
                                </Label>
                                <Input
                                    onChange={this.updateData}
                                    type="date"
                                    name="EndDate"
                                    id="EndDate"
                                    placeholder="date placeholder"
                                    className={css(
                                        this.styles.inputSelect,
                                        this.styles.dateSelect
                                    )}
                                    value={searchSealTerms.EndDate}
                                />
                            </Col>
                        </Row>
                    </div>
                </div>
            )
        );
    }
}

SearchUser.defaultProps = {
    searchTitle: "blank",
    searchType: ["name"],
    data: [],
};

const mapStateToProps = ({ app, auth, carseals, settings }) => {
    const { theme, width } = app;
    const { authUser } = auth;
    const {
        teamsSelectList,
        unitsSelectList,
        searchSealTerms,
        sealSearchUsers,
        sealSearchPositions,
        searchedSeals,
    } = carseals;
    const { classificationsListSelect, sitesList } = settings;
    return {
        theme,
        width,
        teamsSelectList,
        unitsSelectList,
        searchSealTerms,
        classificationsListSelect,
        sitesList,
        sealSearchUsers,
        sealSearchPositions,
        searchedSeals,
        authUser,
    };
};

export default withRouter(
    injectIntl(
        connect(mapStateToProps, {
            fetchTeamsSelect,
            fetchUnitsSelect,
            searchSeals,
            setSealSearchTerms,
            setLoader,
            fetchClassificationsListSelect,
            fetchSitesList,
            fetchSealSearchSelect,
        })(SearchUser)
    )
);

const styles = (theme) =>
    StyleSheet.create({
        flexRow: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            height: "2.25rem",
            paddingLeft: 0,
        },
        fourParts: {
            width: "25%",
            maxWidth: "25%",
        },
        advancedRow: {
            margin: 0,
            padding: 0,
        },
        inputSelect: {
            border: "none",
            boxShadow: "none",
            borderRadius: 0,
            fontWeight: 400,
            fontSize: 12,
            letterSpacing: 2,
            border: "1px solid white",
            paddingTop: 0,
            paddingRight: 0,
            paddingBottom: 0,
            color: theme.color.themeColor,
            backgroundColor: theme.color.compColor,
            "::placeholder": {
                letterSpacing: 1,
                color: theme.color.fontColor,
                fontSize: 12,
            },
        },
        inputText: {
            border: "none",
            boxShadow: "none",
            borderRadius: 0,
            fontWeight: 400,
            fontSize: 12,
            letterSpacing: 2,
            marginBottom: 0,
            padding: 0,
            width: "100%",
            marginLeft: 5,
            color: theme.color.themeColor,
            borderBottom: `solid whitesmoke 1px`,
            backgroundColor: theme.color.compColor,
            "::placeholder": {
                letterSpacing: 1,
                color: theme.color.themeColor,
                fontSize: 12,
            },
        },
        searchWrapper: {
            position: "relative",
            display: "flex",
            flexDirection: "row",
        },
        searchIcon: {
            color: theme.color.themeColor,
            marginRight: 12,
            ":hover": {
                cursor: "pointer",
                opacity: ".8",
            },
        },
        closeIcon: {
            color: theme.color.fontColor,
            position: "absolute",
            top: 5,
            right: 5,
            fontSize: 16,
            ":hover": {
                cursor: "pointer",
                opacity: ".8",
            },
        },
        labelDropdown: {
            fontSize: 12,
            color: theme.color.fontColor,
            whiteSpace: "nowrap",
        },
        labelText: {
            fontSize: 12,
            color: theme.color.fontColor,
            whiteSpace: "nowrap",
        },
    });
