import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";
import { history } from "../store/history";
import { API } from "../api";
import {
    FETCH_SETTINGS,
    FETCH_ROLES_LIST,
    FETCH_ROLE,
    FETCH_PERMISSIONS_LIST,
    CREATE_ROLE,
    EDIT_SETTINGS,
    EDIT_ROLE,
    DELETE_ROLE,
    FETCH_ISOLATION_EQUIPMENT_LIST,
    FETCH_ISOLATION_HAZARDS_LIST,
    FETCH_SITES_LIST,
    FETCH_SITE_BY_ID,
    CREATE_SITE,
    EDIT_SITE,
    FETCH_TERMS,
    UPDATE_TERMS,
    CREATE_EQUIPMENT,
    UPLOAD_IMAGE,
    CREATE_HAZARD,
    FETCH_CLASSIFICATIONS_LIST,
    CREATE_CLASSIFICATION,
    FETCH_PERMITS_LIST,
    CREATE_PERMIT,
    FETCH_CLASSIFICATION_BY_ID,
    FETCH_PERMIT_BY_ID,
    FETCH_EQUIPMENT_BY_ID,
    FETCH_HAZARD_BY_ID,
    SET_HAZARD_INACTIVE,
    SET_EQUIPMENT_INACTIVE,
    SET_CLASSIFICATION_INACTIVE,
    SET_PERMIT_INACTIVE,
    SET_SITE_INACTIVE,
    EDIT_CLASSIFICATION,
    EDIT_PERMIT,
    EDIT_EQUIPMENT,
    FETCH_CLASSIFICATIONS_LIST_SELECT,
    EDIT_HAZARD,
} from "constants/ActionTypes";
import {
    fetchRolesList,
    setSettings,
    setRolesList,
    setRole,
    setPermissionsList,
    setIsolationEquipmentList,
    setIsolationHazardsList,
    setSitesList,
    setSite,
    setActiveSetting,
    setTerms,
    fetchTerms,
    setClassificationsList,
    setPermitsList,
    setClassification,
    setPermit,
    setEquipment,
    fetchHazardById,
    setHazard,
    fetchEquipmentById,
    fetchClassificationById,
    fetchPermitById,
    fetchSiteById,
    setClassificationsListSelect,
} from "actions/Settings";
import { userSignInSuccess, userSignOut, setAuthUser } from "actions/Auth";
import { showMessage, setLoader, setTermsMessages } from "actions/App";

const getTheme = (state) => state.app.theme;

function* requestSettings() {
    try {
        const settings = JSON.parse(
            sessionStorage.getItem("user_data")
        ).Settings;
        if (settings.message) {
            if (settings.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(settings.message, "message"));
            }
        } else {
            yield put(setSettings(settings));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestRolesList({ payload }) {
    try {
        const roles = yield call(API, payload.url, payload.data);
        if (roles.message) {
            if (roles.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(roles.message, "message"));
            }
        } else if (!roles.data) {
            yield put(setRolesList([]));
        } else {
            roles.data = roles.data.sort(function (a, b) {
                a = JSON.stringify(a["Name"]);
                b = JSON.stringify(b["Name"]);
                return a.localeCompare(b);
            });
            yield put(setRolesList(roles.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestRole({ payload }) {
    try {
        const role = yield call(API, payload.url, payload.data);
        if (role.message) {
            if (role.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(role.message, "message"));
                yield history.push("/app/home");
            }
        } else if (role === "") {
            yield put(showMessage("notifications.error.fail.noClient"));
            yield history.push("/app/home");
        } else {
            if (!role.data.Permissions) {
                role.data.Permissions = [];
            }
            yield put(setRole(role.data));
        }
    } catch (error) {
        console.warn(error);
        yield history.push("/app/home");
    }
}

function* requestPermissionsList({ payload }) {
    try {
        const permissions = yield call(API, payload.url, payload.data);
        if (permissions.message) {
            if (permissions.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(permissions.message, "message"));
            }
        } else if (!permissions.data) {
            yield put(setPermissionsList([]));
        } else {
            permissions.data = permissions.data.filter(
                (perm) =>
                    perm.Name != "InternalClientCreate" &&
                    perm.Name != "InternalClientList" &&
                    perm.Name != "InternalClientDetails" &&
                    perm.Name != "InternalClientNoteCreate"
            );
            permissions.data = permissions.data.sort(function (a, b) {
                a = JSON.stringify(a["Description"]);
                b = JSON.stringify(b["Description"]);
                return a.localeCompare(b);
            });
            yield put(setPermissionsList(permissions.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestCreateRole({ payload }) {
    try {
        const role = yield call(API, payload.url, payload.data);
        if (role.message) {
            if (role.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(role.message, "message"));
            }
        } else if (role.validationError) {
            var error = Object.keys(role.validationError).map(function (key) {
                return role.validationError[key];
            });
            yield put(showMessage(error, "message"));
        } else {
            yield put(showMessage("notifications.success.add", "success"));
            if (!payload.another) {
                yield put(setActiveSetting(1));
                yield history.push("/app/settings/site");
            }
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestEditSettings({ payload }) {
    try {
        const settings = yield call(API, payload.url, payload.data);
        if (settings.message) {
            if (settings.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(settings.message, "message"));
            }
        } else {
            yield put(showMessage("notifications.success.edit", "success"));
            let user = JSON.parse(sessionStorage.getItem("user_data"));
            user.Settings = payload.data;
            sessionStorage.setItem("user_data", JSON.stringify(user));
            yield put(userSignInSuccess(user));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestEditRole({ payload }) {
    try {
        const required = {
            Name: payload.data.Name,
            Description: payload.data.Description,
        };
        for (var key in required) {
            if (required[key] === undefined || required[key] === "") {
                yield put(
                    showMessage(`notifications.error.blank.${key}`, "warning")
                );
                return;
            }
        }
        // hiding this in case we need it later, but for now it sounds like we want to allow them to update a user role even with no perms selected
        // if(payload.data.Permissions.length === 0){
        //     yield put(showMessage(`notifications.error.blank.Permissions`));
        //     return;
        // }
        const role = yield call(API, payload.url, payload.data);
        if (role.message) {
            if (role.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(role.message, "message"));
            }
        } else {
            yield put(showMessage("notifications.success.edit", "success"));
            yield put(fetchRolesList());
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestDeleteRole({ payload }) {
    try {
        if (!payload.data) {
            yield put(showMessage(`notifications.error.permission.deny`));
            return;
        }
        const role = yield call(API, payload.url, payload.data);
        if (role.message) {
            if (role.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(role.message, "message"));
            }
        } else {
            if (role.data.Error != "") {
                yield put(showMessage(role.data.Error, "message"));
                yield put(fetchRolesList());
            } else {
                yield put(
                    showMessage("notifications.success.delete", "success")
                );
                yield put(fetchRolesList());
            }
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestIsolationEquipmentList({ payload }) {
    try {
        const equipment = yield call(API, payload.url, payload.data);
        if (equipment.message) {
            if (equipment.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(equipment.message, "message"));
            }
        } else if (!equipment.data) {
            yield put(setIsolationEquipmentList([]));
        } else {
            equipment.data = equipment.data.sort(function (a, b) {
                a = JSON.stringify(a["TypeName"]);
                b = JSON.stringify(b["TypeName"]);
                return a.localeCompare(b);
            });
            yield put(setIsolationEquipmentList(equipment.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestIsolationHazardsList({ payload }) {
    try {
        const hazards = yield call(API, payload.url, payload.data);
        if (hazards.message) {
            if (hazards.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(hazards.message, "message"));
            }
        } else if (!hazards.data) {
            yield put(setIsolationHazardsList([]));
        } else {
            hazards.data = hazards.data.sort(function (a, b) {
                a = JSON.stringify(a["TypeName"]);
                b = JSON.stringify(b["TypeName"]);
                return a.localeCompare(b);
            });
            yield put(setIsolationHazardsList(hazards.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestSitesList({ payload }) {
    try {
        const sites = yield call(API, payload.url, payload.data);
        if (sites.message) {
            if (sites.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(sites.message, "message"));
            }
        } else if (!sites.data) {
            yield put(setSitesList([]));
        } else {
            sites.data.map((s) => {
                if (!s.SiteName) {
                    s.SiteName = s.TypeName;
                }
            });
            sites.data = sites.data.sort(function (a, b) {
                a = JSON.stringify(a["SiteName"]);
                b = JSON.stringify(b["SiteName"]);
                return a.localeCompare(b);
            });
            yield put(setSitesList(sites.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestSite({ payload }) {
    try {
        const site = yield call(API, payload.url, payload.data);
        if (site.message) {
            if (site.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(site.message));
            }
        } else if (site.data === "") {
            yield put(showMessage("notifications.error.fail.notClientMachine"));
            yield history.push("/app/settings/site");
        } else {
            const theme = yield select(getTheme);
            site.data.map = [
                {
                    color: theme.color.themeColor,
                    lat:
                        site.data.LatLong !== ""
                            ? JSON.parse(site.data.LatLong.split(",")[0])
                            : "",
                    lng:
                        site.data.LatLong !== ""
                            ? JSON.parse(
                                  site.data.LatLong.split(",")[1].split(" ")[1]
                              )
                            : "",
                },
            ];
            yield put(setSite(site.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestCreateSite({ payload }) {
    try {
        if (payload.data.LatLong === "") {
            payload.data.LatLong = "40.409515207749735, -111.8676472012585";
        }
        const site = yield call(API, payload.url, payload.data);
        if (site.message) {
            if (site.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(site.message, "message"));
            }
        } else if (site.validationError) {
            var error = Object.keys(site.validationError).map(function (key) {
                return site.validationError[key];
            });
            yield put(showMessage(error, "message"));
        } else {
            yield put(showMessage("notifications.success.add", "success"));
            if (!payload.another) {
                yield put(setActiveSetting(2));
                yield history.push("/app/settings/site");
            }
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestEditSite({ payload }) {
    try {
        payload.data.TypeCountryId = Number(payload.data.TypeCountryId);
        const site = yield call(API, payload.url, payload.data);
        if (site.message) {
            if (site.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(site.message, "message"));
            }
        } else {
            yield put(showMessage("notifications.success.edit", "success"));
            yield put(setActiveSetting(2));
            yield history.push(`/app/settings/display/site/${site.data.Id}`);
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestTerms({ payload }) {
    try {
        const terms = yield call(API, payload.url, payload.data);
        if (terms.message) {
            if (terms.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(terms.message, "message"));
            }
        } else if (!terms.data) {
            yield put(setTerms([]));
        } else {
            yield put(setTerms(terms.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestUpdateTerms({ payload }) {
    let newUserData = JSON.parse(sessionStorage.getItem("user_data"));
    for (let i = 0; i < newUserData.Terms.length; i++) {
        if (
            newUserData.Terms[i] &&
            newUserData.Terms[i].DefaultTerm === payload.data.defaultTerm
        ) {
            newUserData.Terms[i] = {
                DefaultTerm: `${payload.data.defaultTerm}`,
                Singular: `${payload.data.singular}`,
                Plural: `${payload.data.plural}`,
            };
        }
    }
    try {
        const required = {
            Id: payload.data.id,
            Singular: payload.data.singular,
            Plural: payload.data.plural,
        };
        for (var key in required) {
            if (required[key] === undefined || required[key] === "") {
                yield put(
                    showMessage(`notifications.error.blank.${key}`, "warning")
                );
                return;
            }
        }
        let terms = `{"terms": [{"id":${payload.data.id},"singular":"${payload.data.singular}","plural":"${payload.data.plural}"}]}`;
        const term = yield call(API, payload.url, terms);
        if (term.message) {
            if (term.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(term.message, "message"));
            }
        } else if (!newUserData.Permissions.includes("AdminTermUpdate")) {
            yield put(
                showMessage("notifications.error.permission.deny", "warning")
            );
        } else {
            let oldTerms = JSON.parse(
                sessionStorage.getItem("user_data")
            ).Terms;
            yield sessionStorage.setItem(
                "user_data",
                JSON.stringify(newUserData)
            );
            yield put(setAuthUser(newUserData));
            yield put(fetchTerms());
            yield put(setTermsMessages(newUserData.Terms, oldTerms));
            yield put(showMessage("notifications.success.edit", "success"));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestCreateEquipment({ payload }) {
    try {
        const equipment = yield call(API, payload.url, payload.data);
        if (equipment.message) {
            if (equipment.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(equipment.message, "message"));
            }
        } else if (equipment.validationError) {
            var error = Object.keys(equipment.validationError).map(function (
                key
            ) {
                return equipment.validationError[key];
            });
            yield put(showMessage(error, "message"));
        } else {
            yield put(showMessage("notifications.success.add", "success"));
            if (!payload.another) {
                yield put(setActiveSetting(5));
                yield history.push("/app/settings/site");
            }
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestUploadImage({ payload }) {
    const formData = new FormData();
    formData.append("file", payload.data.file);
    formData.append("filename", payload.data.filename);
    try {
        const image = yield call(API, payload.url, formData);
        if (image.message) {
            if (image.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(image.message, "message"));
            }
        } else if (image.validationError) {
            var error = Object.keys(image.validationError).map(function (key) {
                return image.validationError[key];
            });
            yield put(showMessage(error, "message"));
        } else {
            yield put(showMessage("notifications.success.add", "success"));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestCreateHazard({ payload }) {
    try {
        const hazard = yield call(API, payload.url, payload.data);
        if (hazard.message) {
            if (hazard.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(hazard.message, "message"));
            }
        } else if (hazard.validationError) {
            var error = Object.keys(hazard.validationError).map(function (key) {
                return hazard.validationError[key];
            });
            yield put(showMessage(error, "message"));
        } else {
            yield put(showMessage("notifications.success.add", "success"));
            if (!payload.another) {
                yield put(setActiveSetting(4));
                yield history.push("/app/settings/site");
            }
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestClassificationsList({ payload }) {
    try {
        const classifications = yield call(API, payload.url, payload.data);
        if (classifications.message) {
            if (classifications.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(classifications.message, "message"));
            }
        } else if (!classifications.data) {
            yield put(setClassificationsList([]));
        } else {
            classifications.data = classifications.data.sort(function (a, b) {
                a = JSON.stringify(a["TypeName"]);
                b = JSON.stringify(b["TypeName"]);
                return a.localeCompare(b);
            });
            yield put(setClassificationsList(classifications.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestCreateClassification({ payload }) {
    try {
        const classification = yield call(API, payload.url, payload.data);
        if (classification.message) {
            if (classification.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(classification.message, "message"));
            }
        } else if (classification.validationError) {
            var error = Object.keys(classification.validationError).map(
                function (key) {
                    return classification.validationError[key];
                }
            );
            yield put(showMessage(error, "message"));
        } else {
            yield put(showMessage("notifications.success.add", "success"));
            if (!payload.another) {
                yield put(setActiveSetting(10));
                yield history.push("/app/settings/site");
            }
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestPermitsList({ payload }) {
    try {
        const permits = yield call(API, payload.url, payload.data);
        if (permits.message) {
            if (permits.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(permits.message, "message"));
            }
        } else if (!permits.data) {
            yield put(setPermitsList([]));
        } else {
            permits.data = permits.data.sort(function (a, b) {
                a = JSON.stringify(a["TypeName"]);
                b = JSON.stringify(b["TypeName"]);
                return a.localeCompare(b);
            });
            yield put(setPermitsList(permits.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestCreatePermit({ payload }) {
    try {
        const permit = yield call(API, payload.url, payload.data);
        if (permit.message) {
            if (permit.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(permit.message, "message"));
            }
        } else if (permit.validationError) {
            var error = Object.keys(permit.validationError).map(function (key) {
                return permit.validationError[key];
            });
            yield put(showMessage(error, "message"));
        } else {
            yield put(showMessage("notifications.success.add", "success"));
            if (!payload.another) {
                yield put(setActiveSetting(7));
                yield history.push("/app/settings/site");
            }
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestClassification({ payload }) {
    try {
        payload.data.Id = Number(payload.data.Id);
        const classification = yield call(API, payload.url, payload.data);
        if (classification.message) {
            if (classification.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(classification.message));
            }
        } else if (classification.data === "") {
            yield put(showMessage("notifications.error.fail.notClientMachine"));
            yield history.push("/app/settings/site");
        } else {
            yield put(setClassification(classification.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestPermit({ payload }) {
    try {
        payload.data.Id = Number(payload.data.Id);
        const permit = yield call(API, payload.url, payload.data);
        if (permit.message) {
            if (permit.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(permit.message));
            }
        } else if (permit.data === "") {
            yield put(showMessage("notifications.error.fail.notClientMachine"));
            yield history.push("/app/settings/site");
        } else {
            yield put(setPermit(permit.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestEquipment({ payload }) {
    try {
        payload.data.Id = Number(payload.data.Id);
        const equipment = yield call(API, payload.url, payload.data);
        if (equipment.message) {
            if (equipment.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(equipment.message));
            }
        } else if (equipment.data === "") {
            yield put(showMessage("notifications.error.fail.notClientMachine"));
            yield history.push("/app/settings/site");
        } else {
            yield put(setEquipment(equipment.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFetchHazardById({ payload }) {
    try {
        payload.data.Id = Number(payload.data.Id);
        const hazard = yield call(API, payload.url, payload.data);
        if (hazard.message) {
            if (hazard.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(hazard.message));
            }
        } else if (hazard.data === "") {
            yield put(showMessage("notifications.error.fail.notClientMachine"));
            yield history.push("/app/settings/site");
        } else {
            yield put(setHazard(hazard.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestEditClassification({ payload }) {
    try {
        payload.data.Id = Number(payload.data.Id);
        const classification = yield call(API, payload.url, payload.data);
        if (classification.message) {
            if (classification.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(classification.message, "message"));
            }
        } else {
            yield put(showMessage("notifications.success.edit", "success"));
            yield put(setActiveSetting(10));
            yield history.push(
                `/app/settings/display/classification/${payload.data.Id}`
            );
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestEditPermit({ payload }) {
    try {
        payload.data.Id = Number(payload.data.Id);
        const permit = yield call(API, payload.url, payload.data);
        if (permit.message) {
            if (permit.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(permit.message, "message"));
            }
        } else {
            yield put(showMessage("notifications.success.edit", "success"));
            yield put(setActiveSetting(11));
            yield history.push(
                `/app/settings/display/permit/${payload.data.Id}`
            );
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestEditEquipment({ payload }) {
    try {
        payload.data.Id = Number(payload.data.Id);
        const equipment = yield call(API, payload.url, payload.data);
        if (equipment.message) {
            if (equipment.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(equipment.message, "message"));
            }
        } else {
            yield put(showMessage("notifications.success.edit", "success"));
            yield put(setActiveSetting(9));
            yield history.push(
                `/app/settings/display/equipment/${payload.data.Id}`
            );
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestEditHazard({ payload }) {
    try {
        payload.data.Id = Number(payload.data.Id);
        const hazard = yield call(API, payload.url, payload.data);
        if (hazard.message) {
            if (hazard.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(hazard.message, "message"));
            }
        } else {
            yield put(showMessage("notifications.success.edit", "success"));
            yield put(setActiveSetting(4));
            yield history.push(
                `/app/settings/display/hazard/${payload.data.Id}`
            );
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestSetHazardInactive({ payload }) {
    try {
        payload.data.Id = Number(payload.data.Id);
        const hazard = yield call(API, payload.url, payload.data);
        if (hazard.message) {
            if (hazard.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(hazard.message, "message"));
            }
        } else {
            yield put(showMessage("notifications.success.edit", "success"));
            yield put(fetchHazardById(payload.data.Id));
            yield put(setLoader(false));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestSetEquipmentInactive({ payload }) {
    try {
        payload.data.Id = Number(payload.data.Id);
        const equipment = yield call(API, payload.url, payload.data);
        if (equipment.message) {
            if (equipment.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(equipment.message, "message"));
            }
        } else {
            yield put(showMessage("notifications.success.edit", "success"));
            yield put(fetchEquipmentById(payload.data.Id));
            yield put(setLoader(false));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestSetClassificationInactive({ payload }) {
    try {
        payload.data.Id = Number(payload.data.Id);
        const classification = yield call(API, payload.url, payload.data);
        if (classification.message) {
            if (classification.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(classification.message, "message"));
            }
        } else {
            yield put(showMessage("notifications.success.edit", "success"));
            yield put(fetchClassificationById(payload.data.Id));
            yield put(setLoader(false));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestSetPermitInactive({ payload }) {
    try {
        payload.data.Id = Number(payload.data.Id);
        const permit = yield call(API, payload.url, payload.data);
        if (permit.message) {
            if (permit.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(permit.message, "message"));
            }
        } else {
            yield put(showMessage("notifications.success.edit", "success"));
            yield put(fetchPermitById(payload.data.Id));
            yield put(setLoader(false));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestSetSiteInactive({ payload }) {
    try {
        const site = yield call(API, payload.url, payload.data);
        if (site.message) {
            if (site.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(site.message, "message"));
            }
        } else {
            yield put(showMessage("notifications.success.edit", "success"));
            yield put(fetchSiteById(payload.data.Id));
            yield put(setLoader(false));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestClassificationsListSelect({ payload }) {
    try {
        const classifications = yield call(API, payload.url, payload.data);
        if (classifications.message) {
            if (classifications.message === "Token is expired") {
                yield put(userSignOut());
            } else {
                yield put(showMessage(classifications.message, "message"));
            }
        } else if (!classifications.data) {
            yield put(setClassificationsListSelect([]));
        } else {
            classifications.data = classifications.data.sort(function (a, b) {
                a = JSON.stringify(a["TypeName"]);
                b = JSON.stringify(b["TypeName"]);
                return a.localeCompare(b);
            });
            yield put(setClassificationsListSelect(classifications.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

export function* selectSettings() {
    yield takeEvery(FETCH_SETTINGS, requestSettings);
}

export function* selectRolesList() {
    yield takeEvery(FETCH_ROLES_LIST, requestRolesList);
}

export function* selectRole() {
    yield takeEvery(FETCH_ROLE, requestRole);
}

export function* selectPermissionsList() {
    yield takeEvery(FETCH_PERMISSIONS_LIST, requestPermissionsList);
}

export function* createRole() {
    yield takeEvery(CREATE_ROLE, requestCreateRole);
}

export function* editSettings() {
    yield takeEvery(EDIT_SETTINGS, requestEditSettings);
}

export function* editRole() {
    yield takeEvery(EDIT_ROLE, requestEditRole);
}

export function* deleteRole() {
    yield takeEvery(DELETE_ROLE, requestDeleteRole);
}

export function* selectIsolationEquipmentList() {
    yield takeEvery(
        FETCH_ISOLATION_EQUIPMENT_LIST,
        requestIsolationEquipmentList
    );
}

export function* selectIsolationHazardsList() {
    yield takeEvery(FETCH_ISOLATION_HAZARDS_LIST, requestIsolationHazardsList);
}

export function* selectSitesList() {
    yield takeEvery(FETCH_SITES_LIST, requestSitesList);
}

export function* selectSiteById() {
    yield takeEvery(FETCH_SITE_BY_ID, requestSite);
}

export function* createSite() {
    yield takeEvery(CREATE_SITE, requestCreateSite);
}

export function* editSite() {
    yield takeEvery(EDIT_SITE, requestEditSite);
}

export function* selectTerms() {
    yield takeEvery(FETCH_TERMS, requestTerms);
}

export function* updateTerms() {
    yield takeEvery(UPDATE_TERMS, requestUpdateTerms);
}

export function* createEquipment() {
    yield takeEvery(CREATE_EQUIPMENT, requestCreateEquipment);
}

export function* uploadImage() {
    yield takeEvery(UPLOAD_IMAGE, requestUploadImage);
}

export function* createHazard() {
    yield takeEvery(CREATE_HAZARD, requestCreateHazard);
}

export function* selectClassificationsList() {
    yield takeEvery(FETCH_CLASSIFICATIONS_LIST, requestClassificationsList);
}

export function* createClassification() {
    yield takeEvery(CREATE_CLASSIFICATION, requestCreateClassification);
}

export function* selectPermitsList() {
    yield takeEvery(FETCH_PERMITS_LIST, requestPermitsList);
}

export function* createPermit() {
    yield takeEvery(CREATE_PERMIT, requestCreatePermit);
}

export function* selectClassificationById() {
    yield takeEvery(FETCH_CLASSIFICATION_BY_ID, requestClassification);
}

export function* selectPermitById() {
    yield takeEvery(FETCH_PERMIT_BY_ID, requestPermit);
}

export function* selectEquipmentById() {
    yield takeEvery(FETCH_EQUIPMENT_BY_ID, requestEquipment);
}

export function* selectFetchHazardById() {
    yield takeEvery(FETCH_HAZARD_BY_ID, requestFetchHazardById);
}

export function* selectSetHazardInactive() {
    yield takeEvery(SET_HAZARD_INACTIVE, requestSetHazardInactive);
}

export function* selectSetEquipmentInactive() {
    yield takeEvery(SET_EQUIPMENT_INACTIVE, requestSetEquipmentInactive);
}

export function* selectSetClassificationInactive() {
    yield takeEvery(
        SET_CLASSIFICATION_INACTIVE,
        requestSetClassificationInactive
    );
}

export function* selectSetPermitInactive() {
    yield takeEvery(SET_PERMIT_INACTIVE, requestSetPermitInactive);
}

export function* selectSetSiteInactive() {
    yield takeEvery(SET_SITE_INACTIVE, requestSetSiteInactive);
}

export function* editClassification() {
    yield takeEvery(EDIT_CLASSIFICATION, requestEditClassification);
}

export function* editPermit() {
    yield takeEvery(EDIT_PERMIT, requestEditPermit);
}

export function* editEquipment() {
    yield takeEvery(EDIT_EQUIPMENT, requestEditEquipment);
}

export function* editHazard() {
    yield takeEvery(EDIT_HAZARD, requestEditHazard);
}

export function* selectClassificationsListSelect() {
    yield takeEvery(
        FETCH_CLASSIFICATIONS_LIST_SELECT,
        requestClassificationsListSelect
    );
}

export default function* rootSaga() {
    yield all([
        fork(selectSettings),
        fork(selectRolesList),
        fork(selectRole),
        fork(selectPermissionsList),
        fork(createRole),
        fork(editSettings),
        fork(editRole),
        fork(deleteRole),
        fork(selectIsolationEquipmentList),
        fork(selectIsolationHazardsList),
        fork(selectSitesList),
        fork(selectSiteById),
        fork(createSite),
        fork(editSite),
        fork(selectTerms),
        fork(updateTerms),
        fork(createEquipment),
        fork(uploadImage),
        fork(createHazard),
        fork(selectClassificationsList),
        fork(createClassification),
        fork(selectPermitsList),
        fork(createPermit),
        fork(selectClassificationById),
        fork(selectPermitById),
        fork(selectEquipmentById),
        fork(selectFetchHazardById),
        fork(editClassification),
        fork(editPermit),
        fork(editEquipment),
        fork(editHazard),
        fork(selectSetHazardInactive),
        fork(selectSetEquipmentInactive),
        fork(selectSetClassificationInactive),
        fork(selectSetPermitInactive),
        fork(selectSetSiteInactive),
        fork(selectClassificationsListSelect),
    ]);
}
