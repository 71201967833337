import {
    SET_TEAMS,
    SET_TEAMS_SELECT,
    SET_UNITS,
    SET_UNITS_SELECT,
    SET_SEALS,
    SET_SEAL,
    SET_SITES_SEALS,
    SET_POSITIONS,
    SET_TEAM,
    SET_UNIT,
    SET_POSITION,
    SET_SEALS_ACTIVITY,
    SET_SEAL_ACTIVITY_FILTER,
    SET_ACTIVE_SEAL_TAB,
    SET_SEAL_ACTIVITY,
    SET_SEAL_SNAPSHOT,
    SET_SEARCHED_SEALS,
    SET_SEAL_SEARCH_TERMS,
    SET_SEAL_AUDIT_LIST,
    SET_AUDIT_SEARCH_TERMS,
    SET_SNAPSHOT_SEARCH_TERMS,
    SET_SEAL_FILTER,
    SET_POSITIONS_SELECT,
    SET_SEAL_SEARCH_USERS,
    SET_SEAL_SEARCH_POSITIONS,
    SET_SEAL_ACTIVITY_SEARCH_ACTIVITY_TYPES,
    SET_SEAL_ACTIVITY_SEARCH_LIST,
    SET_SEAL_ACTIVITY_SEARCH_TERMS,
    SET_SEAL_SEARCH_SEALS,
    SET_SEAL_SEARCH_DEVICES,
    SET_SEAL_LOCK,
} from "constants/ActionTypes";

import * as UTILS from "util";

const INIT_STATE = {
    teamsList: [],
    teamsSelectList: [],
    unitsList: [],
    unitsSelectList: [],
    sealsList: [],
    sealObject: {},
    siteSealsList: [],
    positionsList: [],
    teamObject: null,
    unitObject: null,
    positionObject: null,
    sealsActivity: [],
    sealSnapshot: null,
    activeSealTab: sessionStorage.getItem("activeSealTab") || "0",
    sealActivity: [],
    searchedSeals: [],
    searchedSealActivity: [],
    searchedSealAuditList: [],
    positionsListSelect: [],
    sealSearchUsers: [],
    sealSearchPositions: [],
    sealActivitySearchTypes: [],
    sealSearchSeals: [],
    sealSearchDevices: [],
    sealLockObject: null,
    sealLockFilterList: [
        {
            title: "comp.table.header.lock",
            width: "40%",
            active: false,
            sortable: true,
            sortTitle: "Label",
            order: false,
            mobile: true,
        },
        {
            title: "comp.table.header.siteName",
            width: "15%",
            active: false,
            sortable: true,
            sortTitle: "SiteName",
            order: false,
            mobile: false,
        },
        {
            title: "comp.table.header.serialNumber",
            width: "15%",
            active: false,
            sortable: true,
            sortTitle: "SerialNumber",
            order: false,
            mobile: false,
        },
        {
            title: "comp.table.header.macAddress",
            width: "15%",
            active: false,
            sortable: true,
            sortTitle: "MacAddress",
            order: false,
            mobile: false,
        },
        {
            title: "pages.carSeals.Type",
            width: "15%",
            active: false,
            sortable: true,
            sortTitle: "SealId",
            order: false,
            mobile: false,
        },
    ],
    searchSealLockTerms: {
        Label: "",
        SerialNumber: "",
        MacAddress: "",
        SiteId: "",
        IsActive: "1",
    },
    sealFilterList: [
        {
            title: "form.location",
            width: "22.29%",
            active: true,
            sortable: true,
            sortTitle: "Location",
            order: false,
            mobile: true,
        },
        {
            title: "form.locationId",
            width: "8%",
            active: false,
            sortable: true,
            sortTitle: "LocationIdentifier",
            order: false,
            mobile: false,
        },
        {
            title: "pages.Team.Type",
            width: "7.29%",
            active: false,
            sortable: true,
            sortTitle: "TypeTeamName",
            order: false,
            mobile: false,
        },
        {
            title: "pages.Unit.Type",
            width: "9.29%",
            active: false,
            sortable: true,
            sortTitle: "TypeUnitName",
            order: false,
            mobile: false,
        },
        {
            title: "form.label",
            width: "14.29%",
            active: false,
            sortable: true,
            sortTitle: "PlacedDeviceName",
            order: false,
            mobile: false,
        },
        {
            title: "form.position.default",
            width: "10.29%",
            active: false,
            sortable: true,
            sortTitle: "DefaultSealPositionName",
            order: false,
            mobile: false,
        },
        {
            title: "form.position",
            width: "10.29%",
            active: false,
            sortable: true,
            sortTitle: "PlacedPositionName",
            order: false,
            mobile: false,
        },
        {
            title: "form.placedBy",
            width: "14.29%",
            active: false,
            sortable: true,
            sortTitle: "PlacedByName",
            order: false,
            mobile: true,
        },
    ],
    auditFilterList: [
        {
            title: "form.location",
            width: "18.29%",
            active: false,
            sortable: true,
            sortTitle: "Location",
            order: false,
            mobile: true,
        },
        {
            title: "pages.Team.Type",
            width: "10.29%",
            active: false,
            sortable: true,
            sortTitle: "TeamName",
            order: false,
            mobile: false,
        },
        {
            title: "pages.Unit.Type",
            width: "14.29%",
            active: false,
            sortable: true,
            sortTitle: "UnitName",
            order: false,
            mobile: false,
        },
        {
            title: "form.locationIdentifier",
            width: "14.29%",
            active: false,
            sortable: true,
            sortTitle: "LocationIdentifier",
            order: false,
            mobile: false,
        },
        {
            title: "form.site",
            width: "14.29%",
            active: false,
            sortable: true,
            sortTitle: "SiteName",
            order: false,
            mobile: false,
        },
        {
            title: "form.distancePrevious",
            width: "14.29%",
            active: false,
            sortable: true,
            sortTitle: "DistanceFromPrevious",
            order: false,
            mobile: true,
        },
        {
            title: "form.auditFailed",
            width: "14.29%",
            active: false,
            sortable: true,
            sortTitle: "AuditFailed",
            order: false,
            mobile: true,
        },
    ],
    typeFilterList: [
        {
            title: "form.name",
            width: "23%",
            active: true,
            sortable: true,
            sortTitle: "TypeName",
            order: false,
            mobile: true,
        },
        {
            title: "comp.button.active",
            width: "20%",
            active: false,
            sortable: true,
            sortTitle: "IsActive",
            order: false,
            mobile: true,
        },
        {
            title: "comp.table.header.createdBy",
            width: "25%",
            active: false,
            sortable: true,
            sortTitle: "CreatedByName",
            order: false,
            mobile: false,
        },
        {
            title: "comp.table.header.dateCreated",
            width: "25%",
            active: false,
            sortable: true,
            sortTitle: "CreatedDate",
            style: { textAlign: "right" },
            order: false,
            mobile: false,
        },
    ],
    sealActivityFilterList: [
        {
            title: "comp.table.cell.activity",
            width: "10%",
            active: false,
            sortable: true,
            sortTitle: "ActivityTypeName",
            order: false,
            mobile: true,
        },
        {
            title: "comp.table.header.user",
            width: "16.667%",
            active: false,
            sortable: true,
            sortTitle: "ActivityByName",
            order: false,
            mobile: true,
        },
        {
            title: "form.device",
            width: "18.667%",
            active: false,
            sortable: true,
            sortTitle: "DeviceName",
            order: false,
            mobile: false,
        },
        {
            title: "form.location",
            width: "27%",
            active: false,
            sortable: true,
            sortTitle: "Location",
            order: false,
            mobile: false,
        },
        {
            title: "pages.Position.Type",
            width: "10%",
            active: false,
            sortable: true,
            sortTitle: "PositionName",
            style: { textAlign: "left" },
            order: false,
            mobile: false,
        },
        {
            title: "comp.table.header.date",
            width: "17.667%",
            active: true,
            sortable: true,
            sortTitle: "ActivityDate",
            style: { textAlign: "right" },
            order: true,
            mobile: true,
        },
    ],
    teamMemberFilterList: [
        {
            title: "pages.TeamMembers.Type",
            width: "16.667%",
            active: true,
            sortable: true,
            sortTitle: "Name",
            style: { textAlign: "left" },
            order: false,
            mobile: true,
        },
    ],
    searchSealTerms: {
        TypeUnitId: 0,
        TypeTeamId: 0,
        Location: "",
        LocationIdentifier: "",
        TypeClassificationId: 0,
        MocPlaceName: "",
        MocPlaceLink: "",
        MocRemoveName: "",
        MocRemoveLink: "",
        Purpose: "",
        Note: "",
        PnIdName: "",
        SecondaryPnIdName: "",
        SiteId: "",
        HasFlare: "",
        HasFlareModel: "",
        HasReliefPath: "",
        IsSis: "",
        StartDate: "",
        EndDate: "",
        PlacedStatus: "",
        PlacedById: "",
        PositionId: 0,
        LockLabel: "",
    },
    searchAuditTerms: {
        Location: "",
        LocationIdentifier: "",
        CreatedByName: "",
        TeamId: 0,
        UnitId: 0,
        SiteId: "",
        StartingLatLong: "",
        Failed: "",
    },
    searchSnapshotTerms: {
        Id: 0,
        SiteId: "",
    },
    sealActivityTerms: {
        Id: "",
        SealId: "",
        SystemTypeSealActivityId: 0,
        ActivityById: "",
        LockLabel: "",
        Location: "",
        PositionId: 0,
        StartDate: "",
        EndDate: "",
    },
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_SEAL_SNAPSHOT: {
            return {
                ...state,
                sealSnapshot: action.payload,
            };
        }
        case SET_TEAMS: {
            return {
                ...state,
                teamsList: action.payload.sort(function (a, b) {
                    if (isNaN(a.TypeName)) {
                        return a.TypeName.localeCompare(b.TypeName);
                    } else {
                        if (+a.TypeName > +b.TypeName) {
                            return 1;
                        } else return -1;
                    }
                }),
            };
        }
        case SET_TEAMS_SELECT: {
            return {
                ...state,
                teamsSelectList: UTILS.sortList(
                    false,
                    action.payload,
                    "TypeName"
                ),
            };
        }
        case SET_UNITS: {
            return {
                ...state,
                unitsList: action.payload,
            };
        }
        case SET_UNITS_SELECT: {
            return {
                ...state,
                unitsSelectList: action.payload,
            };
        }
        case SET_SEALS: {
            return {
                ...state,
                sealsList: action.payload,
            };
        }
        case SET_SEAL: {
            return {
                ...state,
                sealObject: action.payload,
            };
        }
        case SET_SITES_SEALS: {
            return {
                ...state,
                siteSealsList: action.payload,
            };
        }
        case SET_POSITIONS: {
            return {
                ...state,
                positionsList: action.payload,
            };
        }
        case SET_TEAM: {
            return {
                ...state,
                teamObject: action.payload,
            };
        }
        case SET_UNIT: {
            return {
                ...state,
                unitObject: action.payload,
            };
        }
        case SET_POSITION: {
            return {
                ...state,
                positionObject: action.payload,
            };
        }
        case SET_SEALS_ACTIVITY: {
            return {
                ...state,
                sealsActivity: action.payload,
            };
        }
        case SET_SEAL_ACTIVITY_FILTER: {
            return {
                ...state,
                sealActivityFilterList: action.payload,
            };
        }
        case SET_ACTIVE_SEAL_TAB: {
            return {
                ...state,
                activeSealTab: action.payload,
            };
        }
        case SET_SEAL_ACTIVITY: {
            return {
                ...state,
                sealActivity: action.payload,
            };
        }
        case SET_SEARCHED_SEALS: {
            return {
                ...state,
                searchedSeals: action.payload,
            };
        }
        case SET_SEAL_SEARCH_TERMS: {
            return {
                ...state,
                searchSealTerms: action.payload,
            };
        }
        case SET_SEAL_AUDIT_LIST: {
            return {
                ...state,
                searchedSealAuditList: action.payload,
            };
        }
        case SET_AUDIT_SEARCH_TERMS: {
            return {
                ...state,
                searchAuditTerms: action.payload,
            };
        }
        case SET_SNAPSHOT_SEARCH_TERMS: {
            return {
                ...state,
                searchSnapshotTerms: action.payload,
            };
        }
        case SET_SEAL_FILTER: {
            return {
                ...state,
                sealFilterList: action.payload,
            };
        }
        case SET_POSITIONS_SELECT: {
            return {
                ...state,
                positionsListSelect: action.payload,
            };
        }
        case SET_SEAL_SEARCH_USERS: {
            return {
                ...state,
                sealSearchUsers: action.payload,
            };
        }
        case SET_SEAL_SEARCH_POSITIONS: {
            return {
                ...state,
                sealSearchPositions: action.payload,
            };
        }
        case SET_SEAL_ACTIVITY_SEARCH_ACTIVITY_TYPES: {
            return {
                ...state,
                sealActivitySearchTypes: action.payload,
            };
        }
        case SET_SEAL_ACTIVITY_SEARCH_LIST: {
            return {
                ...state,
                searchedSealActivity: action.payload,
            };
        }
        case SET_SEAL_ACTIVITY_SEARCH_TERMS: {
            return {
                ...state,
                sealActivityTerms: action.payload,
            };
        }
        case SET_SEAL_SEARCH_SEALS: {
            return {
                ...state,
                sealSearchSeals: action.payload,
            };
        }
        case SET_SEAL_SEARCH_DEVICES: {
            return {
                ...state,
                sealSearchDevices: action.payload,
            };
        }
        case SET_SEAL_LOCK: {
            return {
                ...state,
                sealLockObject: action.payload,
            };
        }
        default:
            return state;
    }
};
