import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import App from "./App";
import Settings from "./Settings";
import Auth from "./Auth";
import Locks from "./Locks";
import Tickets from "./Tickets";
import Machines from "./Machines";
import Users from "./Users";
import Client from "./Client";
import Help from "./Help";
import Carseals from "./Carseals";
import Feedback from "./Feedback";
import RequestInfo from "./RequestInfo";
import { history } from "../store/history";

const reducers = combineReducers({
    app: App,
    settings: Settings,
    auth: Auth,
    locks: Locks,
    tickets: Tickets,
    machines: Machines,
    users: Users,
    client: Client,
    help: Help,
    carseals: Carseals,
    feedback: Feedback,
    requestInfo: RequestInfo,
    router: connectRouter(history),
});

export default reducers;
